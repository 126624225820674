//
// 프로그램 명    : plugins.ts
// 프로그램 설명  : 전역설정
//    
// 작성자         : 20211001     (주)UCRM / 정우현
// 수정이력)
//

import store from '@/store'

declare global {
    interface Window {
        $$broadcast: Function
    }
}

window.$$broadcast = function (eventName) {
    const args = [...arguments].splice(1)
    window.dispatchEvent(new CustomEvent(eventName, {
        detail: args
    }))
}

export default {
    install: (app, options) => {
        app.config.globalProperties.$isDev = process.env.NODE_ENV == 'development'
        // 홈 화면
        app.config.globalProperties.$homeUrl = '/app/user'

        app.config.globalProperties.$setTitle = title => {
            store.dispatch('setTitle', { data: title })
        }
        app.config.globalProperties.$setMainBtn = text => {
            store.dispatch('setMainBtn', { data: text })
        }

        app.config.globalProperties.$callbackMainBtn = () => { console.log('콜백 함수 초기화 필요') }
        app.config.globalProperties.$setMainBtnCallback = callback => {
            app.config.globalProperties.$callbackMainBtn = callback
        }

        app.config.globalProperties.$setSideTitle = title => {
            store.dispatch('setSideTitle', { data: title })
        }
        app.config.globalProperties.$setSideBtns = data => {
            /* sideBtns = [
             *     [
             *          { name: '', path: '' },
             *          { name: '', path: '' },
             *     ]
             * ]
            */
            store.dispatch('setSideBtns', { data })
        }

        // #region 좌측메뉴 트리 관련
        app.config.globalProperties.$setTreeSelect = data => {
            store.dispatch('setTreeSelect', { data })
        }
        app.config.globalProperties.$setTreeData = data => {
            /*
             * treeData = [
             *      { title: '', value: '' }
             * ]
             *
            */
            store.dispatch('setTreeData', { data })
        }
        app.config.globalProperties.$callbackTreeData = () => { console.log('콜백 함수 초기화 필요') }
        app.config.globalProperties.$setTreeDataCallback = callback => {
            app.config.globalProperties.$callbackTreeData = callback
        }
        //#endregion

        // #region 외부 함수용 콜백처리
        app.config.globalProperties.$$fn = {}
        app.config.globalProperties.$$on = (_app, eventName, fn) => {
            app.config.globalProperties.$$remove(eventName)

            if (!app.config.globalProperties.$$fn.hasOwnProperty(eventName)) {
                app.config.globalProperties.$$fn[eventName] = []
            }
            app.config.globalProperties.$$fn[eventName].push(fn)

            window.addEventListener(eventName, fn)

            if (_app && !_app.$options.beforeUnmount) {
                _app.$options.beforeUnmount = function () {
                    app.config.globalProperties.$$remove(eventName)
                }
            }
        }

        app.config.globalProperties.$$broadcast = function (eventName) {
            const args = [...arguments].splice(1)
            window.dispatchEvent(new CustomEvent(eventName, {
                detail: args
            }))
        }

        app.config.globalProperties.$$remove = (eventName) => {
            const fn = app.config.globalProperties.$$fn
            if (fn[eventName]) {
                fn[eventName].forEach((r) => {
                    window.removeEventListener(eventName, r)
                })
            }
        }
        //#endregion

        app.config.globalProperties.$movePage = page => {
            window.scrollTo(0, 0)
            app.config.globalProperties.$router.push(page)
        }

        app.config.globalProperties.$moveHelp = cateNm => {
            const data = store.getters.getHelpList
            app.config.globalProperties.$movePage(`/app/board/help/${data[cateNm]}`)
        }
        
        app.config.globalProperties.$callbackSideBtns = () => { console.log('콜백 함수 초기화 필요') }
        app.config.globalProperties.$setSideBtnsCallback = callback => {
            app.config.globalProperties.$callbackSideBtns = callback
        }

        // 연락처관련 LeftMenu에서 특정 그룹 선택시 매인 리스트 갱신에 필요한 함수등록
        app.config.globalProperties.$callbackGroupSelect = () => { console.log('콜백 함수 초기화 필요') }
        app.config.globalProperties.$setGroupSelectCallback = callback => {
            app.config.globalProperties.$callbackGroupSelect = callback
        }

        app.config.globalProperties.$filters = {
            range(item, reverse) {
                const result = []
                if (!reverse) {
                    for (var i = item[0]; i <= item[1]; i++) {
                        result.push(i)
                    }
                }
                else {
                    for (var i = item[0]; i >= item[1]; i--) {
                        result.push(i)
                    }
                }
                return result
            },
            /*
             * min: 시작 시간
             * max: 종료 시간
             * step: 시간 간격(분)
            */
            timeList(min, max, step) {
                const result = []
                for (let i = min; i < max; i++) {
                    for (let j = 0; j < (60 / step); j++) {
                        result.push(`${i.zf(2)}:${(step * j).zf(2)}:00`)
                    }
                }
                result.push(`${max.zf(2)}:00:00`)
                return result
            }
        }

        app
            .directive('drt-center', {
                // v-drt-center="{{v-if or v-show value}}"
                mounted(el, binding, vnode, parentVnode) {
                    const { value } = binding
                    const callback = (mutationList, observer) => {
                        el.style.left = '50%'
                        el.style.top = '50%'
                        el.style.marginLeft = `-${el.clientWidth / 2}px`
                        el.style.marginTop = `-${el.clientHeight / 2}px`
                        /*
                        if (observer) {
                            observer.disconnect()
                        }
                        if (observer2) {
                            observer2.disconnect()
                        }
                        */
                    }

                    /*
                     * const observer = new MutationObserver(callback)

                    const observer2 = new IntersectionObserver(callback)

                    observer.observe(el, {
                        childList: true,
                        subtree: false,
                    })

                    observer2.observe(el)

                    callback(null, null)
                    */

                    if (value) {
                        callback(null, null)
                    }
                }
            })
            .directive('drt-mnt', {
                mounted(el, binding) {
                    const { value } = binding
                    if (value instanceof Function) {
                        value()
                    }
                }
            })
            .directive('drt-caret-target', {
                mounted(el, binding, vnode) {
                    const { value } = binding
                    app.config.globalProperties[`$${value}`] = {
                        insert(text) {
                            text = text || ''
                            const target = el
                            if (target != null) {
                                const val = target.value
                                const prevStr = val.substring(0, target.selectionStart)
                                const afterStr = val.substring(target.selectionEnd, val.length)
                                const returnStr = '{0}{1}{2}'.fmt(prevStr, text, afterStr)

                                const pos = (prevStr.length + text.length)

                                target.value = returnStr

                                setTimeout(() => {
                                    target.focus()
                                    target.setSelectionRange(pos, pos)
                                })

                                vnode.props.onCaretUpdate({ target: el })
                            }
                        }
                    }

                }
            })
    }
}