

    import store from '@/store'
    import TmplLeftMenu from '@/types/tmpl_leftmenu'
    import JSDPageCtrl from '@/components/PageCtrl.vue';
    import TemplateService from '@/service/TemplateService'
    import utils from '@/helpers/utils'                         //공용으로 사용될 js함수를 import

    export default {
        name: 'tmpl_resv_list',
        components: {
            JSDPageCtrl,
        },
        data() {
            return {
                ui: {                    
                    is_cancel: false,                                        
                    custs: {                    //받으시는분
                        is_view: false,            
                        top: 0,
                        left : 0,
                    },
                    preview: {                  //미리보기
                        is_view: false,
                        top: 0,
                        left: 0,
                    },
                    is_check_all: false,
                    chk_list: [],                           //check-box리스트

                },
                api: {
                    list: {
                        SEARCH_KEY: '',         //검색 KEY(이름/ 휴대폰 번호)
                        MEDIA_TYPE: 0,          //0:전체 1:SMS 2:MMS
                        SEND_TYPE: 0,           //0:전체 1:폰발송 2:웹발송
                        CNT_PAGE: 10,           //페이지 조회건수
                        STRT_DA: new Date().toStr('yyyy-MM-dd'),
                        END_DA: new Date().addMonth(1).toStr('yyyy-MM-dd'),
                    },
                    detail: {                   //상세조회시 사용한다,.
                        SEND_DA: '',
                        SEND_TM: '',
                        SEND_NO: 0,
                        TMPL_NM : ''
                    },
                    delete: {
                        SEARCH_KEY: '',         //검색 KEY(이름/ 휴대폰 번호)
                        MEDIA_TYPE: 0,          //0:전체 1:SMS 2:MMS
                        SEND_TYPE: 0,           //0:전체 1:폰발송 2:웹발송                        
                        STRT_DA: '',
                        END_DA: '',
                        DEL_TYPE: 0,
                        MESG_NO: 0,
                        DATA: '',
                        IS_DEBUG : false,
                    },
                    page_cnt: [10, 30, 50, 70, 100, 150, 300],        //페이징처리 건수배열
                    
                },
                arr: {
                    resv_list: [],              //예약목록                    
                    preview: [],                //템플릿 메시지
                },  
                page: {
                    val: 1,
                    maxCnt: 1
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('My템플릿 편집');
                this.$setMainBtn('+ 템플릿 작성')
                this.$setMainBtnCallback(() => {
                    this.$nextTick(() => {
                        //템플릿 작성과 연결되야 함
                    });
                });

                //좌측메뉴 제목                
                this.$setSideTitle('문자 시리즈');

                //좌측 메뉴 구성
                this.$setSideBtns(TmplLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('문자시리즈')
                    }
                });

                this.$nextTick(() => {
                    //연락처 상세 페이지에서 넘어온 값이 있으면 처리한다.
                    if (sessionStorage.hasOwnProperty('custview_data')) {
                        let data = JSON.parse(sessionStorage.getItem('custview_data'))

                        let strt_da = data.STRT_DA.toDate('yyyyMMdd')
                        let end_da = data.END_DA.toDate('yyyyMMdd')                       

                        this.api.list.SEARCH_KEY = data.CUST_NM;
                        this.api.list.STRT_DA = strt_da.toStr('yyyy-MM-dd');
                        this.api.list.END_DA = end_da.toStr('yyyy-MM-dd');

                        sessionStorage.removeItem('custview_data');
                    }


                    this.getResvList()
                })
            },
            getResvList() {
                // 문자템플릿 예약 리스트
                //console.log(this.api.list);
                TemplateService.tmpl_resv_list(
                    this.page.val,
                    this.api.list,
                    (res) => {
                        this.arr.resv_list = res.data[0];       //예약 리스트
                        //console.log(res.data[0]);

                        this.page.maxCnt = this.arr.resv_list.length > 0 ? this.arr.resv_list[0].MAX_CNT : 1
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set()
                        })
                    }
                );
            },
            detail_set(obj) {
                //상세조회 부분 클릭시 상세조회할 부분을 api에 넣는다.
                this.api.detail.SEND_DA = obj['CUST_SEND_DA']
                this.api.detail.SEND_TM = obj['CUST_SEND_TM']
                this.api.detail.SEND_NO = obj['SEND_NO']
                this.api.detail.TMPL_NM = obj['TMPL_NM']
            },
            preview_view(event, type) {                
                // 수신연락처 리스트 조회, 수신 메시지 리스트 조회

                TemplateService.tmpl_resv_detail(
                    {
                        SEARCH_KEY: this.api.list.SEARCH_KEY,
                        STRT_DA: this.api.list.STRT_DA,
                        END_DA: this.api.list.END_DA,
                        MEDIA_TYPE: this.api.list.MEDIA_TYPE,
                        SEND_TYPE: this.api.list.SEND_TYPE,
                        VIEW_TYPE: type,                        //1:연락처 리스트 2:메시지 리스트
                        SEND_DA: this.api.detail.SEND_DA,
                        SEND_TM: this.api.detail.SEND_TM,
                        SEND_NO: this.api.detail.SEND_NO,
                        TMPL_NM: this.api.detail.TMPL_NM,
                    },
                    (res) => {
                        this.arr.preview = res.data[0];       //예약 리스트
                        //console.log(res.data[0]);

                        let pos = utils.abspos(event);

                        if (type == 1) {
                            //수신 연락처 목록
                            this.ui.custs.left = pos.x;
                            //this.ui.custs.top = pos.y;
                            this.ui.custs.top = document.documentElement.scrollTop + 350;
                            //this.ui.custs.left = 550

                            this.ui.custs.is_view = true
                        }
                        else if (type == 2) {
                            //메시지 미리보기
                            //this.ui.preview.left = pos.x;
                            //this.ui.preview.top = pos.y;

                            this.ui.preview.top = document.documentElement.scrollTop + 300;
                            this.ui.preview.left = 850

                            this.ui.preview.is_view = true
                        }
                    }
                );
            },  // 수신연락처 리스트 조회, 수신 메시지 리스트 조회
            check_all() {
                //전체 체크에 따른 동작
                //console.log('check_all()');

                this.ui.chk_list = []

                if (this.ui.is_check_all) {
                    for (var i = 0; i < this.arr.resv_list.length; i++) {                        
                        this.ui.chk_list.push(this.arr.resv_list[i].SEQ_NO)
                    }
                }                
            },            
            resv_delete_update(del_type) {
                // 삭제 api호출하여 실제 삭제처리

                //console.log('resv_delete_update');
                //console.log(this.api.delete);

                TemplateService.tmpl_delete(
                    this.api.delete,
                    (res) => {
                        if (res.data > 0) {
                            alert('삭제되었습니다.')
                            this.ui.chk_list = []
                            if (del_type == 1) {
                                //메시지 미리보기 reload
                                this.preview_view(null, 2)
                                this.getResvList()                                
                            }
                            else if (del_type == 2) {
                                //수신리스트 reload
                                this.getResvList()
                            }
                            this.ui.is_cancel = false;
                        }
                        else {
                            alert('삭제에 실패했습니다.')
                        }                        
                    }
                )
            },  // 삭제 api호출하여 실제 삭제처리
            resv_mesg_delete(obj) {
                //예약메시지 단건 취소
                this.api.delete.MESG_NO = Number(obj['AUMS_MESG_NO'])
                this.api.delete.DEL_TYPE = 1            //1:단건삭제 2:리스트다건 삭제
                this.api.delete.IS_DEBUG = false

                //api호출해서 삭제
                this.resv_delete_update(this.api.delete.DEL_TYPE)
            },  //예약메시지 단건 취소
            resv_cancel() {
                //api호출로 다건및 리스트 상클릭으로 삭제
                //api호출해서 삭제
                this.resv_delete_update(this.api.delete.DEL_TYPE)
            },
            resv_bulk_delete() {
                //일괄 선택분 예약취소. 다건선택후 상단 일괄삭제로 삭제

                //console.log('resv_bulk_delete()');
                //console.log(this.ui.chk_list);

                if (this.ui.chk_list.length == 0) {
                    alert('삭제할 예약목록을 선택해 주세요')
                    return
                }
                let data = []
                for (var i = 0; i < this.ui.chk_list.length; i++) {
                    let idx = this.arr.resv_list.findIndex(x => x.SEQ_NO == this.ui.chk_list[i])

                    //console.log(this.ui.chk_list[i]);
                    //console.log(idx);

                    data.push({
                        SEND_DA: this.arr.resv_list[idx].CUST_SEND_DA,
                        SEND_TM: this.arr.resv_list[idx].CUST_SEND_TM,
                        TMPL_NM: this.arr.resv_list[idx].TMPL_NM,
                        SEND_NO: this.arr.resv_list[idx].SEND_NO,
                        SEND_CNT: this.arr.resv_list[idx].SEND_CNT,
                    })
                }
                this.api.delete.SEARCH_KEY = this.api.list.SEARCH_KEY   //검색 KEY(이름/ 휴대폰 번호)
                this.api.delete.MEDIA_TYPE = this.api.list.MEDIA_TYPE   //0:전체 1:SMS 2:MMS
                this.api.delete.SEND_TYPE = this.api.list.SEND_TYPE     //0:전체 1:폰발송 2:웹발송
                this.api.delete.STRT_DA = this.api.list.STRT_DA
                this.api.delete.END_DA = this.api.list.END_DA
                this.api.delete.DEL_TYPE = 2                            //1:단건삭제 2:리스트다건 삭제
                this.api.delete.DATA = JSON.stringify(data)
                this.api.delete.IS_DEBUG = false

                //삭제여부 alert창 띄우기
                this.ui.is_cancel = true                
            },  //일괄 선택분 예약취소. 다건선택후 상단 일괄삭제로 삭제
            resend() {

                if (this.arr.preview.length <= 0) {
                    alert('수신인 목록이 없습니다.')
                    return
                }

                let data = []
                let detail = this.arr.preview
                let idx = 0

                //console.log(this.arr.preview);
                for (var i = 0; i < detail.length; i++) {
                    idx = data.findIndex(x => x.CUST_NO == detail[i].CUST_NO);

                    if (idx < 0) {
                        data.push({
                            CUST_NO: detail[i].CUST_NO,
                            CUST_NM: detail[i].CUST_NM,
                            PHONE_CNO: detail[i].PHONE_CNO
                        });
                    }
                }
                //console.log(data);

                let cust_data: string = JSON.stringify(data);
                sessionStorage.setItem('custList', cust_data)
                //console.log(sessionStorage.getItem('custList'));
                //console.log('sessionStorage');
                //발송 페이지로 연결한다.

                this.$movePage('/app/ums')
            },  //다시선택 발송화면으로 연결
            resv_delete( obj ) {
                // 예약리스트 삭제. 리스트상의 삭제 버튼 처리
                let data = []
                data.push({
                    SEND_DA: obj['CUST_SEND_DA'],
                    SEND_TM: obj['CUST_SEND_TM'],
                    TMPL_NM: obj['TMPL_NM'],
                    SEND_NO: obj['SEND_NO'],
                    SEND_CNT: obj['SEND_CNT'],
                })

                //console.log('resv_delete');
                //console.log(data);

                this.api.delete.SEARCH_KEY = this.api.list.SEARCH_KEY   //검색 KEY(이름/ 휴대폰 번호)
                this.api.delete.MEDIA_TYPE = this.api.list.MEDIA_TYPE   //0:전체 1:SMS 2:MMS
                this.api.delete.SEND_TYPE = this.api.list.SEND_TYPE     //0:전체 1:폰발송 2:웹발송
                this.api.delete.STRT_DA = this.api.list.STRT_DA
                this.api.delete.END_DA = this.api.list.END_DA
                this.api.delete.DEL_TYPE = 2                            //1:단건삭제 2:리스트다건 삭제
                this.api.delete.DATA = JSON.stringify(data)
                this.api.delete.IS_DEBUG = false

                //삭제여부 alert창 띄우기
                this.ui.is_cancel = true                
            },  // 예약리스트 삭제. 리스트상의 삭제 버튼 처리
            moveTerm(type) {
                //조회일자 수정하고 조회
                if (type == '1W') {
                    this.api.list.STRT_DA = new Date().toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().addDate(7).toStr('yyyy-MM-dd')
                }
                else if (type == '1M') {
                    this.api.list.STRT_DA = new Date().toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().addMonth(1).toStr('yyyy-MM-dd')
                }
                else if (type == '3M') {
                    this.api.list.STRT_DA = new Date().toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().addMonth(3).toStr('yyyy-MM-dd')
                }
                else if (type == '') {
                    this.api.list.STRT_DA = new Date().toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().addMonth(6).toStr('yyyy-MM-dd')
                }
                //변경된 일자로 조회
                this.movePage(1)
            },  //조회일자 수정하고 조회
            movePage(page) {              
                // page이동.검색시 초기값                
                this.ui.chk_list = []
                this.ui.is_cancel = false
                this.ui.custs.is_view = false
                this.ui.preview.is_view = false
                this.ui.is_check_all = false
                
                this.page.val = page
                this.getResvList();
            },
            
        },
        mounted() {
            this.init();
        },
    }
