
    import UserService from '@/service/UserService'

    export default {
        name: 'JSDCoinShortage',
        props: {
            coin: {                 //부족한 코인
                type: Number,
                require: true,
            },
            left: {                 //레이어 띄울 위치 Left
                type: Number,
                default: 110,
            },
            top: {                  //레이어 띄울 위치 top
                type: Number,
                default: 200,
            },
            message: {
                type: String,
                default: '코인이 부족합니다.<br><b>상샹레터 웹사이트에서 코인 충전 후<br>다시 예약하여 주시기 바랍니다. <br><br>',
            },
        },
        data() {
            return {
                is_view: false,          //레이어 띄울지 여부
            }
        },
        methods: {
            init() {
                //console.log('JSDCustSelect');
            },
            view() {
                //console.log('search()');
                //외부에서 부르는 함수. 여기서 실제 고객 검색을 한다.
                this.is_view = true;
                this.getCustList();
            },
            Coin_Charge() {
                //코인 충전하는 모듈과 연계한다.
            },
            getCustList() {

            },
        },
        mounted() {
            this.init();
        },
    }
