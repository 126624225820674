 export { }

declare global {
    interface Number {
        zf(len: number): string
        floor(): number
        round(): number
        char(): string
        ceil(): number
        toComma(): string
        padLeft(n: number, str:string):string
    }

    interface String {
        startsWith(str: string): boolean
        endsWith(str: string): boolean
        isPhone(isHypen: boolean): boolean
        isHP(isHypen?: boolean): boolean
        reAll(o, n): string
        mapping(obj): object
        num(): number
        trim(): string
        koLen(isSmt?: boolean): number
        prev(token: string): number
        koCut(limit: number, isSmt?: boolean): string
        toCharCodeArr(): Array<string>
        koNor(): number
        cdata(): string
        isDate(fm: string): boolean
        toChosung(): string
        toDate(fm: string): Date
        toComma(): string
        cvPhone(): string
        fmt(...args): string
        xmlStr(): string
        toNo(): number
        mask(type: string): string
    }

    interface Date {
        addTime(time: number): Date
        clone(): Date
        addMin(min: number): Date
        addHour(hour: number): Date
        addDate(day: number): Date
        addWeek(week: number): Date
        addMonth(month: number): Date
        addYear(year: number): Date
        getLastDateOfMonth(): number
        getFirstDayWeekOfMonth(): number
        getLastDayWeekOfMonth(): number
        getFirstDateOfWeek(): number
        getFirstDateOfNextWeek(): number
        getWeekOfMonth(): number
        left(d: Date): number
        toStr(fm: string): string
        getBizWeekOfMonth(t: string): number
        getDayName(f: boolean): string
        getMonths(): number
        getDayOrder(): number
    }

    interface Array<T> {
        division(n: number): Array<T>
        charCodeArrToStr(token: string): string
        join2(s: string): string
        toTree(k: string, pk: string, dk: string): Array<T>
        copy(): Array<T>
    }
}

// Number.prototype
Number.prototype.zf = function(len: number) {
    const m = this < 0
    const rv = (m ? (this * -1) : this) + ''
    const dif = len - rv.length
    let zero = ''
    if (dif > 0) {
        for (let i = 0; i <dif; i++) { zero += '0' }
    }
    return (m ? '-' : '') + zero + rv
    return 
}

Number.prototype.floor = function () {
    return Math.floor(this)
}

Number.prototype.round = function () {
    return Math.round(this)
}

Number.prototype.ceil = function () {
    return Math.ceil(this)
}

Number.prototype.char = function () {
    return String.fromCharCode(this)
}

Number.prototype.toComma = function () {
    let n, reg
    n = this
    if (!isNaN(n)) {
        reg = /(^[+-]?\d+)(\d{3})/
        while (reg.test(n)) {
            n = n.toString().replace(reg, '$1' + ',' + '$2')
        }
        return n
    }
    return this
}

Number.prototype.padLeft = function (n, str) {
    // 숫자변수에 pad처리한다.
    // n=20
    // n.padLeft(5, '0') -> '00020'
    return Array(n - String(this).length + 1).join(str || '0') + this;
}

// String.prototype
String.prototype.startsWith = function (str) {
    if (this.length < str.length) {
        return false
    } else {
        return this.indexOf(str) === 0
    }
}

String.prototype.endsWith = function (str) {
    let l
    l = this.lastIndexOf(str)
    if (l < 0) {
        return false
    } else {
        return l + str.length === this.length
    }
}

String.prototype.isPhone = function (isIgH) {
    if (isIgH == true) {
        return (/^[\d]{2,3}[\d]{3,4}[\d]{4}$/).test(this)
    }
    else {
        return (/^[\d]{2,3}\-[\d]{3,4}\-[\d]{4}$/).test(this)
    }
}

//올바른 휴대폰 번호인가.
String.prototype.isHP = function () {
    if (this.indexOf('-') < 0) {
        return /^01[\d]{1}[\d]{3,4}[\d]{4}$/.test(this)
    } else {
        return /^01[\d]{1}\-[\d]{3,4}\-[\d]{4}$/.test(this)
    }
}

String.prototype.reAll = function (o, n) {
    return this.split(o).join(n)
}
String.prototype.mapping = function (obj) {
    const reg = /{(\d+)}/g
    return this.replace(reg, function (match1, key) {
        return obj[key]
    })
}
String.prototype.num = function () {
    return Number(this)
}

String.prototype["eval"] = function () {
    return eval("(" + this + ")")
}

String.prototype.trim = function () {
    return this.replace(/^\s*|\s*$/g, '')
}

String.prototype.koLen = function (isSmt) {
    if (isSmt) {
        var SMTCnt = 0;
        var SMTTemp = this.toString();

        while (SMTTemp.indexOf("[%발신자%]") >= 0) {
            SMTCnt += 30;
            SMTTemp = SMTTemp.replace("[%발신자%]", '');
        }
        while (SMTTemp.indexOf("[%호격%]") >= 0) {
            SMTCnt += 10;
            SMTTemp = SMTTemp.replace("[%호격%]", '');
        }
        while (SMTTemp.indexOf("[%호칭%]") >= 0) {
            SMTCnt += 10;
            SMTTemp = SMTTemp.replace("[%호칭%]", '');
        }
        while (SMTTemp.indexOf("[%이름%]") >= 0) {
            SMTCnt += 8;
            SMTTemp = SMTTemp.replace("[%이름%]", '');
        }

        return SMTCnt + SMTTemp.length * 2
    }
    else {
        const arr = this.toCharCodeArr()
        let count = this.koNor()
        for (let i = 0; i < arr.length; i++) {
            count += arr[i] < 128 ? 1 : 2
        }
        return count
    }
}

String.prototype.prev = function (token) {
    return this.indexOf(token) != -1 ? this.substring(0, this.indexOf(token)) : ''
}

String.prototype.koCut = function (limit, isSmt) {
    const arr = this.toCharCodeArr()
    let count = 0
    let i = 0
    const sw = true // 스위치
    for (;i < arr.length; i++) {
        // 치환자 예외처리
        if (this.charAt(i) == '[') {
            const tmp = this.substring(i).prev(']') + ']'
            const def = tmp.koNor()
            if (def != 0) {
                if ((count + tmp.koLen(isSmt)) > limit) {
                    break
                }
                else {
                    count += def
                }
            }
        }
        if (sw && (count += (arr[i] < 128 ? 1 : 2)) > limit) {
            break
        }
    }
    arr.splice(i, arr.length - i)
    return arr.charCodeArrToStr()
}

String.prototype.toCharCodeArr = function () {
    const rv = new Array(0)
    for (let i = 0; i < this.length; i++) {
        rv.push(this.charCodeAt(i))
    }
    return rv
}

String.prototype.koNor = function () {
    let rv = 0, tmp,
        /* 치환자 목록 */
        cr =
            [
                //	[글자차이, 해당단어] 
                [-8, /\[%이체일%]/g],
                [-6, /\[%기념월%\]|\[%기념일%\]/g],
                [-5, /\[은\/는\]|\[이\/가\]|\[을\/를\]|\[와\/과\]|\[에게\/께\]/g],
                [-4, /\[%당사자호칭%\]|\[%언제%\]|\[%생일%\]|\[%간지%\]/g],
                [-2, /\[%나이%\]|\[%동물%\]/g],
                //	[ 0, /\[%발신자%\]/g],
                [2, /\[%호격%\]|\[%호칭%\]/g],
                [4, /\[%년도%\]/g],
                [6, /\[%기념일내용%\]/g]
            ]

    for (let i = 0; i < cr.length; i++) {
        tmp = cr[i]
        const reg = this.match(tmp[1])
        if (reg != null) {
            rv += (reg.length * tmp[0])
        }
    }

    return rv
}

// CDATA치환
String.prototype.cdata = function () {
    let str
    str = this.split(']]>').join(']]]]><![CDATA[')
    return "<![CDATA[" + str + "]]>"
}

String.prototype.isDate = function (fm) {
    let d
    d = this.toDate(fm)
    if (d !== null) {
        return d.toStr(fm) == this
    } else {
        return false
    }
}
//초성으로 모두 변환함.
String.prototype.toChosung = function () {
    let cho, code, i, j, k, len, result
    cho = ["ㄱ", "ㄲ", "ㄴ", "ㄷ", "ㄸ", "ㄹ", "ㅁ", "ㅂ", "ㅃ", "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅉ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"]
    result = ''
    for (j = k = 0, len = this.length; k < len; j = ++k) {
        i = this[j]
        code = this.charCodeAt(j) - 44032
        result += code > -1 && code < 11172 ? cho[Math.floor(code / 588)] : i
    }
    return result
}

// String -> Date 특정 포맷으로
String.prototype.toDate = function (fm) {
    if (fm == null) {
        switch (this.length) {
            case 10:
                fm = 'yyyy-MM-dd'
                break
            case 5:
                fm = 'MM-dd'
                break
            case 16:
                fm = 'yyyy-MM-dd HH:mm'
                break
            case 19:
                fm = 'yyyy-MM-dd HH:mm:ss'
                break
            default:
                return null

        }
    }
    let H, M, d, i, m, s, y, z
    if (fm.length !== this.length) {
        null
    }
    y = 2000
    M = 0
    d = 1
    H = 0
    m = 0
    s = 0
    z = 0
    i
    if ((i = fm.indexOf('yyyy')) !== -1) {
        y = this.substring(i, i + 4).toNo()
    }
    if ((i = fm.indexOf('MM')) !== -1) {
        M = this.substring(i, i + 2).toNo() - 1
    }
    if ((i = fm.indexOf('dd')) !== -1) {
        d = this.substring(i, i + 2).toNo()
    }
    if ((i = fm.indexOf('HH')) !== -1) {
        H = this.substring(i, i + 2).toNo()
    }
    if ((i = fm.indexOf('mm')) !== -1) {
        m = this.substring(i, i + 2).toNo()
    }
    if ((i = fm.indexOf('ss')) !== -1) {
        s = this.substring(i, i + 2).toNo()
    }
    if ((i = fm.indexOf('zzz')) !== -1) {
        z = this.substring(i, i + 3).toNo()
    }
    return new Date(y, M, d, H, m, s, z)
}

String.prototype.toComma = function () {
    let n, reg
    n = this.reAll(',', '')
    if (!isNaN(n)) {
        reg = /(^[+-]?\d+)(\d{3})/
        while (reg.test(n)) {
            n = n.replace(reg, '$1' + ',' + '$2')
        }
        return n
    }
    return this + ''
}
//올바른 전화번호로 변환
String.prototype.cvPhone = function () {
    const no = this.reAll('-', '')
    if (!isNaN(no)) {
        switch (no.length) {
            case 9:
                // 00-000-0000 9
                return no.substring(0, 2) + '-' + no.substring(2, 5) + '-' + no.substring(5, 9)
            case 10:
                if (no.sub(0, 2) == '02') {
                    // 00-0000-0000 10
                    return no.substring(0, 2) + '-' + no.substring(2, 6) + '-' + no.substring(6, 10)
                }
                else {
                    // 000-000-0000 10
                    return no.substring(0, 3) + '-' + no.substring(3, 6) + '-' + no.substring(6, 10)
                }

            case 11:
                // 000-0000-0000 11
                return no.substring(0, 3) + '-' + no.substring(3, 7) + '-' + no.substring(7, 11)
        }
    }
    return this + ''
}

//C# String.Format
String.prototype.fmt = function (...args) {
    let arg, reg
    reg = /{(\d+)}/g
    arg = arguments
    return this.replace(reg, function (match1, num) {
        return arg[num]
    })
}

String.prototype.xmlStr = function () {
    return this.reAll('&', '&amp').reAll('<', '&lt').reAll('>', '&gt').reAll("'", '&apos').reAll('"', '&quot')
}

String.prototype.toNo = function () {
    return Number(this)
}

String.prototype.mask = function (type) {
    if (this.length) {
        const data = this.split('-')
        data[1] = data[1].replace(/\d/g, '*')
        return data.join('-')
    }
    return ''
}

// 현재 Date 시간을 time (ms)만큼 추가 복제 아님
Date.prototype.addTime = function (time) {
    //var clone
    //clone = new Date(this)
    this.setTime(this.getTime() + time)
    return this
}
Date.prototype.clone = function () {
    return new Date(this)
}
// *분 추가 복제X
Date.prototype.addMin = function (min) {
    return this.addTime(min * 60000)
}
// *시간 추가 복제X
Date.prototype.addHour = function (hour) {
    return this.addMin(60 * hour)
}
// *일 추가 복제X
Date.prototype.addDate = function (day) {
    return this.addHour(24 * day)
}
// *주 추가 복제X
Date.prototype.addWeek = function (wek) {
    return this.addDate(wek * 7)
}
// *달 추가 복제X
Date.prototype.addMonth = function (m) {
    const current = this.getMonth()
    this.setMonth(current + m)
    if (current == this.getMonth()) {
        this.addDate(-this.getDate())
    }
    return this
}
Date.prototype.addYear = function (y) { return this.addMonth(12 * y) }
//해당 달의 마지막 날짜를 구함
Date.prototype.getLastDateOfMonth = function () {
    return this.clone().addMonth(1).addDate(-this.getDate()).getDate()
}
//
Date.prototype.getFirstDayWeekOfMonth = function () {
    return this.clone().addDate(-this.getDate() + 1).getDay()
}

Date.prototype.getLastDayWeekOfMonth = function () {
    return this.clone().addMonth(1).addDate(-this.getDate()).getDay()
}

Date.prototype.getFirstDateOfWeek = function () {
    return this.clone().addDate(-this.getDay())
}

Date.prototype.getFirstDateOfNextWeek = function () {
    return this.clone().addDate((6 - this.getDay) + 1)
}

Date.prototype.getWeekOfMonth = function () {
    let dt, rem
    dt = this.getFirstDateOfWeek()
    rem = dt.getDate() % 7
    if (dt.getMonth() !== this.getMonth()) {
        return 1
    }
    return Math.ceil((dt.getDate() - 1) / 7) + 1
}

Date.prototype.left = function (d) {
    let t
    t = d.getTime()
    return Math.floor((new Date(this.getTime() - t).getTime()) / 1000 / 60 / 60 / 24)
}

Date.prototype.toStr = function (fm) {
    let rv
    rv = fm
    rv = rv.replace('yyyy', this.getFullYear())
    rv = rv.replace('MM', (this.getMonth() + 1).zf(2))
    rv = rv.replace('dd', this.getDate().zf(2))
    rv = rv.replace('HH', this.getHours().zf(2))
    rv = rv.replace('mm', this.getMinutes().zf(2))
    rv = rv.replace('ss', this.getSeconds().zf(2))
    rv = rv.replace('zzz', this.getMilliseconds().zf(3))
    return rv
}

Date.prototype.getBizWeekOfMonth = function (t) {
    let _t, d, d_first, d_last, d_temp, day_left, i, idx, k, left, len, tmp
    d = new Date(this.getTime())
    d_first = new Date(this.getTime())
    d_last = new Date(this.getTime())
    d_temp = new Date(this.getTime())
    d_first.setDate(1)
    d_temp.setDate(1)
    d_last.setDate(1)
    d_last.addMonth(1)
    d_last.addDate(-1)
    left = d_last.left(d_first)
    tmp = []
    day_left = 4 - d.getDay()
    d.addDate(day_left)
    i = 0
    for (k = 0, len = left.length; k < len; k++) {
        i = left[k]
        if (d_temp.getDay() === 4) {
            tmp.push(d_temp.toStr("yyyy-MM-dd"))
        }
        d_temp.addDate(1)
    }
    idx = tmp.indexOf(d.toStr("yyyy-MM-dd"))
    if (t !== void 0) {
        _t = t.toLowerCase()
        if (_t === "f") {
            return idx === 0
        } else if (_t === "l") {
            return idx === tmp.length - 1
        }
    }
    if (idx !== -1) {
        return idx + 1
    } else {
        return -1
    }
}

Date.prototype.getDayName = function (f) {
    let arr, d
    d = this.getDay()
    arr = ['일', '월', '화', '수', '목', '금', '토']
    if (f === true) {
        return arr[d] + '요일'
    }
    return arr[d]
}

Date.prototype.getMonths = function () { return this.getMonth() + 1 }

Date.prototype.getDayOrder = function () {
    const day = this.getDate()
    let val = day / 7
    const remind = day & 7

    if (remind > 0) {
        val += 1
    }

    return Math.floor(val)
}

// 배열을 n만큼 나눠줌
Array.prototype.division = function (n) {
    const arr = this
    const len = arr.length
    const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0)
    const tmp = []

    for (let i = 0; i < cnt; i++) {
        tmp.push(arr.splice(0, n))
    }

    return tmp
}

Array.prototype.charCodeArrToStr = function (token) {
    let rv = ""
    for (let i = 0; i < this.length; i++) {
        rv += String.fromCharCode(Number(this[i]))
    }
    return rv
}

Array.prototype.join2 = function (s) {
    const arr = this
    const r = []
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].length > 0) {
            r.push(arr[i])
        }
    }
    const ret = r.join(s)
    return ret.reAll(s + s, s)
}

Array.prototype.toTree = function (key, parentKey, depthKey) {
    let map = {}, node, roots = []
    const list = this
    for (var i = 0; i < list.length; i++) {
        map[list[i][key]] = i
        list[i].childs = []
    }

    for (var i = 0; i < list.length; i++) {
        node = list[i]
        if (node[parentKey] != null) {
            list[map[node[parentKey]]].childs.push(node)
        } else {
            roots.push(node)
        }
    }
    return roots
}

function cloneObject(obj) {
    const clone = {};
    for (const key in obj) {
        if (typeof obj[key] == "object" && obj[key] != null) {
            clone[key] = cloneObject(obj[key]);
        } else {
            clone[key] = obj[key];
        }
    }

    return clone;
}

Array.prototype.copy = function () {
    const obj = this
    if (obj === null || typeof (obj) !== "object") {
        return obj;
    }

    return cloneObject(obj)
}