// 우측 메뉴 처리

export default {    
    menu_list :
    [
        [
            { name: '나의 정보', path: '/app/mypage/myinfo' },
            { name: '발신번호 설정', path: '/app/mypage/send' },
            { name: '앱 사용 설정', path: '/app/mypage/app' },
            { name: '코인', path: '/app/mypage/coin' },
        ],
    ],    
};

