import api from '@/utils/api'

export default class MemoService
{
    ////그룹리스트. 미지정 그룹포함 고객수 포함
    //public static group_list(BLVR_CATE_CD:string, callback: (result: any) => void): void {
    //    const url: string = `api/group/list/${BLVR_CATE_CD}`;
    //    api.get(`${url}`).then((r) => callback(r))
    //}
    ////그룹 추가/수정/삭제
    //public static group_edit(data: any, callback: (result: any) => void): void {
    //    api.post(`api/group/edit`, data).then((r) => callback(r))
    //}
    ////그룹에 그룹원 추가
    //public static group_cust_add(data: any, callback: (result: any) => void): void {
    //    api.post(`api/group/edit/cust/add`, data).then((r) => callback(r))
    //}
    ////그룹에 그룹원 삭제
    //public static group_cust_del(data: any, callback: (result: any) => void): void {
    //    api.post(`api/group/edit/cust/del`, data).then((r) => callback(r))
    //}
    ////그룹 정렬순서 변경
    //public static group_order(data: any, callback: (result: any) => void): void {
    //    api.post(`api/group/order`, data).then((r) => callback(r))
    //}
    ////그룹리스트. 그룹명만 조회함
    //public static group_title_list(callback: (result: any) => void): void {
    //    const url: string = `api/group/group_list`;
    //    api.get(`${url}`).then((r) => callback(r))
    //}
    ////그룹일괄 변경. 그룹명과 그룹정렬순서를 일괄변경한다.
    //public static group_bulk_update(data: any, callback: (result: any) => void): void {
    //    api.post(`api/group/bulk/update`, data).then((r) => callback(r))
    //}

    ////그룹일괄 변경. 다수 연락처의 그룹을 일괄 변경한다.
    //public static group_bulk_cust_update(data: any, callback: (result: any) => void): void {
    //    api.post(`api/group/bulk/cust/update`, data).then((r) => callback(r))
    //}

    //메모 tag 리스트 조회
    public static memo_tag_list(is_option: string, data: any, callback: (result: any) => void): void {
        const url: string = `api/memo/tag/list/${is_option}`;
        
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`${url}?${query.join('&')}`).then((r) => callback(r))
    }

    //메모 web 리스트 조회
    public static memo_web_tag_list(view_type: number, ord_no:number,  callback: (result: any) => void): void {
        const url: string = `api/memo/tag/web/list/${view_type}/${ord_no}`;

    //    const query: any[] = []
    //    Object.keys(data).forEach(k => {
    //        if (Object.prototype.hasOwnProperty.call(data, k)) {
    //            query.push(`${k}=${data[k]}`)
    //        }
    //    })
    //    api.get(`${url}?${query.join('&')}`).then((r) => callback(r))
        api.get(`${url}`).then((r) => callback(r))
    }

    //메모 tag update 등록/수정/삭제 모두 처리한다.
    public static memo_tag_update(data: any, callback: (result: any) => void): void {
        api.post(`api/memo/tag/update`, data).then((r) => callback(r))
    }

    //메모 태그 삭제
    public static memo_tag_delete(data: any, callback: (result: any) => void): void {
        api.post(`api/memo/tag/delete`, data).then((r) => callback(r))
    }

    //메모 tag정렬처리 - 단건처리
    public static memo_tag_order_update(data: any, callback: (result: any) => void): void {
        api.post(`api/memo/tag/order/update`, data).then((r) => callback(r))
    }

    //메모 tag bulk update 
    public static memo_tag_bulk_update(data: any, callback: (result: any) => void): void {
        api.post(`api/memo/tag/bulk/update`, data).then((r) => callback(r))
    }

    //메모 리스트 조회
    public static memo_web_list(page: number, data: any, callback: (result: any) => void): void {
        api.post(`api/memo/list/${page}`, data).then((r) => callback(r))
    }

    //메모 bulk update 삭제 및 ta일괄 변경
    public static memo_web_bulk_update(data: any, callback: (result: any) => void): void {
        api.post(`api/memo/bulk/update`, data).then((r) => callback(r))
    }

    //메모 update 등록/수정/삭제
    public static memo_web_update(data: any, callback: (result: any) => void): void {
        api.post(`api/memo/update`, data).then((r) => callback(r))
    }
}