<!--

프로그램 명  : Login.vue
프로그램 설명: 상생레터 로그인

작성자		 : 20211231 (주)UCRM/정우현
수정이력)
1)2022-03-02 김태훈 도무넷에서 데이터 받을때 검증로직 추가
2)2023-10-20 김태훈 발송안되는 내용 추가함
-->
<template>
    <div class="mainArea">
        <!-- 컨텐츠 영역 -->
        <div class="loginArea">
            <div class="box">
                <div class="mainslider" style="margin:0 auto;">
                    <div v-for="r in banner.main"><img :src="($isDev ? 'https://test.jsdsms.co.kr' : '') + r.url" /></div>
                </div>

                <div class="loginInputArea mgB15">
                    <span class="loginTitle mgR10">아이디</span>
                    <input type="text" class="login mgR10" placeholder="아이디를 입력하세요" v-model="id" ref="id" maxlength="20">
                    <span class="loginTitle mgR10">패스워드</span>
                    <input type="password" class="password  mgR15" placeholder="패스워드를 입력하세요" v-model="pw" ref="pw" maxlength="20" @keyup.enter="jsd_login">
                    <button class="blueBtn" style="margin-bottom:40px;" @click="jsd_login">로그인</button>
                </div>

                <div style="width:650px; border-radius:10px; padding:10px; margin:0 auto 30px; background:#efefef; font-size:20px;" v-if="isNoti">
                    <span class="bold red">
                        {{`현재 통신사와 전산문제로 ${new Date(this.enableSendDate).addDate(-1).toStr('MM월 dd일')}까지 단문 문자의 발송이 되지 않으니\n장문 문자와 폰발송을 이용하시기 바랍니다.`}}
                    </span>
                </div>

                <div style="width:650px; border-radius:10px; padding:10px; margin:0 auto 30px; background:#efefef; font-size:17px;">
                    아이디와 패스워드는 <b>도무넷과 동일</b> 합니다.
                </div>

                <div style="position:relative; border-bottom:1px solid #000000; width:700px; margin:0 auto 20px;" v-if="isDevIp">
                    <div style="position:absolute; top:-10px; left:326px; background:#ffffff; width:50px;">또는</div>
                </div>

                <!-- 신도코드로 로그인  -->
                <div class="loginInputArea" v-if="isDevIp">
                    <span class="loginTitle mgR10">신도코드</span>
                    <input type="text" class="login mgR10" placeholder="신도코드" v-model="userCd" @keyup.enter="login">
                    <button type="button" class="yellowBtn" @click="login()">신도코드로 로그인</button>
                </div>


                <div class="bannerslider">
                    <div v-for="r in banner.sub"><a :href="r.link" target="_blank"><img :src="($isDev ? 'https://test.jsdsms.co.kr' : '') + r.url" class="mgR10"></a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import api from '@/utils/api'
    import store from '@/store'
    import AuthService from '@/service/AuthService'
    import CommonService from '@/service/CommomService'
    import $ from 'jquery'
    window.jQuery = $
    require('bxslider/dist/jquery.bxslider')

    export default {
        name: 'UserLogin',
        computed: {
            enableSendDate() {
                return store.getters.getEnableSendDate.toDate('yyyy-MM-dd')
            },
            isNoti() {
                return new Date() < this.enableSendDate
            },
            isDevIp() {
                return this.$root.IP.indexOf('124.198.60') > -1
            }
        },
        data() {
            return {
                userCd: 0,
                id: '',
                pw: '',
                arr: {
                    login_no: '',
                },
                IP: '',
                banner: {
                    main: [],
                    sub: [],
                }
            }
        },
        methods: {
            init() {
                console.log(this.enableSendDate)
                CommonService.sysconf_select('BNR_MAIN', (res) => {
                    const { data } = res
                    if (data.length) {
                        this.banner.main = JSON.parse(data[0].SYS_VALUE)

                        this.$nextTick(() => {
                            $('.mainslider').bxSlider({
                                speed: 500,
                                pause: 4000,
                                mode: 'fade', // horizontal', 'vertical', 'fade'
                                auto: true,
                                pause: 4000, // 시간 조절
                                captions: false,
                                slideWidth: 1000, // 베너 가로 사이즈
                                controls: false,
                                touchEnabled: (navigator.maxTouchPoints > 0),
                            });
                        })
                    }
                })
                CommonService.sysconf_select('BNR_SUB', (res) => {
                    const { data } = res
                    if (data.length) {
                        this.banner.sub = JSON.parse(data[0].SYS_VALUE)

                        this.$nextTick(() => {
                            $('.bannerslider').bxSlider({
                                minSlides: 5,
                                maxSlides: 5,
                                speed: 1000,
                                pause: 4000,
                                mode: 'horizontal', // horizontal', 'vertical', 'fade'
                                pager: false,
                                auto: true,
                                pause: 4000, // 시간 조절
                                captions: false,
                                slideWidth: 150, // 베너 가로 사이즈
                                slideMargin: 30,
                                touchEnabled: (navigator.maxTouchPoints > 0),
                            });
                        })
                    }
                })
            },
            login() {
                document.cookie = `JSD_USER=; expires=${'1970-01-01'.toDate('yyyy-MM-dd').toUTCString()}`
                this.$cookie.removeCookie('JSD_USER')

                // 신도코드로 바로 로그인....테스트용 오픈후 주석처리되야 함.
                const loginData = {
                    JSD_USER_CD: this.userCd,
                    JSD_LOGIN_ID: this.id,
                    JSD_LOGIN_PW: this.pw,
                    STM_CD: '1',
                    OS_INFO: ''
                }
                AuthService.login(
                    loginData,
                    (res) => {
                        const data = res.data

                        if (data.RESULT > 0) {
                            const token = data.TOKEN
                            const user = data.USER[0]

                            Promise.all([
                                store.dispatch('setToken', { data: token }),
                                store.dispatch('setUser', { data: user }),
                                store.dispatch('setSystemCode', { data: 'A' }),
                            ]).then(() => {
                                this.$cookie.setCookie('JSD_USER', token, { expire: '1h' })

                                api.defaults.headers.common['Authorization'] = 'Bearer ' + token

                                //session정보를 등록한다. 이중로그인 방지
                                AuthService.session_add(
                                    {
                                        SSON_ID: token,     //token등록
                                        STM_CD: 'A',        //1:웹사이트 2:관리자로그인 3:APP
                                        ACNT_NO: user.ACNT_NO
                                    },
                                    (res) => {
                                    }
                                );

                                this.$nextTick(() => {
                                    this.$root.movePage(this.$homeUrl)
                                })
                            })
                        }
                        else {
                            alert(data.RESULT_MESG)
                        }
                    }
                )
            },
            jsd_login() {
                console.log(document.cookie)
                document.cookie = `JSD_USER=; expires=${'1970-01-01'.toDate('yyyy-MM-dd').toUTCString()}`
                this.$cookie.removeCookie('JSD_USER')

                //도무넷을 통한 로그인처리 프로세스. 비동기 실행이라 동기처리 식으로 처리하게

                //도무넷에 로그인시도
                AuthService.login_jsd(
                    {
                        JSD_LOGIN_ID: this.id,
                        JSD_LOGIN_PW: this.pw
                    },
                    (res) => {
                        let errMsg = res.data.errMsg
                        let jsd_data = res.data.data
                        let jsd_user_cd = (jsd_data == null ? '' : jsd_data.individual_code);

                        //console.log(jsd_user_cd);
                        //console.log(errMsg);

                        //로그인 정보 이력을 남긴다.
                        AuthService.login_history(
                            {
                                CMD: 'I',
                                JSD_USER_CD: jsd_user_cd,
                                STM_CD: '1',
                                LOGIN_TYPE_CD: '1',
                                JSD_LOGIN_ID: this.id,
                                JSD_LOGIN_PW: this.pw,
                                BATCH_RES_CD: '',
                            },
                            (res) => {

                                //응답이 빈 공간으로 오면 경고후 종료. 없는 id와 비번인 경우임
                                if (jsd_user_cd == '' || jsd_user_cd == 0) {
                                    alert('도무넷 ID와 비밀번호를 올바르게 입력해 주세요.');
                                    this.$refs.id.focus();
                                    return
                                }

                                //로그인확인을 위한 로그인 일련번호 세션에 저장
                                this.arr.login_no = res.data.RESULT_LOGIN_NO;
                                sessionStorage.setItem('login_no', this.arr.login_no)

                                // 오류일경우 메시지 출력, 정상일 경우 상생레터가입자 확인

                                if (res.data.RESULT > 0) {
                                    this.jsd_user_exists(jsd_data)
                                }
                                else {
                                    alert('로그인 실패! 관리자에게 문의하세요!(-1)')
                                }
                            }
                        )
                    }
                )
            },  //도무넷을 통한 로그인처리 프로세스

            jsd_user_exists(obj) {
                //신도코드로 상생레터 가입여부 확인
                let errMsg = ''
                let user_data = {
                    individual_code: obj["individual_code"],
                    user_name: obj["user_name"],
                    gender_name: obj["gender_name"],
                    date_div_name: obj["date_div_name"],
                    birth_date: obj["birth_date"],
                    phone_number: obj["phone_number"],
                }



                // data검증하고 정상이 아니면 return
                //if ( user_data.user_name ) {
                //    return
                //}



                //신도 상생레터 가입여부 확인
                AuthService.user_exists(
                    user_data,
                    (res) => {
                        let acnt_no = ''
                        errMsg = res.data.errMsg
                        acnt_no = res.data.RESULT

                        if (res.data.RESULT > 0) {
                            //존재함
                            this.login_log_update('S', acnt_no)

                            //로그인 처리
                            this.login_session(acnt_no)
                            return
                        }
                        else if (res.data.RESULT == -1) {
                            //-1 : 상생레터 미가입자 입니다. 가입처리가 필요합니다.
                            let data = JSON.stringify(user_data);
                            sessionStorage.setItem('userData', data)
                            this.login_log_update('E', -1)
                            this.$movePage('/app/join')
                        }
                        else if (res.data.RESULT == -2) {
                            //-2 : 서비스 상태가 정상이 아닙니다. 상태코드 :
                            alert('errMsg');
                            this.login_log_update('E', -2)
                            return
                        }
                        else if (res.data.RESULT == -3) {
                            //-3 : 휴대폰이 미인증 상태입니다.

                            //페이지 이동처리 - 휴대폰 인증화면으로
                            return
                        }
                        else if (res.data.RESULT == -4) {
                            //-4 : 상생레터에서 삭제된 상태입니다. 관리자에게 문의해 주세요.
                            this.login_log_update('E', -4)
                            alert('errMsg');
                            return
                        }
                        else {
                            this.login_log_update('E', null)
                            errMsg = '알수 없는 오류 : 관리자 연락하세요. /n' + errMsg
                            alert('errMsg');
                            return
                        }
                    }
                )

            },  //신도코드로 상생레터 가입여부 확인

            login_session(acnt_no) {
                //로그인하고 세션 생성처리

                AuthService.login_jsd_session(
                    {
                        ACNT_NO: acnt_no
                    },
                    (res) => {
                        if (res.data.ACNT_NO > 0) {

                            const token = res.data.TOKEN
                            const user = res.data.USER[0]
                            Promise.all([
                                store.dispatch('setToken', { data: token }),
                                store.dispatch('setUser', { data: user }),
                                store.dispatch('setSystemCode', { data: '1' }),
                            ]).then(() => {
                                this.$cookie.setCookie('JSD_USER', token, { expire: '1h' })
                                api.defaults.headers.common['Authorization'] = 'Bearer ' + token

                                //session정보를 등록한다. 이중로그인 방지
                                AuthService.session_add(
                                    {
                                        SSON_ID: token,     //token등록
                                        STM_CD: '1',        //1:웹사이트 2:관리자로그인 3:APP
                                        ACNT_NO: user.ACNT_NO
                                    },
                                    (res) => {
                                    }
                                );

                                //로그인했으니 페이지로 이동
                                this.$movePage(this.$homeUrl)
                            })
                        }
                    }
                )
            },  //로그인하고 세션 생성처리

            login_log_update(batch_res_cd, acnt_no) {
                //로그인 시도 완료/오류 처리
                AuthService.login_history(
                    {
                        CMD: 'U',
                        LOGIN_NO: this.arr.login_no,
                        ACNT_NO: acnt_no,
                        BATCH_RES_CD: batch_res_cd,
                    },
                    (res) => {
                        sessionStorage.removeItem('login_no')
                    }
                )
            },  //로그인 시도 완료/오류 처리
        },
        mounted() {
            this.init()
            if (this.$root.isLogin) {
                this.$root.movePage(this.$homeUrl)
            }
        }
    }
</script>