import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    plugins: [
        createPersistedState()
    ],
    state: {
        ORG_DATA: [],
        ORG_TREE: [],
        JSD_MENUS: [],
        token: '',
        user: {},
        title: '',
        sideTitle: '',
        sideBtns: [],
        treeData: [],
        treeSelect: '',
        mainBtn: '',
        helpList: {},
        systemCode: '1',
        enableSendDate: '',
  },
    getters: {
        getOrgData(state) {
            return state.ORG_DATA
        },
        getOrgTree(state) {
            return state.ORG_TREE
        },
        getMenus(state) {
            return state.JSD_MENUS
        },
        getToken(state) {
            return state.token
        },
        getUser(state) {
            return state.user
        },
        getTitle(state) {
            return state.title
        },
        getSideTitle(state) {
            return state.sideTitle
        },
        getSideBtns(state) {
            return state.sideBtns
        },
        getTreeData(state) {
            return state.treeData
        },
        getTreeSelect(state) {
            return state.treeSelect
        },
        getMainBtn(state) {
            return state.mainBtn
        },
        getHelpList(state) {
            return state.helpList
        },
        getSystemCode(state) {
            return state.systemCode || '1'
        },
        getEnableSendDate(state) {
            return state.enableSendDate
        }
    },
    mutations: {
        setOrgData(state, { data }) {
            state.ORG_DATA = data
        },
        setOrgTree(state, { data }) {
            state.ORG_TREE = data
        },
        setMenus(state, { data }) {
            state.JSD_MENUS = data
        },
        setToken(state, { data }) {
            state.token = data
        },
        setUser(state, { data }) {
            state.user = data
        },
        setTitle(state, { data }) {
            state.title = data
        },
        setSideTitle(state, { data }) {
            state.sideTitle = data
        },
        setSideBtns(state, { data }) {
            state.sideBtns = data
        },
        setTreeData(state, { data }) {
            state.treeData = data
        },
        setTreeSelect(state, { data }) {
            state.treeSelect = data
        },
        setMainBtn(state, { data }) {
            state.mainBtn = data
        },
        setHelpList(state, { data }) {
            state.helpList = data
        },
        setSystemCode(state, { data }) {
            state.systemCode = data
        },
        setEnableSendDate(state, { data }) {
            state.enableSendDate = data
        }
    },
    actions: {
        setOrgData({ state, commit }, { data }) {
            commit('setOrgData', { data })
        },
        setOrgTree({ state, commit }, { data }) {
            commit('setOrgTree', { data })
        },
        setMenus({ state, commit }, { data }) {
            commit('setMenus', { data })
        },
        setToken({ state, commit }, { data }) {
            commit('setToken', { data })
        },
        setUser({ state, commit }, { data }) {
            commit('setUser', { data })
        },
        setTitle({ state, commit }, { data }) {
            commit('setTitle', { data })
        },
        setSideTitle({ state, commit }, { data }) {
            commit('setSideTitle', { data })
        },
        setSideBtns({ state, commit }, { data }) {
            commit('setSideBtns', { data })
        },
        setTreeData({ state, commit }, { data }) {
            commit('setTreeData', { data })
        },
        setTreeSelect({ state, commit }, { data }) {
            commit('setTreeSelect', { data })
        },
        setMainBtn({ state, commit }, { data }) {
            commit('setMainBtn', { data })
        },
        setHelpList({ state, commit }, { data }) {
            commit('setHelpList', { data })
        },
        setSystemCode({ state, commit }, { data }) {
            commit('setSystemCode', { data })
        },
        setEnableSendDate({ state, commit }, { data }) {
            commit('setEnableSendDate', { data })
        },
        logout({ state, commit }) {
            commit('setToken', { data: '' })
            commit('setUser', { data: {} })
        },
    },
    modules: {

    },
})
