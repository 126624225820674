import axios from 'axios'
import store from '@/store'

const api = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Methods": "OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization, authorization",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": process.env.VUE_APP_API_HOST,
        "Authorization": store.getters.getToken ? "Bearer " + store.getters.getToken : ""
    },
    transformRequest: [
        (data) => {
            return JSON.stringify(data)
        },
    ],
    transformResponse: [
        (data) => {
            if (data) {
                return JSON.parse(data)
            }
        }
    ],
})

export default api;