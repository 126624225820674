<template>
    <div :class="listAreaClass">
        <ul>
            <li>
                <dl>
                    <template v-for="r in treeData">
                        <dt :class="{'select': r.value == treeSelect || r.isOpen, 'solo': !r.childs}" @click="itemClick(r)">
                            <div class="innerArrow" v-if="r.childs && r.childs.length">
                                <img src="../assets/images/box-arrow-right.gif">
                            </div>
                            {{r.title}}
                            <span class="right blue" v-if="r.useCnt">{{r.cnt}}</span>
                        </dt>
                        <dd v-if="r.childs && r.isOpen">
                            <dl>
                                <JSDTreeItem :item ="r.childs"></JSDTreeItem>
                            </dl>
                        </dd>
                    </template>
                </dl>
            </li>
        </ul>
    </div>
</template>

<script>
    import store from '@/store'
    import JSDTreeItem from '@/components/TreeItem.vue'
    /*
     * treeData
     * [
     *      { title: '제목', cnt: 0, value: '', childs: [] } 
     *      - title: 제목
     *      - cnt: 건 수
     *      - useCnt: 건수 표시 여부
     *      - value: 선택시 값
     *      - childs: 자식 배열
     * ]
    */
    export default {
        name: 'JSDMenuList',
        components: {
            JSDTreeItem,
        },
        computed: {
            treeData() {
                const result = store.getters.getTreeData
                for (var i = 0; i < result; i++) {
                    if (!result[i].hasOwnProperty('isOpen')) {
                        result[i].isOpen = false
                    }
                }
                return result
            },
            treeSelect() {
                return store.getters.getTreeSelect
            },
            listAreaClass() {
                return this.$route.path.indexOf('/app/user') > -1 ? 'userListArea' : 'treeListArea'
            }
        },
        methods: {
            itemClick(r) {
                if (r.hasOwnProperty('childs') && r.childs.length > 0) {
                    r.isOpen = !r.isOpen
                }
                else {
                    this.$callbackTreeData(r)
                }
            }
        }
    }
</script>