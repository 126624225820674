<template>
    <div class="box" v-if="!ui.isView && !ui.isEdit">
        <div class="pageTitleTab left" v-if="!isNotice">
            <div class="tabBtn" :class="{'select': api.list.PDS_CATE_NO == r.PDS_CATE_NO}" v-for="r in arr.cate" v-show="r.ORD_NO > 1" @click="moveCate(r.PDS_CATE_NO)">{{r.PDS_CATE_NM}}</div>
        </div>

        <div class="searchArea right">
            <input type="text" class="search" maxlength="20" placeholder="게시글 검색" @input="api.list.SEARCH_KEY = $event.target.value" @keyup.enter="movePage(1)"><div class="searchBtn" @click="movePage(1)">검색</div>
        </div>

    </div>

    <div class="box" v-if="!ui.isView && !ui.isEdit">
        <table class="tableList">

            <tbody>
                <tr>
                    <th>No</th>
                    <th>구분</th>
                    <th>제목</th>
                    <th>작성일</th>
                    <th>작성자</th>
                    <th>조회수</th>
                </tr>

                <tr v-for="r in arr.list" @click="view(r)">
                    <td>{{r.SEQ_NO}}</td>
                    <td>{{r.IS_IMPO_CHK ? '중요공지' : '일반공지'}}<br v-if="r.IS_IMPO_CHK">{{r.IS_IMPO_CHK ? `${r.IMPO_STRT_DA}~${r.IMPO_END_DA}` : ''}}</td>
                    <td>{{[r.IS_WEB ? '웹' : '',r.IS_APP ? '앱' : ''].join(' / ')}}</td>
                    <td>{{r.TITLE}}</td>
                    <td>{{r.REG_DA}}</td>
                    <td>{{r.ACNT_NM}}</td>
                </tr>

                <tr v-if="!arr.list.length">
                    <td colspan="6">조회된 게시글이 없습니다.</td>
                </tr>
            </tbody>
        </table>

        <div class="conBottom">
            <JSDPageCtrl ref="pageCtrl" :page="page" :cntPage="api.list.CNT_PAGE" :maxCnt="page.maxCnt" @page-move="movePage"></JSDPageCtrl>

            <div class="rightbtn">
                <select class="selectBox left" v-model="api.list.CNT_PAGE" @change="movePage(1)">
                    <option v-for="n in arr.cnt" v-bind:value="n">{{`${n}줄 보기`}}</option>
                </select>
            </div>
        </div>
    </div>

    <!-- #region 글 내용 -->
    <div class="box" v-if="ui.isView && !ui.isEdit">
        <table class="tableList">

            <tbody>
                <tr>
                    <th>No</th>
                    <th>말머리</th>
                    <th>제목</th>
                    <th>작성일</th>
                    <th>작성자</th>
                    <th>조회수</th>
                </tr>

                <tr class="select">
                    <td>{{arr.view.SEQ_NO}}</td>
                    <td>{{arr.view.IS_IMPO_CHK ? '중요공지' : '일반공지'}}<br v-if="arr.view.IS_IMPO_CHK">{{arr.view.IS_IMPO_CHK ? `${arr.view.IMPO_STRT_DA}~${arr.view.IMPO_END_DA}` : ''}}</td>
                    <td>{{[arr.view.IS_WEB ? '웹' : '',arr.view.IS_APP ? '앱' : ''].join(' / ')}}</td>
                    <td>{{arr.view.TITLE}}</td>
                    <td>{{arr.view.REG_DA}}</td>
                    <td>{{arr.view.ACNT_NM}}</td>
                </tr>

                <tr>
                    <td colspan="6" class="writeContentsView" v-html="arr.view.CONT"></td>
                </tr>


            </tbody>
        </table>

        <div class="conBottom">
            <div class="leftbtn">
                <div class="fnBtn" @click="goList()">목록으로</div>
            </div>
        </div>

    </div>

    <!-- 이전글 다음글 -->
    <div class="box" v-if="arr.prev.length || arr.next.length">
        <table class="tableList">
            <tbody>
                <tr>
                    <th>No</th>
                    <th>구분</th>
                    <th>표시</th>
                    <th>제목</th>
                    <th>작성일</th>
                    <th>작성자</th>
                </tr>

                <tr v-for="r in arr.prev" @click="view(r)">
                    <td>{{r.SEQ_NO}}</td>
                    <td>{{r.IS_IMPO_CHK ? '중요공지' : '일반공지'}}<br v-if="r.IS_IMPO_CHK">{{r.IS_IMPO_CHK ? `${r.IMPO_STRT_DA}~${r.IMPO_END_DA}` : ''}}</td>
                    <td>{{[r.IS_WEB ? '웹' : '',r.IS_APP ? '앱' : ''].join(' / ')}}</td>
                    <td>{{r.TITLE}}</td>
                    <td>{{r.REG_DA}}</td>
                    <td>{{r.ACNT_NM}}</td>
                </tr>

                <tr v-for="r in arr.next" @click="view(r)">
                    <td>{{r.SEQ_NO}}</td>
                    <td>{{r.IS_IMPO_CHK ? '중요공지' : '일반공지'}}<br v-if="r.IS_IMPO_CHK">{{r.IS_IMPO_CHK ? `${r.IMPO_STRT_DA}~${r.IMPO_END_DA}` : ''}}</td>
                    <td>{{[r.IS_WEB ? '웹' : '',r.IS_APP ? '앱' : ''].join(' / ')}}</td>
                    <td>{{r.TITLE}}</td>
                    <td>{{r.REG_DA}}</td>
                    <td>{{r.ACNT_NM}}</td>
                </tr>

                <tr v-if="!arr.prev.length">
                    <td colspan="6">이전 게시글이 없습니다.</td>
                </tr>
                <tr v-if="!arr.next.length">
                    <td colspan="6">다음 게시글이 없습니다.</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- #endregion -->
</template>

<script>
    import store from '@/store'

    import BoardService from '@/service/BoardService'
    import JSDPageCtrl from '@/components/PageCtrl.vue'

    export default {
        name: 'JSDUserBoard',
        components: {
            JSDPageCtrl,
        },
        computed: {
            treeSelect() {
                return store.getters.getTreeSelect
            },
            isNotice() {
                return this.$route.params.viewType == 'notice'
            },
            hasCate() {
                return this.$route.params.boardCate.length && this.$route.params.boardCate != undefined
            }
        },
        data() {
            return {
                ui: {
                    isView: false,
                    isEdit: false,
                },
                api: {
                    cate: {
                        VIEW_TYPE: '2',
                        BOARD_CATE_CD: '1',
                    },
                    list: {
                        LIST_TYPE: 'N',
                        BOARD_CATE_CD: '1',
                        SEARCH_KEY: '',
                        PDS_CATE_NO: -1,
                        VIEW_TYPE: '',
                        CNT_PAGE: 30,
                    },
                    board: {
                        LIST_TYPE: 'N',
                        PDS_NO: -1,
                        PDS_CATE_NO: -1,
                        SEARCH_KEY: '',
                    }
                },
                arr: {
                    cate:[],
                    list: [],
                    view: {},
                    prev: [],
                    next: [],
                    cnt: [30, 50 ,70, 100],
                },
                page: {
                    val: 1,
                    maxCnt: 1
                }
            }
        },
        methods: {
            init() {
                /// 카테고리 정보가 있으면 좌측메뉴 관련 초기화를 하지 않음
                if (!this.hasCate) {
                    this.$setSideTitle('게시판 목록')
                    this.$setSideBtns([])
                    this.$setTreeDataCallback((r) => {
                        this.$setTitle(`${r.BOARD_CATE_CD == '1' ? '공지사항' : '도움말'}${r.BOARD_CATE_CD == '1' ? '' : ' - ' + r.PDS_CATE_NM}`)
                        this.api.list.LIST_TYPE = r.BOARD_CATE_CD == '1' ? 'N' : 'H'

                        this.api.list.BOARD_CATE_CD = r.BOARD_CATE_CD
                        this.api.list.PDS_CATE_NO = r.PDS_CATE_NO

                        this.$setTreeSelect(`${r.BOARD_CATE_CD}-${r.PDS_CATE_NO}`)

                        const treeData = store.getters.getTreeData
                        treeData.forEach((r) => {
                            if (r.hasOwnProperty('isOpen')) {
                                r.isOpen = store.getters.getTreeSelect.startsWith(r.BOARD_CATE_CD)
                            }
                        })
                        this.$setTreeData(treeData)

                        this.movePage(1)
                        this.moveView('isList')
                        this.$movePage(`/app/board/${r.BOARD_CATE_CD == '1' ? 'notice' : 'help'}`)
                    })
                }

                this.$nextTick(() => {
                    this.api.list.LIST_TYPE = this.isNotice ? 'N' : 'H'
                    this.api.board.LIST_TYPE = this.isNotice ? 'N' : 'H'

                    this.getCate()
                })
            },
            getCate() {
                BoardService.cateList(this.api.cate, (res) => {
                    const data = res.data[0]
                    const cate = []

                    data.forEach((r) => {
                        if (r.ORD_NO > 1) {
                            const item = cate.find(c => c.BOARD_CATE_CD == r.BOARD_CATE_CD)
                            if (item) {
                                item.childs.push(Object.assign(r, {
                                    title: r.PDS_CATE_NM,
                                    value: `${r.BOARD_CATE_CD}-${r.PDS_CATE_NO}`,
                                }))
                            }
                        }
                        else {
                            r.isOpen = store.getters.getTreeSelect.startsWith(r.BOARD_CATE_CD)
                            cate.push(Object.assign(r, {
                                title: r.PDS_CATE_NM,
                                value: `${r.BOARD_CATE_CD}-${r.PDS_CATE_NO}`,
                                childs: []
                            }))
                        }
                    })

                    if (cate.length) {
                        const select = this.isNotice ? cate[0] : cate[1]
                        
                        let cateNo = 0
                        if (!this.isNotice) {
                            cateNo = this.$route.params.boardCate || select.childs[0].PDS_CATE_NO
                        }
                        this.api.list.PDS_CATE_NO = cateNo
                        this.$setTreeSelect(`${select.BOARD_CATE_CD}-${cateNo}`)
                        this.$setTitle(select.BOARD_CATE_CD == '1' ? '공지사항' : '도움말')
                    }

                    if (!this.hasCate) {
                        this.$setTreeData(cate)
                    }

                    if (this.$route.params.boardNo.length) {
                        this.api.list.LIST_TYPE = this.$route.params.viewType == 'notice' ? 'N' : 'H'
                        this.api.board.PDS_NO = this.$route.params.boardNo
                        this.api.board.PDS_CATE_NO = this.api.list.LIST_TYPE == 'N' ? -1 : this.$route.params.boardCate

                        this.getView()
                    }
                    else {
                        this.getList()
                    }
                })
            },
            getList() {
                BoardService.list(this.page.val, this.api.list, (res) => {
                    const { data } = res
                    const date = new Date()
                    if (this.api.list.LIST_TYPE == 'N') {
                        this.arr.list = data[0]

                        this.arr.list.forEach((r) => {
                            if (r.IMPO_STRT_DA.length && r.IMPO_END_DA.length) {
                                r.IS_IMPO_CHK = date > r.IMPO_STRT_DA.toDate('yyyy-MM-dd') && date < r.IMPO_END_DA.toDate('yyyy-MM-dd').addDate(1)
                            }
                        })
                    }
                    else {
                        data[0].forEach((r) => {
                            r.BOARD_CATE_CD = '2'
                        })
                        this.arr.cate = data[0]
                        this.arr.list = data[1]
                    }


                    this.page.maxCnt = this.arr.list.length ? this.arr.list[0].MAX_CNT : 1
                    this.$nextTick(() => {
                        this.$refs.pageCtrl.set()
                    })
                })
            },
            getView() {
                this.api.board.SEARCH_KEY = this.api.board.SEARCH_KEY
                BoardService.select(this.api.board.PDS_NO, this.api.board, (res) => {
                    const { data } = res
                    const date = new Date()

                    this.arr.view = data[0].length ? data[0][0] : null
                    this.arr.prev = data[1]
                    this.arr.next = data[2]

                    if (this.arr.view.IMPO_STRT_DA.length && this.arr.view.IMPO_END_DA.length) {
                        this.arr.view.IS_IMPO_CHK = date > this.arr.view.IMPO_STRT_DA.toDate('yyyy-MM-dd') && date < this.arr.view.IMPO_END_DA.toDate('yyyy-MM-dd').addDate(1)
                    }

                    if (!this.arr.view) {
                        alert('게시글 정보를 찾을 수 없습니다.')
                        this.goList()
                    }
                    this.moveView('isView')
                })
            },
            view(r) {
                const boardCate = this.api.list.LIST_TYPE == 'N' ? 0 : this.api.list.PDS_CATE_NO
                location.href = (`/app/board/${this.api.list.LIST_TYPE == 'N' ? 'notice' : 'help'}/${boardCate}/${r.PDS_NO}`)
            },
            clear() {
                this.api.list.SEARCH_KEY = ''

                this.api.board.CMD = 'I'
                this.api.board.PDS_NO = -1
            },
            moveCate(cateNo) {
                this.api.list.PDS_CATE_NO = cateNo
                this.movePage(1)
            },
            movePage() {
                this.page.val = 1
                this.getList()
            },
            moveView(view) {
                this.ui.isView = false
                this.ui.isEdit = false

                this.ui[view] = true

                if (view == 'isList') {
                    this.clear()
                    this.movePage(1)
                }
            },
            goList() {
                location.href = `/app/board/${this.$route.params.viewType}`
            },
        },
        mounted() {
            this.init()
        }
    }
</script>