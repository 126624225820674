<!--
Program Name    : UmsSendLayer.vue
Description		: 문자발송용 레이어 모음

Author		: 정우현
Create Date	:

History
-->


<template>
    <!-- #region 분할 발송 레이어 -->
    <div class="layer deletAlt msgDivision" v-show="layer.split">
        <div class="layerTitle">문자 메시지 분할 발송</div>

        <div class="layerMain">

            <div class="layerMainTitle">
                발송일시
            </div>

            <div class="overflow timeSelectArea" v-for="(r, idx) in arr.splitList">
                <!-- 수정시 나오는 화면 -->
                <div class="left mgR15">
                    <input type="date" v-model="r.SEND_DA" @change="changeDate(r)" v-if="idx == 0">

                    <span v-if="idx > 0">
                        {{r.DATE.toStr('yyyy년 MM월 dd일').substr(2)}}
                        {{`(${r.DATE.getDayName(false)})`}}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <select class="selectBox" style="margin-right:0;" v-model="r.SEND_HOUR">
                        <option v-for="r in $filters.range([7, 21])">{{r.zf(2)}}</option>
                    </select>:
                    <select class="selectBox" v-model="r.SEND_MIN">
                        <option v-for="r in $filters.range([0, 5])" :value="r*10">{{(r*10).zf(2)}}</option>
                    </select>
                </div>
                <!-- 수정시 나오는 화면 종료 -->
                <!--<div class="left retimeBlock" v-if="idx > 0">
                    {{r.DATE.toStr('yyyy년 MM월 dd일').substr(2)}}
                    <span class="">{{`(${r.DATE.getDayName(false)})`}}</span>
                    <select class="selectBox" style="margin-right:0;" v-model="r.SEND_HOUR">
                        <option v-for="r in $filters.range([7, 21])">{{r.zf(2)}}</option>
                    </select>:
                    <select class="selectBox" v-model="r.SEND_MIN">
                        <option v-for="r in $filters.range([0, 5])" :value="r*10">{{(r*10).zf(2)}}</option>
                    </select>
                </div>-->

                <div class="right">
                    <input style="width:40px;background:none;text-align:center;" type="text" v-model="r.SEND_CNT" readonly>건
                </div>
            </div>

            <div class="grayBox">
                클릭하여 발송 시작 일자를 수정하실 수 있습니다
            </div>
        </div>
        <div class="layerBtnArea">
            <button class="blueBtn" @click="callback('split')">확인</button>&nbsp;
            <button class="grayBtn" @click="hide('split')">취소</button>
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region 코인 부족 안내 -->
    <div class="layer deletAlt" v-show="layer.coin">
        <div class="layerTitle">코인 부족 안내</div>

        <div class="layerMain ">
            <div class="layerMainText">
                <span class="bold">코인이 부족</span>합니다. <br>
                <br>
                상생레터 웹사이트에서 코인 충전 후<br>다시 예약하여 주시기 바랍니다. <br>
                <br>
                <span class="bold">필요 코인</span> : <span class="bold red">{{needCoin.toComma()}} 코인 </span>
            </div>
        </div>
        <div class="layerBtnArea">
            <button class="blueBtn" @click="">코인충전</button>&nbsp;
            <button class="grayBtn" @click="hide('coin')">취소</button>
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region 폰발송 가능 건수 초과 -->
    <div class="layer deletAlt" v-show="layer.exceed">
        <div class="layerTitle">폰발송 가능 건수 초과 안내</div>

        <div class="layerMain ">
            <div class="layerMainText">
                <span class="bold red">당일 폰발송 가능 건수 ({{exceedPhone.toComma()}}건)를 초과</span>하였습니다. <br>
                <br>
                <span class="bold">
                    초과한 {{exceedCoin.toComma()}}건에 대해서 웹발송을 하거나 <br>
                    다른 날짜에 폰발송을 예약
                </span>하실 수 있습니다.

            </div>
            <div class="mgB10">
                <label><input type="radio" class="mgR05" v-model="exceedType" value="0" />다른 날짜에 폰발송 예약 (무료)</label>
            </div>
            <div class="mgB10">
                <label><input type="radio" class="mgR05" v-model="exceedType" value="1" />당일 코인발송 (<span class="blue">{{exceedPhone.toComma()}}</span>건 폰발송 + <span class="red">{{exceedCoin.toComma()}}건 코인발송)</span></label>
            </div>
            <div>
                <label><input type="radio" class="mgR05" v-model="exceedType" value="2" />메시지 작성 화면으로 돌아가기</label>
            </div>
        </div>
        <div class="layerBtnArea">
            <button class="blueBtn" @click="exceed()">확인</button>&nbsp;
            <button class="grayBtn" @click="hide('exceed')">취소</button>
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region 예약 발송 레이어 -->
    <div class="layer deletAlt" v-show="layer.resv">
        <div class="layerTitle">예약발송 시간 설정</div>

        <div class="layerMain ">
            <div class="timeSelectArea">
                일시 <input type="date" v-model="resvDate">

                <select class="selectBox" v-model="resvHour">
                    <option v-for="r in $filters.range([7, 21])" :value="r">{{r.zf(2)}}</option>
                </select>:
                <select class="selectBox" v-model="resvMin">
                    <option v-for="r in $filters.range([0, 5])" :value="r * 10">{{(r * 10).zf(2)}}</option>
                </select>

                <!-- <input type="time" step="1800"  min="09:00" max="19:00"  required> -->
            </div>

            <div class="layerMainText mgB15">
                <!--<p class="bold">{{custs}}에게 지정된 시간에<br> 문자 메시지를 예약 발송합니다.</p>-->
                <p>예약 취소는 예약리스트에서 가능합니다.</p>
                <p>
                    <span class="bold">&lt;글 저장 안내&gt;</span><br />
                    <span class="bold">'문자 내용 저장 및 예약'</span> 버튼을 누르시면, 즐겨찾기 글에 문자 내용이 저장됩니다. (이미지는 저장되지 않습니다.)
                    <br /><br />
                    <span class="bold">즐겨찾기 글은 최대 50개까지</span> 저장할 수 있으며, 초과하는 경우 추가로 저장되지 않으므로 주의하시기 바랍니다.
                </p>
            </div>
        </div>
        <div class="layerBtnArea">
            <button class="blueBtn" @click="callback('resv:save')">문자 내용<br />저장 및 예약</button>&nbsp;
            <button class="blueBtn" @click="callback('resv')">예약 발송</button>&nbsp;
            <button class="grayBtn" @click="hide('resv')">취소</button>
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region 예약 발송 레이어 -->
    <div class="layer deletAlt" v-show="layer.send">
        <div class="layerTitle">알림</div>

        <div class="layerMain ">
            <div class="layerMainText mgB15">
                <!--<p class="bold">{{custs}}에게 지정된 시간에<br> 문자 메시지를 예약 발송합니다.</p>-->
                <p>메시지를 발송하시겠습니까?</p>
                <p>
                    <span class="bold">&lt;글 저장 안내&gt;</span><br />
                    <span class="bold">'문자 내용 저장 및 예약'</span> 버튼을 누르시면, 즐겨찾기 글에 문자 내용이 저장됩니다. (이미지는 저장되지 않습니다.)
                    <br /><br />
                    <span class="bold">즐겨찾기 글은 최대 50개까지</span> 저장할 수 있으며, 초과하는 경우 추가로 저장되지 않으므로 주의하시기 바랍니다.
                </p>
            </div>
        </div>
        <div class="layerBtnArea">
            <button class="blueBtn" @click="callback('send:save')">문자 내용<br />저장 및 발송</button>&nbsp;
            <button class="blueBtn" @click="callback('send')">문자 발송</button>&nbsp;
            <button class="grayBtn" @click="hide('send')">취소</button>
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region 회신번호 등록 레이어-->
    <div class="layer deletAlt" v-show="layer.certPhone">
        <div class="layerTitle">알림</div>

        <div class="layerMain ">
            문자 발송을 위해서 발신번호를 등록해주세요. <br>
            <br>
            <b>발신번호는 본인명의의 휴대폰 번호만 등록</b>하실 수 있습니다.

        </div>

        <div class="layerBtnArea mgT10">
            <button class="blueBtn" @click="$movePage('/app/mypage/send')">발신번호 등록</button>
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region -->
    <div style="background:#ffffff; position:fixed; top:calc(50% - 150px); left:calc(50% - 150px); width:300px; padding:20px; border:1px solid #b1b0b0; border-top:4px solid #0a73f5; box-shadow:3px 3px 5px rgba(0,0,0,0.2);z-index:1001;" v-show="layer.stop">
        <div style="text-align:center; font-weight:bold; font-family:malgun-godic;letter-spacing:-1px;font-size:18px;border-bottom:1px solid #b1b0b0;padding:0 0 10px;">
            모든 발송(폰/웹)이 중지 되었습니다.
        </div>
        <div style="text-align:center; padding:15px 0 20px; font-family:malgun-godic;letter-spacing:-1px;font-size:15px;">
            발송중지 상태 해제는 본사의 확인을 거친후<br>
            진행 됩니다 (tel. 02-719-4300)
        </div>
        <div style="text-align:center;">
            <button type="button" style="width:150px;" @click="hide('stop')">확인</button>
        </div>
    </div>
    <!-- #endregion -->
</template>

<script>
    import store from '@/store'

    import UmsService from '@/service/UmsService'
    import UserService from '@/service/UserService'

    export default {
        name: 'UmsSendLayer',
        props: {
            custList: Array,
            SEND_CAPA_CNT: Number,
            COIN_BAL_AMT: Number,
        },
        components: {

        },
        computed: {
            custs() {
                if (this.arr.custList.length) {
                    const cust = []
                    cust.push(this.arr.custList[0].CUST_NM)
                    if (this.arr.custList.length > 1) {
                        cust.push(`외 ${this.arr.custList.length - 1}명`)
                    }
                    return cust.join(' ')
                }
                return ''
            }
        },
        data() {
            return {
                layer: {
                    split: false,
                    exceed: false,
                    coin: false,
                    resv: false,
                    certPhone: false,
                    send: false,
                    stop: false,
                },
                arr: {
                    custList: [],
                    splitList: [],
                },
                exceedType: 0,
                exceedPhone: 0,
                exceedCoin: 0,
                needCoin: 0,
                resvDate: new Date().toStr('yyyy-MM-dd'),
                resvHour: new Date().toStr('HH'),
                resvMin: new Date().toStr('mm'),
                IS_STOP: false,
            }
        },
        methods: {
            init(s) {
                switch (s) {
                    case 'split':
                        break;
                    case 'exceed':
                        this.exceedType = 0
                        this.exceedPhone = 0
                        this.exceedCoin = 0
                        break;
                    case 'coin':
                        this.needCoin = 0
                        break;
                    case 'resv':
                        this.resvDate = new Date().toStr('yyyy-MM-dd')
                        this.resvHour = new Date().toStr('HH')
                        this.resvMin = new Date().toStr('mm')
                        break;
                }
            },
            show(s) {
                setTimeout(() => {
                    this.layer[s] = true
                    this.$root.setLoading(true)
                }, 500)
            },
            hide(s) {
                this.layer[s] = false
                this.init(s)
                this.$root.setLoading(false)
            },
            getSendStopConfig() {
                UserService.conf_bulk_list({
                    DATA: 'EM3'
                }, (res) => {
                    const { EM3 } = res.data
                    this.IS_STOP = EM3 == 'Y'
                    if (this.IS_STOP) {
                        this.show('stop')
                    }
                })
            },
            getSplitInfo(date) {
                // 문자 메시지 분할 발송 레이어 화면
                // 폰발송 분할 건수와 일자를 띄운다.
                this.arr.custList = this.custList

                const end = date.clone()
                end.addMonth(2)

                // 폰발송 분산건수 정보를 가져온다.
                UmsService.ums_smt_stat({
                    VIEW_YM: '',
                    STRT_DA: date.toStr('yyyyMMdd'),
                    END_DA: end.toStr('yyyyMMdd'),
                    VIEW_DT: '',
                    SEND_TIME: date.toStr('HH:mm'),
                    SEND_CNT: this.arr.custList.length,
                    VIEW_TYPE: 3
                }, (res) => {
                    if (!this.layer.split) {
                        this.show('split')
                    }                    

                    res.data.forEach((r) => {
                        const sendDt = `${r.SOLAR_DA} ${r.SEND_TIME}`.toDate('yyyyMMdd HH:mm')
                        var min = sendDt.getMinutes()
                        min = ((min % 10 == 0) ? (min / 10) + 1 : Math.ceil(min / 10)) * 10
                        sendDt.setMinutes(min)
                        r.SEND_DA = sendDt.toStr('yyyy-MM-dd')
                        r.SEND_HOUR = sendDt.getHours()
                        r.SEND_MIN = sendDt.getMinutes()
                        r.DATE = sendDt
                    })
                    
                    this.arr.splitList = res.data
                })
            },
            getExceed() {
                this.arr.custList = this.custList
                this.exceedPhone = this.SEND_CAPA_CNT
                this.exceedCoin = this.custList.length - this.SEND_CAPA_CNT
                this.show('exceed')
                console.log(this.exceedType)
            },
            getResv(date) {
                var min = date.getMinutes()
                min = ((min % 10 == 0) ? (min / 10) + 1 : Math.ceil(min / 10)) * 10
                date.setMinutes(min)

                this.arr.custList = this.custList
                this.resvDate = date.toStr('yyyy-MM-dd')
                this.resvHour = date.getHours()
                this.resvMin = date.getMinutes()

                this.show('resv')
            },
            changeDate(r) {
                const date = `${r.SEND_DA} ${r.SEND_TIME}`.toDate('yyyy-MM-dd HH:mm')
                r.DATE = date
                this.getSplitInfo(date)
            },
            getCoin(coinCalc) {
                this.needCoin = coinCalc - this.COIN_BAL_AMT
                this.show('coin')
            },
            callback(type) {
                //레이어 버튼 클릭 처리
                const ret = {
                    type,
                }

                if (type == 'split') {
                    //분산 발송 일때 체크
                    //console.log(this.arr.splitList);

                    if (this.arr.splitList.length) {
                        if (this.arr.splitList[0].DATE < new Date()) {
                            alert('분산발송 시작 일시는 과거일 수 없습니다.')
                            return
                        }
                        for (var i = 0; i < this.arr.splitList.length; i++) {
                            const date = `${this.arr.splitList[i].SEND_DA} ${this.arr.splitList[i].SEND_HOUR}:${this.arr.splitList[i].SEND_MIN.zf(2)}`.toDate('yyyy-MM-dd HH:mm')
                            this.arr.splitList[i].DATE = date
                        }
                        //console.log(this.arr.splitList);
                        this.hide('split')
                        ret['data'] = {
                            list: this.arr.splitList
                        }
                    }
                }
                else if (type == 'resv' || type == 'resv:save') {
                    const date = `${this.resvDate} ${this.resvHour.zf(2)}:${this.resvMin.zf(2)}`.toDate('yyyy-MM-dd HH:mm')
                    if (date < new Date()) {
                        alert('예약일시는 과거일 수 없습니다.')
                        return
                    }
                    this.hide('resv')
                    ret['data'] = {
                        date,
                        isSave: type == 'resv:save'
                    }
                }
                else if (type == 'send' || type == 'send:save') {
                    this.hide('send')
                    ret['data'] = {
                        isSave: type == 'send:save'
                    }
                }
                this.$emit('layer-callback', ret)
            },
            exceed() {
                if (this.exceedType == 0) {
                    this.hide('exceed')
                    this.getSplitInfo(new Date())
                }
                else if (this.exceedType == 1) {
                    this.hide('exceed')
                    this.callback('exceed')

                }
                else {
                    this.hide('exceed')
                }
            },
        },
        mounted() {
            if (this.$root.user && !this.$root.user.SEND_PHON_CNO) {
                this.show('certPhone')
                this.$root.setLoading(true)
            }
            this.getSendStopConfig()
        }
    }
</script>