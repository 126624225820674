
    import store from '@/store'
    import CommomService from '@/service/CommomService'
    import GroupService from '@/service/GroupService'
    import UserService from '@/service/UserService'
    import draggable from 'vuedraggable'
    import UserLeftMenu from '@/types/user_leftmenu'
    import HanKeys from '@/types/hanKeys'
    import CustSelectFilter from '@/components/CustSelectFilter.vue';
    var _ = require('lodash')
    export default {
        name: 'GroupList',
        components: {
            draggable,
            CustSelectFilter,
        },
        data() {
            return {
                ui: {
                    del_layer: false,     //그룹삭제 레이어
                    excl_layer: false,    //그룹제외 레이어
                    move_layer: false,    //그룹이동/복사 레이어
                    edit_layer: false,    //그룹수정 레이어
                    mesg_layer: false,    //결과알림 레이어
                    not_group_del_layer: false,    //미지정 그룹 비우기
                    group_init_layer: false,     //그룹초기화레이어
                    group_nm: '',           //그룹 추가에 사용하는 그룹명
                    edit_group_nm: '',      //그룹 삭제나 그룹명 수정에 사용하는 변수

                    group_proc: {
                        cmd: '',            //excl : 그룹에서 제외,
                        //del: 연락처 삭제
                        //move: 그룹이동
                        //copy: 그룹복사

                        cnt: 0,             //삭제나 제외할 건수
                        group_nm: '',      //현재 그룹명
                        txt: '',            //삭제나 제외 타이틀이 옴
                        group_no: 0,        //삭제나 제외할 그룹번호
                        idx: 0,             //삭제나 제외할 index
                        cust_nos: '',       //처리할 연락처번호들
                        group_info: [],     //그룹선택 select에 사용될 현재그룹제외 그룹정보
                        new_group_no: 0,    //신규선택그룹번호
                    },


                    //상세조건들. 필터 값
                    filter_val: {
                        BLVR_CATE: '0',     //0:전체 1:상생방송 :4:역사광복 9:미지정
                        SEX: '',
                        RTNG: '',
                        BLVR_RELA: '',
                        REGI: '',
                        SELECT_TXT: '',
                        BLVR_CATE_CD: '',
                        BOOK_NOS: '',
                    },
                },
                api: {
                    list: {
                        //list조회를 위한 api
                        BLVR_CATE: '0',           //0: 전체 1: 상생 4: 역사 9: 미지정
                        HAN_KEY: '',            //가나다순검색에 사용되는 한글 초성값 ㄱ~ㅎ A F O Z #
                        SEARCH_KEY: '',         //검색 KEY(이름/ 휴대폰 번호)
                        SEX: '',                //성별 ','로 연결된 문자열
                        RTNG: '',               //연락처 등급 ','로 연결된 문자열
                        BLVR_RELA: '',          //신도와의관계코드 ','로 연결된 문자열
                        REGI: '',               //지역 ','로 연결된 문자열
                        GROUP_NO: 0,            //그룹선택시 그룹번호가 온다.   -1:미지정 NULL(0)이면 그룹상관없이 전체 조회
                        PAGE: 1,                //현재 조회하는 page
                        CNT_PAGE: 10,           //조회페이지당 건수
                        BLVR_CATE_CD: '',
                        BOOK_NOS: '',
                    },
                    edit: {
                        CMD: '',            //I:등록 U:수정 D:삭제
                        GROUP_NO: 0,
                        GROUP_NM: '',
                        GROUP_NOS: '',     //그룹일괄삭제에 사용함.
                        DEL_TYPE: 1,       //1:그룸만삭제 2:그룹에속한 고객도 삭제(휴지통)
                    },
                },
                arr: {
                    group_list: [],         //그룹 리스트
                    han_key_list: [],       //상단 ㄱㄴㄷ검색
                    group_cust_list: [],     //그룹별 연락처 리스트
                    group_org_list: [],
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                this.$setTitle('그룹 관리');
                //좌측메뉴 제목
                this.$setSideTitle('');
                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);
                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                //이동과 복사에 사용할 그룹정보리스트
                GroupService.group_title_list(
                    (res) => {
                        this.arr.group_org_list = res.data[0];
                        //console.log(this.arr.group_org_list );
                    });

                this.$nextTick(() => {
                    for (var i = 0; i < HanKeys.han_keys.length; i++) {
                        this.arr.han_key_list.push({ "title": HanKeys.han_keys[i].title, "value": HanKeys.han_keys[i].value });
                        // console.log({ 'title': HanKeys.han_keys[i].title, 'value': HanKeys.han_keys[i].value })
                    }
                    this.getList('');
                });
            },
            getList(view_type: string) {
                //
                // 그룹 리스트 - 연락처 카운트 적용 및 필터링 적용
                //  view_type : '' (전체 신규 조회 및 모든 연락처상세 off, 전체선택 off)
                //                  - 초기화면 그룹추가/삭제/
                //              'custs' : 현재그룹별 인원건수 재설정 및 그룹별 고객리스트 reload처리
                //                  - 그룹명 변경, 그룹내 인원 이동/복사/삭제

                GroupService.group_cust_list(
                    {
                        BLVR_CATE: this.api.list.BLVR_CATE,
                        HAN_KEY: this.api.list.HAN_KEY,
                        SEARCH_KEY: this.api.list.SEARCH_KEY,
                        SEX: this.api.list.SEX,
                        RTNG: this.api.list.RTNG,
                        BLVR_RELA: this.api.list.BLVR_RELA,
                        REGI: this.api.list.REGI,
                        BOOK_NOS: this.api.list.BOOK_NOS,
                    },
                    (res) => {
                        this.arr.group_list = res.data[0];

                        if (view_type === '') {
                            // 그룹별 상세 연락처 리스트 초기화 처리
                            // 그룹 전체 리스트및 연락처 건수를 설정하고 하위 연락처 리스트를 초기화 및 화면을 닫는 상태로 만든다.
                            this.arr.group_cust_list = [];
                            for (var i = 0; i < this.arr.group_list.length; i++) {
                                this.arr.group_cust_list.push({
                                    is_all: false,
                                    is_view: false,
                                    custs: [],
                                    cust_nos: [],
                                })
                            }
                        }
                        else if (view_type === 'custs') {
                            // 그룹별 상세 연락처 리스트중 화면이 열린것만 reload처리한다.
                            // 그룹원에 대한 작업의 경우 인원명단 변경이 있어 열린 그룹의 연락처리스트를 reload한다
                            for (var i = 0; i < this.arr.group_cust_list.length; i++) {
                                if (this.arr.group_cust_list[i].is_view === true) {
                                    this.cust_list_view(this.arr.group_list[i].GROUP_NO, this.arr.group_cust_list[i])
                                }
                            }
                        }
                    });
            },
            cust_list_view(group_no, group_cust) {
                //
                // 특정 그룹내 연락처 리스트만 다시 요청하여 구성한다.
                //  - 그룹이 열려있는 상태의 건만 처리되게 한다.
                //  group_no : 그룹번호
                //  group_cust : 그룹별 연락처

                //console.log(group_cust)
                if (group_cust.is_view) {
                    GroupService.group_cust_detail_list(
                        {
                            GROUP_NO: group_no,
                            BLVR_CATE: this.api.list.BLVR_CATE,
                            HAN_KEY: this.api.list.HAN_KEY,
                            SEARCH_KEY: this.api.list.SEARCH_KEY,
                            SEX: this.api.list.SEX,
                            RTNG: this.api.list.RTNG,
                            BLVR_RELA: this.api.list.BLVR_RELA,
                            REGI: this.api.list.REGI,
                            BOOK_NOS: this.api.list.BOOK_NOS,
                        },
                        (res) => {
                            group_cust.custs = res.data[0];
                        });
                }
            },
            group_detail_check(group, group_cust) {
                //
                //  group : 그룹정보 object
                //  group_cust : 그룹별 연락처 object
                //  특정 그룹을 클릭시 해당 그룹이 닫힌상태면 열게하고
                //  열린상태는 화면만 닫게 한다.
                //

                //console.log('group_detail_check')
                //console.log(group)
                //console.log(group_cust)

                //처리한 연락처 건수가 있어야 처리되게 한다.
                if (group.CNT <= 0) {
                    return
                }

                //그룹별 연락처를 toggle처리한다
                group_cust.is_view = !group_cust.is_view;

                if (group_cust.is_view) {
                    // 그룹별 연락처가 open이면 해당 리스트를 가져온다.
                    this.cust_list_view(group.GROUP_NO, group_cust)
                }
            },
            group_check_all(group_cust) {
                //
                // 해당 그룹별 연락처 전체 체크 여부
                //  group_cust : 그룹별 연락처 object

                //console.log(group_cust)

                if (group_cust.is_all) {
                    group_cust.is_view = true;      //체크일때 화면을 연다.
                    group_cust.cust_nos = []

                    for (var i = 0; i < group_cust.custs.length; i++) {
                        group_cust.cust_nos.push(group_cust.custs[i].CUST_NO)
                    }
                }
                else {
                    group_cust.cust_nos = []
                }

                //console.log(group_cust)
            },
            select_assign(type, obj) {
                //
                // 검색 조건을 변경한다.
                // type : 클릭한 항목
                // obj : 선택값
                if (type == 'ㄱㄴㄷ') {
                    // obj에 row-object가 옴
                    this.api.list.HAN_KEY = obj['value'];
                }
                else if (type == 'BLVR_CATE') {
                    // obj에 선택값이 옴
                    this.api.list.BLVR_CATE = obj
                    this.ui.filter_val.BLVR_CATE = obj
                }
                this.getList('');
            },
            setCustSelectFilter(value) {
                //
                // 연락처 상세조회 - 선택값을 받아서 this.api.list에 SET한다
                //
                console.log(value);
                this.ui.filter_val = value;
                this.api.list.BLVR_CATE = this.ui.filter_val.BLVR_CATE     //0: 전체 1: 상생 4: 역사 9: 미지정
                this.api.list.SEX = this.ui.filter_val.SEX                 //성별 ','로 연결된 문자열
                this.api.list.RTNG = this.ui.filter_val.RTNG               //연락처 등급 ','로 연결된 문자열
                this.api.list.BLVR_RELA = this.ui.filter_val.BLVR_RELA     //신도와의관계코드 ','로 연결된 문자열
                this.api.list.REGI = this.ui.filter_val.REGI               //지역
                this.api.list.BLVR_CATE_CD = this.ui.filter_val.BLVR_CATE_CD    //대상자 선택
                this.api.list.BOOK_NOS = this.ui.filter_val.BOOK_NOS        //선택도서
                this.getList('');
            },
            select_layerView() {
                //
                // 연락처 상세조회 레이어창
                //
                //console.log(this.ui.filter_val);
                this.all_layer_off();
                this.$refs.CustSelectFilter.view();
            },
            group_add() {
                //
                // group 추가
                //
                //console.log(this.ui.group_nm)
                if (this.ui.group_nm.koLen() < 4) {
                    alert('새 그룹명은 2글자(2byte)이상을 입력해 주세요.');
                    this.$refs.group_nm.focus();
                    return;
                }
                //같은 그룹명이 있으면 체크
                let idx = this.arr.group_list.findIndex(x => x.GROUP_NM == this.ui.group_nm)
                if (idx >= 0) {
                    alert('동일한 그룹명을 등록하실수 없습니다.');
                    return
                }
                //api에 값추가
                this.api.edit.CMD = 'I';
                this.api.edit.GROUP_NM = this.ui.group_nm;
                GroupService.group_edit(
                    this.api.edit,
                    (res) => {
                        if (res.data > 0) {
                            alert('새그룹 추가가 완료되었습니다.');
                            this.getList('');
                            this.ui.group_nm = ''
                        }
                        else {
                            alert('새그룹 추가에 실패했습니다.');
                        }
                    }
                );
            },
            order_update(group_no, cmd) {
                //
                // 그룹순서 변경
                // cmd - up/down/top/bottom
                let cur_idx: number = -1;
                let max_idx: number = -1;
                let next_idx: number = -1;
                let tmp_obj: any = null;
                for (var i = 0; i < this.arr.group_list.length; i++) {
                    if (this.arr.group_list[i].GROUP_NO == group_no) {
                        cur_idx = i;
                        max_idx = this.arr.group_list.length - 1;
                        break;
                    }
                }
                if (cmd === 'up') {
                    if (cur_idx === 0) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = cur_idx - 1;
                    tmp_obj = this.arr.group_list[next_idx];
                    this.arr.group_list[next_idx] = this.arr.group_list[cur_idx];
                    this.arr.group_list[cur_idx] = tmp_obj;
                }
                else if (cmd === 'top') {
                    if (cur_idx === 0) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = 0;
                    tmp_obj = this.arr.group_list[cur_idx];
                    //console.log(cur_idx)
                    for (var i = cur_idx - 1; i >= 0; i--) {
                        //console.log(i)
                        this.arr.group_list[i + 1] = this.arr.group_list[i];
                    }
                    this.arr.group_list[next_idx] = tmp_obj;
                }
                else if (cmd === 'down') {
                    if (cur_idx === max_idx) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = cur_idx + 1;
                    tmp_obj = this.arr.group_list[next_idx];
                    this.arr.group_list[next_idx] = this.arr.group_list[cur_idx];
                    this.arr.group_list[cur_idx] = tmp_obj;
                }
                else if (cmd === 'bottom') {
                    if (cur_idx === max_idx) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    tmp_obj = this.arr.group_list[cur_idx];
                    for (var i = cur_idx; i < max_idx; i++) {
                        this.arr.group_list[i] = this.arr.group_list[i + 1];
                    }
                    this.arr.group_list[max_idx] = tmp_obj;
                }
                //변경된 정렬순서를 반영한다.
                this.order_update_save();
            },
            order_update_save() {
                //
                // 그룹수정화면 저장
                //  - 정렬순서 및 수정된 명칭 저장
                ////동일한 그룹명이 있는지 체크
                //for (var i = 0; i < this.arr.group_list.length; i++) {
                //    let group_nm = this.arr.group_list[i].GROUP_NM
                //    let idx = this.arr.group_list.findIndex(x => x.GROUP_NM == group_nm)
                //    if (idx >= 0 && idx != i) {
                //        alert('동일한 그룹명을 등록하실수 없습니다.');
                //        return
                //    }
                //}
                let group_info = [];
                let data: string = '';
                for (var i = 0; i < this.arr.group_list.length; i++) {
                    group_info.push({
                        "GROUP_NO": this.arr.group_list[i].GROUP_NO,
                        "GROUP_NM": this.arr.group_list[i].GROUP_NM,
                        "ORD_NO": i + 1
                    });
                }
                data = JSON.stringify(group_info);
                GroupService.group_bulk_update(
                    {
                        GROUP_INFO: data
                    },
                    (res) => {
                        if (res.data > 0) {
                            this.getList('');
                        }
                        else {
                            alert('그룹 순서변경이 실패했습니다.');
                        }
                    }
                );
            },
            group_init() {
                //
                // 그룹전체 초기화
                //
                GroupService.group_cust_init(
                    {
                        CMD: "G",
                    },
                    (res) => {
                        if (res.data > 0) {
                            alert('그룹 리스트 초기화에 성공했습니다.');
                            this.getList('');
                        }
                        else {
                            alert('그룹 리스트 초기화에 실패했습니다.');
                        }
                        this.all_layer_off();
                    }
                );
            },
            all_layer_off() {
                //
                // 현재 사용하는 모든 레이어를 off시킨다.
                // 레이어창이 이중으로 뜰수도 있음으로 모두 off후 작업하게 한다.
                this.ui.del_layer = false     //그룹삭제 레이어
                this.ui.excl_layer = false    //그룹제외 레이어
                this.ui.move_layer = false    //그룹이동/복사 레이어
                this.ui.edit_layer = false    //그룹수정 레이어
                this.ui.mesg_layer = false    //결과알림 레이어
                this.ui.group_init_layer = false    //결과알림 레이어
                this.ui.not_group_del_layer = false     //미지정그룹 삭제 레이어
            },
            group_del_layer(obj) {
                //
                // 삭제할 그룹번호를 설정하고
                //
                this.ui.edit_group_nm = obj['GROUP_NM']
                this.api.edit.GROUP_NO = Number(obj['GROUP_NO']);
                this.api.edit.GROUP_NOS = '';
                this.all_layer_off()
                this.ui.del_layer = true;
            },
            group_edit_layer(obj) {
                //
                //  그룹명 수정
                //
                this.ui.edit_group_nm = obj['GROUP_NM']
                this.api.edit.GROUP_NO = Number(obj['GROUP_NO']);
                this.api.edit.GROUP_NOS = '';
                this.all_layer_off()
                this.ui.edit_layer = true;
            },
            group_del() {
                //
                // 그룹삭제 - 실제 그룹 자체를 삭제하고 화면 reload
                //
                this.api.edit.CMD = 'D';
                GroupService.group_edit(
                    this.api.edit,
                    (res) => {
                        this.all_layer_off();
                        if (res.data > 0) {
                            alert('그룹 삭제가 완료되었습니다.');
                            this.getList('');
                        }
                        else {
                            alert('그룹 삭제에 실패했습니다.');
                        }
                    }
                );
            },
            not_group_del() {
                //
                // 미지정 그룹 비우기 - 미지정그룹에 있는 연락처 비우기(휴지통이동)
                //
                this.api.edit.CMD = 'D';
                this.api.edit.GROUP_NO = -1;
                this.api.edit.DEL_TYPE = 2       //1:그룸만삭제 2:그룹에속한 고객도 삭제(휴지통)

                GroupService.group_edit(
                    this.api.edit,
                    (res) => {
                        this.all_layer_off();
                        if (res.data > 0) {
                            alert('미지정 그룹 연락처가 모두 휴지통으로 이동되었습니다.');
                            this.getList('');
                        }
                        else {
                            alert('미지정 그룹 비우기에 실패했습니다.');
                        }
                    }
                );
            },
            group_update() {
                //
                // 그룹명수정
                //
                if (this.ui.edit_group_nm.koLen() < 4) {
                    alert('수정하실 그룹명은 2글자(2byte)이상을 입력해 주세요.');
                    this.$refs.edit_group_nm.focus();
                    return;
                }
                //같은 그룹명이 있으면 체크
                let idx = this.arr.group_list.findIndex(x => x.GROUP_NM == this.ui.edit_group_nm)
                if (idx >= 0) {
                    alert('이미등록된 그룹명으로 수정하실수 없습니다.');
                    return
                }
                this.api.edit.CMD = 'U';
                this.api.edit.GROUP_NM = this.ui.edit_group_nm;
                GroupService.group_edit(
                    this.api.edit,
                    (res) => {
                        this.all_layer_off();
                        if (res.data > 0) {
                            alert('그룹명 수정이 완료되었습니다.');
                            this.getList('custs');
                        }
                        else {
                            alert('그룹명 수정에 실패했습니다.');
                        }
                    }
                );
            },

            group_custs_proc(cmd, group, group_cust) {
                //
                // 그룹 삭제/제외/이동/복사 설정
                //  cmd : excl : 그룹에서 제외,
                //        del : 연락처 삭제
                //        move : 그룹이동
                //        copy : 그룹복사
                //  group : 그룹정보
                //  group_cust : 그룹내 연락처 정보
                //  group_proc: {
                //  cnt: 0,            //삭제나 제외할 건수
                //  group_nm : '',      //현재 그룹명
                //  txt: '',       //삭제나 제외 타이틀이 옴
                //  group_no: 0,        //삭제나 제외할 그룹번호
                //  idx: 0,            //삭제나 제외할 index
                //  },

                this.ui.group_proc.cmd = cmd
                this.ui.group_proc.cnt = group_cust.cust_nos.length
                this.ui.group_proc.group_nm = group.GROUP_NM;
                this.ui.group_proc.group_no = group.GROUP_NO;


                if (this.ui.group_proc.cnt == 0) {
                    alert('선택한 연락처가 없습니다.');
                    return;
                }

                this.ui.group_proc.cust_nos = group_cust.cust_nos.join(',');
                this.ui.group_proc.group_info = []      //그룹선택용 그룹정보

                if (cmd === 'excl') {
                    this.ui.group_proc.txt = '제외'
                    this.ui.excl_layer = true
                }
                else if (cmd === 'del') {
                    this.ui.group_proc.txt = '삭제'
                    this.ui.excl_layer = true
                }
                else if (cmd === 'move') {
                    this.ui.group_proc.txt = '이동'
                    this.ui.move_layer = true
                }
                else if (cmd === 'copy') {
                    this.ui.group_proc.txt = '복사'
                    this.ui.move_layer = true
                }

                //현재 open그룹외의 그룹을 필터링한다.
                let org_list = this.arr.group_org_list
                for (var i = 0; i < org_list.length; i++) {
                    if (org_list[i].GROUP_NO != group.GROUP_NO) {
                        this.ui.group_proc.group_info.push({
                            GROUP_NO: org_list[i].GROUP_NO,
                            GROUP_NM: org_list[i].GROUP_NM,
                        })
                    }
                }

            },
            group_custs_clear() {
                //
                // 그룹작업후 선택한 연락처 번호들을 초기화한다.
                //
                for (var i = 0; i < this.arr.group_list.length; i++) {
                    this.arr.group_cust_list[i].cust_nos = []
                }
            },
            group_custs_exec() {
                //
                // 그룹 삭제/제외/이동/복사 실행
                // - group_custs_proc에서 설정된 정보를 가지고 실제 실행한다
                //

                let cmd = this.ui.group_proc.cmd;
                let cust_nos = this.ui.group_proc.cust_nos;
                let group_no = this.ui.group_proc.group_no;
                let new_group_no = this.ui.group_proc.new_group_no;
                let cmd_txt = '';
                let mesg = '';

                if (cmd === 'del') {
                    UserService.cust_del(
                        {
                            CUST_NOS: cust_nos,
                        },
                        (res) => {

                            if (res.data > 0) {
                                mesg = '연락처 삭제에 성공했습니다.'
                                this.ui.group_proc.cust_nos = []
                                this.all_layer_off()
                                this.getList('custs');
                            }
                            else {
                                mesg = '연락처 삭제에 실패했습니다.';
                            }
                            alert(mesg);
                        }
                    );
                }
                else {
                    if (cmd === 'move') {
                        cmd_txt = 'M'
                    }
                    else if (cmd === 'copy') {
                        cmd_txt = 'C'
                    }
                    else {
                        cmd_txt = 'D'
                    }

                    GroupService.group_cust_bulk_update(
                        {
                            CMD: cmd_txt,            //M:그룹이동 C:그룹복사 D:그룹제외
                            CUST_NOS: cust_nos,
                            GROUP_NO: group_no,
                            NEW_GROUP_NO: new_group_no,
                        },
                        (res) => {
                            const { RESULT, RESULT_MESG } = res.data
                            if (RESULT > 0) {
                                this.all_layer_off()
                                this.getList('custs');
                                this.group_custs_clear();
                            }
                            else {
                                alert(RESULT_MESG)
                            }
                        }
                    )
                }


            },
        },
        mounted() {
            this.init();
        },
    }
