    
    import store from '@/store'

    import CommomService from '@/service/CommomService'
    import GroupService from '@/service/GroupService'
    import draggable from 'vuedraggable'
    import UserLeftMenu from '@/types/user_leftmenu'

    var _ = require('lodash')

    export default {
        name: 'GroupList',
        components: {
            draggable,
        },
        data() {
            return {
                ui: {
                    del_layer: false,
                    group_nos: [],
                    is_all: false,
                    group_nm: '',
                    is_unspc_view : false,
                },
                api: {
                    edit: {
                        CMD: '',            //I:등록 U:수정 D:삭제
                        GROUP_NO: 0,
                        GROUP_NM: '',
                        GROUP_NOS: '',     //그룹일괄삭제에 사용함.
                        DEL_TYPE: 1,       //1:그룸만삭제 2:그룹에속한 고객도 삭제(휴지통)
                    },                    
                },
                arr: {
                    group_list : [],        //그룹 리스트
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                this.$setTitle('그룹 관리');
                //좌측메뉴 제목
                this.$setSideTitle('');
                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);
                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });


                this.$nextTick(() => {
                    this.getList();
                });

                

            },
            getList() {
                GroupService.group_title_list(
                    (res) => {
                            this.arr.group_list = res.data[0];
                    });
            },
            group_add() {
                //
                // group 추가 
                //
                //console.log(this.ui.group_nm)
                if (this.ui.group_nm.koLen() < 4) {
                    alert('새 그룹명은 2글자(2byte)이상을 입력해 주세요.');
                    return;
                }

                //같은 그룹명이 있으면 체크
                let idx = this.arr.group_list.findIndex(x => x.GROUP_NM == this.ui.group_nm)
                if (idx >= 0) {
                    alert('동일한 그룹명을 등록하실수 없습니다.');
                    return
                }

                //api에 값추가
                this.api.edit.CMD = 'I';
                this.api.edit.GROUP_NM = this.ui.group_nm;

                GroupService.group_edit(
                    this.api.edit,
                    (res) => {
                        if (res.data > 0) {
                            alert('새그룹 추가가 완료되었습니다.');
                            this.getList();
                            this.ui.group_nm = ''
                        }
                        else {
                            alert('새그룹 추가에 실패했습니다.');
                        }
                    }
                );
            },
            group_del_layer(type, group_no) {
                // 
                // 삭제할 그룹번호를 설정하고 
                //
                if (type === 'one') {
                    //그룹 단건삭제
                    this.api.edit.GROUP_NO = group_no;
                    this.api.edit.GROUP_NOS = '';
                    this.ui.del_layer = true;
                    
                }
                else {
                    //그룹 다건 삭제
                    this.api.edit.GROUP_NO = -2;

                    if (this.ui.group_nos.length === 0) {
                        alert('삭제할 그룹이 선택되지 않았습니다.');
                        return;
                    }
                    this.api.edit.GROUP_NOS = this.ui.group_nos.join(',');                    
                    this.ui.del_layer = true;
                }
                //다른 레이어창은 닫는다
                this.ui.is_unspc_view = false       
            },
            group_del() {
                //
                // 그룹삭제
                // 
                console.log('group_del');
                this.api.edit.CMD = 'D';
                console.log(this.api.edit)

                GroupService.group_edit(
                    this.api.edit,
                    (res) => {
                        if (res.data > 0) {
                            alert('그룹 삭제가 완료되었습니다.');
                            this.getList();
                            this.ui.del_layer = false;
                        }
                        else {
                            alert('그룹 삭제에 실패했습니다.');
                        }
                    }
                );
            },
            unspc_group_view() {
                this.ui.del_layer = false,
                this.ui.is_unspc_view = true
            },  //미지정 그룹 삭제 레이어 오픈...디른 레이어창은 닫늗다.
            unspc_group_del(del_type) {
                console.log('unspc_group_del');

                this.api.edit.CMD = 'D';
                this.api.edit.GROUP_NO = -1
                this.api.edit.DEL_TYPE = del_type

                GroupService.group_edit(
                    this.api.edit,
                    (res) => {                        
                        if (res.data > 0) {
                            let mesg = del_type = 1 ? "기본그룹(그룹미지정)에 속한 연락처가 모두 삭제되었습니다" :
                                "기본그룹(그룹미지정)에 속한 연락처가 휴지통으로 이동 되었습니다.";

                            alert(mesg);
                            this.getList();                            
                        }
                        else {
                            alert('그룹 삭제에 실패했습니다.');
                        }
                        this.ui.is_unspc_view = false;
                    }
                );
            },  //미지정그룹 삭제
            order_update(group_no, cmd) {
                //
                // 그룹순서 변경
                // cmd - up/down/top/bottom

                let cur_idx: number = -1;
                let max_idx: number = -1;
                let next_idx: number = -1;
                let tmp_obj: any = null;
                

                for (var i = 0; i < this.arr.group_list.length; i++) {
                    if (this.arr.group_list[i].GROUP_NO == group_no) {
                        cur_idx = i;
                        max_idx = this.arr.group_list.length -1;
                        break;
                    }
                }

                if (cmd === 'up' ) {
                    if (cur_idx === 0) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = cur_idx - 1;
                    tmp_obj = this.arr.group_list[next_idx];
                    this.arr.group_list[next_idx] = this.arr.group_list[cur_idx];
                    this.arr.group_list[cur_idx] = tmp_obj;

                }
                else if (cmd === 'top') {
                    if (cur_idx === 0) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = 0;
                    tmp_obj = this.arr.group_list[cur_idx];
                    //console.log(cur_idx)

                    for (var i = cur_idx - 1; i >= 0; i--) {
                        //console.log(i)
                        this.arr.group_list[i + 1] = this.arr.group_list[i];
                    }
                    this.arr.group_list[next_idx] = tmp_obj;
                }
                else if (cmd === 'down') {
                    if (cur_idx === max_idx) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = cur_idx +  1;
                    tmp_obj = this.arr.group_list[next_idx];
                    this.arr.group_list[next_idx] = this.arr.group_list[cur_idx];
                    this.arr.group_list[cur_idx] = tmp_obj;
                }
                else if (cmd === 'bottom') {
                    if (cur_idx === max_idx) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }                    
                    tmp_obj = this.arr.group_list[cur_idx];
                    for (var i = cur_idx; i < max_idx; i++) {
                        this.arr.group_list[i] = this.arr.group_list[i+1];
                    }
                    this.arr.group_list[max_idx] = tmp_obj;
                }
                //console.log(cur_idx)
                //console.log(max_idx)
            },
            save_all() {
                //
                // 그룹수정화면 저장
                //  - 정렬순서 및 수정된 명칭 저장


                //동일한 그룹명이 있는지 체크
                for (var i = 0; i < this.arr.group_list.length; i++) {
                    let group_nm = this.arr.group_list[i].GROUP_NM

                    let idx = this.arr.group_list.findIndex(x => x.GROUP_NM == group_nm)
                    if (idx >= 0 && idx != i) {
                        alert('동일한 그룹명을 등록하실수 없습니다.');
                        return
                    }
                }

                if (confirm('수정하신 정보들을 일괄 저장하시겠습니까?') === false) {
                    return;
                }

                let group_info = [];
                let data: string = '';
                for (var i = 0; i < this.arr.group_list.length; i++) {
                    group_info.push({
                        "GROUP_NO": this.arr.group_list[i].GROUP_NO,
                        "GROUP_NM": this.arr.group_list[i].GROUP_NM,
                        "ORD_NO":i + 1
                    });
                }
                data = JSON.stringify(group_info);
                //console.log(data);

                GroupService.group_bulk_update(
                    {
                        GROUP_INFO: data
                    },
                    (res) => {
                        if (res.data > 0) {
                            alert('그룹 일괄수정이 완료되었습니다.');
                            this.getList();
                        }
                        else {
                            alert('그룹 일괄수정이 실패했습니다.');
                        }
                    }
                );
            },
            check_all() {
                //
                // 전체 체크버튼 동작
                // 
                this.ui.group_nos = [];     //먼저 선택한 그룹들 초기화 하고 

                if (this.ui.is_all === true) {
                    for (var i = 0; i < this.arr.group_list.length; i++) {
                        this.ui.group_nos.push(this.arr.group_list[i].GROUP_NO);
                    }
                }                
            },
            showLayer(type) {
                this.ui[type] = true
            },
            hideLayer(type) {
                this.ui[type] = false
            },

        },
        mounted() {
            this.init();
        },
    }
