<template>
    <div class="headArea">
        <div class="titleArea">
            상생레터
        </div>
        <div v-if="isLogin">
            <ul class="headTitleMenu">
                <li>'<span class="bold">{{user.ACNT_NM}}</span>'님 환영합니다.</li>
                <li><div class="headTitlebtn mypageBtn" @click="mypage()">MY페이지</div></li>
                <li><div class="headTitlebtn logoutBtn" @click="logout()">로그아웃</div></li>
                <li>로그인 유효시간 : {{expireTime2}}</li>
                <li class="coin" style="cursor:pointer;" @click="visible(true)">코인 : {{(user.COIN_BAL_AMT || 0).toComma()}} Coin</li>
                <li @click="visible(true)"><div class="headTitlebtn coinBtn">코인 충전 및 현황</div></li>
            </ul>
            <div class="left"></div>
            <ul class="headMenu">
                <li v-for="r in menuList" v-bind:class="isSelected(r.MENU_URL)" @click="move($event, r.MENU_URL)">
                    {{r.MENU_NM}}
                </li>
                <li style="float:right; font-size:14px;" v-if="user.IS_MNGR" @click="login($event, true)">관리자 로그인</li>
            </ul>
        </div>
    </div>

    <div class="layer deletAlt" style="right:10px; left:auto; top:40px; z-index:10000;position:fixed;" v-if="ui.coinLayer">
        <div class="layerTitle">코인현황</div>

        <div class="layerMain ">
            <div class="layerMainText">
                보유 코인 : {{user.COIN_BAL_AMT.toComma()}} Coin
            </div>
        </div>
        <div class="layerBtnArea">
            <button class="blueBtn" @click="coinCharge">코인충전</button>
            <button class="redBtn" @click="coinUserList('charge')">코인 선물</button>
            <button class="grayBtn" @click="coinUserList()">사용이력 확인</button>
            <button class="grayBtn" @click="visible(false)">닫기</button>
        </div>
    </div>

    <AdminLoginLayer ref="adminLogin"></AdminLoginLayer>
</template>

<script>
    import store from '@/store'
    import AuthService from '@/service/AuthService'

    import AdminLoginLayer from '@/components/AdminLoginLayer.vue'

    export default {
        name: 'JSDHeader',
        components: {
            AdminLoginLayer,
        },
        computed: {
            user() {
                return store.getters.getUser
            },
            menuList() {
                return store.getters.getMenus
            },
            isLogin() {
                return this.$root.isLogin
            },
            expireTime() {
                return this.$root.expireTime
            },
            expireTime2() {
                let min = Math.floor(this.$root.expireTime / 60)
                let sec = Math.floor(this.$root.expireTime % 60)
                return `${min.zf(2)}:${sec.zf(2)}`
            },
            isDevIp() {
                return this.$root.IP.indexOf('124.198.60') > -1
            },
        },
        data() {
            return {
                ui: {
                    coinLayer: false,
                },
            }
        },
        methods: {
            home() {
                location.href = this.$homeUrl
            },
            isSelected(url) {
                if (url.startsWith('/app/ums')) {
                    return location.pathname == url ? 'select' : ''
                }
                else {
                    return location.href.indexOf(url) != -1 ? 'select' : ''
                }
            },
            logout() {
                this.$root.logout()
            },
            async login(e, isAdmin) {
                if (isAdmin) {
                    // 개발대역에선 응대 편의성을 위해 관리자 인증기능 스킵
                    if (this.isDevIp && this.user.ADM_PASS == 'Y') {
                        this.$refs.adminLogin.addSession()
                    }
                    else {
                        this.$refs.adminLogin.show()
                    }
                }
                else {
                    this.move(e, '/app/login')
                }
            },
            mypage() {
                //mypage
                this.move(null, '/app/mypage/myinfo')
            },
            move(e, url) {
                e = e || {}
                if (e['ctrlKey'] && e.ctrlKey) {
                    window.open(
                        url,
                        '_blank'
                    )
                }
                else {
                    location.href = url
                }
            },
            visible(isVisible) {
                this.ui.coinLayer = isVisible
            },
            coinCharge() {
                if (process.env.NODE_ENV == 'development') {
                    alert('개발환경에서는 코인충전 기능이 제한됩니다.')
                    return;
                }

                const url = `${process.env.VUE_APP_DOMAIN}/pay/ep/CoinCharge.aspx`
                const target = 'coinCharge'
                const form = document.createElement('form')
                form.setAttribute('method', 'post')
                form.setAttribute('action', url)
                form.setAttribute('target', target)

                var input = document.createElement('input')
                input.type = 'hidden'
                input.name = 'TOKEN'
                input.value = store.getters.getToken ? "Bearer " + store.getters.getToken : ""
                form.appendChild(input)

                var input2 = document.createElement('input')
                input2.type = 'hidden'
                input2.name = 'ACNT_NO'
                input2.value = this.user.ACNT_NO
                form.appendChild(input2)

                var input3 = document.createElement('input')
                input3.type = 'hidden'
                input3.name = 'ACNT_NM'
                input3.value = this.user.ACNT_NM
                form.appendChild(input3)

                var input4 = document.createElement('input')
                input4.type = 'hidden'
                input4.name = 'HDPH_NO'
                input4.value = this.user.SEND_PHON_CNO
                form.appendChild(input4)

                document.body.appendChild(form)
                window.open('', 'coinCharge', 'toolbar=no, width=423,height=391,scrollbars=no,resizable=no')
                form.submit()
                document.body.removeChild(form)
            }, 
            coinUserList(type) {
                //코인 사용이력 리스트
                //세션에 코인충전 창을 띄울지여부를 설정한다.
                sessionStorage.setItem('coin_pres', 'n')
                if (type == 'charge') {
                    sessionStorage.setItem('coin_pres', 'y')
                }

                this.move(null, '/app/mypage/coin')
            },
        },
        mounted() {

        }
    }
</script>