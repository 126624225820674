
    import store from '@/store'
    import TmplLeftMenu from '@/types/tmpl_leftmenu'
    import UserService from '@/service/UserService'

    export default {
        name: 'tmpl_setting',
        components: {
        },
        data() {
            return {
                arr: {
                    list : [],      //사용자 설정값 리스트
                },
                api: {
                    AUMS_NOTI_YN: '',       //자동문자 폰발송 발송전 알림 사용여부
                    AUMS_NOTI_DAY: 0,       //자동문자 폰발송 발송전 알림 일자
                    AUMS_NOTI_TM: 0,        //자동문자 폰발송 발송전 알림 시간
                    AUMS_HOLI_SEND_YN: '',  //자동문자 휴일 발송여부
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('템플릿 설정');
                this.$setMainBtn('+ 템플릿 작성')
                this.$setMainBtnCallback(() => {
                    this.$nextTick(() => {
                        //템플릿 작성과 연결되야 함
                    });
                });

                //좌측메뉴 제목                
                this.$setSideTitle('문자 시리즈');

                //좌측 메뉴 구성
                this.$setSideBtns(TmplLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('문자시리즈')
                    }
                });

                //화면 표시
                this.$nextTick(() => {
                    this.getConfList()
                })
            },
            getConfList() {
                // 사용자 설정 리스트 조회
                //console.log('getConfList()');

                UserService.conf_bulk_list(
                    {
                        DATA: 'AUMS_NOTI_YN, AUMS_NOTI_DAY, AUMS_NOTI_TM, AUMS_HOLI_SEND_YN',
                    },                    
                    (res) => {
                        this.arr.list = res.data;			// 예약리스트 조회
                        this.api.AUMS_NOTI_YN = this.arr.list.AUMS_NOTI_YN
                        this.api.AUMS_NOTI_DAY = this.arr.list.AUMS_NOTI_DAY
                        this.api.AUMS_NOTI_TM = this.arr.list.AUMS_NOTI_TM
                        this.api.AUMS_HOLI_SEND_YN = this.arr.list.AUMS_HOLI_SEND_YN
                        console.log(this.api);
                    }
                );
            },
            conf_save() {
                //설정값 저장

                //설정값을 xml형태로 
                let data: string = '<ROOT>' +
                    '<DATA CONF_TYPE_CD="AUMS_NOTI_YN">' + this.api.AUMS_NOTI_YN + '</DATA>' +
                    '<DATA CONF_TYPE_CD="AUMS_NOTI_DAY">' + this.api.AUMS_NOTI_DAY + '</DATA>' +
                    '<DATA CONF_TYPE_CD="AUMS_NOTI_TM">' + this.api.AUMS_NOTI_TM + '</DATA>' +
                    '<DATA CONF_TYPE_CD="AUMS_HOLI_SEND_YN">' + this.api.AUMS_HOLI_SEND_YN + '</DATA>' +
                    '</ROOT>'

                UserService.conf_bulk_update(
                    {
                        DATA : data,
                    },
                    (res) => {
                        if(res.data > 0) {
                            alert('템플릿 설정이 변경되었습니다.')
                        }
                        else {
                            alert('템플릿 설정 변경에 실패했습니다.');
                        }
                    }
                )
            },            
        },
        mounted() {
            this.init();
        },
    }
