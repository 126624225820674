<template>
    <slot></slot>
    <div v-if="loading" style="text-align: center">
        <div class="spinner"></div>
    </div>
</template>

<script>
    export default {
        name: 'JSDInfiniteScroll',
        computed: {
            parentNode() {
                return this.$el.parentNode
            }
        },
        props: {
            loading: Boolean
        },
        methods: {
            scrollHandler(event) {
                const { scrollHeight, scrollTop } = this.parentNode
                const outerHeight = this.parentNode.getBoundingClientRect().height

                if (scrollHeight == (scrollTop + outerHeight)) {
                    this.$emit('InfiniteScroll')
                }
            }
        },
        mounted() {
            //this.parentNode.addEventListener('scroll', this.scrollHandler)
            this.parentNode.onscroll = this.scrollHandler
            if (!this.parentNode.classList.contains('infiniteScrollBox')) {
                this.parentNode.classList.add('infiniteScrollBox')
            }
        },
        unmounted() {
            //this.parentNode.removeEventListener('scroll', this.scrollHandler)
            this.parentNode.onscroll = null
        }
    }
</script>

<style scoped>
    .spinner {
        padding:0!important;
        display: inline-block; /* Setting display to inline-block prevents the custom event from being fired multiple times at once */
        margin: 5px auto;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        border: 2px dashed black;
        position: relative;
        animation: spin 2s ease infinite;
    }

        .spinner::before {
            position: absolute;
            content: "";
            top: 0;
            left: -7px;
            width: 40%;
            height: 70%;
            background: white;
        }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
</style>