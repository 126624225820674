<!--
프로그램 명  : UserDupList.vue
프로그램 설명: 연락처 중복 대상자 리스트
                - 디른 신도에게 등록된 연락처 리스트를 조회
작성자		 : 20211007 (주)UCRM/김태훈
수정이력)
-->
<template>
    <div class="box">

        <table class="tableList">
            <tr>
                <th>No</th>
                <th>이름</th>
                <th>호칭</th>

                <th>대표 연락처</th>
                <th>그룹</th>
                <th>지역</th>
                <th>도서</th>
            </tr>
            <tr v-for="r in arr.dup_list">
                <td>{{r.SEQ_NO}}</td>
                <td @click="cust_layer_view(r);" style="cursor:pointer">{{r.CUST_NM}}({{r.RTNG_CD}}/{{r.CUST_AGE}}/{{ r.IS_SEX == '1' ? '남' : '여'}})</td>
                <td>{{r.VOCA_NM}}</td>
                <td>
                    {{r.PHONE_CNO}}
                    <span class="blue" v-if="r.PHONE_CNT > 0">(+{{r.PHONE_CNT}})</span>
                </td>
                <td>{{r.GROUP_NMS}}</td>
                <td>{{r.REGI_CDNM}}</td>
                <td>
                    {{r.BOOK_TITLE}}
                    <span class="blue" v-if="r.JSD_BOOK_CNT > 0">(+{{r.JSD_BOOK_CNT}})</span>
                </td>
            </tr>
            <tr v-if="arr.dup_list.length == 0">
                <td colspan="7">검색결과가 없습니다.</td> 
            </tr>
                
        </table>

        <!--페이징 처리-->
        <div class="conBottom">
            <JSDPageCtrl ref="pageCtrl" :page="page" :cntPage="api.list.CNT_PAGE" :maxCnt="page.maxCnt" @page-move="movePage"></JSDPageCtrl>
            <div class="rightbtn">
                <select class="selectBox left" v-model="api.list.CNT_PAGE" @change="movePage(1)">
                    <option v-for="n in arr.cnt" v-bind:value="n">{{`${n}줄 보기`}}</option>
                </select>
            </div>
        </div>

        <!--연락처 상세 보기  -->
        <JSDCustView ref="JSDCustView" :cust_no="ui.cust_layer.cust_no"></JSDCustView>
    </div>
</template>

<script>
    import store from '@/store'
    import JSDPageCtrl from '@/components/PageCtrl.vue';
    import UserService from '@/service/UserService'
    import UserLeftMenu from '@/types/user_leftmenu'
    import JSDCustView from '@/components/CustView.vue';

    export default {
        name: 'UserDupList',
        components: {
            JSDPageCtrl,
            JSDCustView
        },
        data() {
            return {
                ui: {
                    cust_layer: {
                        cust_no: 0,
                    },
                },
                api: {
                    list: {
                        SEARCH_KEY: '',
                        CNT_PAGE: 10,
                    },                    
                },
                arr: {
                    dup_list: [],
                    cnt: [10, 30, 50, 70, 100, 300],
                },
                page: {
                    val: 1,
                    maxCnt: 1
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('대상자 중복 리스트');


                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                this.$nextTick(() => {
                    this.getList()
                });
            },
            getList() {
                //console.log('getList');
                // 
                // 휴지통 리스트 조회 
                // 

                UserService.dup_list(
                    this.page.val,
                    this.api.list.CNT_PAGE,
                    (res) => {
                        //console.log(res.data[0]);
                        this.arr.dup_list = res.data[0];				//휴지통리스트조회

                        this.page.maxCnt = this.arr.dup_list.length > 0 ? this.arr.dup_list[0].MAX_CNT : 1
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set();
                        })
                    }
                )
            },
            cust_layer_view(obj) {
                //
                // 연락처 상세 보기
                //
                this.ui.cust_layer.cust_no = obj["CUST_NO"];

                console.log('-------------');
                console.log(this.ui.cust_layer);

                // 동기화 ...
                this.$nextTick(() => {
                    this.$refs.JSDCustView.cust_view();
                });
            },
            movePage(page) {
                // 
                // page이동.검색시 초기값
                //                 
                this.page.val = page
                this.getList();
            },
        },
        mounted() {
            this.init();
        },
    }
</script>
