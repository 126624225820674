
    import api from '@/utils/api'

    import store from '@/store'
    import AuthService from '@/service/AuthService'
    

    export default {
        name: 'JoinMember',
        components: {
        },
        data() {
            return {
                ui: {
                    is_all_check: false,    //전체동의여부
                    is_agre: false,     //약관동의여부
                    is_pers: false,     //개인정보 수집여부
                    crtf_txt: '',       //sms인증번호
                    crtf_stat: 1,      //1:sms인증번호 전송전 2:sms인증번호 확인중 3:sms인증완료
                    resTimeData: '',           //인증남은시간
                    timeCounter: 0,             //인증처리시간
                },
                api: {
                    individual_code: '',    //신도코드
                    user_name: '',          //이름
                    gender_name: '',        //성별(남 / 여)
                    date_div_name: '',      //음양구분(음 / 양)
                    birth_date: '',         //생년월일
                    phone_number: '',       //휴대폰번호
                    wide_name: '',          //광역명
                    dojang_name: '',        //도장명
                    area_number: '',        //구역명
                    sindo_sep: '',          //신도구분명(신도 / 불참 / 불신)
                    duty_code: '',          //직책코드
                    duty_name: '',          //직책코드명
                    belo_txt: '',           //소속명
                },
                arr: {
                    acnt_no: -1,            //상생레터 사용자번호
                    result_mesg: '',        //회원가입 결과 메시지
                    login_no : '',
                }
            }
        },
        computed: {
        },
        methods: {
            init() {
            
                this.$nextTick(() => {
                    //앞에서 전달받은 신도기본 데이터를 가져온다.
                    if (sessionStorage.hasOwnProperty('userData')) {
                        let data = JSON.parse(sessionStorage.getItem('userData'))

                        this.api.individual_code = data.individual_code
                        this.api.user_name = data.user_name
                        this.api.gender_name = data.gender_name
                        this.api.date_div_name = data.date_div_name
                        this.api.birth_date = data.birth_date
                        this.api.phone_number = data.phone_number

                        sessionStorage.removeItem('userData')

                        this.getUserInfo();

                    }
                    else {
                        alert('신도정보가 없습니다.')
                        this.$movePage('/app/login')
                    }
                })                
            },
            getUserInfo() {
                // 신도정보를 가져온다. 도무넷에서 정보를 가져온다.
                AuthService.info2_jsd(
                    {
                        JSD_USER_CD: this.api.individual_code                        
                    },
                    (res) => {
                        let errMsg = res.data.errMsg
                        let data = res.data.data

                        if (res.data.data == null) {
                            alert(errMsg)
                            this.$movePage('/app')
                            return
                        }
                        this.api.wide_name = data.wide_name
                        this.api.dojang_name = data.dojang_name
                        this.api.area_number = data.area_number
                        this.api.sindo_sep = data.sindo_sep
                        this.api.duty_code = data.duty_code
                        this.api.duty_name = data.duty_name

                        this.api.belo_txt = this.api.wide_name + '광역 ' + this.api.dojang_name + '도장 ' + this.api.area_number + '구역'
                    }
                )
            },
            crtf_txt_send() {
                //sms 인증번호 발송
                AuthService.certSend(
                    {
                        HDPH_CNO: this.api.phone_number,
                        CHECK_SECOND: 180,
                    },
                    (res) => {
                        if (res.data.RESULT > 0) {
                            alert('인증번호를 발송했습니다.')
                            this.ui.crtf_stat = 2
                            this.start()                            
                        }
                        else {
                            alert('SMS인증번호 발송에 실패했습니다.(' + res.data.RESULT_MESG + ')');
                        }
                    }
                )
            },
            crtf_txt_check() {
                //sms 인증번호 확인
                AuthService.certCheck(
                    {
                        HDPH_CNO: this.api.phone_number,
                        CRTF_TXT: this.ui.crtf_txt,
                        CHECK_SECOND: 180,
                    },
                    (res) => {
                        if (res.data.RESULT > 0) {
                            alert('승인완료. 상생레터 가입절차를 마무리 해주십시오.')
                            this.ui.crtf_stat = 3

                        }
                        else {
                            alert( res.data.RESULT_MESG);
                        }
                    }
                )
            },
            joinMember() {
                //상생레터 회원가입처리  
                if (!this.ui.is_pers) {
                    alert('상생레터 가입시에는 개인정보 수집 및 이용동의가 필요합니다.')
                    return
                }
                if (!this.ui.is_agre) {
                    alert('상생레터 가입시에는 서비스 이용 약관동의가 필요합니다.')
                    return
                }

                //상생레터회원가입
                AuthService.user_insert(
                    {
                        individual_code: this.api.individual_code,
                        user_name: this.api.user_name,
                        gender_name: this.api.gender_name,
                        date_div_name: this.api.date_div_name,
                        birth_date: this.api.birth_date,
                        phone_number: this.api.phone_number,
                        dojang_name: this.api.dojang_name,
                        area_number: this.api.area_number,
                        sindo_sep: this.api.sindo_sep,
                        duty_code: this.api.duty_code,
                        duty_name: this.api.duty_name,                          
                    },
                    (res) => {
                        if (res.data.ACNT_NO > 0) {
                            alert('상생레터 가입완료!')
                            let acnt_no = res.data.ACNT_NO

                            //로그인처리 하고 page이동
                            AuthService.login_jsd_session(
                                {
                                    ACNT_NO: acnt_no
                                },
                                (res) => {
                                    if (res.data.ACNT_NO > 0) {
                                        const token = res.data.TOKEN
                                        const user = res.data.USER[0]
                                        store.dispatch('setToken', { data: token })
                                        store.dispatch('setUser', { data: user })

                                        if (this.$cookie.isCookieAvailable('JSD_USER')) {
                                            this.$cookie.removeCookie('JSD_USER')
                                        }

                                        this.$cookie.setCookie('JSD_USER', token, 1)
                                        api.defaults.headers.common['Authorization'] = 'Bearer ' + token

                                        this.$nextTick(() => {
                                            this.$root.movePage('/app')
                                        })

                                        //로그인했으니 페이지로 이동                            
                                        this.$movePage(this.$homeUrl)
                                    }
                                }
                            )
                        }
                        else {
                            alert('상생레터가입실패(' + res.data.RESULT_MESG + ') \n확인후 다시 시도해 주세요.');
                        }
                    }

                )

            },
            check_all() {
                //전체동의
                this.ui.is_agre = this.ui.is_all_check
                this.ui.is_pers = this.ui.is_all_check
            },           

            start() {
                this.ui.timeCounter = 180;
                this.ui.resTimeData = this.prettyTime();

                // timer시작하기
                this.polling = setInterval(() => {
                    this.ui.timeCounter-- //1찍 감소
                    this.ui.resTimeData = this.prettyTime()
                    if (this.ui.timeCounter <= 0)
                        this.timeStop()
                }, 1000)
            },

            prettyTime() {
                let minutes: number = Math.floor(this.ui.timeCounter / 60);
                let secondes = this.ui.timeCounter % 60;
                return minutes.padLeft(1, '0') + ":" + secondes.padLeft(2, '0');
            },

            timeStop() {
                clearInterval(this.polling);                

                //미인증상태로 되될린다.
                this.ui.crtf_stat = 1
                this.ui.crtf_txt = ''
            },
        },
        mounted() {
            this.init()
        },
    }
