// 문자발송 우측 메뉴 처리
export default {    
    menu_list: [
        [
            { name: '문자발송', path: '/app/ums' },
            { name: '발송이력', path: '/app/ums/sendlist' },
            { name: '예약리스트', path: '/app/ums/resvlist' },
            // { name: '발송현황', path: '/app/ums/status' },
            { name: '발송설정', path: '/app/ums/setting' },            
        ],
        [            
            { name: '도움말', path: '/app/board/help' },
        ],
    ],
    advList: [
        [
            { name: '광고문자', path: '/app/ums/adv' },
            { name: '불법 스팸 방지를 위한 정보통신망법 안내서 보기', path: '', type: 'kisa' },
            { name: '광고 문자 관련 법규 및 주의사항', path: '', type: 'warn' },
            { name: '수신거부목록', path: '', type: 'reject' },
        ],
        [
            { name: '도움말', path: '/app/board/help' },
        ],
    ],
};

