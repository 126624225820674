
    import JSDPageCtrl from '@/components/PageCtrl.vue';
    import UserService from '@/service/UserService'
    import CommomService from '@/service/CommomService'
    import GroupService from '@/service/GroupService'
    import HanKeys from '@/types/hanKeys'
    import UserLeftMenu from '@/types/user_leftmenu'
    import JSDCustView from '@/components/CustView.vue';
    import CustSelectFilter from '@/components/CustSelectFilter.vue';

    export default {
        name: 'BulkList',
        components: {
            JSDPageCtrl,
            JSDCustView,
            CustSelectFilter,
        },
        data() {
            return {
                ui: {
                    group_layer: {
                        is_view: false,
                        left: 823,
                        top: 470,
                        mode: '',
                        group_nos: [],
                        cust_no: 0,
                        aaa: [],
                    },
                    blar_cate_cd_layer: {
                        is_view: false,
                        left: 823,
                        top: 470,
                        blar_cate_cd: ''
                    },
                    blar_rela_cd_layer: {
                        is_view: false,
                        left: 823,
                        top: 470,
                        blar_rela_cd: ''
                    },
                    regi_cd_layer: {
                        is_view: false,
                        left: 823,
                        top: 470,
                        regi_cd: ''
                    },
                    jsdbook_layer: {
                        is_view: false,
                        left: 823,
                        top: 470,
                        book_nos: [],
                        mode: '',
                        cust_no: 0,
                    },
                    voca_layer: {
                        is_view: false,
                        left: 544,
                        top: 424,
                        voca_nm: ''
                    },
                    age_layer: {
                        is_view: false,
                        left: 1244,
                        top: 424,
                        year: 0,
                        age: 0,
                    },
                    filter_val: {
                        BLVR_CATE: '0',     //0:전체 1:상생방송 :4:역사광복 9:미지정
                        SEX: '',
                        RTNG: '',
                        BLVR_RELA: '',
                        REGI: '',
                        SELECT_TXT: '',
                        BLVR_CATE_CD: '',
                        BOOK_NOS : '',
                    },
                    is_order: false,
                    cust_layer: {
                        cust_no: 0,
                    },

                },
                api: {
                    list: {
                        //list조회를 위한 api
                        ORDER_TYPE: 1,          //1:가나다순 2:최근등록순
                        BLVR_CATE: 0,           //0: 전체 1: 상생 4: 역사 9: 미지정
                        HAN_KEY: '',            //가나다순검색에 사용되는 한글 초성값 ㄱ~ㅎ A F O Z #
                        SEARCH_KEY: '',         //검색 KEY(이름/ 휴대폰 번호)
                        SEX: '',                //성별 ','로 연결된 문자열
                        RTNG: '',               //연락처 등급 ','로 연결된 문자열
                        BLVR_RELA: '',          //신도와의관계코드 ','로 연결된 문자열
                        REGI: '',               //지역 ','로 연결된 문자열
                        BOOK_NOS : '',
                        GROUP_NO: 0,            //그룹선택시 그룹번호가 온다.   -1:미지정 NULL(0)이면 그룹상관없이 전체 조회
                        PAGE: 1,               //현재 조회하는 page
                        CNT_PAGE: 10,           //조회페이지당 건수
                        BLVR_CATE_CD: '',
                    },
                    page_cnt: [10, 30, 50, 70, 100, 300],        //페이징처리 건수배열
                },
                arr: {
                    cust_list: [],
                    code_list: [],               //code리스트
                    left_group_list: [],
                    group_list: [],              //group list
                    han_key_list: [],            //상단 ㄱㄴㄷ검색
                    blar_cate_cd_list: [],       //BLVR_CATE_CD
                    blar_rela_cd_list: [],       //BLVR_RELA_CD
                    regi_cd_list: [],            //REGI_CD
                    book_list: [],                //증산도 도서
                },
                page: {
                    val: 1,
                    maxCnt: 1
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                this.$setTitle('일괄 수정');

                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                this.$nextTick(() => {
                    this.getList();
                    CommomService.code_keys(
                        {
                            KEYS: 'BLVR_CATE_CD,BLVR_RELA_CD,REGI_CD',
                        },
                        (res) => {
                            this.arr.code_list = res.data[0];				//코드리스트 조회
                            this.arr.blar_cate_cd_list = this.arr.code_list.filter(x => x.CATE_KEY === 'BLVR_CATE_CD');
                            this.arr.blar_rela_cd_list = this.arr.code_list.filter(x => x.CATE_KEY === 'BLVR_RELA_CD');
                            this.arr.regi_cd_list = this.arr.code_list.filter(x => x.CATE_KEY === 'REGI_CD');
                        }
                    );
                    //그룹리스트 조회
                    GroupService.group_title_list((res) => { this.arr.group_list = res.data[0]; });
                    UserService.jsdbook_list((res) => { this.arr.book_list = res.data[0]; });

                    for (var i = 0; i < HanKeys.han_keys.length; i++) {
                        this.arr.han_key_list.push({ "title": HanKeys.han_keys[i].title, "value": HanKeys.han_keys[i].value });
                        // console.log({ 'title': HanKeys.han_keys[i].title, 'value': HanKeys.han_keys[i].value })
                    }

                    //좌측 그룹 리스트 구성
                    this.left_group_list();
                });
            },
            left_group_list() {
                //left_group_list : 좌측 그룹리스트
                GroupService.group_list(
                    "0",
                    (res) => {
                        this.arr.left_group_list = res.data[0];
                        let tree_group_list = [];
                        for (var i = 0; i < this.arr.left_group_list.length; i++) {
                            //좌측 트리구조...그룹리스트
                            // title : 그룹명
                            // useCnt : 카운트 표시여부
                            // cnt : 그룹내 숫자
                            // 클릭시 전달할 값 (그룹번호)
                            tree_group_list.push({ 'title': this.arr.left_group_list[i].GROUP_NM, 'useCnt': true, 'cnt': this.arr.left_group_list[i].CNT, 'value': this.arr.left_group_list[i].GROUP_NO });
                        }
                        this.$setTreeData(tree_group_list);

                        //좌측 그룹명 클릭시 처리(리스트 리로드)
                        this.$setTreeDataCallback((r) => {
                            this.api.list.GROUP_NO = r.value;
                            this.movePage(1);

                        });
                    }
                );
            },
            layer_off_all() {
                //모든 레이어창 off
                this.ui.group_layer.is_view = false;
                this.ui.blar_cate_cd_layer.is_view = false;
                this.ui.blar_rela_cd_layer.is_view = false;
                this.ui.regi_cd_layer.is_view = false;
                this.ui.jsdbook_layer.is_view = false;
                this.ui.voca_layer.is_view = false;
                this.ui.age_layer.is_view = false;
            },
            phone_check(phone_cno: string) {
                //휴대폰번호 유효성 체크
                if (!phone_cno.isHP(false) || phone_cno.substring(0, 3) != '010') {
                    alert('올바른 휴대폰 번호를 입력하세요');
                    return false;
                }
                return true;
            },
            bulk_save() {
                let data: string = '';
                let arr = [];

                //데이터 무결성 검사 (핸드폰번호가 맞는지, 수정되는 번호가 중복이 있는지 확인한다.)
                for (var i = 0; i < this.arr.cust_list.length; i++) {
                    if (this.phone_check(this.arr.cust_list[i].PHONE_CNO) == false) {
                        return;
                    }
                    let phone_cno = this.arr.cust_list[i].PHONE_CNO
                    let idx = this.arr.cust_list.findIndex(x => x.PHONE_CNO == phone_cno)

                    if (idx >-1 && idx != i) {
                        alert('휴대폰 번호를 중복되어 등록될수 없습니다.')
                        return
                    }
                }

                if (confirm('저장하시겠습니까?') === false) {
                    return;
                }

                //[{"CUST_NO":"1","VOCA_NM":"과장님","PHONE_CNO":"010-1234-1234","GROUP_NOS":"1,2,3,4,5",
                //"BLVR_CATE_CD": "1", "RTNG_CD": "A", "BIRT_YE": 1984, "IS_SEX": 1, "BLVR_RELA_CD": "01", "REGI_CD": "01", "JSD_BOOK": "1,2,3,4,"},
                for (var i = 0; i < this.arr.cust_list.length; i++) {
                    arr.push({
                        "CUST_NO": this.arr.cust_list[i].CUST_NO,
                        "VOCA_NM": this.arr.cust_list[i].VOCA_NM,
                        "PHONE_CNO": this.arr.cust_list[i].PHONE_CNO,
                        "GROUP_NOS": this.arr.cust_list[i].GROUP_NOS,
                        "BLVR_CATE_CD": this.arr.cust_list[i].BLVR_CATE_CD,
                        "RTNG_CD": this.arr.cust_list[i].RTNG_CD,
                        "BIRT_YE": this.arr.cust_list[i].BIRT_YE,
                        "IS_SEX": this.arr.cust_list[i].IS_SEX,
                        "BLVR_RELA_CD": this.arr.cust_list[i].BLVR_RELA_CD,
                        "REGI_CD": this.arr.cust_list[i].REGI_CD,
                        "JSD_BOOK": this.arr.cust_list[i].BOOK_NOS,
                    });
                }
                data = JSON.stringify(arr);
                //console.log(data);
                UserService.bulk_update(
                    {
                        DATA: data
                    },
                    (res) => {
                        const { RESULT, RESULT_MESG } = res.data

                        if (RESULT > 0) {
                            alert('일괄 저정되었습니다.');
                            this.getList();

                            //좌측 그룹 리스트 구성
                            this.left_group_list();
                        }
                        else {
                            alert('일괄 저장에 실패했습니다. \n' + RESULT_MESG);
                        }
                    }
                )

            },
            getList() {
                // 연락처 리스트 조회 : 조회 조건에 맞는 리스트 조회
                let blar_cate_cd: string = ''
                if (this.api.list.BLVR_CATE == '1') {
                    if (this.api.list.BLVR_CATE_CD == '') {
                        blar_cate_cd = '1,2,3'
                    }
                    else {
                        let tmp = this.api.list.BLVR_CATE_CD.split(',').filter(x => x.koLen() > 0)
                        blar_cate_cd = tmp.join(',')
                    }
                }
                else {
                    blar_cate_cd = this.api.list.BLVR_CATE
                }

                UserService.cust_list(
                    this.page.val,
                    {
                        ORDER_TYPE: this.api.list.ORDER_TYPE,
                        BLVR_CATE: blar_cate_cd,
                        HAN_KEY: this.api.list.HAN_KEY,
                        SEARCH_KEY: this.api.list.SEARCH_KEY,
                        SEX: this.api.list.SEX,
                        RTNG: this.api.list.RTNG,
                        BLVR_RELA: this.api.list.BLVR_RELA,
                        REGI: this.api.list.REGI,
                        GROUP_NO: this.api.list.GROUP_NO,
                        BOOK_NOS: this.api.list.BOOK_NOS,
                        CNT_PAGE: this.api.list.CNT_PAGE,
                    },
                    (res) => {
                        this.arr.cust_list = res.data[0];				//일괄수정용 연락처 리스트 조회

                        this.page.maxCnt = this.arr.cust_list.length > 0 ? this.arr.cust_list[0].MAX_CNT : 1
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set();
                        })
                    }
                )
            },
            cust_layer_view(obj) {
                //
                // 연락처 상세 보기
                //
                this.ui.cust_layer.cust_no = obj["CUST_NO"];

                //console.log('-------------');
                //console.log(this.ui.cust_layer);

                // 동기화 ...
                this.$nextTick(() => {
                    this.$refs.JSDCustView.cust_view();
                });
            },
            abspos(e) {
                //
                // 마우스 클릭한 위치를 구해 레이어 띄울때 사용
                //
                this.x = e.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft);
                this.y = e.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop);
                return this;
            },
            groupView(event, mode, is_view, obj) {
                //
                // 그룹선택 클릭시 레이어창 조정
                //
                this.layer_off_all()        //전체레이어 닫고 시작
                let pos = this.abspos(event);
                this.ui.group_layer.left = pos.x;
                this.ui.group_layer.top = pos.y;

                this.ui.group_layer.is_view = is_view;
                this.ui.group_layer.mode = mode;

                //console.log('groupView');
                //console.log(this.ui.group_layer.group_nos);


                //전체 클릭시에는 선택한 그룹 초기화하고 개별선택시 연락처의 그룹설정해 놓음
                if (mode === 'all') {
                    this.ui.group_layer.group_nos = [];
                    this.ui.group_layer.cust_no = 0;
                }
                else if (mode === 'one') {
                    //console.log(obj['GROUP_NOS']);
                    this.ui.group_layer.group_nos = [];
                    this.ui.group_layer.cust_no = obj['CUST_NO'];

                    //그룹데이터 있을때만 처리한다.
                    if (obj['GROUP_NOS'].length > 0) {
                        let group_arr = obj['GROUP_NOS'].split(",");

                        for (var i = 0; i < group_arr.length; i++) {
                            this.ui.group_layer.group_nos.push(parseInt(group_arr[i]));
                        }
                    }
                }
                //console.log(this.ui.group_layer.group_nos);
            },
            blar_cateView(event, mode, is_view, obj) {
                //
                // BLVR_CATE_CD 신도 분류코드 클릭시 레이어창 조정
                //
                this.layer_off_all()        //전체레이어 닫고 시작
                let pos = this.abspos(event);
                this.ui.blar_cate_cd_layer.left = pos.x;
                this.ui.blar_cate_cd_layer.top = pos.y;

                this.ui.blar_cate_cd_layer.is_view = is_view;
                if (mode === 'all') {
                    this.ui.blar_cate_cd_layer.blar_cate_cd = '';
                }
                else if (mode === 'one') {
                    this.ui.blar_cate_cd_layer.blar_cate_cd = obj['BLVR_CATE_CD'];
                }
            },
            blar_relaView(event, mode, is_view, obj) {
                //
                // BLVR_RELA_CD 신도 관계코드 클릭시 레이어창 조정
                //
                this.layer_off_all()        //전체레이어 닫고 시작
                let pos = this.abspos(event);
                this.ui.blar_rela_cd_layer.left = pos.x;
                this.ui.blar_rela_cd_layer.top = pos.y;

                this.ui.blar_rela_cd_layer.is_view = is_view;
                if (mode === 'all') {
                    this.ui.blar_rela_cd_layer.blar_rela_cd = '';
                }
                else if (mode === 'one') {
                    this.ui.blar_rela_cd_layer.blar_rela_cd = obj['BLVR_RELA_CD'];
                }
            },
            regi_View(event, mode, is_view, obj) {                
                // REGI_CD 지역 클릭시 레이어창 조정
                this.layer_off_all()        //전체레이어 닫고 시작
                let pos = this.abspos(event);
                this.ui.regi_cd_layer.left = pos.x;
                this.ui.regi_cd_layer.top = pos.y;

                this.ui.regi_cd_layer.is_view = is_view;
                this.ui.regi_cd_layer.regi_cd = '';
            },
            book_View(event, mode, is_view, obj) {                
                // BOOK
                this.layer_off_all()        //전체레이어 닫고 시작
                let pos = this.abspos(event);
                //this.ui.jsdbook_layer.left = (screen.availWidth - pos.x);
                this.ui.jsdbook_layer.left = pos.x - 120;
                this.ui.jsdbook_layer.top = pos.y;

                this.ui.jsdbook_layer.mode = mode;
                this.ui.jsdbook_layer.is_view = is_view;

                //전체 클릭시에는 선택한 그룹 초기화하고 개별선택시 연락처의 그룹설정해 놓음
                if (mode === 'all') {
                    this.ui.jsdbook_layer.book_nos = [];
                    this.ui.jsdbook_layer.cust_no = 0;
                }
                else if (mode === 'one') {
                    //console.log(obj['GROUP_NOS']);
                    this.ui.jsdbook_layer.book_nos = [];
                    this.ui.jsdbook_layer.cust_no = obj['CUST_NO'];
                    //this.ui.group_layer.group_nos = obj['GROUP_NOS'];

                    if (obj['BOOK_NOS'].length > 0) {
                        let book_arr = obj['BOOK_NOS'].split(",");
                        console.log(book_arr);

                        for (var i = 0; i < book_arr.length; i++) {
                            this.ui.jsdbook_layer.book_nos.push(parseInt(book_arr[i]));
                        }
                    }
                }
            },
            voca_View(event, mode, is_view, obj) {
                this.layer_off_all()        //전체레이어 닫고 시작
                let pos = this.abspos(event);
                this.ui.voca_layer.left = pos.x;
                this.ui.voca_layer.top = pos.y;

                this.ui.voca_layer.is_view = is_view;
                this.ui.voca_layer.voca_nm = [];
            },
            age_View(event, mode, is_view, obj) {
                this.layer_off_all()        //전체레이어 닫고 시작
                let pos = this.abspos(event);
                this.ui.age_layer.left = pos.x;
                this.ui.age_layer.top = pos.y;

                this.ui.age_layer.is_view = is_view;
                this.ui.age_layer.year = 0;
                this.ui.age_layer.age = 0;
            },
            select_assign(type, obj) {
                //
                // 검색 조건을 변경한다.
                // type : 클릭한 항목
                // obj : 선택값
                if (type == 'ㄱㄴㄷ') {
                    // obj에 row-object가 옴
                    this.api.list.HAN_KEY = obj['value'];
                }
                else if (type == 'BLVR_CATE') {
                    // obj에 선택값이 옴
                    this.api.list.BLVR_CATE = obj;
                    this.ui.filter_val.BLVR_CATE = obj
                }
                else if (type === 'order') {
                    //1:가나다순 2:최근등록순
                    this.api.list.ORDER_TYPE = this.ui.is_order ? 2 : 1;
                }

                //화면을 다시 reload한다.
                this.movePage(1);
            },
            age_assign(type, mode, obj) {
                //
                // 나이/ 출생년도 체크
                //
                let newYear: number = new Date().getFullYear();

                if (mode === 'all') {
                    if (type === 'AGE') {
                        if (this.ui.age_layer.age <= 0 || this.ui.age_layer.age > 90) {
                            alert('나이를 바르게 (1~90) 입력해 주세요.');
                            return;
                        }
                        this.ui.age_layer.year = newYear - this.ui.age_layer.age;
                    }
                    else if (type === 'YE') {
                        if (this.ui.age_layer.year > newYear) {
                            alert('연도를 바르게 입력해 주세요.');
                            return;
                        }
                        this.ui.age_layer.age = newYear - this.ui.age_layer.year;
                    }
                }
                else if (mode === 'one') {
                    let age: number = 0;
                    let year: number = 0;

                    age = obj['CUST_AGE'];
                    year = obj['BIRT_YE'];

                    //console.log(age);
                    //console.log(year);

                    if (type === 'AGE') {
                        if (age <= 0 || age > 90) {
                            alert('나이를 바르게 (1~90) 입력해 주세요.');
                            return;
                        }
                        obj['BIRT_YE'] = newYear - age;
                    }
                    else if (type === 'YE') {
                        if (year > newYear) {
                            alert('연도를 바르게 입력해 주세요.');
                            return;
                        }
                        obj['CUST_AGE'] = newYear - year;
                    }
                }
            },
            cust_assign(type, mode, key, value) {
                //
                // 신도가 연락처의 데이터를 변경시킴. 실제 편집한 데이터를 바인됭 데이터에 반영시킨다.
                //

                //console.log(type);
                //console.log(mode);
                //console.log(key);
                //console.log(value);

                // GROUP처리
                if (type == 'GROUP') {
                    let group_nms: string = '';
                    let group_nos: string = '';
                    let cust_no: number = 0;

                    //console.log(this.ui.group_layer.group_nos.length);

                    for (var i = 0; i < this.ui.group_layer.group_nos.length; i++) {
                        let codes = this.arr.group_list.filter(x => x.GROUP_NO === this.ui.group_layer.group_nos[i]);
                        group_nms = group_nms + '#' + codes[0].GROUP_NM;
                    }
                    group_nos = JSON.stringify(this.ui.group_layer.group_nos);
                    group_nos = group_nos.replace("[", "");
                    group_nos = group_nos.replace("]", "");

                    //console.log(group_nms)
                    //console.log(group_nos)

                    if (mode === 'all') {
                        for (var i = 0; i < this.arr.cust_list.length; i++) {
                            this.arr.cust_list[i].GROUP_NMS = group_nms;
                            this.arr.cust_list[i].GROUP_NOS = group_nos;
                        }
                    }
                    else {
                        cust_no = this.ui.group_layer.cust_no;
                        for (var i = 0; i < this.arr.cust_list.length; i++) {
                            // 지정한 고객을 찾는다.
                            if (this.arr.cust_list[i].CUST_NO === cust_no) {
                                this.arr.cust_list[i].GROUP_NMS = group_nms;
                                this.arr.cust_list[i].GROUP_NOS = group_nos;
                            }
                        }
                    }
                    this.ui.group_layer.is_view = false;
                    return;
                }
                else if (type == 'BOOK') {
                    let book_nms: string = '';
                    let book_nos: string = '';
                    let cust_no: number = 0;

                    //console.log('BOOK');
                    //console.log(this.ui.jsdbook_layer.book_nos.length);
                    //console.log(this.ui.jsdbook_layer.book_nos);

                    for (var i = 0; i < this.ui.jsdbook_layer.book_nos.length; i++) {
                        let codes = this.arr.book_list.filter(x => x.BOOK_NO === this.ui.jsdbook_layer.book_nos[i]);
                        //console.log(codes);
                        book_nms = book_nms + '#' + codes[0].TITLE;
                    }
                    book_nos = JSON.stringify(this.ui.jsdbook_layer.book_nos);
                    book_nos = book_nos.replace("[", "");
                    book_nos = book_nos.replace("]", "");

                    //console.log(book_nms)
                    //console.log(book_nos)
                    //console.log(mode)

                    if (mode == 'all') {
                        for (var i = 0; i < this.arr.cust_list.length; i++) {
                            this.arr.cust_list[i].BOOK_NMS = book_nms;
                            this.arr.cust_list[i].BOOK_NOS = book_nos;
                        }
                    }
                    else {
                        cust_no = this.ui.jsdbook_layer.cust_no;
                        for (var i = 0; i < this.arr.cust_list.length; i++) {
                            // 지정한 고객을 찾는다.
                            if (this.arr.cust_list[i].CUST_NO === cust_no) {
                                this.arr.cust_list[i].BOOK_NMS = book_nms;
                                this.arr.cust_list[i].BOOK_NOS = book_nos;
                            }
                        }
                    }
                    this.ui.jsdbook_layer.is_view = false;
                    return;
                }

                //for (var i = 0; i < this.arr.cust_list.length; i++) {
                //    this.arr.cust_list[i].BIRT_YE = this.ui.age_layer.year;
                //    this.arr.cust_list[i].CUST_AGE = this.ui.age_layer.age;
                //}


                if (mode == 'all') {

                    // voca : 호칭설정은 형체크후 설정한다.
                    if (type === 'voca') {
                        let voca_nm: string = this.ui.voca_layer.voca_nm;

                        if (voca_nm.trim().koLen() < 5) {
                            alert('호칭을 정상적으로 (2글자이상) 입력해 주세요.');
                        }
                        else {
                            for (var i = 0; i < this.arr.cust_list.length; i++) {
                                this.arr.cust_list[i].VOCA_NM = voca_nm.trim();
                            }

                            this.ui.voca_layer.is_view = false;     //layer창 닫기
                        }
                        return;
                    }
                    else if (type === 'AGE') {
                        let newYear: number = new Date().getFullYear();

                        if (this.ui.age_layer.age <= 0 || this.ui.age_layer.age > 90) {
                            alert('나이를 바르게 (1~90) 입력해 주세요.');
                            return;
                        }
                        if (this.ui.age_layer.year > newYear) {
                            alert('연도를 바르게 입력해 주세요.');
                            return;
                        }
                    }

                    console.log(this.ui.regi_cd_layer.regi_cd);

                    // 일괄 변경
                    for (var i = 0; i < this.arr.cust_list.length; i++) {
                        if (type === 'RTNG_CD') {
                            this.arr.cust_list[i].RTNG_CD = value;
                        }
                        else if (type === 'IS_SEX') {
                            this.arr.cust_list[i].IS_SEX = value;
                        }
                        else if (type === 'AGE') {
                            this.arr.cust_list[i].BIRT_YE = this.ui.age_layer.age;
                            this.arr.cust_list[i].CUST_AGE = this.ui.age_layer.year;
                        }
                        else if (type === 'REGI_CD') {
                            this.arr.cust_list[i].REGI_CD = this.ui.regi_cd_layer.regi_cd;
                        }
                        else if (type === 'BLVR_CATE_CD') {
                            this.arr.cust_list[i].BLVR_CATE_CD = this.ui.blar_cate_cd_layer.blar_cate_cd;
                        }
                        else if (type === 'BLVR_RELA_CD') {
                            this.arr.cust_list[i].BLVR_RELA_CD = this.ui.blar_rela_cd_layer.blar_rela_cd;
                        }
                    }
                }
                else if (mode == 'one') {
                    //단건 변경

                    for (var i = 0; i < this.arr.cust_list.length; i++) {
                        // 지정한 고객을 찾는다.
                        if (this.arr.cust_list[i].CUST_NO == key) {
                            if (type == 'RTNG_CD') {
                                this.arr.cust_list[i].RTNG_CD = value;
                            }
                            else if (type == 'IS_SEX') {
                                this.arr.cust_list[i].IS_SEX = value;
                            }
                        }
                    }
                }

                // 레이어창을 닫는다
                this.layer_off_all()

            },
            select_layerView(event, is_view) {
                //
                // 상세조회 레이어를 띄운다.
                //
                this.$refs.CustSelectFilter.view();
            },
            setCustSelectFilter(value) {
                console.log(value);
                this.ui.filter_val = value;

                this.api.list.BLVR_CATE = this.ui.filter_val.BLVR_CATE     //0: 전체 1: 상생 4: 역사 9: 미지정
                this.api.list.SEX = this.ui.filter_val.SEX                 //성별 ','로 연결된 문자열
                this.api.list.RTNG = this.ui.filter_val.RTNG               //연락처 등급 ','로 연결된 문자열
                this.api.list.BLVR_RELA = this.ui.filter_val.BLVR_RELA     //신도와의관계코드 ','로 연결된 문자열
                this.api.list.REGI = this.ui.filter_val.REGI               //지역
                this.api.list.BLVR_CATE_CD = this.ui.filter_val.BLVR_CATE_CD    //대상자 선택
                this.api.list.BOOK_NOS = this.ui.filter_val.BOOK_NOS        //도서
                this.movePage(1);
            },
            movePage(page) {
                //
                // page이동.검색시 초기값
                //
                this.page.val = page
                this.getList();
            },
        },
        mounted() {
            this.init();
        },
    }
