
    import store from '@/store'
    import UmslLeftMenu from '@/types/ums_leftmenu'
    import UmsService from '@/service/UmsService'
    import JSDPageCtrl from '@/components/PageCtrl.vue';

    import utils from '@/helpers/utils'                         //공용으로 사용될 js함수를 import

    const _ = require('lodash')


    export default {
        name: 'UmsResvList',
        components: {
            JSDPageCtrl,
        },
        data() {
            return {
                ui: {
                    detail: {               //수신인 레이어
                        is_view: false,
                        left: 0,
                        top: 0,
                    },
                    preview: {                  //미리보기
                        is_view: false,
                        left: 0,
                        top: 0,
                    },
                    delete: {
                        is_view: false,
                        is_all : false,
                        del_nos: [],

                    },
                },
                api: {
                    list: {
                        SEARCH_KEY: '',         //검색KEY :내용, 이름, 전화번호
                        STRT_DA: new Date().toStr('yyyy-MM-dd'),   //조회시작일
                        END_DA: new Date().addMonth(1).toStr('yyyy-MM-dd'),                 //조회종료일
                        MEDIA_TYPE_CD: '',      //'':전체 1:SMS 3:MMS(LMS + MMS)
                        SMT_TYPE: 0,            // 0:전체 1:폰발송 2:웹발송                        
                        CNT_PAGE: 10,           //페이지당 조회건수 
                    },
                    detail_list: {
                        SEARCH_KEY: '',         //검색KEY :내용, 이름, 전화번호
                        SEND_DA: '',            //발송일자
                        SEND_TM: '',            //발송시간
                        REG_DT : '',
                        MEDIA_TYPE_CD: '',      //'':전체 1:SMS 3:MMS(LMS + MMS)
                        SEND_TYPE : '',         //발송타입
                        SMT_TYPE: 0,            // 0:전체 1:폰발송 2:웹발송                        
                        CNT_PAGE: 10000,        //페이지당 조회건수 
                    },
                    delete: {
                        SEND_TYPE: '',         //발송타입
                        DEL_TYPE: 1,            //1:해당일시로 삭제 2:특정건 지정 삭제
                        SEARCH_KEY: '',         //검색KEY :내용, 이름, 전화번호
                        SEND_DA: '',            //발송일자
                        SEND_TM: '',            //발송시간
                        REG_DT: '',
                        MEDIA_TYPE_CD: '',      //'':전체 1:SMS 3:MMS(LMS + MMS)                        
                        SMT_TYPE: 0,            // 0:전체 1:폰발송 2:웹발송   
                        DEL_UMS: '',            //삭제할 고유번호  [{"NO":1},{"NO":5},{"NO":2},{"NO":3}]
                    },
                    resend: {
                        RESEND_TYPE: 2,         //재발송타입 1:발송이력 2:발송예약
                        SEARCH_KEY: '',         //검색KEY :내용, 이름, 전화번호
                        MEDIA_TYPE_CD: '',      //'':전체 1:SMS 3:MMS(LMS + MMS)
                        SMT_TYPE: 0,            //0:전체 1:폰발송 2:웹발송
                        SEND_STAT_CD: '',       //'':전체 1:발송대기 2:발송중 3:발송성공 4:발송실패
                        SEND_TYPE: '',          //타입    AUMS:템플릿 SMS LMS MMS SMT
                        SEND_DATA: '',          //JSON
                    },
                    page_cnt: [10, 30, 50, 70, 100, 150, 300],        //페이징처리 건수배열
                },
                arr: {
                    resv_list: [],              //발송이력 전체 리스트
                    detail_list: [],            //발송이력 상세 리스트
                    resend_list: [],            //재발송용 리스트
                },
                page: {
                    val: 1,
                    maxCnt: 1
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('예약리스트');
                this.$setMainBtn('+ 문자 발송')
                this.$setMainBtnCallback(() => {
                    this.$movePage('/app/ums')
                });

                //좌측메뉴 제목
                this.$setSideTitle('문자 발송');

                //좌측 메뉴 구성
                this.$setSideBtns(UmslLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('문자')
                    }
                });
                this.$nextTick(() => {
                    //연락처 상세 페이지에서 넘어온 값이 있으면 처리한다.
                    if (sessionStorage.hasOwnProperty('custview_data')) {
                        let data = JSON.parse(sessionStorage.getItem('custview_data'))

                        let strt_da = data.STRT_DA.toDate('yyyyMMdd')
                        let end_da = data.END_DA.toDate('yyyyMMdd')

                        this.api.list.SEARCH_KEY = data.CUST_NM;
                        this.api.list.STRT_DA = strt_da.toStr('yyyy-MM-dd');
                        this.api.list.END_DA = end_da.toStr('yyyy-MM-dd');

                        sessionStorage.removeItem('custview_data');
                    }

                    this.getUmsResvList()
                })
            },
            getUmsResvList() {                
                //예약리스트
                //console.log('getUmsResvList');
                UmsService.ums_resv_list(
                    this.page.val,
                    this.api.list,
                    (res) => {
                        this.arr.resv_list = res.data;			// 예약리스트 조회

                        this.page.maxCnt = this.arr.resv_list.length > 0 ? this.arr.resv_list[0].MAX_CNT : 1
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set();
                        })
                        
                    }
                );
            },
            getUmsResvDetailList() {
                //예약 상세 리스트
                UmsService.ums_resv_detail_list(
                    1,
                    {
                        SEARCH_KEY : this.api.detail_list.SEARCH_KEY,
                        SEND_DA: this.api.detail_list.SEND_DA,
                        SEND_TM : this.api.detail_list.SEND_TM,
                        REG_DT : this.api.detail_list.REG_DT,
                        MEDIA_TYPE_CD : this.api.detail_list.MEDIA_TYPE_CD,
                        SMT_TYPE : this.api.detail_list.SMT_TYPE,
                        SEND_TYPE : this.api.detail_list.SEND_TYPE,
                        CNT_PAGE : this.api.detail_list.CNT_PAGE
                    },
                    (res) => {
                        this.arr.detail_list = res.data;			// 예약리스트 조회                        
                    }
                );
            },
            detail_view(event, obj) {
                //상세 조회
                if (Number(obj.TOT_CNT) == 1) {        //복수건만 창이뜬다
                    return
                }

                let pos = utils.abspos(event);

                this.ui.detail.left = pos.x;
                this.ui.detail.top = pos.y;

                this.api.detail_list.SEARCH_KEY = this.api.list.SEARCH_KEY
                this.api.detail_list.SEND_DA = String(obj.SEND_DA)
                this.api.detail_list.SEND_TM = String(obj.SEND_TM)
                this.api.detail_list.REG_DT = String(obj.REG_DT)
                this.api.detail_list.MEDIA_TYPE_CD = this.api.list.MEDIA_TYPE_CD
                this.api.detail_list.SMT_TYPE = this.api.list.SMT_TYPE
                this.api.detail_list.SEND_TYPE = String(obj.SEND_TYPE)

                this.getUmsResvDetailList()
                this.ui.detail.is_view = true
            },
            preview_view(event, obj) {
                //상세 조회
                let pos = utils.abspos(event);

                this.ui.preview.left = pos.x;
                this.ui.preview.top = pos.y;

                this.api.detail_list.SEARCH_KEY = this.api.list.SEARCH_KEY
                this.api.detail_list.SEND_DA = String(obj.SEND_DA)
                this.api.detail_list.SEND_TM = String(obj.SEND_TM)
                this.api.detail_list.REG_DT = String(obj.REG_DT)
                this.api.detail_list.MEDIA_TYPE_CD = this.api.list.MEDIA_TYPE_CD
                this.api.detail_list.SMT_TYPE = this.api.list.SMT_TYPE
                this.api.detail_list.SEND_TYPE = String(obj.SEND_TYPE)

                this.getUmsResvDetailList()
                this.ui.preview.is_view = true
            },
            resv_delete(event, obj, del_type) {
                //예약 삭제 처리

                if (confirm('예약내용을 삭제하시겠습니까??') == false) {
                    return
                }
                this.api.delete.SEND_TYPE = String(obj.SEND_TYPE)
                this.api.delete.DEL_TYPE = del_type
                this.api.delete.SEARCH_KEY = this.api.list.SEARCH_KEY
                this.api.delete.SEND_DA = String(obj.SEND_DA)
                this.api.delete.SEND_TM = String(obj.SEND_TM)
                this.api.delete.REG_DT = String(obj.REG_DT)
                this.api.delete.MEDIA_TYPE_CD = this.api.list.MEDIA_TYPE_CD
                this.api.delete.SMT_TYPE = this.api.list.SMT_TYPE

                console.log('resv_delete');
                console.log(obj);


                //1:해당일시로 삭제 2:특정건 지정 삭제
                 if (del_type == 2) {
                    let tmp = []
                    tmp.push({
                        NO: Number(obj.RESV_NO)
                    })
                    this.api.delete.DEL_UMS = JSON.stringify(tmp)
                }

                UmsService.ums_resv_delete(
                    this.api.delete,
                    (res) => {
                        if (res.data > 0) {
                            alert('발송예약 삭제에 성공했습니다.');
                            this.ui.detail.is_view = false
                            this.ui.preview.is_view = false
                            this.getUmsResvList()
                            this.ui.delete.del_nos = []
                        }
                        else {
                            alert('발송예약 삭제에 실패했습니다.');
                        }                    
                    }
                )

            },
            resv_del_all() {
                // 전체 선택 삭제처리
                if (this.ui.delete.del_nos.length == 0) {
                    alert('삭제할 예약목록을 선택해 주세요.')
                    return
                }

                if (confirm('예약내용을 일괄 삭제하시겠습니까??') == false) {
                    return
                }

                let bulk_del = []
                for (var i = 0; i < this.ui.delete.del_nos.length; i++) {
                    let tmp = this.arr.resv_list.filter(x => x.SEQ_NO == this.ui.delete.del_nos[i])
                    
                    bulk_del.push({
                        SEND_DA: String(tmp[0].SEND_DA),
                        SEND_TM: String(tmp[0].SEND_TM),
                        MEDIA_TYPE_CD: this.api.list.MEDIA_TYPE_CD,
                        SMT_TYPE: this.api.list.SMT_TYPE,
                        REG_DT : String(tmp[0].REG_DT),
                        SEND_TYPE : String(tmp[0].SEND_TYPE),
                    })
                }

                console.log(bulk_del);
                UmsService.ums_resv_bulk_delete(
                    {
                        SEARCH_KEY: this.api.list.SEARCH_KEY,
                        DATA: JSON.stringify(bulk_del),
                    },
                    (res) => {
                        if (res.data > 0) {
                            this.ui.detail.is_view = false
                            this.ui.preview.is_view = false
                            alert('발송예약 일괄삭제에 성공했습니다.');
                            this.getUmsResvList()
                        }
                        else {
                            alert('발송예약 삭제에 실패했습니다.');
                        }
                    }
                )

                
            },
            setData(type) {
                if (type == '1W') {
                    this.api.list.STRT_DA = new Date().toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().addWeek(1).toStr('yyyy-MM-dd')
                }
                else if (type == '1M') {
                    this.api.list.STRT_DA = new Date().toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().addMonth(1).toStr('yyyy-MM-dd')
                }
                else if (type == '3M') {
                    this.api.list.STRT_DA = new Date().toStr('yyyy-MM-dd')
                    this.api.list.END_DA = new Date().addMonth(3).toStr('yyyy-MM-dd')
                }
                this.movePage(1)
            },
            check_all() {
                // 삭제 전체 체크및 해제 처리
                if (this.ui.delete.is_all == false) {
                    this.ui.delete.del_nos = []
                }
                else {
                    this.ui.delete.del_nos = []
                    for (var i = 0; i < this.arr.resv_list.length; i++) {
                        this.ui.delete.del_nos.push(this.arr.resv_list[i].SEQ_NO)
                    }
                }
            },
            resend_recvList() {
                //받으시는분 목록에서 재발송
                if (this.arr.detail_list.length <= 0) {
                    alert('수신인 목록이 없습니다.')
                    return
                }

                let data = []
                let detail = this.arr.detail_list
                let idx = 0

                for (var i = 0; i < detail.length; i++) {
                    idx = data.findIndex(x => x.CUST_NO == detail[i].CUST_NO);

                    if (idx < 0) {
                        data.push({
                            CUST_NO: detail[i].CUST_NO,
                            CUST_NM: detail[i].CUST_NM,
                            PHONE_CNO: detail[i].RECV_HDPH_NO
                        });
                    }
                }               

                let cust_data: string = JSON.stringify(data);
                sessionStorage.setItem('custList', cust_data)
                //console.log(sessionStorage.getItem('custList'));
                //console.log('sessionStorage');

                //발송 페이지로 연결한다.
                this.$movePage('/app/ums')
            },  //받으시는분 목록에서 재발송
            resend_ums() {
                //연락처 선택한것들 다시 발송처리
                //선택한 리스트의 목록을 구해 세션 스토리지에 넣어서 문자 발송 페이지로 연견한다.
                if (this.ui.delete.del_nos == 0) {
                    alert('예약리스트중 다시 발송할 목록을 선택해 주세요.')
                    return
                }

                let send_data = []
                for (var i = 0; i < this.ui.delete.del_nos.length; i++) {
                    let tmp = this.arr.resv_list.filter(x => x.SEQ_NO == this.ui.delete.del_nos[i])
                    //console.log(tmp);
                    send_data.push({
                        SEND_DA: tmp[0].SEND_DA,
                        SEND_TM: tmp[0].SEND_TM,
                        REG_DT: tmp[0].REG_DT,
                        SEND_TYPE: tmp[0].SEND_TYPE,
                    })
                }

                this.api.resend.SEARCH_KEY = this.api.list.SEARCH_KEY
                this.api.resend.MEDIA_TYPE_CD = this.api.list.MEDIA_TYPE_CD //'':전체 1:SMS 3:MMS(LMS + MMS)
                this.api.resend.SMT_TYPE = this.api.list.SMT_TYPE           //0:전체 1:폰발송 2:웹발송
                this.api.resend.SEND_STAT_CD = ''   //'':전체 1:발송대기 2:발송중 3:발송성공 4:발송실패                
                this.api.resend.SEND_DATA = JSON.stringify(send_data)

                //console.log(this.api.resend);

                UmsService.ums_resend(
                    this.api.resend,
                    (res) => {
                        this.arr.resend_list = res.data[0];				//일괄수정용 연락처 리스트 조회

                        let cust_data: string = JSON.stringify(this.arr.resend_list);
                        sessionStorage.setItem('custList', cust_data)
                        //console.log(sessionStorage.getItem('custList'));
                        //console.log('sessionStorage');
                        //발송 페이지로 연결한다.
                        this.$router.replace('/app/ums')
                    }
                );

            },
            movePage(page) {
                // page이동.검색시 초기값
                this.page.val = page
                this.ui.delete.is_all = false
                this.getUmsResvList()
            },

        },
        mounted() {
            this.init()
        },
    }
