// 우측 메뉴 처리

export default {    
    menu_list: [
        [
            { name: '연락처', path: '/app/user' },
            { name: '일괄수정', path: '/app/user/userbulk' },
        ],        
        [{ name: '그룹관리', path: '/app/user/groupCust' },],
        [
            { name: '연락처 엑셀 업로드', path: '/app/user/excel/upload' },
            { name: '연락처 엑셀 다운로드', path: '/app/user/excel/down' },
            { name: '중복 연락처 정리', path: '/app/user/dupcnta' },
            { name: '대상자 중복 리스트', path: '/app/user/duplist' },
        ],
        [
            { name: '휴지통', path: '/app/user/trash' },
            { name: '도움말', path: '/app/board/help' },
        ],
    ]
};

