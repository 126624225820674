
    import TemplateService from '@/service/TemplateService'

    export default {
        name: 'TmplDistLayer',
        props: {
            cust_cnt: {
                type: Number,
                default: 0,
            },
            send_type: {
                type: Number,       //1:웹발송 2:폰발송 3: 폰발송 + 웹발송
                default: 2,
            },
            tmpl_list: {
                type: Object,
                default() {
                    return {
                        SEQ_NO: -1,        //회차
                        ITEM_NO: -1,        //아이템번호
                        CUST_SEND_MON: -1,      //발송주기 월
                        CUST_SEND_DAY: -1,      //발송주기 일
                        CUST_SEND_TM: '10:00',  //발송시간
                        TITLE: '',          //제목
                        CONT: '',           //내용
                        IMG_NM: '',         //이미지파일명
                        IMG_PATH: '',       //이미지경로
                        IMG_URL: '',        //
                        SEND_TXT: '',      //
                    }
                },
            },

        },
        computed: {
        },
        data() {
            return {
                is_view_dist: false,        //레이어 띄울지 여부
                is_view_alert: false,       //한도초과 분산여부
                api: {
                    stat: {
                        CUST_CNT: 0,
                        SEND_TYPE: 0,
                        DATA: '',
                        WK_DA: new Date().toStr('yyyyMMdd'),
                    },
                    send: {
                        send_info: [],
                    },
                    send_type: 2,          // 2:폰발송 3: 폰발송 + 웹발송
                },
                arr: {
                    dist_list: [],          //분산리스트
                    is_dist: '',            //분산처리여부
                    tmpl: [],              //템플릿 정보 초기화
                },
                ui: {
                    proc_cnt: 0,

                },
            }
        },
        methods: {
            init() {
                //템플릿 정보를 받아서 컴포넌트에서 사용될 array를 만든다.
                console.log('init()');

            },
            view() {
                this.api.stat.WK_DA = new Date().toStr('yyyyMMdd')
                this.arr.tmpl = []
                for (var i = 0; i < this.tmpl_list.length; i++) {
                    this.arr.tmpl.push({
                        SEND_NO: this.tmpl_list[i].SEQ_NO,
                        MON: this.tmpl_list[i].CUST_SEND_MON,
                        DAY: this.tmpl_list[i].CUST_SEND_DAY,
                        TM: this.tmpl_list[i].CUST_SEND_TM,
                        RESV_DA: '',
                    });
                }

                this.ui.proc_cnt = 0
                this.api.stat.SEND_TYPE = this.send_type    //1:웹발송 2:폰발송 3: 폰발송 + 웹발송
                this.api.stat.CUST_CNT = this.cust_cnt

                this.getList()
            },
            change_date(obj) {
                //발송 시작일을 강제로 변경시 분산처리를 다시 호출한다.
                if (obj['SEND_DA2'].toDate('yyyy-MM-dd') <= new Date().toStr('yyyy-MM-dd').toDate('yyyy-MM-dd')) {
                    alert('변경하실 날짜는 당일 이후로만 변경하실수 있습니다.');

                    //날짜를 rollback한다
                    obj['SEND_DA2'] = obj['SEND_DA'].toDate('yyyyMMdd').toStr('yyyy-MM-dd')
                    return
                }
                this.api.stat.WK_DA = obj.SEND_DA2.toDate('yyyy-MM-dd').toStr('yyyyMMdd')
                this.getList()
            },
            dist_view() {
                // 분산처리 창 띄우기
                this.api.stat.SEND_TYPE = this.api.send_type

                this.getList()

                this.is_view_alert = false
                this.is_view_dist = true
            },
            getList() {
                //분산처리되는 리스트를 가져온다.
                //this.api.stat.DATA = JSON.stringify(this.arr.tmpl)
                this.api.stat.DATA = this.arr.tmpl
                //console.log(this.api.stat);

                TemplateService.tmpl_stat_list(
                    this.api.stat,
                    (res) => {
                        this.arr.dist_list = res.data[0]
                        this.arr.is_dist = res.data[1][0].IS_DIST

                        this.ui.proc_cnt += 1       //실행횟수 count
                        //console.log('this.arr.dist_list');
                        //console.log(this.arr.dist_list);
                        //console.log('this.arr.tmpl');
                        //console.log(this.arr.tmpl);

                        for (var i = 0; i < this.arr.tmpl.length; i++) {
                            let tmp = this.arr.dist_list.filter(x => x.SEND_NO == this.arr.tmpl[i].SEND_NO)
                            console.log(tmp[0]);
                            this.arr.tmpl[i].RESV_DA = tmp[0].SEND_DA2.reAll("-", "")
                        }

                        if (this.arr.dist_list.length > this.arr.tmpl.length && this.ui.proc_cnt == 1) {
                            this.is_view_alert = true
                            this.is_view_dist = false
                        }
                        else {
                            this.is_view_dist = true
                        }


                    }
                );
            },  //분산처리되는 리스트를 가져온다.
            tmpl_info_save() {
                //신도가 설정한 템플릿 분산 정보를 템플릿 저장을 위한 정보로 변환하여 전달한다.
                //console.log('tmpl_info_save');
                let list = []
                let tmpl = this.arr.tmpl
                for (var i = 0; i < tmpl.length; i++) {
                    let dist = this.arr.dist_list.filter(x => x.SEND_NO == tmpl[i].SEND_NO)

                    for (var j = 0; j < dist.length; j++) {
                        list.push({
                            SEND_DA: dist[j].SEND_DA2,
                            SEND_TM: tmpl[i].TM,
                            IS_SMT: dist[j].IS_SMT,
                            SEND_NO: tmpl[i].SEND_NO,
                            CUST_CNT: dist[j].SEND_CNT,

                            TITLE: '',              //컴포넌트 호출부분에서 처리
                            CONT: '',               //컴포넌트 호출부분에서 처리
                            IMG_PATH: '',           //컴포넌트 호출부분에서 처리
                            IMG_SAVE_NM: '',        //컴포넌트 호출부분에서 처리
                        })
                    }

                }
                this.$emit("setTmplInfo", list);
                this.is_view_dist = false       //레이어 띄울지 여부
                this.is_view_alert = false       //한도초과 분산여부
            },  //신도가 설정한 템플릿 분산 정보를 템플릿 저장을 위한 정보로 변환하여 전달한다.
        },
        mounted() {
            this.init();
        },
    }
