import api from '@/utils/api'
import axios from 'axios'
import store from '@/store'

export default class UploadService {
    public static imgUpload(data: any, callback: (result: any) => void): void {
        var url = `${process.env.VUE_APP_API_HOST}api/admin/upload/img`
        if (process.env.NODE_ENV == 'development') {
            url = url.replace('api/', '')
        }
        axios.post(url, data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Methods': 'OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': store.getters.getToken ? 'Bearer ' + store.getters.getToken : ''
            }
        }).then((r) => callback(r))
    }

    public static imgBulkUpload(data: any, callback: (result: any) => void): void {
        var url = `${process.env.VUE_APP_API_HOST}api/upload/img/multi`
        if (process.env.NODE_ENV == 'development') {
            url = url.replace('api/', '')
        }
        axios.post(url, data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Methods': 'OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': store.getters.getToken ? 'Bearer ' + store.getters.getToken : ''
            }
        }).then((r) => callback(r))
    }
}