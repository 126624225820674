import api from '@/utils/api'

export default class GroupService
{
    //그룹리스트. 미지정 그룹포함 고객수 포함
    public static group_list(BLVR_CATE_CD:string, callback: (result: any) => void): void {
        const url: string = `api/group/list/${BLVR_CATE_CD}`;
        api.get(`${url}`).then((r) => callback(r))
    }
    //그룹 추가/수정/삭제
    public static group_edit(data: any, callback: (result: any) => void): void {
        api.post(`api/group/edit`, data).then((r) => callback(r))
    }
    //그룹에 그룹원 추가
    public static group_cust_add(data: any, callback: (result: any) => void): void {
        api.post(`api/group/edit/cust/add`, data).then((r) => callback(r))
    }
    //그룹에 그룹원 삭제
    public static group_cust_del(data: any, callback: (result: any) => void): void {
        api.post(`api/group/edit/cust/del`, data).then((r) => callback(r))
    }
    //그룹 정렬순서 변경
    public static group_order(data: any, callback: (result: any) => void): void {
        api.post(`api/group/order`, data).then((r) => callback(r))
    }
    //그룹리스트. 그룹명만 조회함
    public static group_title_list(callback: (result: any) => void): void {
        const url: string = `api/group/group_list`;
        api.get(`${url}`).then((r) => callback(r))
    }
    //그룹일괄 변경. 그룹명과 그룹정렬순서를 일괄변경한다.
    public static group_bulk_update(data: any, callback: (result: any) => void): void {
        api.post(`api/group/bulk/update`, data).then((r) => callback(r))
    }

    //그룹일괄 변경. 다수 연락처의 그룹을 일괄 변경한다.
    public static group_bulk_cust_update(data: any, callback: (result: any) => void): void {
        api.post(`api/group/bulk/cust/update`, data).then((r) => callback(r))
    }

    //그룹별 연락처 카운트 리스트 ... 미지정만 들어가 있음
    // - 필터링 조건을 줄수 있음
    public static group_cust_list(data: any, callback: (result: any) => void): void {
        api.post(`api/group/cust/list`, data).then((r) => callback(r))
    }

    //그룹별 연락처 - 상세 연락처 리스트
    // - 필터링 조건을 줄수 있음
    // - 필터링된 그룹의 연락처 리스트를 조회한다.
    public static group_cust_detail_list(data: any, callback: (result: any) => void): void {
        api.post(`api/group/cust/detail/list`, data).then((r) => callback(r))
    }

    //그룹의 연락처 이동, 복사, 그룹제외 처리
    public static group_cust_bulk_update(data: any, callback: (result: any) => void): void {
        api.post(`api/group/cust/bulk/update`, data).then((r) => callback(r))
    }

    //그룹및 연락처 초기화
    public static group_cust_init(data: any, callback: (result: any) => void): void {
        api.post(`api/group/cust/init`, data).then((r) => callback(r))        

    }
}