
    import UserService from '@/service/UserService'
    import CommomService from '@/service/CommomService'
    import GroupService from '@/service/GroupService'
    import MemoService from '@/service/MemoService'

    import utils from '@/helpers/utils'                         //공용으로 사용될 js함수를 import

    export default {
        name: 'JSDTestCustView',
        props: {
            cust_no: Number,           //연락처 고객번호            
            is_edit: Boolean,
        },
        data() {
            return {
                is_view: false,          //레이어 띄울지 여부
                is_edit_mode : false,
                ui: {
                    is_voca_nm: false,
                    group_nms: '',
                    group_nos: [],          //화면상의 선택한 그룹번호
                    is_group_view: false,
                    book_nos: [],
                    book_nms: '',
                    is_book_view: false,
                    is_phone_view: false,
                    phone_arr: [{ RPRS_CD: '1', PHONE_CNO: '', IS_RPRS: 1 }],         //폰번호관련
                    RPRS_CD: '1',            
                    IS_RPRS: 1,
                    phone_arr_index: 0,
                    group_layer: {
                        top: 0,
                        left : 0,
                    },
                    phone_layer: {
                        top: 0,
                        left: 0,
                    },
                    book_layer: {
                        top: 0,
                        left: 0,
                    },
                    memo_tag_layer: false,      //메모별 태그변경 팝업 레이어                    
                    memo_tag_list: [],          //선택한 메모 태그
                    IS_IMPO: true,             //중요메모여부
                    IS_CALL: false,             //알림여부
                },
                api: {
                    cust_info: {
                        CUST_NO : 0,
                        CUST_NM: '',           //연락처이름
                        VOCA_NM: '',             //호칭
                        CUST_GROUP: '',        //그룹번호
                        CUST_PHONE: '',        //폰정보
                        BLVR_TYPE_CD: '1',      //1 일반신도 2 포교대상자
                        BLVR_CATE_CD: '1',      //1	상생방송적극시청 2상생방송가끔시청 3 상생방송시청안함 4 역사광복 9미지정                        
                        BLVR_RELA_CD: '99',
                        JSD_BOOK: '',          //증산도 도서
                        IS_SEX: 1,              //성별
                        RTNG_CD: '',            //신도등급코드 A B C D 9:미지정
                        REGI_CD: '99',          //99: 미지정
                        BIRT_YE: 0,             //출생년도
                        CUST_AGE: 0,           //나이          
                        CUST_INFO: '',
                        MEMO: '',               //메모
                        MEMO_INFO: '',          //메모 설정정보

                    }, 
                },
                code_list: [],              //코드리스트
                phone_arr: [],
                group_list: [],             //그룹리스트

                cust_info: [],              //고객정보                
                book_arr: [],                 //
                book_list: [],                //증산도 도서 전체목록
                memo_tag_list: [],              //메모 tag전체 리스트

                book_nos: [],              //선택한 도서 목록
                tag_nos: [],                 //선택한 tag목록
                tag_list: [],               //메모 tag리스트


                view_top: 100,
                view_left: 700,

            }
        },
        methods: {
            init() {
                //
                // 이 컨트롤 초기화시 작업
                // - 각종 코드값과 도서정보등을 불러온다.

                // 도서정보 조회
                UserService.jsdbook_list((res) => { this.book_list = res.data[0]; });


                //메모 tag를 select
                MemoService.memo_web_tag_list(
                    2,      //1:좌측 레이어용 2:메모용 TAG리스트 용 
                    1,      //@VIEW_TYPE=2일때 1:직접정렬값으로 2:태그명 오름차순 3:태그명 내림차순
                    (res) => {
                        this.tag_list = res.data[0];
                    }
                );

                //코드사전 조회
                CommomService.code_keys(
                    {
                        KEYS: 'RTNG_CD,BLVR_CATE_CD,BLVR_RELA_CD,REGI_CD,RPRS_CD',
                    },
                    (res) => {
                        this.code_list = res.data[0];				//코드리스트 조회                                       
                        //console.log(this.code_list);
                    }
                );

                //그룹목록 조회
                GroupService.group_title_list(
                    (res) => {
                        //console.log('group_list');
                        //console.log(res.data[0]);
                        //console.log(res.data[0].length);
                        // 마지막 미지정 그룹은 선택되지 않게 한다.
                        for (var i = 0; i < res.data[0].length; i++) {
                            this.group_list.push(res.data[0][i]);
                        }
                    }
                );
            },            
            cust_edit() {
                this.is_view = true;
                //고객 수정시 
                this.$nextTick(() => {
                    //console.log('CustEdit cust_edit');
                    //console.log(this.is_edit_mode);
                    this.is_edit_mode = true;
                    this.is_view = true;
                    this.getCustInfo();

                    //scroll되는 화면의 중앙에 위치하게 한다.
                    this.view_top = document.documentElement.scrollTop + 100;
                });
            },
            cust_add() {
                //고객신규 등록시 
                this.is_view = true;
                this.form_Init()
                this.is_edit_mode = false;

                //console.log('CustEdit cust_add');
                //console.log(this.is_edit_mode);
            },
            getCustInfo() {
                //console.log('getCustInfo');
                // 연락처 정보 조회                
                UserService.cust_select(
                    this.cust_no,
                    (res) => {
                        this.cust_info = res.data[0][0];
                        //console.log(this.cust_info);

                        //api데이터 초기화
                        this.api.cust_info.CUST_NO = this.cust_no
                        this.api.cust_info.CUST_NM = this.cust_info.CUST_NM             //연락처이름
                        this.api.cust_info.VOCA_NM = this.cust_info.VOCA_NM             //호칭
                        this.api.cust_info.BLVR_CATE_CD = this.cust_info.BLVR_CATE_CD   //1	상생방송적극시청 2상생방송가끔시청 3 상생방송시청안함 4 역사광복 9미지정
                        this.api.cust_info.BLVR_RELA_CD = this.cust_info.BLVR_RELA_CD
                        this.api.cust_info.BLVR_TYPE_CD = this.cust_info.BLVR_TYPE_CD       //1 일반신도 2 포교대상자
                        this.api.cust_info.BIRT_YE = this.cust_info.BIRT_YE              //출생년도
                        this.api.cust_info.CUST_AGE = this.cust_info.CUST_AGE             //나이
                        this.api.cust_info.IS_SEX = this.cust_info.IS_SEX              //성별                                               
                        
                        this.api.cust_info.RTNG_CD = this.cust_info.RTNG_CD             //신도등급코드 A B C D 9= 미지정
                        this.api.cust_info.REGI_CD = this.cust_info.REGI_CD           //99=  미지정                                               

                        this.api.cust_info.JSD_BOOK_JSON = ''
                        this.api.cust_info.CUST_GROUP = ''

                        
                        //폰정보
                        if (this.cust_info.CUST_PHONE_JSON.length > 0) {
                            let phone_arr = JSON.parse(this.cust_info.CUST_PHONE_JSON);

                            this.ui.phone_arr = [];
                            for (var i = 0; i < phone_arr.length; i++) {
                                this.ui.phone_arr.push({ RPRS_CD: phone_arr[i].RPRS_CD, PHONE_CNO: phone_arr[i].PHONE_CNO, IS_RPRS: phone_arr[i].IS_RPRS });
                            }
                            this.api.cust_info.CUST_PHONE = JSON.stringify(this.ui.phone_arr);
                            this.ui.phone_arr_index = 0;
                        }

                        //증산도 도서
                        this.ui.book_nms = '';
                        this.ui.book_nos = [];
                        if (this.cust_info.JSD_BOOK_JSON.length > 0) {                            
                            let books = JSON.parse(this.cust_info.JSD_BOOK_JSON)
                            for (var i = 0; i < books.length; i++) {
                                this.ui.book_nos.push( books[i].BOOK_NO)
                                this.ui.book_nms = this.ui.book_nms + '#' + books[i].TITLE
                            }
                            this.api.cust_info.JSD_BOOK = JSON.stringify(this.ui.book_nos);                            
                        }

                        //그룹
                        this.ui.group_nms = this.cust_info.CUST_GROUPS;
                        //console.log(this.cust_info.CUST_GROUPS);
                        //console.log(this.cust_info.CUST_GROUP_NOS);
                        
                        this.ui.group_nos = [];
                        if (this.cust_info.CUST_GROUPS.length > 0) {                            
                            let group_arr = this.cust_info.CUST_GROUP_NOS.split(',');
                            for (var i = 0; i < group_arr.length; i++) {
                                this.ui.group_nos.push(group_arr[i]);
                            }
                        }
                    }
                );

            },

            memo_tag_view() {
                // memo tag 선택 레이어 팝업 view
                this.ui.memo_tag_layer = true;
            },  
            memo_tag_update() {
                // 등록할 memo tag선택 tag표시용 object에를 구성한다.
                this.ui.memo_tag_layer = false;
                this.ui.memo_tag_list = [];
                
                for (var i = 0; i < this.tag_nos.length; i++) {
                    let idx = this.tag_list.findIndex(e => e.TAG_NO == this.tag_nos[i])

                    if (idx >= 0) {
                        this.ui.memo_tag_list.push({
                            TAG_NO: this.tag_list[idx].TAG_NO,
                            TAG_NM: this.tag_list[idx].TAG_NM,
                        });
                    }
                }                
            },
            memo_state_update(type) {
                // 메모 설정 부분 update
                if (type == 'impo') {
                    this.ui.IS_IMPO = !this.ui.IS_IMPO;
                }
                else if (type == 'call') {
                    this.ui.IS_CALL = !this.ui.IS_CALL;
                }
            },

            cust_layer_close() {
                this.$nextTick(() => {
                    //console.log(this.is_view)
                    this.is_view = false;
                });
            },
            cust_save(type: string) {
                //console.log('cust_save');
                //console.log(type);

                // 연락처 정보 저장
                // 필수입력 체크
                if (this.api.cust_info.CUST_NM.trim().koLen() < 4) {
                    alert('이름을 두글자이상 정상적으로 입력해 주세요');
                    this.$nextTick(() => { this.$refs.CUST_NM.focus(); });
                    return;
                }

                let phone_no: string = this.ui.phone_arr[this.ui.phone_arr.length - 1].PHONE_CNO.trim()
                let rprs_cd: string = this.ui.phone_arr[this.ui.phone_arr.length - 1].RPRS_CD

                if (phone_no.length < 5) {
                    alert('전화번호를 정상적으로 입력해 주세요.');
                    return;
                }
                //폰추가하기 전에 번호 체크 한번더
                if (this.phone_check(phone_no, rprs_cd) == false) {
                    return;
                }

                //console.log(this.ui.phone_arr);
                this.api.cust_info.CUST_PHONE = JSON.stringify(this.ui.phone_arr);

                //this.api.cust_info.CUST_INFO.IS_SEX = this.api.cust_info.IS_SEX;
                //this.api.cust_info.CUST_INFO.BIRT_YE = this.api.cust_info.BIRT_YE;
                //this.api.cust_info.CUST_INFO.RTNG_CD = this.api.cust_info.RTNG_CD;
                //this.api.cust_info.CUST_INFO.BLVR_RELA_CD = this.api.cust_info.BLVR_RELA_CD;
                //this.api.cust_info.CUST_INFO.REGI_CD = this.api.cust_info.REGI_CD;
                        
                //console.log(type);                
                //return;

                if (this.is_edit) {

                    //그룹정보/도서정보를 최종으로 수정
                    this.group_save();
                    this.book_save();

                    let cust_info = [];
                    cust_info.push({
                        BLVR_CATE_CD: this.api.cust_info.BLVR_CATE_CD,
                        IS_SEX: this.api.cust_info.IS_SEX,
                        BIRT_YE: this.api.cust_info.BIRT_YE,
                        RTNG_CD: this.api.cust_info.RTNG_CD,
                        BLVR_RELA_CD: this.api.cust_info.BLVR_RELA_CD,
                        REGI_CD: this.api.cust_info.REGI_CD,
                        CUST_GROUP: this.api.cust_info.CUST_GROUP,
                        JSD_BOOK: this.api.cust_info.JSD_BOOK,
                    });
                    this.api.cust_info.CUST_INFO = JSON.stringify(cust_info);                   

                    UserService.cust_update(
                        this.api.cust_info,
                        (res) => {
                            if (res.data.RESULT > 0) {
                                alert('연락처 수정에 성공했습니다.');
                                this.$emit('page_reload');
                                this.is_view = false;                                    
                            }
                            else {
                                alert('연락처 등록에 실패했습니다.(' + res.data.RESULT_MESG + ')');
                            }
                        }
                    );
                }
                else {
                    let tags = []

                    for (var i = 0; i < this.tag_nos.length; i++) {
                        tags.push({
                            TAG_NO: this.tag_nos[i]
                        })
                    }

                    var memo_info = {
                        IS_IMPO: (this.ui.IS_IMPO) ? 1 : 0,
                        IS_CALL: (this.ui.IS_CALL) ? 1 : 0,
                        CALL_DA: '',
                        CALL_TM: '',
                        TAGS: tags
                    };

                    //memo정보를 json형태로 만들어 넣는다.
                    //console.log('cust_add');
                    //console.log(memo_info);
                    //console.log(JSON.stringify(memo_info));
                    this.api.cust_info.MEMO_INFO =  JSON.stringify(memo_info)

                    let cust_info = {
                        //IS_SEX: this.api.cust_info.IS_SEX,
                        BIRT_YE: this.api.cust_info.BIRT_YE,
                        RTNG_CD: this.api.cust_info.RTNG_CD,
                        BLVR_RELA_CD: this.api.cust_info.BLVR_RELA_CD,
                        REGI_CD: this.api.cust_info.REGI_CD,
                        BLVR_CATE_CD: this.api.cust_info.BLVR_CATE_CD,
                    }
                    this.api.cust_info.CUST_INFO = JSON.stringify(cust_info);
                    //console.log('this.api.cust_info');
                    //console.log(this.api.cust_info);

                    UserService.cust_add(
                        this.api.cust_info,
                        (res) => {                            
                            if (res.data.RESULT > 0) {
                                let mesg: string = '연락처 등록에 성공했습니다.'

                                //리턴된 메시지가 있으면 결과 alert에 같이 표시한다.
                                if (res.data.RESULT_MESG.koLen() > 0) {
                                    mesg = mesg + '\n' + res.data.RESULT_MESG
                                }                                
                                alert(mesg);
                                if (type == 'ONE') {
                                    this.$emit('page_reload');
                                    this.is_view = false;
                                }
                            }
                            else {
                                alert('연락처 등록에 실패했습니다.(' + res.data.RESULT_MESG + ')');
                            }                            
                        }
                    );
                }

            },
            cust_save_init() {

                // 연락처 저장하고 다시 초기화 처리 
                this.cust_save( 'CONTINUE' );
                this.form_Init();
                this.$nextTick(() => { this.$refs.CUST_NM.focus(); });
            },
            form_Init() {
                // 입력화면 초기화 및 다시 입력받을수 있게 정리

                //화면 제어부 초기화
                this.ui.is_voca_nm = false;
                this.ui.group_nms =  ''
                this.ui.group_nos = []  //화면상의 선택한 그룹번호
                this.ui.is_group_view = false
                this.ui.book_nos = []
                this.ui.book_nms = ''
                this.ui.is_book_view = false
                this.ui.is_phone_view = false
                this.ui.phone_arr = [{ RPRS_CD: '1', PHONE_CNO: '', IS_RPRS: 1 }]         //폰번호관련
                this.ui.RPRS_CD = '1'
                this.ui.IS_RPRS = 1
                this.ui.phone_arr_index = 0
                this.ui.IS_IMPO =  true            //중요메모여부
                this.ui.IS_CALL = false             //알림여부
                this.tag_nos = []                   //선택한 tag목록
                this.ui.memo_tag_list = []

                //api데이터 초기화
                this.api.cust_info.CUST_NM=  ''             //연락처이름
                this.api.cust_info.VOCA_NM=  ''             //호칭
                this.api.cust_info.CUST_GROUP=  ''          //그룹번호
                this.api.cust_info.CUST_PHONE =  ''         //폰정보
                this.api.cust_info.BLVR_TYPE_CD=  '1'       //1 일반신도 2 포교대상자
                this.api.cust_info.BLVR_CATE_CD=  '1'       //1	상생방송적극시청 2상생방송가끔시청 3 상생방송시청안함 4 역사광복 9미지정
                this.api.cust_info.BLVR_RELA_CD=  '99'
                this.api.cust_info.JSD_BOOK =  ''           //증산도 도서
                this.api.cust_info.IS_SEX =  1              //성별
                this.api.cust_info.RTNG_CD=  ''             //신도등급코드 A B C D 9= 미지정
                this.api.cust_info.REGI_CD=  '99'           //99=  미지정
                this.api.cust_info.BIRT_YE=  0              //출생년도
                this.api.cust_info.CUST_AGE = 0             //나이
                this.api.cust_info.MEMO = ''                //메모
                this.api.cust_info.MEMO_INFO = ''           //메모
            },
            setVOCA_NM() {
                //호칭설정
                if (this.ui.is_voca_nm) {
                    if (this.api.cust_info.CUST_NM.trim().koLen() < 4 ) {
                        alert('이름을 두글자이상 정상적으로 입력해 주세요');
                        this.$nextTick(() => { this.$refs.CUST_NM.focus(); });
                        return;
                    }
                    this.api.cust_info.VOCA_NM = this.api.cust_info.CUST_NM + '님'
                }
            },            
            group_view( e ) {
                // 그룹조회 레이어 활성화
                let pos = utils.abspos(e);
                this.ui.group_layer.left = pos.x;
                this.ui.group_layer.top = pos.y;

                this.ui.is_group_view = true;
            },
            group_save() {
                // 선택한 그룹정보를 api에 설정한다.
                let group_arr = [];

                this.ui.group_nms = '';

                //console.log(this.ui.group_nos);
                //console.log(this.group_list);

                for (var i = 0; i < this.ui.group_nos.length; i++) {
                    let codes = this.group_list.filter(x => x.GROUP_NO == this.ui.group_nos[i]);
                    this.ui.group_nms = this.ui.group_nms + '#' + codes[0].GROUP_NM;
                    group_arr.push({ GROUP_NO:codes[0].GROUP_NO });
                }
                this.api.cust_info.CUST_GROUP = JSON.stringify(group_arr);
                this.ui.is_group_view = false;
            },
            book_view(e) {                
                //증산도 도서선택 레이어 활성화
                let pos = utils.abspos(e);
                this.ui.book_layer.left = pos.x;
                this.ui.book_layer.top = pos.y;

                this.ui.is_book_view = true;
                
            },
            book_save() {
                //증산도 도서선택 
                let book_arr = [];
                this.ui.book_nms = '';

                for (var i = 0; i < this.ui.book_nos.length; i++) {
                    let codes = this.book_list.filter(x => x.BOOK_NO == this.ui.book_nos[i]);
                    this.ui.book_nms = this.ui.book_nms + '#' + codes[0].TITLE;
                    book_arr.push({ BOOK_NO: codes[0].BOOK_NO });
                }
                this.api.cust_info.JSD_BOOK = JSON.stringify(book_arr);
                console.log(this.api.cust_info.JSD_BOOK);
                this.ui.is_book_view = false;
            },
            phone_view(index, e) {
                //연락처구분 창보기. 현재 전화번호의 상태를 선택된 상태로 띄운다.

                let pos = utils.abspos(e);
                this.ui.phone_layer.left = pos.x;
                this.ui.phone_layer.top = pos.y;

                this.ui.is_phone_view = true;
                //console.log(obj)                
                this.ui.RPRS_CD = this.ui.phone_arr[index].RPRS_CD;
                this.ui.IS_RPRS = this.ui.phone_arr[index].IS_RPRS;
                this.ui.phone_arr_index = index;
            },
            phone_remove(idx) {
                //해당연럭처 삭제
                //console.log('phone_remove');

                if (this.ui.phone_arr.length > 1) {
                    this.ui.phone_arr = this.ui.phone_arr.filter(function (element, index) {
                        if (idx !== index) {
                            return true;
                        }
                    });
                }                
            },
            phone_save() {
                //전화번호 종류및 대표번호 변경처리                
                let index: number = this.ui.phone_arr_index;

                //console.log('phone_save');
                //console.log(index);

                if (this.ui.IS_RPRS == 1 && this.ui.RPRS_CD != '1') {
                    alert('대표번호는 휴대폰번호만 올수 있습니다.');
                    return;
                }

                //현재번호가 대표번호면 나머지는 대표번호를 끈다.
                if (this.ui.IS_RPRS == 1) {
                    for (var i = 0; i < this.ui.phone_arr.length; i++) {
                        if (i != index && this.ui.phone_arr[i].IS_RPRS == 1) {
                            this.ui.phone_arr[i].IS_RPRS = 0;
                        }
                    }
                }

                this.ui.phone_arr[index].RPRS_CD = this.ui.RPRS_CD;
                this.ui.phone_arr[index].IS_RPRS = this.ui.IS_RPRS;

                this.api.cust_info.CUST_PHONE = JSON.stringify(this.ui.phone_arr);;
                //console.log(this.ui.phone_arr);
                this.ui.is_phone_view = false;
            },
            phone_arr_add() {
                // 전화번호 추가
                //  - 마지막 번호가 정상등록된건지 확인하고 정상이 아니면 더해지지 않게 한다.
                //if (this.ui.phone_arr.length === 0) {
                //    alert('전화번호를 입력하신후 추가해 주세요.');
                //    return;
                //}

                //console.log(this.ui.phone_arr.length);
                //console.log(this.ui.phone_arr);

                let phone_no: string = this.ui.phone_arr[this.ui.phone_arr.length -1].PHONE_CNO.trim();
                let rprs_cd: string = this.ui.phone_arr[this.ui.phone_arr.length - 1].RPRS_CD

                //console.log(phone_no);
                //console.log(rprs_cd);
                //console.log(this.ui.phone_arr);

                if (phone_no.length < 5) {
                    alert('전화번호를 정상적으로 입력해 주세요.');
                    return;
                }
                //폰추가하기 전에 번호 체크 한번더
                if (this.phone_check(phone_no, rprs_cd) == false) {
                    return;
                }

                this.ui.phone_arr.push({ RPRS_CD: '1',  PHONE_CNO: '', IS_RPRS: 0 });
            },
            phone_check(phone_cno: string, RPRS_CD: string) {
                //console.log(this.ui.phone_arr);
                //console.log(phone_cno);
                //console.log(RPRS_CD);

                //폰번호 체크                
                if (RPRS_CD == '1') {
                    //휴대폰번호
                    if (!phone_cno.isHP(false) || phone_cno.substring(0,3) != '010' ) {
                        alert('올바른 휴대폰 번호를 입력하세요');
                        return false;
                    }
                }
                else {
                    if (!phone_cno.isPhone(false) || phone_cno.substring(0, 3) == '010') {
                        alert('올바른 전화 번호를 입력하세요');
                        return false;
                    }
                }
                return true;
            },
            cust_age(type) {
                //나이와 출생년도 설정
                let newYear: number = new Date().getFullYear();

                if (type == 'AGE') {
                    if (this.api.cust_info.CUST_AGE <= 0 || this.api.cust_info.CUST_AGE > 90) {
                        alert('나이를 바르게 (1~90) 입력해 주세요.');
                        return;
                    }
                    this.api.cust_info.BIRT_YE = newYear - this.api.cust_info.CUST_AGE;
                }
                else if (type === 'YEAR') {
                    if (this.api.cust_info.BIRT_YE > newYear) {
                        alert('출생연도를 바르게 입력해 주세요.');
                        return;
                    }
                    this.api.cust_info.CUST_AGE = newYear - this.api.cust_info.BIRT_YE;
                }
            },
            autoPhone(obj, e) {
                //폰번호에 자동으로 -추가 
                var text = e.target.value;
                text = text.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-");
                //console.log(text);
                e.target.value = text;
                obj.PHONE_CNO = text;
            },
            getCodeNm(cate_key: string, code: string): string {
                // 코드값을 읽는 함수
                let arr = this.code_list.filter(x => x.CATE_KEY == cate_key).filter(x => x.CODE == code);
                let code_nm: string = '';

                //실제 데이터가 연결되기 전에는 로직처리 안되게 함......화면에 bind되는 시점문제로 오류나는 케이스
                if (arr.length > 0) {
                    code_nm = arr[0].CODE_NM;
                }
                return code_nm;
            },

        },
        computed: {            
        },
        mounted() {
            this.init();
        },
    }
