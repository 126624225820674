<template>
    <div class="layer adminSystem" style="top:0;left:0;">
        <div class="layerTitle">입금 후 충전 신청</div>

        <div class="layerMain ">
            <div class="layerMainText">


                <div class="mgB15 overflow">
                    <div class="left mgR15 bold" style="width:80px;">입금자명</div>
                    <div><input type="text" class="textshort" v-model="api.DEPO_NM" @change="api.DEPO_NM = $event.target.value"></div>
                </div>

                <div class="mgB15 overflow">
                    <div class="left mgR15 bold" style="width:80px;">휴대폰 번호</div>
                    <div><input type="text" class="textshort" v-model="api.DEPO_HDPH_NO" @change="api.DEPO_HDPH_NO = $event.target.value.cvPhone()"></div>
                </div>

                <div class="mgB15 overflow">
                    <div class="left mgR15 bold" style="width:80px;">금액</div>
                    <div><input type="text" class="textshort" v-model="api.DEPO_AMT" @change="api.DEPO_AMT = $event.target.value.toComma()" @focus="$event.target.select()"></div>
                </div>

                <div class="mgB15 overflow">
                    <label>
                        <input type="checkbox" v-model="api.IS_DIFF"> 충전할 ID와 입금자가 다른 경우에만 체크해주세요.
                    </label>
                </div>

                <div class="mgB15 overflow" v-if="api.IS_DIFF">
                    <div class="left mgR15 bold" style="width:140px;">충전할 분(이름)</div>
                    <div><input type="text" class="textshort" v-model="api.CHAR_NM" @change="api.CHAR_NM = $event.target.value"></div>
                </div>

                <div class="mgB15 overflow" v-if="api.IS_DIFF">
                    <div class="left mgR15 bold" style="width:140px;">충전할 분 휴대폰 번호</div>
                    <div><input type="text" class="textshort" v-model="api.CHAR_HDPH_NO" @change="api.CHAR_HDPH_NO = $event.target.value.cvPhone()"></div>
                </div>

                <div class="mgB15 overflow">
                    <div>
                        <ul style="border:1px solid #000000;padding:10px; border-radius:10px; background:#ffffff;">
                            <li style="padding:5px 0 5px;"><b>입금하실 곳</b> : <br>신한은행 140-011-401027 <b>예금주 : ㈜U-CRM</b></li>
                            <li style="padding:5px 0 0;" class="red bold">입금하신 다음 입금자명, 휴대폰번호, 금액을 남겨주시면 확인 후, 코인을 충전해 드립니다. </li>
                            <li style="padding:5px 0;">- 업무시간 외 입금건은 <br>다음 업무 시간에 순차적으로 처리됩니다. </li>
                            <li style="padding:5px 0;">※ <b>업무시간</b> : 월~금 10:00~17:30 점심시간 12:30~13:30<br>(토, 일, 공휴일 휴무)</li>
                        </ul>

                    </div>
                </div>

            </div>
        </div>
        <div class="layerBtnArea">
            <button class="blueBtn" @click="save()">저장</button>
            <button class="grayBtn" @click="cancel()">취소</button>
        </div>
    </div>
</template>

<script setup>
    import { ref, toRefs, reactive, onMounted, computed, inject, nextTick } from 'vue'

    import PayService from '@/service/PayService'
    import store from '@/store'

    const react = reactive({
        api: {
            SEQ_NO: 0,
            DEPO_NM: '',
            DEPO_HDPH_NO: '',
            DEPO_AMT: 0,
            IS_DIFF: false,
            CHAR_NM: '',
            CHAR_HDPH_NO: '',
        }
    })

    const user = computed({
        get() {
            return store.getters.getUser
        },
        set() {

        }
    })

    const { api } = toRefs(react)

    function valid() {
        if (!api.value.DEPO_NM.length) {
            alert('입금자명을 입력해주세요.')
            return false
        }
        if (!api.value.DEPO_HDPH_NO.length || !api.value.DEPO_HDPH_NO.isPhone(false)) {
            alert('입금자 휴대폰 번호를 확인해주세요.')
            return false
        }
        if (!api.value.DEPO_AMT.toString().length && parseInt(api.value.DEPO_AMT.toString().reAll(',', '')) == NaN) {
            alert('입금 금액을 확인해주세요.')
            return false
        }
        else if (parseInt(api.value.DEPO_AMT.toString().reAll(',', '')) < 1) {
            alert('입금 금액은 0원일 수 없습니다.')
            return false
        }

        if (api.value.IS_DIFF) {
            if (!api.value.CHAR_NM.length) {
                alert('충전할 분 이름을 입력해주세요.')
                return false
            }
            else if (!api.value.CHAR_HDPH_NO.length || !api.value.CHAR_HDPH_NO.isPhone(false)) {
                alert('충전할 분 휴대폰 번호를 확인해주세요.')
                return false
            }
        }
        return true
    }

    function save() {
        if (valid()) {
            api.value.DEPO_AMT = parseInt(api.value.DEPO_AMT.toString().reAll(',', ''))
            // ``으로 텍스트 입력시 pre처럼 입력한대로 화면에 표시됨
            if (confirm(`아래의 입력 정보로 충전 신청을 ${api.value.SEQ_NO > 0 ? '수정' : '접수'}합니다.
===========================================
입금자명 : ${api.value.DEPO_NM}
입금자 휴대폰 번호 : ${api.value.DEPO_HDPH_NO}
입금 금액 : ${api.value.DEPO_AMT.toComma()}${api.value.IS_DIFF ? `
충전할 분 : ${api.value.CHAR_NM}
충전할 분 휴대폰 번호${api.value.CHAR_HDPH_NO}` : ''}
===========================================`
            )) {
                PayService.reqUpdate(api.value, (res) => {
                    if (res.data > 0) {
                        alert(`충전 신청이 ${api.value.SEQ_NO > 0 ? '수정' : '접수'}되었습니다.`)
                        cancel()
                    }
                    else {
                        alert(`충전 신청${api.value.SEQ_NO > 0 ? '수정' : ''}을 실패했습니다.`)
                    }
                })
            }
        }
    }

    function cancel() {
        api.value.SEQ_NO = -1
        api.value.DEPO_NM = user.value.ACNT_NM
        api.value.DEPO_HDPH_NO = user.value.SEND_PHON_CNO
        api.value.DEPO_AMT = 0
        api.value.IS_DIFF = false
        api.value.CHAR_NM = ''
        api.value.CHAR_HDPH_NO = ''

        window.close()
    }

    onMounted(() => {
        window.resizeTo(window.outerWidth, 700)
        if (sessionStorage.hasOwnProperty('REQ_DATA')) {
            const item = JSON.parse(sessionStorage.getItem('REQ_DATA'))
            api.value.SEQ_NO = item.SEQ_NO
            api.value.DEPO_NM = item.DEPO_NM
            api.value.DEPO_HDPH_NO = item.DEPO_HDPH_NO
            api.value.DEPO_AMT = item.DEPO_AMT
            api.value.IS_DIFF = item.IS_DIFF == 1
            api.value.CHAR_NM = item.CHAR_NM
            api.value.CHAR_HDPH_NO = item.CHAR_HDPH_NO

            sessionStorage.removeItem('REQ_DATA')
        }
        else {
            api.value.SEQ_NO = -1
            api.value.DEPO_NM = user.value.ACNT_NM
            api.value.DEPO_HDPH_NO = user.value.SEND_PHON_CNO
        }
    })
</script>