import api from '@/utils/api'

export default class BoardService {
    public static list(page: number, data: any, callback: (result: any) => void): void {
        api.post(`api/board/list/${page}`, data).then((r) => callback(r))
    }

    public static select(pdsNo: number, data: any, callback: (result: any) => void): void {
        api.post(`api/board/select/${pdsNo}`, data).then((r) => callback(r))
    }

    public static cateList(data: any, callback: (result: any) => void): void {
        const url: string = `api/board/cate`
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`${url}?${query.join('&')}`).then((r) => callback(r))
    }
}