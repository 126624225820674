import api from '@/utils/api'

export default class CommomService {

    public static code_keys(data: any, callback: (result: any) => void): void {
        api.post(`api/admin/system/code/key`, data).then((r) => callback(r))
    }

    public static getPrivacy(no: number, data: any, callback: (result: any) => void): void {
        const url = `api/admin/system/privacy/${no}`
        const query = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })

        api.get(`${url}?${query.join('&')}`).then((r) => callback(r))
    }

    public static sysconf_select(key: string, callback: (result: any) => void): void {
        const url: string = `api/admin/system/sysconf/${key}`
        api.get(url).then((r) => callback(r))
    }
}