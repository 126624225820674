<!--
-->
<template>
    <div>
        <h1>test01</h1>

        <input type="text" class="textshort" v-model.trim="search_key" @keyup.enter="cust_search()" maxlength="13">
        <JSDCustSelect ref="JSDCustSelect"
                       :search_key="search_key"
                       :left="400"
                       :top="300"
                       :height="300"
                       :is_direct_add=true
                       @setCustData="setCustData">
        </JSDCustSelect>
        <br />
        {{CustData}}
        <CoinShortage ref="CoinShortage" :coin=5000 :left="400" :top="500" :message="message"></CoinShortage>
        <br /><br /><br /><br />

        <div class="layer deletAlt">
            <div class="layerMain">
                <UmsSendPreview ref="UmsSendPreview"
                                :page_cnt=5
                                :title="send_title"
                                :message="send_mesg"
                                :cust_data="cust_data">
                </UmsSendPreview>
            </div>
        </div>



        <CustSelectFilter ref="CustSelectFilter"
                          :left="200"
                          :top="200"
                          :init_filter="filter_val"
                          @setCustSelectFilter="setCustSelectFilter">
        </CustSelectFilter>
        {{setFilter_val}}
        <br />

        <GroupCustSelect ref="GroupCustSelect"
                         :left="200"
                         :top="200"
                         :cust_info="cust_info"
                         @setCustSelect="setCustSelect">
        </GroupCustSelect>
        {{cust_arr}}
        <br />

        <TmplItemEdit ref="TmplItemEdit"
                      :left="200"
                      :top="200"
                      @setItemInfo="setItemInfo" />


        <TmplDistLayer ref="TmplDistLayer"
                        :cust_cnt="1000"
                        :send_type="2"
                        :tmpl_list="tmpl_list"
                        @setTmplInfo="setTmplInfo"
                        />

        
        <button class="redBtn" @click="coin_shortage()">코인부족</button>&nbsp;
        <button class="redBtn" @click="mesg_prev()">미리보기</button>&nbsp;
        <button class="redBtn" @click="cust_filter()">연락처 선택 필터</button>&nbsp;
        <button class="redBtn" @click="group_select()">그룹 선택 </button>&nbsp;
        <button class="redBtn" @click="item_add()">템플릿아이템등록 </button>&nbsp;
        <br />
        <button class="redBtn" @click="tmplDist()">템플릿분산</button>&nbsp;



        <div class="filebox left mgR10">
            <label for="upFile"> 이미지 업로드</label>
            <input type="file" accept="image/jpeg" id="upFile" @change="getImg()">
        </div>
        <div class="preViewArea" :class="{'show': previewImg.length > 0}">
            <img :src="previewImg" @click="imgRemove()">
        </div>
    </div>
</template>

<script >
    import store from '@/store'
    import UploadService from '@/service/UploadService'

    import JSDCustSelect from '@/components/CustSelect.vue';
    import CoinShortage from '@/components/CoinShortage.vue';
    import UmsSendPreview from '@/components/UmsSendPreview.vue';
    import CustSelectFilter from '@/components/CustSelectFilter.vue';
    import GroupCustSelect from '@/components/GroupCustSelect.vue';
    import TmplItemEdit from '@/components/TmplItemEdit.vue';
    import TmplDistLayer from '@/components/TmplDistLayer.vue';

    const _ = require('lodash')

    export default {
        name: '',
        components: {
            JSDCustSelect,
            CoinShortage,
            UmsSendPreview,
            CustSelectFilter,
            GroupCustSelect,
            TmplItemEdit,
            TmplDistLayer,
        },
        data() {
            return {
                search_key: '',
                CustData : [],
                message: '코인이 부족합니다.<br><br><b>상샹레터 웹사이트에서 코인 충전 후<br>다시 예약하여 주시기 바랍니다.<br><br>',
                send_mesg: '[%호칭%] 반갑습니다. 늘 행복하세요.반갑습니다. 늘 행복하세요. [%이름%]님 반가워요!!!!!',
                send_title: '',
                cust_data: '[{"CUST_NO":2078092},{"CUST_NO":2077561},{"CUST_NO":2077807},{"CUST_NO":2073810}]',
                filter_val: {
                    BLVR_CATE: '1',     //0:전체 1:상생방송 :4:역사광복 9:미지정
                    SEX: '1',
                    RTNG: 'A,B',
                    BLVR_RELA: '',
                    REGI: '',
                    BLVR_CATE_CD: '2,3',
                },
                setFilter_val: '',
                cust_arr: [],
                is_ums_preview: false,
                previewImg: '',
                IMG_NM: '',
                IMG_PATH: '',
                IMG_URL: '',
                cust_info: [],
                tmpl_list : [],
            }
        },
        computed: {
        },
        methods: {
            inti() {
                this.cust_info.push({
                    CUST_NO: 2076030
                })
                this.cust_info.push({
                    CUST_NO: 2077978
                })
                this.cust_info.push({
                    CUST_NO: 2078182
                })

                this.tmpl_list.push({
                    SEQ_NO: 1,        //회차                                            
                    CUST_SEND_MON: 0,      //발송주기 월
                    CUST_SEND_DAY: 1,      //발송주기 일
                    CUST_SEND_TM: '10:00',  //발송시간
                })
                this.tmpl_list.push({
                    SEQ_NO: 2,        //회차                                            
                    CUST_SEND_MON: 1,      //발송주기 월
                    CUST_SEND_DAY: 0,      //발송주기 일
                    CUST_SEND_TM: '10:00',  //발송시간
                })
                this.tmpl_list.push({
                    SEQ_NO: 3,        //회차                                            
                    CUST_SEND_MON: 2,      //발송주기 월
                    CUST_SEND_DAY: 0,      //발송주기 일
                    CUST_SEND_TM: '10:00',  //발송시간
                })
                this.tmpl_list.push({
                    SEQ_NO: 4,        //회차                                            
                    CUST_SEND_MON: 3,      //발송주기 월
                    CUST_SEND_DAY: 0,      //발송주기 일
                    CUST_SEND_TM: '10:00',  //발송시간
                })
                this.tmpl_list.push({
                    SEQ_NO: 5,        //회차                                            
                    CUST_SEND_MON: 6,      //발송주기 월
                    CUST_SEND_DAY: 0,      //발송주기 일
                    CUST_SEND_TM: '10:00',  //발송시간
                })

            },
            tmplDist() {
                this.$nextTick(() => {
                    this.$refs.TmplDistLayer.view();
                });                
            },
            setTmplInfo(obj) {
                console.log(obj);
            },
            cust_search() {
                console.log(this.search_key);
                this.$refs.JSDCustSelect.search();
            },
            setCustData(value) {
                this.CustData = value
                console.log(this.CustData);
            },
            coin_shortage() {
                this.$refs.CoinShortage.view();
            },
            mesg_prev() {
                this.is_ums_preview = true
                this.$refs.UmsSendPreview.view();
            },
            cust_filter() {
                this.$refs.CustSelectFilter.view();
            },
            setCustSelectFilter(value) {
                this.setFilter_val = value;
                console.log(this.setFilter_val);
                this.filter_val = this.setFilter_val;
            },
            setCustSelect(value) {
                console.log(value);
                this.cust_arr = value
            },
            group_select() {
                this.$refs.GroupCustSelect.view();
            },
            item_add() {
                this.$refs.TmplItemEdit.view();
            },
            upload() {
                const file = this.getFile()
                const formData = new FormData()
                formData.append('upFile', file)

                UploadService.imgUpload(formData, (res) => {
                    const data = res.data
                    if (data.RESULT > 0) {
                        document.querySelector('#upFile').value = ''

                        this.IMG_NM = data.FILE_NM
                        this.IMG_PATH = data.PATH
                        this.IMG_URL = data.URL

                        this.itemUpdate()
                    }
                    else {
                        alert('이미지 업로드를 실패했습니다.')
                    }
                })
            },
            imgRemove() {
                if (confirm('이미지를 제거하시겠습니까?')) {
                    this.previewImg = ''                    
                    this.IMG_NM = ''
                    this.IMG_PATH = ''
                    this.IMG_URL = ''

                    document.querySelector('#upFile').value = ''
                }
            },
            getFile() {
                console.log('getFile()');
                const elFile = document.querySelector('#upFile')

                console.log(elFile);

                if (elFile && elFile.files.length > 0) {
                    return elFile.files[0]
                }
                return null
            },
            getImg() {
                alert('getImg()')
                const file = this.getFile()

                console.log(file);
                this.previewImg = file != null ? URL.createObjectURL(file) : ''                
            },
        },
        mounted() {
            this.inti()
            this.tmplDist()
        },
    }
</script>