
    import UmsService from '@/service/UmsService'

    import InfiniteScroll from '@/components/InfiniteScroll.vue'

    export default {
        name: 'JSDUmsSendPreview',
        components: {
            InfiniteScroll,
        },
        props: {
            title: {                //lms/mms일때 제목
                type: String,
                default : '',
            }, 
            message: {              //실제발송할 메시지
                type: String,
                require: true,
            },
        },
        data() {
            return {
                custList: [],
                mesgList: [],          //검색한 결과 리스트
                isView: false,
                isLoading: false,
                page: 1,
                cntPage: 50,
                maxPage: 1,
            }
        },
        methods: {
            init() {                
            },
            view(data) {
                if (data) {
                    this.custList = data
                }
                //console.log('view()');
                //외부에서 부르는 함수. 여기서 실제 고객 검색을 한다.
                this.$nextTick(() => {
                    this.page = 1
                    this.mesgList.splice(0, this.mesgList.length)
                    this.getCustList()                    
                })                
            },                        
            getCustList() {
                if (!this.isLoading) {
                    this.isLoading = true
                    UmsService.ums_send_preview(this.page,
                        {
                            TITLE: this.title,
                            MESG: this.message,
                            CUST_DATA: this.custList.filter((r) => r.CUST_NO > 0),
                            CNT_PAGE: this.cntPage,
                        },
                        (res) => {
                            const list = res.data[0]

                            if (list.length) {
                                this.maxPage = list.length > 0 ? (Math.ceil(list[0].MAX_CNT / this.cntPage)) : 1
                                this.custList.filter((r) => r.CUST_NO == 0).forEach((r) => {
                                    this.mesgList.push({
                                        ...r,
                                        TITLE: this.title,
                                        SEND_MESG: this.message
                                    })
                                })
                                this.mesgList.push(...list)
                                this.page++
                            }

                            this.isLoading = false
                        }
                    )
                }
            },
        },
        mounted() {
            this.init();
        },

    }
