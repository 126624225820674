import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "textCenter" }
const _hoisted_5 = { class: "mgT15 mgB15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "layer overflow cutSelectBlock",
    style: _normalizeStyle([{"height":"auto"}, {top : `${$data.top}px`, left : `${$data.left}px`, 'max-height' : `${$props.height}px`}]),
    onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.handleScroll && $options.handleScroll(...args)))
  }, [
    ($data.is_not_found == false)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.view_cust_list, (r) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "mgB10",
              onClick: ($event: any) => ($options.cust_set(r)),
              style: {"cursor":"pointer"}
            }, [
              _createTextVNode(_toDisplayString(r.SEQ_NO) + " ", 1),
              _createElementVNode("b", null, _toDisplayString(r.CUST_NM), 1),
              _createTextVNode(" " + _toDisplayString(r.PHONE_CNO), 1)
            ], 8, _hoisted_2))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    ($data.is_not_found == true)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString($data.not_found_message), 1),
            _createElementVNode("button", {
              class: "blueBtn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.cust_set('')))
            }, "확인")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 36)), [
    [_vShow, $data.is_view]
  ])
}