
    import store from '@/store'
    import AuthService from '@/service/AuthService'
    import UserService from '@/service/UserService'

    export default {
        name: 'AdminLoginLayer',
        computed: {
            user() {
                return store.getters.getUser
            },
            expTimeStr() {
                if (this.expTime > 0) {
                    let min = Math.floor(this.expTime / 60)
                    let sec = Math.floor(this.expTime % 60)
                    return ` ${min.zf(2)}:${sec.zf(2)}`
                }
                else {
                    return '이 만료되었습니다.'
                }
            }
        },
        data() {
            return {
                visible: false,
                expTime: 300,
                invl: {},
                isPushAble: false,
                certTxt: '',
                pushMode: false,
            }
        },
        methods: {
            async send(isSmsMode) {
                const { data } = await this.$root.getPushAble()
                this.isPushAble = data[0].APP_PUSH_YN == 'Y'

                this.pushMode = this.isPushAble && !isSmsMode

                if (!this.pushMode) {
                    UserService.smscert_send({
                        HDPH_CNO: this.user.SEND_PHON_CNO,
                        CHECK_SECOND: 300
                    }, (res) => {
                        const { RESULT, RESULT_MESG } = res.data
                        if (RESULT > 0) {
                            this.authExp = 300
                            if (this.invl) {
                                clearInterval(this.invl)
                            }
                            this.invl = setInterval(() => {
                                this.expTime -= 1
                                if (this.expTime <= 0) {
                                    clearInterval(this.invl)
                                }
                            }, 1000)
                        }
                        else {
                            alert(RESULT_MESG)
                        }
                    })
                }
                else {
                    AuthService.admin({
                        LOGIN_TYPE: 1,
                        ACNT_NM: this.user.ACNT_NM
                    }, (res) => {
                        const { RESULT, RESULT_MESG } = res.data
                        if (RESULT > 0) {

                        }
                        else {
                            alert(RESULT_MESG)
                        }
                    })
                }
            },
            cert() {
                if (!this.pushMode) {
                    if (!this.expTime) {
                        this.send(false)
                        return
                    }

                    if (!this.certTxt.length) {
                        alert('인증번호를 입력해주세요.')
                        return
                    }

                    UserService.smscert_check({
                        HDPH_CNO: this.user.SEND_PHON_CNO,
                        CRTF_TXT: this.certTxt,
                        CHECK_SECOND: 300,
                    }, (res) => {
                        const { RESULT, RESULT_MESG } = res.data
                        if (RESULT > 0) {
                            this.hide()
                            this.addSession()
                        }
                        else {
                            alert(RESULT_MESG)
                        }
                    })
                }
                else {
                    AuthService.admin({
                        LOGIN_TYPE: 3,
                        ACNT_NM: ''
                    }, (res) => {
                        const { RESULT, RESULT_MESG } = res.data
                        if (RESULT > 0) {
                            this.hide()
                            this.addSession()
                        }
                        else {
                            alert(RESULT_MESG)
                        }
                    })
                }
            },
            async addSession() {
                const { data } = await AuthService.userInfo()
                const sson = {
                    SSON_ID: data.TOKEN,
                    STM_CD: '2',
                    ACNT_NO: data.ACNT_NO
                }
                AuthService.session_add(sson, (res) => {
                    sessionStorage.setItem('JSD_SSON', JSON.stringify(sson))
                    location.href = '/admin'
                })
            },
            useSmsCert() {
                this.send(true)
            },
            show() {
                this.visible = true
                this.send(false)
            },
            hide() {
                this.$root.setLoading(false)
                this.visible = false
            }
        }
    }
