
    import UserService from '@/service/UserService'
    import CommomService from '@/service/CommomService'

    export default {
        name: 'JSDCustSelectFilter',
        props: {
            left: {                 //레이어 띄울 위치 Left
                type: Number,
                default: 110,
            },
            top: {                  //레이어 띄울 위치 top
                type: Number,
                default: 200,
            },
            init_filter: {          //filter값 초기치....
                type: Object,
                default() {
                    return {
                        BLVR_CATE: '0',     //0:전체 1:상생방송 :4:역사광복 9:미지정
                        SEX: '',
                        RTNG: '',
                        BLVR_RELA: '',
                        REGI: '',
                        BLVR_CATE_CD : '',
                    }
                },
            }
        },
        data() {
            return {
                is_view: false,          //레이어 띄울지 여부
                code_list: [],          //코드리스트 전체를 담고있는 배열
                book_list: [],
                ui: {
                    select_txt: '',         //현재 필터 조건 선택한 text
                    rela: [],               //선택한 유형 - 최종결과 넘길것
                    regi: [],               //선택한 지역 - 최종결과 넘길것
                    rtng: [],               //선택한 등급 - 최종결과 넘길것
                    sex: [],                //선택한 성별 - 최종결과 넘길것
                    blar: '0',              //선택한 대상자
                    blar_cate_cd: [],       //선택한 대상자 - 상생방송 선택시
                    book_nos:[],
                },

            }
        },
        methods: {
            init() {
                //console.log('init()');
                //console.log(this.init_filter.BLVR_CATE)
                //console.log(this.init_filter.BLVR_CATE_CD)                
                this.ui.blar_cate_cd = String(this.init_filter.BLVR_CATE_CD).split(',')

                // 도서정보 조회
                UserService.jsdbook_list((res) => { this.book_list = res.data[0]; });

                // 코드사전은 한번에 읽는다.
                CommomService.code_keys(
                    {
                        KEYS: 'BLVR_CATE_CD,BLVR_RELA_CD,REGI_CD,RTNG_CD',
                    },
                    (res) => {
                        this.code_list = res.data[0];				//코드리스트 조회
                    }
                );
            },
            clear_value() {
                //모든선택은 미선택 상태로 만든다.
                this.ui.select_txt = ''         //현재 필터 조건 선택한 text
                this.ui.rela = []               //선택한 유형
                this.ui.regi = []               //선택한 지역
                this.ui.rtng = []               //선택한 등급
                this.ui.sex = []                //선택한 성별
                this.ui.blar = '0'              //선택한 대상자
                this.ui.blar_cate_cd = []       //선택한 대상자
                this.ui.book_nos = []           //선택한 도서

                //초기화한것으로 재설정처리
                this.CustSelectFilter()
            },
            view() {
                //console.log(this.init_filter);
                //외부에서 부르는 함수. 여기서 레이어 창을 띄우고 선택값을 레이어에 맞춘다.

                this.$nextTick(() => {
                    this.ui.select_txt = '';                        //조회된 메시지
                    this.ui.blar = this.init_filter.BLVR_CATE       //대상자 구분                
                });

                // 전체 선택이 아니면 넘겨 받은 값으로 화면을 설정한다.
                // 성별 처리
                this.ui.sex = []
                if (this.init_filter.SEX.length > 0) {
                    let sex = this.init_filter.SEX.split(',')       //성별
                    for (var i = 0; i < sex.length; i++) {
                        this.ui.sex.push(sex[i])
                    }
                }
                
                // 등급처리                
                this.ui.rtng= []                
                if (this.init_filter.RTNG.length > 0) {
                    let rtng = this.init_filter.RTNG.split(',')      //선택한 등급
                    for (var i = 0; i < rtng.length; i++) {
                        this.ui.rtng.push(rtng[i])
                    }
                }            

                //유형처리                
                this.ui.rela = []                
                if (this.init_filter.BLVR_RELA.length > 0) {
                    let rela = this.init_filter.BLVR_RELA.split(',')      //선택한 유형
                    for (var i = 0; i < rela.length; i++) {
                        this.ui.rela.push(rela[i])
                    }
                }                

                // 지역 처리
                this.ui.regi = []                
                if (this.init_filter.REGI.length > 0) {
                    let regi = this.init_filter.REGI.split(',')      //선택한 유형
                    for (var i = 0; i < regi.length; i++) {
                        this.ui.regi.push(regi[i])
                    }
                }                
                this.is_view = true;
            },
            CustSelectFilter() {
                //선택한 filter값을 parent에게 전달한다.
                let select_txt: string = '';

                //console.log('CustSelectFilter');
                //console.log(this.ui.sex);
                //console.log(this.ui.rtng);
                //console.log(this.ui.rela);
                //console.log(this.ui.regi);

                //선택한 조회 조건에 대한 string을 완성한다                
                //성별 txt생성
                
                if (this.ui.sex.length > 0) {
                    select_txt += '성별 : ';
                    for (var i = 0; i < this.ui.sex.length; i++) {
                        if (this.ui.sex[i] == '0') {
                            select_txt += '여자 '
                        }
                        else if (this.ui.sex[i] == '1') {
                            select_txt += '남자 '
                        }
                    }
                }                    

                //등급 txt생성 
                if (this.ui.rtng.length > 0) {
                    select_txt += '등급 : ';
                    for (var i = 0; i < this.ui.rtng.length; i++) {
                        select_txt += this.ui.rtng[i] + ' ';
                    }
                }

                //유형 txt생성
                if (this.ui.rela.length > 0) {
                    select_txt += '유형 : ';
                    let rela_cd = this.code_list.filter(x => x.CATE_KEY == 'BLVR_RELA_CD');

                    for (var i = 0; i < this.ui.rela.length; i++) {
                        let rela_cds = rela_cd.filter(x => x.CODE == this.ui.rela[i]);
                        select_txt += rela_cds[0].CODE_NM + ' ';
                    }                        
                }

                //지역 txt생성                    
                if (this.ui.regi.length > 0) {
                    select_txt += '지역 : ';
                    let regi_cd = this.code_list.filter(x => x.CATE_KEY == 'REGI_CD');
                    for (var i = 0; i < this.ui.regi.length; i++) {
                        let regi_cds = regi_cd.filter(x => x.CODE == this.ui.regi[i]);
                        select_txt += regi_cds[0].CODE_NM + ' ';
                    }
                }

                //도서 txt생성                    
                if (this.ui.book_nos.length > 0) {
                    select_txt += '도서 : ';                    
                    for (var i = 0; i < this.ui.book_nos.length; i++) {
                        let book_info = this.book_list.filter(x => x.BOOK_NO == this.ui.book_nos[i]);

                        select_txt += book_info[0].TITLE + ' ';
                    }
                }

                //console.log(select_txt);

                //parent로 선택한 값을 전달.
                this.$emit("setCustSelectFilter", {
                    BLVR_CATE: this.ui.blar,
                    SEX: this.ui.sex.join(','),
                    RTNG: this.ui.rtng.join(','),
                    BLVR_RELA: this.ui.rela.join(','),
                    REGI: this.ui.regi.join(','),
                    SELECT_TXT: select_txt,
                    BLVR_CATE_CD: this.ui.blar_cate_cd.join(','),
                    BOOK_NOS: this.ui.book_nos.join(','),
                });
                this.is_view = false;
            },
            rtng_sort(a, b) {
                return a[1] - b[1]
            },

        },
        mounted() {
            this.init();
        },
    }
