
    import store from '@/store'
    import UmslLeftMenu from '@/types/ums_leftmenu'
    import UserService from '@/service/UserService'

    const _ = require('lodash')

    export default {
        name: '',
        components: {
        },
        data() {
            return {
                arr: {
                    list: [],      //사용자 설정값 리스트
                },
                api: {
                    SMT_BALA_CNT: 0,       //폰발송 여유분건수
                    MMS_TITLE: 0,           //MMS발송에 제목 사용여부
                    SMT_ROAM_YN: 0,         //로밍사용여부                    
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('발송설정');
                this.$setMainBtn('+ 문자 발송')
                this.$setMainBtnCallback(() => {
                    this.$movePage('/app/ums')
                });

                //좌측메뉴 제목                
                this.$setSideTitle('문자 발송');

                //좌측 메뉴 구성
                this.$setSideBtns(UmslLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('문자')
                    }
                });

                //화면 표시
                this.$nextTick(() => {
                    this.getConfList()
                })
            },
            getConfList() {
                // 사용자 설정 리스트 조회
                //console.log('getConfList()');

                UserService.conf_bulk_list(
                    {
                        DATA: 'SMT_BALA_CNT,MMS_TITLE,SMT_ROAM_YN',
                    },
                    (res) => {
                        this.arr.list = res.data;			// 예약리스트 조회
                        //console.log(this.arr.list);
                        this.api.SMT_BALA_CNT = this.arr.list.SMT_BALA_CNT
                        this.api.MMS_TITLE = this.arr.list.MMS_TITLE
                        this.api.SMT_ROAM_YN = this.arr.list.SMT_ROAM_YN                        
                        //console.log(this.api);
                        
                    }
                );
            },
            conf_save() {
                //설정값을 xml형태로 
                let data: string = '<ROOT>' +
                    '<DATA CONF_TYPE_CD="SMT_BALA_CNT">' + this.api.SMT_BALA_CNT + '</DATA>' +
                    '<DATA CONF_TYPE_CD="MMS_TITLE">' + this.api.MMS_TITLE + '</DATA>' +
                    '<DATA CONF_TYPE_CD="SMT_ROAM_YN">' + this.api.SMT_ROAM_YN + '</DATA>' +                    
                    '</ROOT>'

                //console.log('conf_save()');
                //console.log(data);
                UserService.conf_bulk_update(
                    {
                        DATA: data,
                    },
                    (res) => {
                        if (res.data > 0) {
                            alert('발송 설정이 변경되었습니다.')
                        }
                        else {
                            alert('발송 설정 변경에 실패했습니다.');
                        }
                    }
                )
                
            },
        },
        mounted() {
            this.init()
        },
    }
