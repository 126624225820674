import api from '@/utils/api'

export default class UserService {

    //휴지통 리스트 - 삭제한 연락처 휴지통리스트 조회
    public static trash_list(page: number, data: any, callback: (result: any) => void): void {
        const url: string = `api/app/contact/trash/${page}`;
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`${url}?${query.join('&')}`).then((r) => callback(r))
    }

    // 휴지통 비우기 처리
    public static trash_clear(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/trash/clear`, data).then((r) => callback(r))
    }

    // 휴지통 넣기/복원 
    public static trash_update(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/trash/update`, data).then((r) => callback(r))
    }

    //대상자 중복 리스트
    public static dup_list(page: number, cntPage : number, callback: (result: any) => void): void {
        const url: string = `api/contact/dup/cust/${page}?&cntPage=${cntPage}`;        
        api.get(`${url}`).then((r) => callback(r))
    }

    //중복 연락처 리스트
    public static dup_cnta_list(callback: (result: any) => void): void {
        const url: string = `api/app/contact/duplist`;
        api.get(`${url}`).then((r) => callback(r))
    }

    //중복 연락처 merge처리
    public static dup_cnta_merge(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/dup/update`, data).then((r) => callback(r))
    }

    // 연락처 리스트 조회 (연락처리스트나 일괄수정 조회에서 사용)
    public static cust_list(page: number, data: any, callback: (result: any) => void): void {
        //`api/app/contact/search/${page}`
        api.post(`api/app/contact/search/${page}`, data).then((r) => callback(r))
    }

    // 발송화면용 연락처 리스트 조회
    public static cust_short_list(data: any, callback: (result: any) => void): void {        
        api.post(`api/app/contact/short`, data).then((r) => callback(r))
    }

    // 도서 목록 리스트
    public static jsdbook_list(callback: (result: any) => void): void {
        const url: string = `api/contact/jsdbook`;
        api.get(`${url}`).then((r) => callback(r))
    }
    // 고개별 도서목록 수정
    public static jsdbook_update(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/jsdbook/update`, data).then((r) => callback(r))
    }

    // 일괄수정
    public static bulk_update(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/bulk/update`, data).then((r) => callback(r))
    }

    // 연락처 삭제
    public static cust_del(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/remove`, data).then((r) => callback(r))
    }

    //연락처 상세
    public static cust_select(custNo: number, callback: (result: any) => void): void {
        const url: string = `api/app/contact/info/${custNo}?`;
        api.get(`${url}`).then((r) => callback(r))
    }

    // 연락처 상세 - 하단 메모 조회
    public static cust_memo_list(custNo: number, page: number, data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/memo/${custNo}/${page}`, data).then((r) => callback(r))
    }
    // 메모 tag리스트 조회
    public static memo_tag_list(is_option: string, callback: (result: any) => void): void {
        const url: string = `api/app/memo/tag/list/${is_option}`;
        api.get(`${url}`).then((r) => callback(r))
    }
    //엑셀컬럼리스트조회
    public static excel_column(callback: (result: any) => void): void {
        const url: string = `api/app/contact/excel/column`;
        api.get(`${url}`).then((r) => callback(r))
    }
    //엑셀 다운
    public static excel_down(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/excel/down`, data).then((r) => callback(r))
    }
    //엑셀업로드
    public static excel_upload(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/excel/upload`, data).then((r) => callback(r))
    }
    //엑셀업로드 리스트조회
    public static excel_upload_list(page: number, cntPage:number, callback: (result: any) => void): void {
        const url: string = `api/app/contact/excel/upload/list/${page}?&cntPage=${cntPage}`;
        api.get(`${url}`).then((r) => callback(r))        
    }
    //엑셀업로드 연락처 등록
    public static excel_upload_move(callback: (result: any) => void): void {
        api.post(`api/app/contact/excel/upload/move`).then((r) => callback(r))
    }

    //폰업로드 제외목록 관리
    public static excl_update(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/excl`, data).then((r) => callback(r))
    }

    //폰업로드 제외목록 리스트
    public static excl_list(page: number, cntPage: number, callback: (result: any) => void): void {
        const url: string = `api/app/contact/excl/${page}?&cntPage=${cntPage}`;
        api.get(`${url}`).then((r) => callback(r))
    }

    //연락처 등록
    public static cust_add(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/add`, data).then((r) => callback(r))
    }
    //연락처 수정
    public static cust_update(data: any, callback: (result: any) => void): void {
        api.post(`api/app/contact/edit`, data).then((r) => callback(r))
    }

    //연락처 빠른검색..이름과 전화번호로 검색하고 레이어로 보여주고 선택할때 사용
    public static cust_quick_search(page: number, cntPage: number, SEARCH_KEY:string, callback: (result: any) => void): void {
        const url: string = `api/app/contact/quick/${page}?&cntPage=${cntPage}&SEARCH_KEY=${SEARCH_KEY}`;
        api.get(`${url}`).then((r) => callback(r))
    }

    // mypage-나의정보
    public static mypage_myinfo(callback: (result: any) => void): void {
        const url: string = `api/app/user/mypage/myinfo`;
        api.get(`${url}`).then((r) => callback(r))
    }
    // mypage-발신번호리스트
    public static mypage_phone_list(callback: (result: any) => void): void {
        const url: string = `api/app/user/mypage/phone/list`;
        api.get(`${url}`).then((r) => callback(r))
    }
    // mypage-발신번호 update
    public static mypage_phone_update(data: any, callback: (result: any) => void): void {        
        api.post(`api/app/user/mypage/phone`, data).then((r) => callback(r))
    }
    // sms인증문자 발송
    public static smscert_send(data: any, callback: (result: any) => void): void {
        api.post(`api/admin/system/cert_sms/send`, data).then((r) => callback(r))
    }
    // sms인증문자 확인
    public static smscert_check(data: any, callback: (result: any) => void): void {
        api.post(`api/admin/system/cert_sms/check`, data).then((r) => callback(r))
    }
    // app접속기기목록
    public static device_list(callback: (result: any) => void): void {
        const url: string = `api/app/user/mypage/device/list`;
        api.get(`${url}`).then((r) => callback(r))
    }
    //사용자 설정 일괄로 조회
    public static conf_bulk_list(data: any, callback: (result: any) => void): void {
        const url: string = `api/app/user/conf/bulk`;
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`${url}?${query.join('&')}`).then((r) => callback(r))
    }
    //사용자 설정값 변경
    public static conf_update(data: any, callback: (result: any) => void): void {
        api.post(`api/app/user/conf`, data).then((r) => callback(r))
    }

    //사용자 설정값 변경
    public static conf_bulk_update(data: any, callback: (result: any) => void): void {
        api.post(`api/app/user/conf/bulk/update`, data).then((r) => callback(r))
    }

}