<template>
    <div>
        <ul>
            <li @click="moveTo(min)">&lt;&lt;</li>
            <li @click="move(-1)">&lt;</li>
            <li v-for="n in range" @click="moveTo(n)" v-bind:class="{'select': n == page.val}">{{n}}</li>
            <li @click="move(1)">&gt;</li>
            <li @click="moveTo(max)">&gt;&gt;</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'JSDPageCtrl',
        props: {
            rTick: Number,
            page: Object,
            cntPage: Number,
            maxCnt: Number,
        },
        data() {
            return {
                range: [],
                min: 1,
                max: 1,
                from: 1,
                to: 1,
            }
        },
        methods: {
            setRange() {
                this.range.splice(0, this.range.length)
                for (var i = this.from; i <= this.to; i++) {
                    this.range.push(i)
                }
            },
            set() {
                this.max = (this.cntPage == 0 || this.maxCnt == 0) ? 1 : (Math.ceil(this.maxCnt / this.cntPage));

                this.to = Math.min(this.max, Math.max(this.page.val + 4, this.min + 9));
                this.from = Math.max(this.to - 9, this.min);

                this.$nextTick(() => {
                    this.setRange()
                })
            },
            move(cnt) {
                var mv = this.page.val + cnt
                if (mv > 0 && mv <= this.max) {
                    this.moveTo(mv)
                }
            },
            moveTo(pg) {
                this.moveToNoCb(pg)
                this.$emit('page-move', pg)
            },
            moveToNoCb(pg) {
                this.page.val = Math.min(Math.max(pg, this.min), this.max)
                this.to = Math.min(this.max, Math.max(this.page.val + 4, this.min + 9));
                this.from = Math.max(this.to - 9, this.min)
            }
        }
    }
</script>

<style scoped>
    ul{
        display:flex;
        list-style:none;
        justify-content:center;
    }

    ul>li { 
        cursor: pointer;
        display:flex;
        align-items:center;
        justify-content:center;
        width: 26px;
        height: 26px;
        padding: 5px;
        border: 1px solid #282828;
        border-collapse: collapse;
        vertical-align: middle;
    }

    ul>li:not(:first-child){
        margin-left:-1px;
    }

    .select {
        color: #ffffff;
        border: 1px solid #1864c3;
        background: #1864c3;
    }
</style>