<!--
-->
<template>
        
</template>

<script >
    import store from '@/store'

    const _ = require('lodash')

    export default {
        name: '',
        components: {
        },
        data() {
            return {
            }
        },
        computed: {
        },
        methods: {
            init() {
                
            },

        },
        mounted() {
            this.init()            
        },
    }
</script>