
    import store from '@/store'
    import GroupService from '@/service/GroupService'
    import UserService from '@/service/UserService'
    import UserLeftMenu from '@/types/user_leftmenu'
    import XLSX from 'xlsx'

    export default {
        name: 'ExcelDown',
        components: {
        },
        data() {
            return {
                call_cnt: 0,
                ui: {
                    chk_all_column: false,
                    chk_all_group: false,                    

                },
                api: {
                    BLVR_CATE_CD: "0",
                    GROUP_NOS: [],          //다운받을 그룹
                    COLUMNS : [],           //다운받을 컬럼
                },
                arr: {
                    column_list:[],         //컬럼목록
                    group_list: [],         //그룹리스트
                    cust_list: [],           //엑셀다운로드할 리스트
                    group: [],
                    column: [],
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('연락처 엑셀 다운로드');

                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);
                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });
                this.$nextTick(() => {

                    //다운로드할 컬럼 리스트
                    UserService.excel_column(
                        (res) => {
                            this.arr.column_list = res.data[0];
                            this.arr.column = res.data[0][0];

                            if (this.call_cnt == 0) {
                                for (var i = 1; i < this.arr.column_list.length; i++) {
                                    //필수컬럼만 선택으로 변경한다.
                                    if (this.arr.column_list[i].IS_DEFAULT === 1) {
                                        this.api.COLUMNS.push(this.arr.column_list[i].COL_NM);
                                    }
                                }
                            }
                            this.call_cnt++

                        }
                    );

                    this.getList();
                });
            },
            getList() {
               
                // 그룹리스트 조회
                GroupService.group_list(
                    this.api.BLVR_CATE_CD,
                    (res) => {
                        this.arr.group_list = res.data[0];
                        this.arr.group = res.data[0][0];
                        //console.log('arr.group_list')
                        //console.log(this.arr.group_list)
                    }
                );
            },
            setBLVR_CATE_CD(code) {
                //
                // 신도분류코드 선택시 하단의 그룹별 연락처 건수를 적용해 반영한다.
                //  0:전체 1:상생방송관련 4:역사광복 9:미지정 설정
                this.api.BLVR_CATE_CD = code;
                this.getList();
            },
            check_all_column( type ) {
                //
                // 컬럼 전체 선택여부 /개별 컬럼 선택시 체크변경
                //

                if (type === 'ALL') {

                    // 컬럼 전체 선택 선택시
                    if (this.ui.chk_all_column === true) {
                        //모든 컬럼 선택 상태로
                        this.api.COLUMNS = [];
                        for (var i = 1; i < this.arr.column_list.length; i++) {
                            this.api.COLUMNS.push(this.arr.column_list[i].COL_NM);
                        }
                    }
                    else {
                        //모든 컬럼 선택 해제 상태로
                        this.api.COLUMNS = [];
                        for (var i = 1; i < this.arr.column_list.length; i++) {
                            //필수컬럼만 선택으로 변경한다.
                            if (this.arr.column_list[i].IS_DEFAULT === 1) {
                                this.api.COLUMNS.push(this.arr.column_list[i].COL_NM);
                            }
                        }
                    }
                }
                else {
                    // 컬럼 개별 선택시 -> 모든 컬럼이 선택되었으면 전체컬럼 on아니면  전체 컬럼 off
                    let chk_col_cnt: number = this.api.COLUMNS.length;
                    this.ui.chk_all_column = chk_col_cnt === this.arr.column_list.length - 1 ? true : false;
                }
            },
            check_all_group( type ) {
                //
                // 그룹 전체 선택여부  /개별 그릅 선택시 체크변경
                //
                if (type === 'ALL') {
                    if (this.ui.chk_all_group === true) {

                        //모든 그룹 선택 상태로
                        this.api.GROUP_NOS = [];
                        for (var i = 1; i < this.arr.group_list.length; i++) {
                            this.api.GROUP_NOS.push(this.arr.group_list[i].GROUP_NO);
                        }
                    }
                    else {
                        //모든 컬럼 선택 해제 상태로
                        this.api.GROUP_NOS = [];
                    }
                }
                else {
                    // 그룹 개별 선택시 -> 모든 그룹이 선택되었으면 전체그룹on아니면  전체그룹 off
                    let chk_group_cnt: number = this.api.GROUP_NOS.length;
                    this.ui.chk_all_group = chk_group_cnt === this.arr.group_list.length - 1 ? true : false;
                }
            },
            saveExcel() {                
                // 엑셀파일로 저장하기                
                if (confirm('선택하신 컬럼과 그룹정보로 엑셀파일을 생성하시겠습니까?') === false) {
                    return;
                }

                UserService.excel_down(
                    {
                        BLVR_CATE: this.api.BLVR_CATE_CD,
                        COLS: this.api.COLUMNS.join(','),
                        GROUPS: this.api.GROUP_NOS.join(','),
                    },
                    (res) => {
                        this.arr.cust_list = res.data[0];
                        // export json to Worksheet of Excel
                        // only array possible
                        var custWS = XLSX.utils.json_to_sheet(this.arr.cust_list);

                        // A workbook is the name given to an Excel file
                        var wb = XLSX.utils.book_new() // make Workbook of Excel

                        // add Worksheet to Workbook
                        // Workbook contains one or more worksheets
                        XLSX.utils.book_append_sheet(wb, custWS, '연락처리스트') // sheetAName is name of Worksheet

                        // export Excel file
                        XLSX.writeFile(wb, 'jsd_cust_list.xlsx') // name of the file is 'jsd_cust_list.xlsx'
                    }
                );
            }
        },
        mounted() {
            this.init();
        },
    }
