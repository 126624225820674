<!--

프로그램 명  : ExcelUploadList.vue
프로그램 설명: 연락처 엑셀업로드 리스트
작성자		 : 20220110 (주)UCRM/김태훈
수정이력)
1)2024-01-16 김태훈 문구수정요청. 
-->
<template>

    <div class="box">
        <div class="pageTitleTab left" style="margin-bottom:20px">
            <div class="tabBtn ">신규등록 : {{arr.new_data_cnt}}</div>
            <div class="tabBtn ">중복데이터 : {{arr.exists_data_cnt}}</div>
            <div class="tabBtn ">오류데이터 : {{arr.err_data_cnt}}</div>
        </div>
    </div>

    <div class="box">
        <table class="tableList">
            <tr>
                <th class="textCenter"><input type="checkbox"></th>
                <th>이름</th>
                <th>호칭</th>
                <th>연락처</th>
                <th>그룹</th>
                <th>상태</th>
                <th>업로드 오류</th>
            </tr>

            <tr v-for="r in arr.list">
                <td class="textCenter">
                    <input type="checkbox" v-model="api.excel_nos" value="r.EXCEL_NO">{{r.SEQ_NO}}
                </td>
                <td>{{r.CUST_NM}}</td>
                <td>{{r.VOCA_NM}}</td>
                <td>{{r.PHONE_CNO}}</td>
                <td>{{r.GROUP_NMS}}</td>
                <td>{{r.DATA_TYPE_CDNM}}</td>
                <td>{{r.ERR_MESG}}</td>
            </tr>
        </table>

        <!--페이징 처리-->
        <div class="conBottom">
            <JSDPageCtrl ref="pageCtrl" :page="page" :cntPage="api.list.CNT_PAGE" :maxCnt="page.maxCnt" @page-move="movePage"></JSDPageCtrl>
            <div class="rightbtn">
                <select class="selectBox left" v-model="api.list.CNT_PAGE" @change="movePage(1)">
                    <option v-for="n in api.page_cnt" v-bind:value="n">{{`${n}줄 보기`}}</option>
                </select>
            </div>
        </div>


        <div class="grayBox">
            ※ 이름과 휴대폰 번호(대표번호)가 없는 연락처는 등록할 수 없습니다. <br>
            ※ 휴대폰 번호(대포번호)가 동일한 <span class="red bold">중복 연락처는 리스트에 표시되지 않으며, 연락처 업로드시 자동 삭제 됩니다.</span> <br>
            ※'이미 등록한 연락처와 중복됩니다.'라는 업로드 오류 내용이 있어도 연락처는 업로드 됩니다.
        </div>

        <div class="conBottom">
            <button class="blueBtn" @click="excel_move()"> {{arr.new_data_cnt}}개 연락처 업로드 실행</button>&nbsp;
            <button class="grayBtn" @click="excel_cancel()"> 취소 </button>
        </div>
    </div>


</template>

<script>
    import store from '@/store'
    import UserLeftMenu from '@/types/user_leftmenu'
    import UserService from '@/service/UserService'
    import JSDPageCtrl from '@/components/PageCtrl.vue';

    const _ = require('lodash')

    export default {
        name: 'ExcelUploadList',
        components: {
            JSDPageCtrl,
        },
        data() {
            return {
                api: {
                    list: {                     //발송이력 리스트 api
                        CNT_PAGE: 10,           //페이지당 조회건수 
                    },
                    excel_nos: [],
                    page_cnt: [10, 30, 50, 70, 100, 300],        //페이징처리 건수배열
                },
                arr: {
                    list: [],
                    new_data_cnt: 0,        //등록될 데이터 건수
                    err_data_cnt: 0,        //오류 데이터 건수
                    exists_data_cnt: 0,     //기존 데이터와 동일한 데이터건수
                },
                page: {
                    val: 1,
                    maxCnt: 1
                },
            }
        },
        computed: {
            upload_count() {
                return this.new_data_cnt
            },
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('연락처 리스트')
                this.$setMainBtn('+ 연락처 등록')
                this.$setMainBtnCallback(() => {
                    this.$nextTick(() => {
                        this.is_edit = false;
                        this.$refs.JSDCustEdit.cust_add();
                    });
                });

                //좌측메뉴 제목                
                this.$setSideTitle('연락처 목록');

                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                this.$nextTick(() => {
                    this.getExcelUploadList()
                })
            },
            getExcelUploadList() {
                //업로드 리스트
                UserService.excel_upload_list(
                    this.page.val,
                    this.api.list.CNT_PAGE,
                    (res) => {
                        //엑셀업로드 리스트 조회
                        this.arr.list = res.data[0]
                        //console.log(this.arr.list );

                        this.page.maxCnt = this.arr.list.length > 0 ? this.arr.list[0].MAX_CNT : 1

                        this.arr.new_data_cnt = res.data[1][0].NEW_DATA_CNT
                        this.arr.err_data_cnt = res.data[1][0].ERR_DATA_CNT
                        this.arr.exists_data_cnt = res.data[1][0].EXISTS_DATA_CNT
   
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set();
                        })
                    }
                )
            },
            excel_move() {
                // 엑셀업로드 실행 - 연락처 등록처리
                if (confirm('연락처에 업로드하겠습니까?') == false) {
                    return
                }

                UserService.excel_upload_move(
                    (res) => {
                        console.log(res.data);
                        //업로드 완료후 화면 전환
                        if (res.data > 0 ) {
                            this.$movePage('/app/user')
                        }                        
                    }
                )
            },
            excel_cancel() {
                // 엑셀업로드 화면으로 이동
                this.$movePage('/app/user/excel/upload')
            },
            movePage(page) {
                // page이동.검색시 초기값
                this.page.val = page
                this.getExcelUploadList();
            },

        },
        mounted() {
            this.init()
        },
    }
</script>