<template>
    <div class="layer " style="top: 57px; left: 40px; padding:15px;" v-if="visible">
        <div><span style="font-size:18px; font-weight:bold;">최근발송</span></div>
        <div class="cutSelectBlock mgB15" style="height:auto; max-height:250px;">
            <div class="cutBlock" v-for="r in arr.list" @click="select(r)">{{`${r.CUST_NM}${r.CNT > 1 ? ` 외 ${r.CNT}명` : ''}`}}</div>
        </div>
        <div class="textCenter"><button class="blueBtn" style="width:80%;" @click="hide">닫기</button></div>
    </div>
</template>

<script>
    import store from '@/store'

    import InfiniteScroll from '@/components/InfiniteScroll.vue'

    import UmsService from '@/service/UmsService'

    export default {
        name: 'UmsRecentSendList',
        components: {
            InfiniteScroll,
        },
        data() {
            return {
                visible: false,
                arr: {
                    list: [],
                },
            }
        },
        methods: {
            get() {
                UmsService.umsRecentList((res) => {
                    const { data } = res
                    if (data.length) {
                        this.arr.list = data[0]
                    }
                })
            },
            select(r) {
                UmsService.umsRecentDetail({
                    SEND_DA: r.SEND_DA,
                    SEND_TM: r.SEND_TM,
                }, (res) => {
                    const { data } = res

                    const ret = data[0].map((r) => {
                        return { CUST_NO: r.CUST_NO, PHONE_CNO: r.RECV_HDPH_NO, CUST_NM: r.CUST_NM }
                    })

                    this.$emit("setCustSelect", ret);
                    this.hide()
                })
            },
            show() {
                this.visible = true
                this.get()
            },
            hide() {
                this.visible = false
            }
        },
        mounted() {

        }
    }
</script>