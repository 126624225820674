
// 검색에 사용되는 ㄱ,ㄴ,ㄷ,ㄹ....
export default {
    han_keys: [
        { title: '전체', value: '' },
        { title: 'ㄱ', value: 'ㄱ' },
        { title: 'ㄴ', value: 'ㄴ' },
        { title: 'ㄷ', value: 'ㄷ' },
        { title: 'ㄹ', value: 'ㄹ' },
        { title: 'ㅁ', value: 'ㅁ' },
        { title: 'ㅂ', value: 'ㅂ' },
        { title: 'ㅅ', value: 'ㅅ' },
        { title: 'ㅇ', value: 'ㅇ' },
        { title: 'ㅈ', value: 'ㅈ' },
        { title: 'ㅋ', value: 'ㅋ' },
        { title: 'ㅍ', value: 'ㅍ' },
        { title: 'ㅎ', value: 'ㅎ' },
        { title: 'A', value: 'A' },
        { title: 'F', value: 'F' },
        { title: 'O', value: 'O' },
        { title: 'Z', value: 'Z' },
        { title: '#', value: '#' },
    ],
};

