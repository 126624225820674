import { createApp } from 'vue'
import { GChart } from 'vue-google-charts'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/utils/ext'
import plugins from '@/utils/plugins'
import { VueCookieNext } from 'vue-cookie-next'
VueCookieNext.config({ expire: '1d', secure: 'true' })

const app = createApp(App)
app.use(GChart)
    .use(store)
    .use(router)
    .use(VueCookieNext)
    .use(plugins)
    .mount('#app')

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
        if (store.getters.getUser['ACNT_NO'] > -1 && app.config.globalProperties.$cookie.isCookieAvailable('JSD_USER')) {
            next()
        }
        else {
            router.push('/app/login')
        }
    }
    else {
        next()
    }
})