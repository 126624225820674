
	import store from '@/store'
	import JSDPageCtrl from '@/components/PageCtrl.vue';
	import PayService from '@/service/PayService'
	import LeftMenu from '@/types/mypage_leftmenu'

	export default {
		name: 'UserCoin',
        components: {
			JSDPageCtrl
		},
		data() {
			return {
                onlyNumber : 0,
				ui: {
					coin_pres: false,
					coin_charge: false,
				},
				api: {
					coin_list: {
						STRT_DA: new Date().addMonth(-1).toStr('yyyy-MM-dd'),
						END_DA: new Date().toStr('yyyy-MM-dd'),
						CNT_PAGE: 10,
						termCd: '4',
					},
					cust: {
						search_key: '',			//검색할명
						cust_layer: false,		//검색결과창
						coin: 0,				//선물할 코인
						mesg: '',				//김철수 님이 코인을 선물하였습니다.
                        acnt_no : 0,
                    },
				},
				arr: {
					term: [
						{ title: '직접', value: '-1' },
						{ title: '최근 7일', value: '2' },
						{ title: '최근 30일', value: '4' },
						{ title: '최근 180일', value: '7' },
						{ title: '최근 1년', value: '9' },
					],
					list: [],
					user_list : [],
					coin_amt: 0,
					cnt: [10, 30, 50, 70, 100],
				},
				user_info: {
					name: '',
					hdph_no: '',
					orgn_nm : '',
                },
				page: {
					val: 1,
					maxCnt: 1
				},
			}
		},
        computed: {
            user() {
                return store.getters.getUser
			},
            smsLimit() {
                return 90
			},
            msgLen() {
                return this.api.cust.mesg.koLen()
			},
			select_info() {
                return this.user_info.name + ' ' + this.user_info.hdph_no + ' ' + this.user_info.orgn_nm
            },
        },
		methods: {
			init() {				
				this.$setTitle('코인 설정');
				this.$setSideTitle('My 페이지');                

				//메뉴 리스트에서 가져온다.
				this.$setSideBtns(LeftMenu.menu_list);

				//좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        // 도움말 기능 처리
                    }
				});

				this.$nextTick(() => { this.getList() })
				this.api.cust.mesg = this.user.ACNT_NM + '님이 코인을 선물하였습니다.';

                let coin_charge = sessionStorage.getItem('coin_pres');
                if (coin_charge == 'y') {
					this.ui.coin_pres = true
                    sessionStorage.setItem('coin_pres', 'n');
				}
                //console.log('coin_charge');
                //console.log(coin_charge);

			},
            contUpdate(e) {
                this.api.cust.mesg = e.target.value
                
                if (this.msgLen > this.smsLimit) {
                    this.api.cust.mesg = this.api.cust.mesg.koCut(90)
                }
            },
			coin_view() {
				// 코인 선물하기 창
				this.showLayer('coin_pres');

                this.user_info.name = ''
                this.user_info.hdph_no = ''
				this.user_info.orgn_nm = ''
				this.api.cust.search_key = ''
                this.api.cust.coin = 0
            },
			getList() {
				//console.log('getList()');

				//코인리스트를 읽어서 화면을 갱신
				PayService.coin_list(
					this.page.val,
					this.api.coin_list,
					(res) => {
						this.arr.list = res.data[0];				//코인이력리스트
						this.arr.coin_amt = res.data[1][0].BAL_AMT;			//코인잔액

						//console.log(res.data[0]);
						//console.log(res.data[1][0].BAL_AMT);

						this.page.maxCnt = this.arr.list.length > 0 ? this.arr.list[0].MAX_CNT : 1
						this.$nextTick(() => {
							this.$refs.pageCtrl.set()
						})
					}
				)
			},
			cust_search() {
				//신도 검색
                //console.log('cust_search()');

				if (this.api.cust.search_key.koLen() < 4) {
					alert('검색할 이름이나 휴대전화 번호를 2글자(4바이트)이상 입력하세요.')
					return
				}
				
                PayService.coin_cust_list(
                    this.page.val,
					{
                        SEARCH_KEY: this.api.cust.search_key,
						PAGE: 1,
                        CNT_PAGE : 100,
					},
                    (res) => {
						this.arr.user_list = res.data[0];				//신도리스트                        

						if (this.arr.user_list.length == 0) {
							alert('조회할 신도정보가 없습니다.');
						}
						else {
							this.api.cust.cust_layer = true;
                            this.user_info.name = ''
                            this.user_info.hdph_no = ''
                            this.user_info.orgn_nm = ''
                        }						
                    }
                )
			},
			coin() {
				//header에 있는 코인 충전 연결 레이어 호출
                this.$root.$refs.headCtrl.coinCharge()
            },
			cust_set(obj : Object) {
				//검색한 신도 선물하기 설정
                this.api.cust.acnt_no = Number( obj['ACNT_NO'] )
				this.api.cust.cust_layer = false

				//console.log(obj);

                this.user_info.name = obj['ACNT_NM'];
                this.user_info.hdph_no = obj['MASK_PHON_CNO'];
                this.user_info.orgn_nm = obj['JSD_ORG_NM'];
            },
			coin_send() {
				//다른 신도에게 코인 선물                

                if (this.api.cust.acnt_no == 0) {
                    alert('코인선물 받을 신도를 선택해 주세요.')
                    return
				}
                if (this.api.cust.coin < 1000) {
                    alert('코인선물은 1,000코인 이상부터 가능합니다.')
                    return
				}
				//console.log(this.arr.coin_amt)
				//console.log(this.api.cust.coin)

				// arr.coin_amt에는 format된 문자열이 옴
                var coin_amt = this.arr.coin_amt.reAll(',', '').num()

                if (coin_amt < this.api.cust.coin) {
                    alert('코인선물은 보유하신 코인내에서 가능합니다.')
                    return
                }

				//코인 선물
                PayService.coin_cust_send(                    
                    {
                        TARG_ACNT_NO: this.api.cust.acnt_no,
                        COIN_AMT: this.api.cust.coin,
                        MESG: this.api.cust.mesg,
                    },
                    (res) => {
                        if (res.data.RESULT > 0) {
							alert('코인을 선물하였습니다.')
                            this.api.cust.cust_layer = false

							//코인차감되었으니 상단에 코인을 변경시킨다.
							this.$root.sessionUpdate()

							//화면 비동기화 처리...
							this.$nextTick(() => {
								this.getList();                                            
							})

                            //선물부분 초기화
                            this.api.cust.anct_no = 0
                            this.api.cust.coin = 0
							this.api.cust.search_key = ''

                            this.hideLayer('coin_pres')
                        }
                        else {
                            alert('코인선물에 실패했습니다.(' + res.data.RESULT_MESG + ')');
                        }
                    }
                )
			},
			moveTerm() {
				switch (this.api.coin_list.termCd) {
					case '2':       //
						this.api.coin_list.STRT_DA = new Date().addDate(-7).toStr('yyyy-MM-dd')
						this.api.coin_list.END_DA = new Date().toStr('yyyy-MM-dd')
						break;
					case '4':       //
						this.api.coin_list.STRT_DA = new Date().addDate(-30).toStr('yyyy-MM-dd')
						this.api.coin_list.END_DA = new Date().toStr('yyyy-MM-dd')
						break;
					case '7':       //
						this.api.coin_list.STRT_DA = new Date().addDate(-180).toStr('yyyy-MM-dd')
						this.api.coin_list.END_DA = new Date().toStr('yyyy-MM-dd')
						break;
					case '9':       //
						this.api.coin_list.STRT_DA = new Date().addDate(-365).toStr('yyyy-MM-dd')
						this.api.coin_list.END_DA = new Date().toStr('yyyy-MM-dd')
						break;
				}
				this.getList();
			},
			movePage(page) {
				this.page.val = page
				this.getList();
			},
			showLayer(type) {
				this.ui[type] = true
			},
			hideLayer(type) {
				this.ui[type] = false
			},
			bindNumber(event) {
                this.onlyNumber = event.target.value;
            },
		},
        watch: {
            onlyNumber() {
                return this.onlyNumber = this.onlyNumber.replace(/[^0-9]/g, '');
            }
        },
		mounted() {
			this.init();
		},
	}
