<template>
    <div class="popSize">

        <div class="popTitle">
            <div class="userNum left">{{title}}</div>

        </div>

        <div class="popmain accessTerms" style="height:500px;">

            <!-- 팝업 내용 -->

            <div class="subtextArea bottomLine">
                <div class="textCenter" style="font-size:23px; font-weight:bold;">
                    {{arr.info.AGRE_TYPE_CDNM}}
                </div>
                <div class="subtextInfo">
                    <div class="mgB10">시행일자 : 2022년 5월 17일<br></div>
                    <div>
                        이전약관 보기
                        <select class="selectBox" v-model="api.AGRE_NO">
                            <option v-for="r in arr.list" :key="r.AGRE_NO" :value="r.AGRE_NO">{{r.APLY_STRT_DA.toDate('yyyy-MM-dd').toStr('yyyy년 MM월 dd일')}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="subtextArea bottomLine">
                <div v-html="arr.info.CONT"></div>
            </div>

        </div>

        <div class="textCenter mgT10 mgB15">
            <button class="blueBtn" onclick="window.close()">닫기</button>
        </div>

    </div>
</template>

<script>
    import CommonService from '@/service/CommomService'

    export default {
        name: 'PrivacyCtrl',
        computed: {
            title() {
                return this.api.AGRE_TYPE_CD == '1' ? '개인정보취급방침' : '이용약관'
            }
        },
        data() {
            return {
                api: {
                    VIEW_TYPE: 4,
                    AGRE_TYPE_CD: '1',
                    AGRE_NO: -1,
                },
                arr: {
                    list: [],
                    info: {},
                },
            }
        },
        methods: {
            init() {
                this.api.AGRE_TYPE_CD = this.$route.params.type
                this.get()
            },
            get() {
                CommonService.getPrivacy(this.api.AGRE_NO, this.api, (res) => {
                    const { data } = res

                    this.arr.list = data[0]
                    this.arr.info = data[1][0]

                    if (this.arr.list.length) {
                        this.api.AGRE_NO = this.arr.list[0].AGRE_NO
                    }
                })
            }
        },
        mounted() {
            this.init()
        },
    }
</script>