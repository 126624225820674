
    import JSDPageCtrl from '@/components/PageCtrl.vue';
    import UserService from '@/service/UserService'
    import CommomService from '@/service/CommomService'
    import GroupService from '@/service/GroupService'
    import HanKeys from '@/types/hanKeys'
    import UserLeftMenu from '@/types/user_leftmenu'
    import JSDCustView from '@/components/CustView.vue';
    import JSDCustEdit from '@/components/CustEdit.vue';
    import CustSelectFilter from '@/components/CustSelectFilter.vue';
    import { callWithAsyncErrorHandling } from 'vue';


    export default {
        name: 'UserList',
        components: {
            JSDPageCtrl,
            JSDCustView,
            JSDCustEdit,
            CustSelectFilter,
        },
        data() {
            return {
                ui: {
                    CHECK_ALL: false,
                    CUST_NOS: [],
                    is_order: false,
                    del_cust_layer: false,
                    del_custs_layer: false,
                    cust_init_layer: false,
                    filter_val: {
                        BLVR_CATE: '0',     //0:전체 1:상생방송 :4:역사광복 9:미지정
                        SEX: '',
                        RTNG: '',
                        BLVR_RELA: '',
                        REGI: '',
                        SELECT_TXT: '',
                        BLVR_CATE_CD: '',
                        BOOK_NOS: '',
                    },
                    //select_layer: {
                    //    is_view: false,
                    //    cate: 0,           //분류 0: 전체 1: 상생 4: 역사 9: 미지정
                    //    sex: [],            //성별
                    //    rtng: [],           //연락처등급
                    //    rela: [],           //관계
                    //    regi: [],           //지역
                    //    chk_rela: false,
                    //    chk_regi: false,
                    //    select_txt: '',    //조회된 메시지
                    //},
                    del_layer: {
                        del_cust_nm: '',
                        del_cust_cnt: 0,
                    },
                    cust_layer: {
                        cust_no: 0,
                    },
                    is_edit: false,
                },

                api: {
                    list: {
                        //list조회를 위한 api
                        ORDER_TYPE: 1,          //1:가나다순 2:최근등록순
                        BLVR_CATE: '0',           //0: 전체 1: 상생 4: 역사 9: 미지정
                        HAN_KEY: '',            //가나다순검색에 사용되는 한글 초성값 ㄱ~ㅎ A F O Z #
                        SEARCH_KEY: '',         //검색 KEY(이름/ 휴대폰 번호)
                        SEX: '',                //성별 ','로 연결된 문자열
                        RTNG: '',               //연락처 등급 ','로 연결된 문자열
                        BLVR_RELA: '',          //신도와의관계코드 ','로 연결된 문자열
                        REGI: '',               //지역 ','로 연결된 문자열
                        GROUP_NO: 0,            //그룹선택시 그룹번호가 온다.   -1:미지정 NULL(0)이면 그룹상관없이 전체 조회
                        PAGE: 1,                //현재 조회하는 page
                        CNT_PAGE: 10,           //조회페이지당 건수
                        BLVR_CATE_CD: '',
                        BOOK_NOS: '',
                    },
                    page_cnt: [10, 30, 50, 70, 100, 150, 300],        //페이징처리 건수배열
                },
                arr: {
                    cust_list: [],
                    left_group_list: [],          //좌측그룹리스트
                    code_list: [],               //code리스트
                    group_list: [],              //group 리스트
                    han_key_list: [],            //상단 ㄱㄴㄷ검색
                    blar_cate_cd_list: [],       //BLVR_CATE_CD
                    blar_rela_cd_list: [],       //BLVR_RELA_CD
                    regi_cd_list: [],            //REGI_CD
                    book_list: [],                //증산도 도서
                },
                page: {
                    val: 1,
                    maxCnt: 1
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                this.$setTitle('연락처 리스트')
                this.$setMainBtn('+ 연락처 등록')
                this.$setMainBtnCallback(() => {
                    this.$nextTick(() => {
                        this.is_edit = false;
                        this.$refs.JSDCustEdit.cust_add();
                    });
                });

                //좌측메뉴 제목
                this.$setSideTitle('연락처 목록');

                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                //좌측 그룹명 클릭시 처리(리스트 리로드)
                this.$setTreeDataCallback((r) => {
                    this.$setTreeSelect(r.value)
                    this.api.list.GROUP_NO = r.value;
                    this.movePage(1);
                });



                this.$nextTick(() => {
                    this.getList();
                    CommomService.code_keys(
                        {
                            KEYS: 'BLVR_CATE_CD,BLVR_RELA_CD,REGI_CD',
                        },
                        (res) => {
                            this.arr.code_list = res.data[0];				//코드리스트 조회
                            this.arr.blar_cate_cd_list = this.arr.code_list.filter(x => x.CATE_KEY === 'BLVR_CATE_CD');
                            this.arr.blar_rela_cd_list = this.arr.code_list.filter(x => x.CATE_KEY === 'BLVR_RELA_CD');
                            this.arr.regi_cd_list = this.arr.code_list.filter(x => x.CATE_KEY === 'REGI_CD');
                        }
                    );
                    //그룹리스트 조회 : 그룹명만 조회한다.
                    GroupService.group_title_list((res) => { this.arr.group_list = res.data[0]; });

                    //좌측 그룹 리스트 초기화
                    this.left_group_list();

                    UserService.jsdbook_list((res) => { this.arr.book_list = res.data[0]; });

                    for (var i = 0; i < HanKeys.han_keys.length; i++) {
                        this.arr.han_key_list.push({ "title": HanKeys.han_keys[i].title, "value": HanKeys.han_keys[i].value });
                        // console.log({ 'title': HanKeys.han_keys[i].title, 'value': HanKeys.han_keys[i].value })
                    }


                });
            },
            user_list_reload() {
                // 연락처 리스트 reload. 다른 컴포넌트에서 호출해서 화면의 메인리스트와 좌측 그룹리스트를
                // 새로 구성한다.

                //메인 리스트를 새로 구성하고
                this.getList();

                //좌측 그룹 리스트 새로 구성한다.
                this.left_group_list();
            },
            getList() {
                //
                // 연락처 리스트 조회 : 조회 조건에 맞는 리스트 조회
                //
                let blar_cate_cd: string = ''

                if (this.api.list.BLVR_CATE == '1') {
                    if (this.api.list.BLVR_CATE_CD == '') {
                        blar_cate_cd = '1,2,3'
                    }
                    else {
                        let tmp = this.api.list.BLVR_CATE_CD.split(',').filter(x => x.koLen() > 0)
                        blar_cate_cd = tmp.join(',')
                    }
                }
                else {
                    blar_cate_cd = this.api.list.BLVR_CATE
                }
                //console.log(this.api.list);
                console.log(blar_cate_cd);
                UserService.cust_list(
                    this.page.val,
                    {
                        ORDER_TYPE: this.api.list.ORDER_TYPE,
                        BLVR_CATE: blar_cate_cd,
                        HAN_KEY: this.api.list.HAN_KEY,
                        SEARCH_KEY: this.api.list.SEARCH_KEY,
                        SEX: this.api.list.SEX,
                        RTNG: this.api.list.RTNG,
                        BLVR_RELA: this.api.list.BLVR_RELA,
                        REGI: this.api.list.REGI,
                        BOOK_NOS: this.api.list.BOOK_NOS,
                        GROUP_NO: this.api.list.GROUP_NO,
                        CNT_PAGE: this.api.list.CNT_PAGE,
                    },
                    (res) => {
                        // console.log('cust_list');
                        this.arr.cust_list = res.data[0];				//일괄수정용 연락처 리스트 조회

                        this.page.maxCnt = this.arr.cust_list.length > 0 ? this.arr.cust_list[0].MAX_CNT : 1
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set();
                        })
                    }
                )
            },
            left_group_list() {
                //left_group_list : 좌측 그룹리스트
                GroupService.group_list(
                    "0",
                    (res) => {
                        this.arr.left_group_list = res.data[0];
                        let tree_group_list = [];
                        for (var i = 0; i < this.arr.left_group_list.length; i++) {
                            //좌측 트리구조...그룹리스트
                            // title : 그룹명
                            // useCnt : 카운트 표시여부
                            // cnt : 그룹내 숫자
                            // 클릭시 전달할 값 (그룹번호)
                            tree_group_list.push({ 'title': this.arr.left_group_list[i].GROUP_NM, 'useCnt': true, 'cnt': this.arr.left_group_list[i].CNT, 'value': this.arr.left_group_list[i].GROUP_NO });
                        }
                        this.$setTreeData(tree_group_list);
                    }
                );
            },
            cust_edit() {
                //수정창을 활성화 시킨다.
                this.$nextTick(() => {
                    this.ui.is_edit = true;
                    this.$refs.JSDCustEdit.cust_edit();
                });
            },
            cust_layer_view(obj) {
                //
                // 연락처 상세 보기
                //
                this.ui.cust_layer.cust_no = obj["CUST_NO"];

                //console.log('-------------');
                //console.log(this.ui.cust_layer);

                // 동기화 ...
                this.$nextTick(() => {

                    this.$refs.JSDCustView.cust_view();
                });
            },
            cust_check_all() {
                //
                // 전체 체크 여부
                //
                if (this.ui.CHECK_ALL === true) {
                    this.ui.CUST_NOS = [];
                    for (var i = 0; i < this.arr.cust_list.length; i++) {
                        this.ui.CUST_NOS.push(this.arr.cust_list[i].CUST_NO);
                    }
                }
                else {
                    this.ui.CUST_NOS = [];
                }
            },
            layer_cust_del() {
                //
                // 선택한 고객을 삭제하기위한 레이어창을 띄움
                //
                let del_cust_cnt: number = this.ui.CUST_NOS.length;

                //console.log(del_cust_cnt)

                if (del_cust_cnt === 0) {
                    alert('삭제할 연락처를 선택해 주세요');
                    return;
                }

                //삭제할 고객의 이름을 찾는다.
                for (var i = 0; i < this.arr.cust_list.length; i++) {
                    if (this.arr.cust_list[i].CUST_NO === this.ui.CUST_NOS[0]) {
                        this.ui.del_layer.del_cust_nm = this.arr.cust_list[i].CUST_NM;
                        break;
                    }
                }
                //console.log(this.ui.del_layer.del_cust_nm )

                if (del_cust_cnt === 1) {
                    this.ui.del_cust_layer = true;
                }
                else {
                    this.ui.del_custs_layer = true;
                    this.ui.del_layer.del_cust_cnt = this.ui.CUST_NOS.length - 1;
                }
            },
            cust_del() {
                //
                // 선택한 고객들 삭제처리
                //
                let cust_nos: string = this.ui.CUST_NOS.join(',');

                UserService.cust_del(
                    {
                        CUST_NOS: cust_nos,
                    },
                    (res) => {
                        if (res.data > 0) {
                            alert('연락처 삭제에 성공했습니다.');
                            this.movePage(1);           //현재 page reload
                            this.ui.del_cust_layer = false;
                            this.ui.del_custs_layer = false;

                            //좌측 그룹 리스트 초기화
                            this.left_group_list();

                            //삭제후
                            this.ui.CUST_NOS = []
                        }
                        else {
                            alert('연락처 삭제에 실패했습니다.');
                        }
                    }
                );
            },
            //cust_init() {
            //    //
            //    // 연락처 리스트 초기화
            //    //
            //    GroupService.group_cust_init(
            //        {
            //            CMD: "C",
            //        },
            //        (res) => {
            //            if (res.data > 0) {
            //                alert('연락처 초기화에 성공했습니다.');
            //                this.movePage(1);           //현재 page reload

            //                this.ui.cust_init_layer = false;

            //                //좌측 그룹 리스트 초기화
            //                this.left_group_list();

            //                //삭제후
            //                this.ui.CUST_NOS = []
            //            }
            //            else {
            //                alert('연락처 초기화에 실패했습니다.');
            //            }
            //            this.ui.cust_init_layer = false;
            //        }
            //    );
            //},
            select_layerView() {
                //
                // 연락처 상세조회 레이어창
                //
                console.log(this.ui.filter_val);
                this.$refs.CustSelectFilter.view();
            },
            setCustSelectFilter(value) {
                console.log(value);
                this.ui.filter_val = value;

                this.api.list.BLVR_CATE = this.ui.filter_val.BLVR_CATE     //0: 전체 1: 상생 4: 역사 9: 미지정
                this.api.list.SEX = this.ui.filter_val.SEX                 //성별 ','로 연결된 문자열
                this.api.list.RTNG = this.ui.filter_val.RTNG               //연락처 등급 ','로 연결된 문자열
                this.api.list.BLVR_RELA = this.ui.filter_val.BLVR_RELA     //신도와의관계코드 ','로 연결된 문자열
                this.api.list.REGI = this.ui.filter_val.REGI               //지역
                this.api.list.BLVR_CATE_CD = this.ui.filter_val.BLVR_CATE_CD    //대상자 선택
                this.api.list.BOOK_NOS = this.ui.filter_val.BOOK_NOS        //선택도서
                this.movePage(1);
            },
            select_assign(type, obj) {
                //
                // 검색 조건을 변경한다.
                // type : 클릭한 항목
                // obj : 선택값

                //console.log(type);
                //console.log(obj);

                if (type == 'ㄱㄴㄷ') {
                    // obj에 row-object가 옴
                    this.api.list.HAN_KEY = obj['value'];
                }
                else if (type == 'BLVR_CATE') {
                    // obj에 선택값이 옴
                    this.api.list.BLVR_CATE = obj
                    this.ui.filter_val.BLVR_CATE = obj
                }
                else if (type == 'order') {
                    //1:가나다순 2:최근등록순
                    this.api.list.ORDER_TYPE = this.ui.is_order ? 2 : 1;
                }
                //화면을 다시 reload한다.
                this.movePage(1);
            },
            ums_send() {
                // 문자 발송으로 연결

                if (this.ui.CUST_NOS.length === 0) {
                    alert('문자를 발송할 연락처를 선택해 주세요');
                    return;
                }

                let cust_list = []
                for (var i = 0; i < this.ui.CUST_NOS.length; i++) {
                    let idx = this.arr.cust_list.findIndex(x => x.CUST_NO == this.ui.CUST_NOS[i])

                    if (this.arr.cust_list[idx].PHONE_CNO.koLen() > 0) {
                        cust_list.push({
                            CUST_NO: this.arr.cust_list[idx].CUST_NO,
                            CUST_NM: this.arr.cust_list[idx].CUST_NM,
                            PHONE_CNO: this.arr.cust_list[idx].PHONE_CNO
                        })
                    }
                }

                if (cust_list.length == 0) {
                    alert('대표 연락처가 있어야 발송하실수 있습니다.')
                    return
                }
                console.log(cust_list);

                let cust_data: string = JSON.stringify(cust_list);
                sessionStorage.setItem('custList', cust_data)
                //console.log(sessionStorage.getItem('custList'));
                //console.log('sessionStorage');
                //발송 페이지로 연결한다.
                //this.$router.replace('/app/ums')
                this.$movePage('/app/ums')
            },
            tmplSend() {
                // 문자 시리즈로 연결

                if (this.ui.CUST_NOS.length === 0) {
                    alert('문자를 발송할 연락처를 선택해 주세요');
                    return;
                }

                let cust_list = []
                for (var i = 0; i < this.ui.CUST_NOS.length; i++) {
                    let idx = this.arr.cust_list.findIndex(x => x.CUST_NO == this.ui.CUST_NOS[i])

                    if (this.arr.cust_list[idx].PHONE_CNO.koLen() > 0) {
                        cust_list.push({
                            CUST_NO: this.arr.cust_list[idx].CUST_NO,
                            CUST_NM: this.arr.cust_list[idx].CUST_NM,
                            PHONE_CNO: this.arr.cust_list[idx].PHONE_CNO
                        })
                    }
                }

                if (cust_list.length == 0) {
                    alert('대표 연락처가 있어야 발송하실수 있습니다.')
                    return
                }

                let cust_data: string = JSON.stringify(cust_list);
                sessionStorage.setItem('custList', cust_data)
                this.$movePage('/app/tmpl')
            },
            movePage(page) {
                //
                // page이동.검색시 초기값
                //
                this.page.val = page
                this.getList();
            },
        },
        mounted() {
            this.init();
        },
    }
