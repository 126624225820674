<!--
프로그램 명  : ExcelUpload.vue
프로그램 설명: 연락처 엑셀업로드                
작성자		 : 20220110 (주)UCRM/김태훈
수정이력)

-->
<template>
    <div class="box">
        <div class="mgB15" style="font-size:18px;">
            1. 우측의 <b>‘엑셀 양식 다운로드‘ 버튼을 눌러 샘플 파일을 다운로드</b> 해 주세요.
            <a href="/excel/Sample(20220110).xlsx" download>
                <button class="grayBtn mgL15">
                    엑셀 (Excel) 양식 다운로드
                </button>
            </a>
        </div>
        <div class="mgB15" style="font-size:18px;">
            2. <b>도움말을 참고</b>하시어 양식에 맞게 연락처를 입력해 주세요.
            <button class="grayBtn mgL15">입력방법 도움말</button>
        </div>
        <div class="mgB15" style="font-size:18px;">
            3. 우측의 <b>‘파일 찾기‘ 버튼을 눌러 업로드할 연락처 파일을 선택한 다음 저장</b>해주세요.
            <!--<button class="grayBtn mgL15">파일 찾기</button>-->
            <input type="file" class="grayBtn mgL15" @change="readFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
        </div>

        <div style="font-size:15px;">
            ※ 엑셀 연락처 업로드시 ‘이미 등록한 연락처와 중복됩니다.’ 메시지가 나타나면서 연락처 업로드가 안될시에는 <b>‘연락처-휴지통’에서 [휴지통 비우기]</b>를 꼭 하시기 바랍니다.
        </div>
        
    </div>
</template>

<script>
    import store from '@/store'
    import XLSX from 'xlsx';
    import UserService from '@/service/UserService'
    import UserLeftMenu from '@/types/user_leftmenu'

    const _ = require('lodash')

    export default {
        name: 'ExcelUpload',
        components: {
        },
        data() {
            return {
                excelJsonData: [],          //excel을 json으로 변환
                api: {
                    upload: {               //업로드
                        CMD: 'I',
                        CUST_CNT: 0,
                        DATA: '',
                        DEL_DATA: '',
                    }
                },

            }
        },
        computed: {
        },
        methods: {
            init() {
                this.$setTitle('연락처 엑셀 업로드');

                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });
            },
            readFile(event) {
                const file = event.target.files[0];
                let reader = new FileReader();
                let tmpResult = {};
                reader.onload = (e) => {
                    let data = reader.result;
                    let workbook = XLSX.read(data, { type: 'binary' });
                    workbook.SheetNames.forEach(sheetName => {
                        workbook.Sheets[sheetName].A1.w = "CUST_NM";
                        workbook.Sheets[sheetName].B1.w = "PHONE_CNO";
                        workbook.Sheets[sheetName].C1.w = "VOCA_NM";
                        workbook.Sheets[sheetName].D1.w = "GROUP_NMS";

                        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        tmpResult = roa;
                    });
                    //console.log('read xlsx');
                    //console.log(this.excelJsonData);
                    //console.log('uplaod data');
                    this.api.upload.CUST_CNT = tmpResult.length     //업로드하는 건수
                    this.api.upload.DATA = JSON.stringify(tmpResult)
                    console.log(this.api.upload);

                    UserService.excel_upload(
                        this.api.upload,
                        (res) => {
                            console.log(res.data);
                            if (res.data > 0) {
                                //업로드 등록 되었으니 리스트로 전환한다.
                                console.log('page이동');
                                this.$movePage('/app/user/excel/upload/list')
                            }
                        }
                    )


                };
                reader.readAsBinaryString(file);
            }
        },
        mounted() {
            this.init()
        },
    }
</script>
