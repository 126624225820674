
    import UserService from '@/service/UserService'

    export default {
        name: 'JSDCustSelect',
        props: {
            search_key: {           //검색할 이름이나 전화번호
                type: String,
                require: true,
            },
            target: {
                type: Object,
                require: true,
            },
            height: {               //레이어 길이
                type: Number,
                default: 200,
            },
            is_direct_add: {        //직접 추가 가능 여부
                type: Boolean,
                default : false,
            },
        },
        data() {
            return {
                is_view: false,          //레이어 띄울지 여부
                is_not_found: false,
                not_found_message: '',  //연락처 검색에서 없을경우 메시지 및 검색키가 입력안되었을 경우
                view_cust_list: [],          //검색한 결과 리스트
                max_cnt: 0,            //검색한 결과건수
                curr_page: 1,           //현재 page
                page_cnt: 20,          //페이지당 표시 데이터 건수
                targetRect: {},
                left: 0,
                top: 0,
            }
        },
        methods: {
            init() {
            },
            search() {
                //외부에서 부르는 함수. 여기서 실제 고객 검색을 한다.

                if (this.search_key.koLen() < 4) {
                    alert('이름(2글자이상)이나 전화번호(숫자 4자리)를  입력해 주세요.')
                    return
                }

                const targetRect = this.target.getBoundingClientRect()
                this.left = targetRect.left
                this.top = targetRect.top + targetRect.height + 10

                this.is_view = true;
                this.cur_page = 1
                this.view_cust_list = []
                this.cust_list = []
                this.getCustList();
            },
            search_off() {
                //외부에서 부르는 함수.
                this.is_view = false;
            },
            cust_set(obj: Object) {
                // 사용자가 선택한 데이터를 부모컴포넌트로 전달한다.
                // 결과 없는 경우도 parent에 알려주기 위해 넘긴다.
                if (obj === '') {
                    this.$emit("setCustData", {});
                }
                else {
                    this.$emit("setCustData", {
                        CUST_NO: Number(obj['CUST_NO']),
                        CUST_NM: String(obj['CUST_NM']),
                        PHONE_CNO: String(obj['PHONE_CNO']),
                    });
                }
                this.is_view = false;
            },
            getCustList() {
                UserService.cust_quick_search(
                    this.curr_page,
                    this.page_cnt,
                    this.search_key,
                    (res) => {
                        let tmp_list = res.data[0]
                        this.is_not_found = false;
                                               

                        //정상적인 핸드폰여부 확인
                        let is_hp: Boolean = this.search_key.isHP();
                        //data가 없을 경우
                        if (tmp_list.length == 0) {
                            if (this.is_direct_add && is_hp ) {
                                //직업 추가가능 true
                                this.view_cust_list.push({
                                    SEQ_NO: 1,
                                    CUST_NO: 0,
                                    CUST_NM: '',
                                    PHONE_CNO: this.search_key,
                                });
                            }
                            else {
                                //직업 추가가능 false
                                this.is_not_found = true;
                                this.not_found_message = '일치하는 연락처가 없습니다.'                                
                                return;
                            }                            
                        }

                        if (this.is_direct_add) {
                            this.max_cnt = 1;                            
                        }
                        else {                            
                            this.max_cnt = tmp_list.length > 0 ? tmp_list[0].MAX_CNT : 1                            
                        }

                        //배열에 추가하는 방식으로 처리한다.
                        //컨트롤 화면을 스크롤할때 다음 페이지를 검색되게 하여 배열리스트에 추가한다.
                        for (var i = 0; i < tmp_list.length; i++) {
                            this.view_cust_list.push({
                                SEQ_NO: Number(tmp_list[i].SEQ_NO),
                                CUST_NO: Number(tmp_list[i].CUST_NO),
                                CUST_NM: String(tmp_list[i].CUST_NM),
                                PHONE_CNO: String(tmp_list[i].PHONE_CNO),
                            });
                        }
                        //선택된 고객이 1명이면 그냥 레이어 띄우지말고 선택완료로 넘어간다.
                        //if (this.max_cnt == 1) {
                        //    this.cust_set(this.view_cust_list[0])
                        //}
                    }
                )
            },
            handleScroll(e) {
                let scrollPostion: number = e.target.scrollTop;          //현재 scroll의 위치
                let scrollHeight: number = e.target.scrollHeight        //전체높이
                let clientHeight: number = e.target.clientHeight        //레이어의 높이
                let max_pos = this.view_cust_list.length;


                //데이터가 있는지 체크하고 맨하단까지 왔음 있을때 진행
                if (max_pos > 0 && this.view_cust_list[max_pos - 1].SEQ_NO == 1) {
                    return
                }

                if (scrollHeight == scrollPostion + clientHeight) {
                    this.curr_page += 1
                    this.getCustList()
                }
            }
        },
        mounted() {
            this.init();
        },

    }
