<template>
    <div class="leftMenuArea">
        
        <!-- 일정등록 버튼 -->
        <div class="sideBtn" v-if="useMainBtn" @click="$callbackMainBtn()">{{mainBtn}}</div>

        <!-- 사이드 메뉴 타이틀 부분 -->
        <div class="sideTitle" v-if="sideTitle.length > 0">{{sideTitle}}</div>

        <component :is="listComponent" v-if="useSideTree"></component>
                
        <div class="btnBlock" v-for="r in sideBtns" >
            <div class="sideBtn" v-for="item in r" v-bind:class="{'select': $route.path == item.path || (isHelp && $route.path.startsWith(item.path))}" @click="$callbackSideBtns(item)">{{item.name}}</div>
        </div>

    </div>
</template>

<script>
    import store from '@/store'

    import JSDMenuList from '@/components/MenuList.vue'

    export default {
        name: 'JSDLeftMenu',
        components: {
            JSDMenuList,
        },
        computed: {
            sideTitle() {
                return store.getters.getSideTitle
            },
            sideBtns() {
                return store.getters.getSideBtns
            },
            mainBtn() {
                return store.getters.getMainBtn
            },
            useMainBtn() {
                return this.$route.meta.useMainBtn
            },
            useSideTree() {
                return this.$route.meta.useSideTree
            },
            treeData() {
                return store.getters.getTreeData
            },
            treeSelect() {
                return store.getters.getTreeSelect
            },
            listComponent() {
                return this.$route.meta.listType
            },
            isHelp() {
                return this.$route.path.startsWith('/app/board/help')
            },
        },
        data() {
            return {

            }
        },
        methods: {

        },
        mounted() {

        },
    }
</script>