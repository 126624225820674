
    import UserService from '@/service/UserService'
    import GroupService from '@/service/GroupService'
    import CustSelectFilter from '@/components/CustSelectFilter.vue'
    import InfiniteScroll from '@/components/InfiniteScroll.vue'
    import utils from '@/helpers/utils'                         //공용으로 사용될 js함수를 import

    import { pushScopeId, render } from 'vue';


    export default {
        name: 'GroupCustSelect',
        components: {
            CustSelectFilter,
            InfiniteScroll,
        },
        props: {
            target: {
                type: Object,
                require: true,
            },
        },
        computed: {
            // 실제 고객번호가 있는 목록만 리턴 초성 그룹 리턴X
            realList() {
                return this.arr.custList.filter((r) => r.CUST_NO > 0)
            },
        },
        data() {
            return {
                is_view: false,             //레이어 띄울지 여부
                is_cust_search: false,     //연락처 빠른 검색용 레이어 출력여부
                search_key: '',             //직접 선택시 조회할것
                not_found_message: '일치하는 이름이나 휴대폰번호가 없습니다.',
                is_not_found: false,       //
                filter_val: {
                    BLVR_CATE: '0',     //0:전체 1:상생방송 :4:역사광복 9:미지정
                    SEX: '',
                    RTNG: '',
                    BLVR_RELA: '',
                    REGI: '',
                    SELECT_TXT: '',
                    BLVR_CATE_CD: '',
                    BOOK_NOS: '',
                },

                api: {
                    BLVR_CATE_CD: '',  //0:전체	1:상생방송 4:역사광복 9:미지정
                    GROUP_NO: 0,        //-1:미지정 0:전체 그외 그룹
                    SEX: '',            //성별
                    RTNG: '',           //등급
                    BLVR_RELA: '',      //신도와의관계
                    REGI: '',           //지역
                    BLVR_CATE: '0',
                    BOOK_NOS: '',
                },
                arr: {
                    groupList: [],      //그룹전체 리스트
                    render: [],         // 원본리스트
                    custList: [],       // 조회리스트
                    searchList: [],
                    selectGroup: new Set(),
                },
                ui: {
                    is_all_check: false,
                    view_cust_search: [],       //연락처 빠른 검색용 배열
                },
                top: 0,
                left: 0,
                filter_top: 0,
                filter_left: 0,
                maxCnt: 0,
                cust_info: new Set(),
                isLoading: true,
            }
        },
        methods: {
            init() {
                this.getGroupList()
                UserService.cust_short_list(
                    {
                        BLVR_CATE: '0',
                        SEX: '',
                        RTNG: '',
                        BLVR_RELA: '',
                        REGI: '',
                        GROUP_NO: 0,
                        BOOK_NOS: '',
                    },
                    (res) => {
                        const { data } = res
                        this.arr.custList = data[0].map((r) => {
                            r.GROUP_NO_ARR = r.GROUP_NOS.length ? r.GROUP_NOS.split(',') : []
                            return r
                        })

                        // 최초 렌더시 건수가 많을경우 view함수에서 렌더가 안도는 문제가 있어 네트워크 통신 끝나고 한번 렌더처리
                        this.$nextTick(() => {
                            this.isLoading = false
                            this.search()
                        })
                    })
            },
            initCust() {
                //초기화 - 기선택 초기화 처리
                for (var i = 0; i < this.arr.custList.length; i++) {
                    this.arr.custList[i].IS_CHECK = false
                }
            },
            view(data) {
                if (data) {
                    this.cust_info = new Set(data)
                } else {
                    this.cust_info.clear()
                }

                //외부에서 부르는 함수. 여기서 실제 고객 검색을 한다.
                this.is_view = true
                this.$nextTick(() => {
                    this.search()
                })
            },
            hide() {
                this.is_view = false
                this.clear()
            },
            clear() {
                this.api.BLVR_CATE_CD = ''
                this.api.GROUP_NO = 0
                this.arr.selectGroup.clear()
                this.api.SEX = ''
                this.api.RTNG = ''
                this.api.BLVR_RELA = ''
                this.api.REGI = ''
                this.api.BLVR_CATE = '0'
                this.api.BOOK_NOS = ''

                this.cust_info.clear()
                this.arr.custList.forEach((r) => {
                    r.IS_CHECK = false
                })

                this.ui.is_all_check = false
            },
            CustSelect() {
                //연락처 선택완료하고 parent에 전달한다.
                const ret = []
                console.time('CUST')
                this.realList.forEach((r) => {
                    if (r.IS_CHECK) {
                        ret.push({
                            CUST_NO: r.CUST_NO,
                            PHONE_CNO: r.PHONE_CNO,
                            CUST_NM: r.CUST_NM
                        })
                    }
                })
                console.timeEnd('CUST')
                this.$emit("setCustSelect", ret);
                this.hide()
            },
            getGroupList() {
                // 그룹 리스트를
                this.arr.groupList = []
                GroupService.group_list(
                    "0",
                    (res) => {
                        const { data } = res
                        for (var i = 1; i < data[0].length; i++) {
                            this.arr.groupList.push({
                                GROUP_NO: Number(data[0][i].GROUP_NO),
                                GROUP_NM: String(data[0][i].GROUP_NM),
                                CNT: Number(data[0][i].CNT),
                            });
                        }
                    }
                )
            },
            search() {
                this.ui.is_all_check = false

                // 초성 인덱스
                const chIdx = []
                this.arr.searchList.splice(0, this.arr.searchList.length)
                console.time('search')
                this.maxCnt = 0
                this.arr.custList.forEach((r) => {
                    if (r.CUST_NO < 1) {
                        this.arr.searchList.push(r)
                    }
                    else {
                        if (this.filter(r) || r.CUST_NO == -1) {
                            r.IS_CHECK = this.cust_info.has(r.CUST_NO)

                            this.arr.searchList.push(r)
                            this.maxCnt++
                        }
                    }
                })
                this.arr.searchList.forEach((r, idx) => {
                    if (r.CUST_NO == -1) {
                        chIdx.push(idx)
                    }
                })

                chIdx.forEach((r, idx) => {
                    // 초성 자기자신값을 빼줌
                    this.arr.searchList[r].CHO_CNT = ((idx < chIdx.length - 1 ? chIdx[idx + 1] : this.arr.searchList.length) - r) - 1
                })

                this.arr.searchList = this.arr.searchList.filter((r) => r.CHO_CNT > 0)

                console.timeEnd('search')
                this.render(true)
            },
            filter(r) {
                return (this.arr.selectGroup.size == 0 || r.GROUP_NO_ARR.some(r => this.arr.selectGroup.has(Number(r)))) &&
                    (
                        (this.api.BLVR_CATE == '' || this.api.BLVR_CATE == '0') ||
                        (this.api.BLVR_CATE == '1' && (this.api.BLVR_CATE_CD == '' || ['1', '2', '3'].includes(r.BLVR_CATE_CD))) ||
                        (this.api.BLVR_CATE == r.BLVR_CATE_CD)
                    ) &&
                    (this.api.SEX == '' || this.api.SEX == r.IS_SEX) &&
                    (this.api.RTNG == '' || new Set(this.api.RTNG.split(',')).has(r.RTNG_CD)) &&
                    (this.api.BLVR_RELA == '' || new Set(this.api.BLVR_RELA.split(',')).has(r.BLVR_RELA_CD)) &&
                    (this.api.REGI == '' || new Set(this.api.REGI.split(',')).has(r.REGI_CD)) &&
                    (this.api.BOOK_NOS == '' || new Set(r.BOOK_NOS.split(',')).has(this.api.BOOK_NOS))
            },
            /*
            getCustList() {
                //선택된 조건에 따른 연락처를 호출한다.
                this.arr.custList.splice(0, this.arr.custList.length)

                let blar_cate_cd: string = ''
                this.api.BLVR_CATE_CD = String(this.api.BLVR_CATE_CD)

                if (this.api.BLVR_CATE == '1') {
                    if (this.api.BLVR_CATE_CD == '') {
                        blar_cate_cd = '1,2,3'
                    }
                    else {
                        const tmp = []
                        const data = this.api.BLVR_CATE_CD.split(',')
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].koLen() > 0) {
                                tmp.push(data[i])
                            }
                        }
                        blar_cate_cd = tmp.join(',')
                    }
                }
                else {
                    blar_cate_cd = this.api.BLVR_CATE
                }


                console.log('cust_short_list');
                console.log(this.api);
                //연락처 리스트 검색
                UserService.cust_short_list(
                    {
                        BLVR_CATE: blar_cate_cd,
                        SEX: this.api.SEX,
                        RTNG: this.api.RTNG,
                        BLVR_RELA: this.api.BLVR_RELA,
                        REGI: this.api.REGI,
                        GROUP_NO: this.api.GROUP_NO,
                        BOOK_NOS: this.api.BOOK_NOS,
                    },
                    (res) => {
                        const { data } = res
                        this.maxCnt = 0
                        //원본리스트에 넣는다.
                        console.time('initData')
                        // 속도 최적화를 위해 Key-Value 콜렉션으로 변환
                        const custInfo = new Set(this.cust_info)
                        for (var i = 0; i < data[0].length; i++) {
                            const isCheck = custInfo.has(data[0][i].CUST_NO)
                            this.arr.custList.push({
                                ROW_NO: data[0][i].ROW_NO,
                                CHO_CHAR: data[0][i].CHO_CHAR,
                                CUST_NM: data[0][i].CUST_NM,
                                CUST_NO: data[0][i].CUST_NO,
                                PHONE_CNO: data[0][i].PHONE_CNO,
                                CUST_AGE: data[0][i].CUST_AGE,
                                IS_SEX: data[0][i].IS_SEX,
                                CHO_CNT: data[0][i].CHO_CNT,
                                IS_CHECK_PHONE: data[0][i].IS_CHECK_PHONE,     //폰번호 정상여부
                                IS_CHECK: isCheck//연락처 선택여부
                            });

                            if (data[0][i].CUST_NO > 0) {
                                this.maxCnt++
                            }
                        }
                        console.timeEnd('initData')

                        this.ui.is_all_check = this.cust_info.length == this.realList.length
                        this.render(true)
                    }
                );
            },
            */
            render(isInit) {
                if (!this.isLoading) {
                    this.isLoading = true

                    // 초기에만 100건 렌더
                    // 증산도 요청으로 모두 표시되게 수정. 스크롤해서 내리는게 불편하다고 해서 변경함
                    // 다시 원복.....고객수 많은경우 다운됨
                    const cnt = isInit ? 100 : 50
                    //console.log("this.arr.render.length = " + this.arr.render.length)
                    //console.log("this.arr.searchList.length = " + this.arr.searchList.length)
                    //const cnt = this.arr.searchList.length;

                    if (isInit) {
                        this.arr.render.splice(0, this.arr.render.length)
                    }
                    for (var i = 0; i < cnt; i++) {
                        if (this.arr.render.length >= this.arr.searchList.length || i >= this.arr.searchList.length) {
                            this.isLoading = false;
                            return
                        }
                        this.arr.render.push(this.arr.searchList[this.arr.render.length])
                    }
                    this.isLoading = false;
                }
            },
            custCheck(type, obj) {
                console.time('check')
                // 연락처 개별/그룹별(초성)/전체 선택시 처리

                if (type == 'all') {
                    //전체 선택시
                    this.arr.searchList.forEach((r) => {
                        if (r.IS_CHECK_PHONE) {
                            r.IS_CHECK = this.ui.is_all_check
                        }
                    })
                }
                else if (type == 'group') {
                    //초성 선택시
                    const chosung = obj['CHO_CHAR']
                    const isCheck = obj['IS_CHECK']

                    this.arr.searchList.forEach((r) => {
                        if (r.IS_CHECK_PHONE && r.CHO_CHAR == chosung && r.CUST_NO > 0) {
                            r.IS_CHECK = isCheck
                        }
                    })
                }
                else if (type == 'cust') {
                    //개별 연락처 선택시
                    const custNo = obj['CUST_NO']
                    const isCheck = obj['IS_CHECK']

                    this.arr.searchList.forEach((r) => {
                        if (r.IS_CHECK_PHONE && r.CUST_NO == custNo) {
                            r.IS_CHECK = isCheck
                        }
                    })
                }

                this.arr.searchList.forEach((r) => {
                    if (r.IS_CHECK) {
                        if (!this.cust_info.has(r.CUST_NO) && r.CUST_NO > 0) {
                            this.cust_info.add(r.CUST_NO)
                        }
                    }
                    else {
                        if (this.cust_info.has(r.CUST_NO)) {
                            this.cust_info.delete(r.CUST_NO)
                        }
                    }
                })

                console.timeEnd('check')
            },
            group_change(groupNo: number) {
                this.api.GROUP_NO = groupNo
                if (groupNo == 0) {
                    this.arr.selectGroup.clear(0, this.arr.selectGroup.length)
                }
                else {
                    if (this.arr.selectGroup.has(groupNo)) {
                        this.arr.selectGroup.delete(groupNo)

                        this.arr.custList.forEach((r) => {
                            if (r.GROUP_NO_ARR.includes(groupNo.toString())) {
                                //console.log(r.CUST_NM)
                                this.cust_info.delete(r.CUST_NO)
                            }
                        })
                    }
                    else {
                        this.arr.selectGroup.add(groupNo)

                        this.arr.custList.forEach((r) => {
                            if (r.GROUP_NO_ARR.includes(groupNo.toString())) {
                                this.cust_info.add(r.CUST_NO)
                            }
                        })
                    }
                }

                //좌측 그룹리스트 변경시 연락처 리스트를 재 호출한다.
                this.search()
            },
            cust_filter(event) {
                // 연락처 필터링 컴포넌트 호출
                let pos = utils.abspos(event);               

                //filter의 위치지정
                this.filter_top = pos.y;
                this.filter_left = pos.x - 300;

                this.filter_val.BLVR_CATE = this.api.BLVR_CATE
                this.$refs.CustSelectFilter.view();
            },
            setCustSelectFilter(obj) {                
                // 연락처 필터링 컴포넌트 결과값 저장
                this.filter_val = obj;
                //console.log('setCustSelectFilter');
                //console.log(this.filter_val);

                this.api.BLVR_CATE = this.filter_val.BLVR_CATE
                this.api.SEX = this.filter_val.SEX                  //성별
                this.api.RTNG = this.filter_val.RTNG                //등급
                this.api.BLVR_RELA = this.filter_val.BLVR_RELA      //신도와의관계
                this.api.REGI = this.filter_val.REGI                    //지역
                this.api.BLVR_CATE_CD = this.filter_val.BLVR_CATE_CD    //대상자 선택

                this.api.BOOK_NOS = this.filter_val.BOOK_NOS    //
                //console.log(this.api);

                this.search()
                //this.getGroupList()
                //this.getCustList()
            },
            cust_search() {
                // 연락처 직접검색. 검색버튼을 새로 누르거나 입력후 엔터를 친거라 검색결과 초기화 처리 하고 시작한다.
                //console.log(this.search_key);
                let str_key: string = this.search_key

                if (str_key.koLen() < 4) {
                    alert('이름(2글자이상)이나 전화번호(숫자 4자리)를  입력해 주세요.')
                    return
                }

                const targetRect = this.$refs.custSearch.getBoundingClientRect()
                this.left = targetRect.left
                this.top = targetRect.top + targetRect.height + 10

                this.is_cust_search = true;
                this.ui.view_cust_search = []
                const tmp = []
                for (var i = 0; i < this.arr.custList.length; i++) {
                    const data = this.arr.custList[i]
                    if (data.IS_CHECK_PHONE && (data.CUST_NM.indexOf(str_key) > -1 || data.PHONE_CNO.indexOf(str_key) > -1)) {
                        tmp.push(data)
                    }
                }

                if (tmp.length > 1) {
                    for (var i = 0; i < tmp.length; i++) {
                        this.ui.view_cust_search.push({
                            SEQ_NO: tmp.length - i,
                            CUST_NM: tmp[i].CUST_NM,
                            PHONE_CNO: tmp[i].PHONE_CNO,
                            CUST_NO: tmp[i].CUST_NO,
                        })
                    }
                }
                else if (tmp.length == 1) {
                    //한건만 찾은경우는 바로 반영하고 레이어를 닫는다.
                    this.setCustData({
                        CUST_NO: tmp[0].CUST_NO,
                    })
                }
                else {
                    this.is_not_found = true
                }

            },
            setCustData(obj) {
                // 연락처에서 선택한 데이터 처리

                let custNo: number = Number(obj['CUST_NO'])

                const orgArr = this.realList.filter((r) => r.IS_CHECK_PHONE && r.CUST_NO == custNo)
                
                if (orgArr.length > 0) {
                    orgArr[0].IS_CHECK = true
                    this.cust_info.push(custNo)
                }

                this.is_cust_search = false         //레이어 닫기
                this.search_key = ''                //검색키 초기화
            },

        },
        mounted() {
            this.$nextTick(() => {
                this.init()
            })
        },
    }
