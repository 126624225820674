<template>
    <!-- #region 특수문자 레이어 -->
    <div class="layer emoticonLayer" style="height:auto;" :style="{left: `${left}px`, top: `${top}px`}" v-show="isShow">
        <div><img class="right" src="../assets/images/boxClose.gif" style="cursor:pointer;width:20px;" alt="close" @click="isShow = false" /></div>
        <table :class="`emoTable${page.val}`" style="display:table;">
            <tbody>
                <tr v-for="list in renderList">
                    <td v-for="(r, index) in list" :key="index" @click="select(r)" v-show="r.length">{{r}}</td>
                </tr>
            </tbody>
        </table>
        <JSDPageCtrl class="mgT10" ref="pageCtrl" :page="page" :cntPage="36" :maxCnt="page.maxCnt" @page-move="movePage"></JSDPageCtrl>
    </div>
    <!-- #endregion -->
</template>

<script>
    import JSDPageCtrl from '@/components/PageCtrl.vue'

    export default {
        name: 'EmojiLayer',
        components: {
            JSDPageCtrl,
        },
        props: {
            // 레이어의 기본위치가 될 요소
            target: {
                type: Object,
                require: true,
            },
            // 캐럿
            carets: {
                type: Object,
                require: true
            },
        },
        computed: {
            renderList() {
                return this.emoList[this.page.val - 1]
            }
        },
        data() {
            return {
                emoList: [
                    [
                        ["♥", "♡", "★", "☆", "▶", "▷"],
                        ["●", "■", "▲", "▒", "♨", "™"],
                        ["℡", "♬", "♪", "☞", "♂", "♀"],
                        ["◆", "◇", "♣", "♧", "☎", "◀"],
                        ["◁", "○", "□", "▼", "∑", "㉿"],
                        ["◈", "▣", "『", "』", "☜", "♬"]
                    ],
                    [
                        [" (^.^)V", " (n . n)", "(/*.*)/"],
                        ["(0^.^0)", "(*^o^*)", "づ^0^)づ"],
                        ["☆(~.^)/", "♡.♡", "o(^^o)"],
                        ["(*^^ㆀ)", "(*^.^)♂", " (^ㅇ^)♬"],
                        ["(⌒ε⌒*)", "(*..)(..*)", "l -,.- l"],
                        ["(@_@)", "ご,.ご", "o(>_<)o"]

                    ],
                    [
                        ["=◑.◐=", "(ら-o-)っ", "S￣ヘ￣S"],
                        ["(Θ_Θ)", "(★.★)", "(づ_ど)"],
                        [" (_._) ", "q⊙.⊙p", "@(*)(*)@"],
                        ["", "", ""],
                        ["", "", ""],
                        ["", "", ""]
                    ]
                ],
                isShow: false,
                page: {
                    val: 1,
                    maxCnt: 1,
                },
                left: 0,
                top: 0,
            }
        },
        methods: {
            visible() {
                this.isShow = !this.isShow
                if (this.isShow) {
                    const { left, top, height } = this.target.getBoundingClientRect()

                    this.left = left
                    this.top = top + height + 10


                    this.page.val = 1
                    this.page.maxCnt = 36 * this.emoList.length

                    this.$nextTick(() => {
                        this.$refs.pageCtrl.set();
                    })
                }
            },
            select(str) {
                if (this.carets) {
                    this.carets.insert(str)
                }
            },
            movePage(page) {
                console.log(page)
            }
        },
    }
</script>