// 메모 우측 메뉴 처리
export default {    
    menu_list: [
        [
            { name: '메모', path: '/app/memo' },
            { name: '메모태그 편집', path: '/app/memo/taglist' },
        ],
        [            
            { name: '도움말', path: '/app/board/help' },
        ],
    ]
};

