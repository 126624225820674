// 
// 프로그램 명: utils.ts
// 프로그램 설명: 서비스 공통에 사용되는 함수
//
// 작성자: 20211122     (주)UCRM / 김태훈
// 수정이력
//

var utils = {
    compare_CustName(a, b) {
        //
        // 연락처 array sort를 위한 
        // a,b : 연락처 이름이 들어간 Object
        // 
        if (a.CUST_NM < b.CUST_NM) {
            return -1;
        }
        if (a.CUST_NM > b.CUST_NM) {
            return 1;
        }
        return 0;
    },
    compare_TmplItem(a, b) {
        //
        // 템플릿 아이템 정렬을 위한 비교 함수
        // a,b : 템플릿의 아이템 CUST_SEND_MON, CUST_SEND_DAY 가 포함되어 있어야 한다.
        // 
        if ((a.CUST_SEND_MON * 30 + a.CUST_SEND_DAY) < (b.CUST_SEND_MON * 30 + b.CUST_SEND_DAY)) {
            return -1;
        }
        if ((a.CUST_SEND_MON * 30 + a.CUST_SEND_DAY) > (b.CUST_SEND_MON * 30 + b.CUST_SEND_DAY)) {
            return 1;
        }
        return 0;
    },
    abspos(e) {
        // 마우스 클릭한 위치를 구해 레이어 띄울때 사용
        this.x = e.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft);
        this.y = e.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop);
        return this;
    },
    test() {
        // 호출 테스트용
        alert('utils_test()')
    },
}

export default utils
