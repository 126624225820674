import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import store from '@/store'

import Login from '@/views/Login.vue'
import Main from '@/views/Main.vue'
import Join from '@/views/JoinMember.vue'
import Privacy from '@/views/Privacy.vue'

//����ó
import userlist     from '@/views/User/UserList.vue'
import grouplist from '@/views/User/GroupList.vue'
import GroupCustList from '@/views/User/GroupCustList.vue'
import bulklist     from '@/views/User/UserBulkList.vue'
import excelUpload from '@/views/User/ExcelUpload.vue'
import excelUploadList from '@/views/User/ExcelUploadList.vue'
import exceldown    from '@/views/User/ExcelDown.vue'
import dupcnta      from '@/views/User/DupCntaList.vue'
import userdup      from '@/views/User/UserDupList.vue'
import trashlist    from '@/views/User/TrashList.vue'

//mypage
import usercoin from '@/views/MyPage/UserCoin.vue'
import myinfo from '@/views/MyPage/MyInfo.vue'
import mysend from '@/views/MyPage/MySend.vue'
import myapp from '@/views/MyPage/MyApp.vue'


//���ø�
import tmpl_list from '@/views/Template/TemplateList.vue'        //���ø�����Ʈ
import tmpl_resv_list from '@/views/Template/ResvList.vue'      //���ø� ���ฮ��Ʈ
import tmpl_mylist from '@/views/Template/MyList.vue'           //MY���ø� ����
import tmpl_setting from '@/views/Template/Setting.vue'         //���ø� ����

//���ڹ߼�
import ums_setting from '@/views/Ums/UmsSetting.vue'            //�߼ۼ���
import ums_resv_list from '@/views/Ums/UmsResvList.vue'         //���ฮ��Ʈ
import ums_send_list from '@/views/Ums/UmsSendList.vue'         //�����̷�
import ums_send from '@/views/Ums/UmsSend.vue'                  //�߼�
import ums_status from '@/views/Ums/UmsStatus.vue'              //�߼���Ȳ

import chargeReq from '@/views/Popup/CoinChargeReq.vue'
import chargeReqList from '@/views/Popup/CoinChargeReqList.vue'

//�޸�
import memo_list from '@/views/Memo/MemoList.vue'
import memo_tag_list from '@/views/Memo/MemoTagList.vue'


//#region ��������
import UserBoard from '@/views/Board/UserBoard.vue'
//#endregion


//test
import test01 from '@/views/Test/test01.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/', redirect: '/app'
    },
    {
        //Main - Page
        path: '/app',
        name: 'Main',
        component: Main,
        meta: {
            isPopup: false,
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: '',
        },
    },
    {
        //�α���
        path: '/app/login',
        name: 'Login',
        component: Login,
        meta: {
            isPopup: false,
            useSide: false,
            requireAuth: false,
        },
    },
    {
        //ȸ������
        path: '/app/join',
        name: 'JoinMember',
        component: Join,
        meta: {
            isPopup: false,
            useSide: false,
            requireAuth: false,
        },
    },
    {
        // �̿���&�������� ��޹�ħ
        path: '/app/privacy/:type?',
        name: 'Privacy',
        component: Privacy,
        props: (route) => {
            route.params.type = route.params.type || '1'
        },
        meta: {
            isPopup: true,
            useSide: false,
            useSideTree: false,
            requireAuth: false,
            listType: '',
        },
    },
    //
    // ����ó
    // 
    {
        /*  ����ó - ����Ʈ */
        path: '/app/user',
        name: 'UserList',
        component: userlist,
        meta: {
            isPopup: false,
            useMainBtn: true,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ����ó - �׷���� */
        path: '/app/user/group',
        name: 'GroupList',
        component: grouplist,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ����ó - �׷���� */
        path: '/app/user/groupCust',
        name: 'GroupCustList',
        component: GroupCustList,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },

    {
        /*  ����ó - �ϰ����� */
        path: '/app/user/userbulk',
        name: 'BulkList',
        component: bulklist,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ����ó - �������ε� */
        path: '/app/user/excel/upload',
        name: 'ExcelUpload',
        component: excelUpload,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ����ó - �������ε� ����Ʈ */
        path: '/app/user/excel/upload/list',
        name: 'ExcelUploadList',
        component: excelUploadList,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ����ó - �����ٿ�ε� */
        path: '/app/user/excel/down',
        name: 'ExcelDown',
        component: exceldown,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ����ó - �ߺ�����ó���� */
        path: '/app/user/dupcnta',
        name: 'DupCntaList',
        component: dupcnta,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ����ó - ����� �ߺ�����Ʈ */
        path: '/app/user/duplist',
        name: 'UserDupList',
        component: userdup,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ����ó - ������ ����Ʈ */
        path: '/app/user/trash',
        name: 'UserTrashList',
        component: trashlist,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },



    //
    // MyPage 
    // 
    {
        /*  MyPage - �������� */
        path: '/app/mypage/myinfo',
        name: 'MyInfo',
        component: myinfo,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },
    {
        /*  MyPage - �߽Ź�ȣ���� */
        path: '/app/mypage/send',
        name: 'MySend',
        component: mysend,
        meta: {
            isPopup: false,
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: '',
        }
    },
    {
        /*  MyPage - app��뼳�� */
        path: '/app/mypage/app',
        name: 'MyApp',
        component: myapp,
        meta: {
            isPopup: false,
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: '',
        }
    },
    {
        /*  MyPage - ���θ���Ʈ */
        path: '/app/mypage/coin',
        name: 'UserCoin',
        component: usercoin,
        meta: {
            isPopup: false,
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: '',
        }
    },

    //
    // ���ø�
    //
    {
        /*  ���ø� - ���ø� ����Ʈ */
        path: '/app/tmpl',
        name: 'TemplateList',
        component: tmpl_list,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ���ø� - ���ø� ���ฮ��Ʈ */
        path: '/app/tmpl/resv',
        name: 'ResvList',
        component: tmpl_resv_list,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },
    {
        /*  ���ø� - MY���ø� ���� */
        path: '/app/tmpl/mylist',
        name: 'MyList',
        component: tmpl_mylist,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },
    {
        /*  ���ø� - ���ø� ���� */
        path: '/app/tmpl/setting',
        name: 'Setting',
        component: tmpl_setting,
        meta: {
            isPopup: false,
            useMainBtn: false,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },

    //
    // ���ڹ߼�
    //
    {
        /*  ���ڹ߼� -*/
        path: '/app/ums/:type?',
        name: 'UmsSend',
        component: ums_send,
        props: (route) => {
            route.params.type == route.params.type || 'ums'
        },
        meta: {
            isPopup: false,
            useMainBtn: true,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  ���ڹ߼� - �߼� ���� */
        path: '/app/ums/setting',
        name: 'UmsSetting',
        component: ums_setting,
        meta: {
            isPopup: false,
            useMainBtn: true,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },
    {
        /*  ���ڹ߼� - �߼��̷�*/
        path: '/app/ums/sendlist',
        name: 'UmsSendList',
        component: ums_send_list,
        meta: {
            isPopup: false,
            useMainBtn: true,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },
    {
        /*  ���ڹ߼� - ���ฮ��Ʈ*/
        path: '/app/ums/resvlist',
        name: 'UmsResvdList',
        component: ums_resv_list,
        meta: {
            isPopup: false,
            useMainBtn: true,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },
    {
        /*  ���ڹ߼� - ���ฮ��Ʈ*/
        path: '/app/ums/status',
        name: 'UmsStatus',
        component: ums_status,
        meta: {
            isPopup: false,
            useMainBtn: true,           //����ó ��Ϲ�ư ��뿩�� (�ֻ��)
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },

    //
    // �޸�
    //
    {
        /*  �޸� - �޸𸮽�Ʈ*/
        path: '/app/memo',
        name: 'MemoList',
        component: memo_list,
        meta: {
            isPopup: false,
            useMainBtn: false,           
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        /*  �޸� - �޸� tag ����Ʈ */
        path: '/app/memo/taglist',
        name: 'MemoTagList',
        component: memo_tag_list,
        meta: {
            isPopup: false,
            useMainBtn: false,
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },



    {
        path: '/app/board/:viewType?/:boardCate?/:boardNo?',
        name: 'UserBoard',
        component: UserBoard,
        props: (route) => {
            route.params.viewType = route.params.viewType || 'notice'
            route.params.boardCate = route.params.boardCate || ''
            route.params.boardNo = route.params.boardNo || ''
        },
        meta: {
            isPopup: false,
            useMainBtn: false,
            useSide: true,
            useSideTree: true,
            requireAuth: true,
            listType: 'JSDMenuList',
        }
    },
    {
        path: '/app/pay/req',
        name: 'CoinChargeReq',
        component: chargeReq,
        meta: {
            isPopup: true,
            useSide: false,
            useSideTree: false,
            requireAuth: false,
            listType: '',
        }
    },
    {
        path: '/app/pay/req/list',
        name: 'CoinChargeReqList',
        component: chargeReqList,
        meta: {
            isPopup: true,
            useSide: false,
            useSideTree: false,
            requireAuth: false,
            listType: '',
        }
    },
    // Test
    //     
    {
        path: '/app/test/test01',
        name: 'test01',
        component: test01,
        meta: {
            isPopup: false,
            useSide: true,
            useSideTree: false,
            requireAuth: true,
            listType: '',
        }
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
