
    import store from '@/store'

    import TemplateService from '@/service/TemplateService'

    import TmplLeftMenu from '@/types/tmpl_leftmenu'
    import JSDPageCtrl from '@/components/PageCtrl.vue';


    export default {
        name: 'tmpl_mylist',
        components: {
            JSDPageCtrl,
        },
        data() {
            return {
                ui: {
                    tmpl_del_layer: false,
                    tmpl_nos: [],
                    is_all: false,

                },
                api: {
                    list: {                                                
                        VIEW_TYPE: 2,               //My템플릿만 조회
                    },
                    delete: {
                        tmpl_nos: [],
                        DEL_TYPE : 1,
                    }
                },
                arr: {
                    mytmpl: [],                     //템플릿 리스트 
                },
                page: {
                    val: 1,
                    maxCnt: 1
                },

            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('My템플릿 편집');
                this.$setMainBtn('+ 템플릿 작성')
                this.$setMainBtnCallback(() => {
                    this.$nextTick(() => {
                        //템플릿 작성과 연결되야 함
                    });
                });

                //좌측메뉴 제목                
                this.$setSideTitle('문자 시리즈');

                //좌측 메뉴 구성
                this.$setSideBtns(TmplLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('문자시리즈')
                    }
                });

                this.$nextTick(() => {
                    this.getMyTmplList()
                })
            },
            getMyTmplList() {
                TemplateService.tmpl_list(                    
                    this.api.list.VIEW_TYPE,
                    (res) => {
                        this.arr.mytmpl = res.data[0];				//마이 템플릿                        
                        console.log(res.data[0]);
                    }
                )
            },
            tmpl_del(type:string, row:any) {
                // 삭제레이어 띄우고 확인처리

                this.api.delete.tmpl_nos = []
                if (type == 'all') {
                    if (this.ui.tmpl_nos.length == 0) {
                        alert('삭제할 템플릿을 선택해 주세요.')
                        return
                    }
                    this.api.delete.tmpl_nos = this.ui.tmpl_nos
                }
                else if (type == 'one') {
                    this.api.delete.tmpl_nos.push({
                        TMPL_NO: row.TMPL_NO,
                    })
                }
                this.ui.tmpl_del_layer = true
            },
            tmpl_delete() {
                // 템플릿 삭제 처리
                let data: string = JSON.stringify(this.api.delete.tmpl_nos)

                TemplateService.tmpl_edit(
                    {
                        CMD: 'D',
                        DEL_TYPE: 1,
                        DATA: data
                    },
                    (res) => {
                        if (res.data > 0) {
                            alert('마이 템플릿 변경 내용이 저장되었습니다.')
                            this.ui.tmpl_del_layer = false;
                            this.getMyTmplList()
                        }
                        else {
                            alert('마이 템플릿 변경에 실패했습니다.');
                        }
                    }
                );
            },
            save_all() {
                // 템플릿 목록 모두 자장. 한번에 업데이트 한다.
                let tmpl = [];
                for (var i = 0; i < this.arr.mytmpl.length; i++) {
                    tmpl.push({
                        TMPL_NO: this.arr.mytmpl[i].TMPL_NO,
                        TMPL_NM: this.arr.mytmpl[i].TMPL_NM,
                        ORD_NO : i +1
                    });
                }

                let data: string = JSON.stringify(tmpl)
                TemplateService.tmpl_edit(
                    {
                        CMD: 'U',
                        DEL_TYPE: 1,
                        DATA: data
                    },
                    (res) => {
                        if (res.data > 0) {
                            alert('마이 템플릿 변경 내용이 저장되었습니다.')
                            this.getMyTmplList()
                        }
                        else {
                            alert('마이 템플릿 변경에 실패했습니다.');
                        }
                    }
                );
            },
            check_all() {
                // 모든 체크 all
                this.ui.tmpl_nos = []

                if (this.ui.is_all) {
                    for (var i = 0; i < this.arr.mytmpl.length; i++) {
                        this.ui.tmpl_nos.push(this.arr.mytmpl[i].TMPL_NO)
                    }
                }
            },
            order_update(tmpl_no : number, cmd : string) {
                //
                // 템플릿 순서 변경
                // cmd - up/down/top/bottom

                let cur_idx: number = -1;
                let max_idx: number = -1;
                let next_idx: number = -1;
                let tmp_obj: any = null;


                cur_idx = this.arr.mytmpl.findIndex(x => x.TMPL_NO == tmpl_no);
                max_idx = this.arr.mytmpl.length - 1;                

                if (cmd === 'up') {
                    if (cur_idx === 0) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = cur_idx - 1;
                    tmp_obj = this.arr.mytmpl[next_idx];
                    this.arr.mytmpl[next_idx] = this.arr.mytmpl[cur_idx];
                    this.arr.mytmpl[cur_idx] = tmp_obj;

                }
                else if (cmd === 'top') {
                    if (cur_idx === 0) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = 0;
                    tmp_obj = this.arr.mytmpl[cur_idx];
                    //console.log(cur_idx)

                    for (var i = cur_idx - 1; i >= 0; i--) {
                        //console.log(i)
                        this.arr.mytmpl[i + 1] = this.arr.mytmpl[i];
                    }
                    this.arr.mytmpl[next_idx] = tmp_obj;
                }
                else if (cmd === 'down') {
                    if (cur_idx === max_idx) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = cur_idx + 1;
                    tmp_obj = this.arr.mytmpl[next_idx];
                    this.arr.mytmpl[next_idx] = this.arr.mytmpl[cur_idx];
                    this.arr.mytmpl[cur_idx] = tmp_obj;
                }
                else if (cmd === 'bottom') {
                    if (cur_idx === max_idx) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    tmp_obj = this.arr.mytmpl[cur_idx];
                    for (var i = cur_idx; i < max_idx; i++) {
                        this.arr.mytmpl[i] = this.arr.mytmpl[i + 1];
                    }
                    this.arr.mytmpl[max_idx] = tmp_obj;
                }

            },
            sort_tmpl(type: string) {
                // 템플릿 정렬 처리 
                if (type == 'ASC') {
                    this.arr.mytmpl.sort(this.compare_tmpl_asc)
                }
                else if (type == 'DESC') {
                    this.arr.mytmpl.sort(this.compare_tmpl_desc)
                }

            },
            compare_tmpl_asc(a, b) {                
                // 템플릿 정렬                
                if (a.TMPL_NM < b.TMPL_NM) {
                    return -1;
                }
                if (a.TMPL_NM > b.TMPL_NM) {
                    return 1;
                }
                return 0;
            },
            compare_tmpl_desc(a, b) {
                // 템플릿 정렬                
                if (a.TMPL_NM > b.TMPL_NM) {
                    return -1;
                }
                if (a.TMPL_NM < b.TMPL_NM) {
                    return 1;
                }
                return 0;
            },

            movePage(page : number) {              
                // page이동.검색시 초기값                
                this.page.val = page
                this.getList();
            },
        },
        mounted() {
            this.init();
        },
    }
