<template>
    <template v-for="r in item">
        <dt :class="{'select': r.value == treeSelect || r.isOpen, 'solo': !r.childs}" @click="itemClick(r)">
            <div class="innerArrow" v-if="r.childs && r.childs.length">
                <img src="../assets/images/box-arrow-right.gif">
            </div>
            {{r.title}}
            <span class="right blue" v-if="r.useCnt">{{r.cnt}}</span>
        </dt>
        <dd v-if="r.childs && r.isOpen">
            <dl>
                <!--<ol>
                    <li v-for="c in r.childs" :class="{'select': c.value == treeSelect}" @click="itemClick(c)">
                        {{c.title}}
                        <span class="right blue" v-if="c.useCnt">{{c.cnt}}</span>
                    </li>
                </ol>-->
                <JSDTreeItem :item="r.childs" v-if="r.childs.length"></JSDTreeItem>
            </dl>
        </dd>
    </template>
</template>

<script>
    import store from '@/store'

    export default {
        name: 'JSDTreeItem',
        props: {
            item: Object
        },
        computed: {
            treeData() {
                const result = store.getters.getTreeData
                for (var i = 0; i < result; i++) {
                    if (!result[i].hasOwnProperty('isOpen')) {
                        result[i].isOpen = false
                    }
                }
                return result
            },
            treeSelect() {
                return store.getters.getTreeSelect
            },
            listAreaClass() {
                return this.$route.path.indexOf('/app/user') > -1 ? 'userListArea' : 'treeListArea'
            }
        },
        methods: {
            itemClick(r) {
                if (r.hasOwnProperty('childs') && r.childs.length > 0) {
                    r.isOpen = !r.isOpen
                }
                else {
                    this.$callbackTreeData(r)
                }
            }
        }
    }
</script>