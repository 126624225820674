
    import store from '@/store'
    import LeftMenu from '@/types/mypage_leftmenu'
    import UserService from '@/service/UserService'

    export default {
        name: 'MyApp',
        components: {
        },
        data() {
            return {                
                ui: {
                    app_layer : false,
                },
                arr: {
                    user_conf: '',
                    device_list : [],
                },                
            }
        },
        computed: {
        },
        methods: {
            init() {
                this.$setTitle('앱 사용 설정');
                this.$setSideTitle('My 페이지');

                //메뉴 리스트에서 가져온다.
                this.$setSideBtns(LeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        // 도움말 기능 처리
                    }
                });
                this.$nextTick(() => {
                    this.getList()
                })
            },
            getList() {                
                //설정값 
                UserService.conf_bulk_list(                    
                    {
                        DATA: 'APP_USE_YN'
                    },
                    (res) => {
                        this.arr.user_conf = res.data
                        //console.log(this.arr.user_conf);
                        //console.log(this.arr.user_conf.APP_USE_YN);
                    }
                );
                //기기 접속리스트 
                UserService.device_list(
                    (res) => {
                        this.arr.device_list = res.data[0]
                    }
                );
            },
            app_layer(layer_view: boolean) {
                this.ui.app_layer = layer_view;
                console.log(layer_view)
                if (!layer_view) {
                    this.user_con_update()
                }
            },

            user_con_update() {

                //사용자 설정값 변경
                UserService.conf_update(
                    {
                        code: 'APP_USE_YN',
                        value: this.arr.user_conf.APP_USE_YN,
                    },
                    (res) => {
                        //console.log(res.data);
                        if (res.data > 0) {
                            this.ui.app_layer = false;
                        }
                        else {
                            alert('APP사용 여부 설정 변경에 실패하였습니다.');
                        }
                    }
                );
            }
        },
        mounted() {
            this.init();
        },
    }
