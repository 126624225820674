import api from '@/utils/api'

export default class TemplateService {

    //템플릿 리스트 조회
    public static tmpl_list(VIEW_TYPE :number, callback: (result: any) => void): void {
        const url: string = `api/template/list/${VIEW_TYPE}`;
        api.get(`${url}`).then((r) => callback(r))
    }

    //템플릿 리스트 상세 조회 - 특정 템플릿의 회차별 조회
    public static tmpl_detail(TMPL_NO: number, callback: (result: any) => void): void {
        const url: string = `api/template/detail/${TMPL_NO}`;
        api.get(`${url}`).then((r) => callback(r))
    }

    //템플릿 예약
    public static tmpl_resv(data: any, callback: (result: any) => void): void {
        api.post(`api/template/resv`, data).then((r) => callback(r))
    }
    //my템플릿에 저장
    public static tmpl_mytmpl(data: any, callback: (result: any) => void): void {
        api.post(`api/template/mytmpl`, data).then((r) => callback(r))
    }
    //템플릿 미리보기 리스트
    public static tmpl_prev_list(data: any, callback: (result: any) => void): void {
        api.post(`api/template/prev/list`, data).then((r) => callback(r))
    }
    //템플릿 수정
    public static tmpl_edit(data: any, callback: (result: any) => void): void {
        api.post(`api/template/edit`, data).then((r) => callback(r))
    }
    // 템플릿 - 예약 리스트 조회
    public static tmpl_resv_list(page: number, data: any, callback: (result: any) => void): void {
        api.post(`api/template/resv/list/${page}`, data).then((r) => callback(r))
    }
    // 템플릿 - 예약 리스트 상세 조회
    public static tmpl_resv_detail(data: any, callback: (result: any) => void): void {
        api.post(`api/template/resv/detail`, data).then((r) => callback(r))
    }
    //템플릿 예약분 삭제
    public static tmpl_delete(data: any, callback: (result: any) => void): void {
        api.post(`api/template/delete`, data).then((r) => callback(r))
    }
    // 템플릿 - 폰발송분선조회
    public static tmpl_stat_list(data: any, callback: (result: any) => void): void {
        api.post(`api/template/smt_stat`, data).then((r) => callback(r))
    }
}