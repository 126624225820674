// 템플릿 우측 메뉴 처리
export default {    
    menu_list: [
        [
            { name: '문자 시리즈', path: '/app/tmpl' },
            { name: '시리즈 예약 리스트', path: '/app/tmpl/resv' },
            { name: 'My시리즈 편집', path: '/app/tmpl/mylist' },
            { name: '시리즈 설정', path: '/app/tmpl/setting' },
        ],
        [            
            { name: '도움말', path: '/app/board/help' },
        ],
    ]
};

