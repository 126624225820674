
    import store from '@/store'

    import TemplateService from '@/service/TemplateService'
    import UmsService from '@/service/UmsService'
    import CommomService from '@/service/CommomService'

    import TmplLeftMenu from '@/types/tmpl_leftmenu'
    import GroupCustSelect from '@/components/GroupCustSelect.vue';
    import UmsSendPreview from '@/components/UmsSendPreview.vue';
    import UmsSendLayer from '@/components/UmsSendLayer.vue'
    import JSDCustSelect from '@/components/CustSelect.vue';
    import TmplItemEdit from '@/components/TmplItemEdit.vue'
    import TmplDistLayer from '@/components/TmplDistLayer.vue';
    import InfiniteScroll from '@/components/InfiniteScroll.vue'
    import RecentSendList from '@/components/RecentSendList.vue'

    import utils from '@/helpers/utils'                         //공용으로 사용될 js함수를 import
    import { pushScopeId } from 'vue';

    const _ = require('lodash')

    export default {
        name: 'tmpl_list',
        components: {
            GroupCustSelect,
            JSDCustSelect,
            TmplItemEdit,
            UmsSendPreview,
            UmsSendLayer,
            TmplDistLayer,
            InfiniteScroll,
            RecentSendList,
        },
        data() {
            return {
                TMPL_NO: -1,           //템플릿번호 좌측 트리메뉴에서 설정된다.
                TMPL_NM: '',           //템플릿 명
                tmpl_item: {            //템플릿 아이템추가
                    CMD: '',            //I신규등록 U:수정 D:삭제
                    TMPL_NO: -1,        //템플릿번호
                    ITEM_NO: -1,        //아이템번호
                    CUST_SEND_MON: -1,      //발송주기 월
                    CUST_SEND_DAY: -1,      //발송주기 일
                    CUST_SEND_TM: '10:00',  //발송시간
                    TITLE: '',
                    MMS_CONT: '',
                    IMG_NM: '',
                    IMG_PATH: '',
                    IMG_URL: '',
                },
                ui: {
                    searchKey: '',
                    searchPhone: '',
                    mytmpl: {
                        is_view: false,
                        title: '',
                    },
                    tmpl_del: {
                        is_view: false,     //레이어 on/off
                        seq_no: 0,         //삭제할 순번
                    },
                    send: {
                        is_smt: '1',       //1:폰발송 0:웹발송
                        sms_amt: 0,
                        lms_amt: 0,
                        mms_amt: 0,
                        coin_amt: 0,       //코인잔액
                    },
                    preview: {
                        is_view: false,
                        seq_no: 1,
                        mesg: '',
                        title: '',
                        cust_data: '',
                        left: 0,            //레이어 위치
                        top: 0,
                    },
                    smt_split: {
                        is_view: false,
                    },
                    ums_info: {
                        sms_cnt: 0,
                        lms_cnt: 0,
                        mms_cnt: 0,
                        sms_amt: 0,
                        lms_amt: 0,
                        mms_amt: 0,
                    },
                    dist_view: {
                        cust_cnt: 0,        //발송고객수
                        send_type: 0,
                    },
                    view_cnt: 20,           //scroll당 표시될 건수
                    base_point: 0,          //원본에서 읽어서 표시할 포인트 기준

                },
                arr: {
                    custList: [],          //발송할 연락처리스트 (최종적으로 이목록만큼 발송된다.)
                    view_custs: [],     //발송할 연락처 화면에 보이는 리스트 (대량처리시 문제로 화면용과 나눠서 처리한다.)
                    group_custs: [],    //그룹선탹한 연락처 리스트
                    tmpl_list: [],      //자동문자 템플릿 상세리스트
                    TMPL_TYPE_CD: '',
                    send_info: [],
                    code_list: [],
                    tmpl_full_list: [],
                    dist_list: [],
                    is_dist: '',
                    selectList: [],    //GroupCustSelect에 넘기는 이미 선택한 연락처 리스트
                    render:[],
                },
                api: {
                    mytmpl: {
                        CMD: '',
                        TMPL_NM: '',
                        DATA: ''
                    },
                    stat: {
                        CUST_CNT: 0,
                        SEND_TYPE: 0,
                        DATA: ''
                    },
                    send: {
                        send_info: [],
                        sms_cnt: 0,
                        lms_cnt: 0,
                        mms_cnt: 0,
                        smt_cnt: 0,
                    },
                    item_list: [],       //update할 item
                    send_item: '',      //발송할 item정보    json형태임
                },
                isSending: false
            }
        },
        computed: {
            Session() {
                // 세션 정보를 사용할수 있게 한다.
                return store.getters.getUser
            }
        },
        methods: {
            init() {
                //좌측메뉴 제목
                this.$setSideTitle('문자 시리즈');

                //좌측 메뉴 구성
                this.$setSideBtns(TmplLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('문자시리즈')
                    }
                });

                CommomService.code_keys(
                    { KEYS: 'TMPL_TYPE_CD', },
                    (res) => {
                        this.arr.code_list = res.data[0];				//코드리스트 조회

                        TemplateService.tmpl_list(
                            1,          //VIEW_TYPE
                            (res) => {
                                this.arr.tmpl_full_list = res.data[0];

                                ////좌측 트리구조...그룹리스트
                                //// title : 그룹명
                                //// useCnt : 카운트 표시여부
                                //// cnt : 그룹내 숫자
                                //// 클릭시 전달할 값 (그룹번호)
                                //let tree_group_list = [];
                                //for (var i = 0; i < this.arr.code_list.length; i++) {
                                //    let tmpl_type_cd: string = this.arr.code_list[i].CODE
                                //    tree_group_list.push({
                                //        title: this.arr.code_list[i].CODE_NM,
                                //        useCnt: false,
                                //        cnt: 0,
                                //        value: -1
                                //    })
                                //    let tmp = this.arr.tmpl_full_list.filter(x => x.TMPL_TYPE_CD == tmpl_type_cd);
                                //    if (tmp.length > 0) {
                                //        for (var j = 0; j < tmp.length; j++) {
                                //            if (i == 0 && j == 0) {
                                //                this.TMPL_NO = tmp[j].TMPL_NO
                                //                this.TMPL_NM = tmp[j].TMPL_NM
                                //            }

                                //            tree_group_list.push({
                                //                title: tmp[j].TMPL_NM,
                                //                useCnt: true,
                                //                cnt: tmp[j].SEND_CNT,
                                //                value: tmp[j].TMPL_NO
                                //            })
                                //        }
                                //    }
                                //}

                                const treeData = []
                                this.arr.code_list.forEach((r, idx) => {
                                    const subList = this.arr.tmpl_full_list.filter(x => x.TMPL_TYPE_CD == r.CODE)

                                    subList.forEach((r) => {
                                        r.title = r.TMPL_NM
                                        r.value = `${r.TMPL_TYPE_CD}-${r.TMPL_NO}`
                                    })


                                    treeData.push({
                                        title: r.CODE_NM,
                                        useCnt: false,
                                        cnt: 0,
                                        value: r.CODE,
                                        childs: subList,
                                        isOpen: idx == 0,
                                    })
                                })

                                if (treeData.length && treeData[0].childs.length) {
                                    const initData = treeData[0].childs[0]
                                    this.TMPL_NO = initData.TMPL_NO
                                    this.TMPL_NM = initData.TMPL_NM

                                    this.$setTitle(`${this.TMPL_NM} 템플릿 편집`);
                                    this.$setTreeSelect(initData.value)
                                }
                                this.$setTreeData(treeData)

                                //좌측 그룹명 클릭시 처리(리스트 리로드)
                                this.$setTreeDataCallback((r) => {
                                    //선택한 시리즈을 표시한다.
                                    this.TMPL_NO = r.hasOwnProperty('childs') ? -1 : r.TMPL_NO
                                    this.TMPL_NM = r.title

                                    this.$setTitle(`${r.title} 시리즈 편집`);
                                    this.$setTreeSelect(r.value)


                                    this.getTmplList()
                                    this.getUmsSendInfo()
                                });


                                this.$nextTick(() => {
                                    if (sessionStorage.hasOwnProperty('custList')) {
                                        this.arr.custList = JSON.parse(sessionStorage.getItem('custList'))
                                        sessionStorage.removeItem('custList')
                                        this.render(true)
                                    }

                                    this.getTmplList()
                                    this.getUmsSendInfo()
                                    this.ui.send.sms_amt = this.Session.SMS_AMT
                                    this.ui.send.lms_amt = this.Session.LMS_AMT
                                    this.ui.send.mms_amt = this.Session.MMS_AMT
                                    this.ui.send.coin_amt = this.Session.COIN_BAL_AMT
                                    this.ui.preview.seq_no = 1
                                    //this.getUmssSmtStat()
                                })
                            }
                        )
                    }
                );

            },
            getTmplList() {
                //자동문자 시리즈 조회
                TemplateService.tmpl_detail(
                    this.TMPL_NO,
                    (res) => {
                        //console.log(res);
                        //console.log(res.data);
                        this.arr.tmpl_list = res.data[0];				//시리즈의 아이템 리스트 조회

                        if (Object.keys(this.arr.tmpl_list).length > 0 ) {
                            this.arr.TMPL_TYPE_CD = this.arr.tmpl_list[0].TMPL_TYPE_CD
                            this.setUms_info();
                        }                        
                    }
                )
            },
            getUmsSendInfo() {
                // 발송관련 정보 조회
                UmsService.ums_send_info(
                    (res) => {
                        //console.log(res.data);
                        this.arr.send_info = res.data;
                        //console.log(this.arr.send_info);
                    }
                );
            },
            getUmssSmtStat() {
                //폰발송 가능건수 조회
                UmsService.ums_smt_stat({
                    VIEW_YM: '',
                    STRT_DA: '',
                    END_DA: '',
                    VIEW_DT: new Date().addDate(1).toStr('yyyyMMdd'),
                    SEND_CNT: 0,
                    VIEW_TYPE: 2
                }, (res) => {
                    //console.log(res.data);
                    this.arr.send_info = res.data;
                    //console.log(this.arr.send_info);
                }
                )
            },
            setUms_info() {
                //코인발송관련 정보를 설정한다. 건수와 금액을 구한다.
                this.ui.ums_info.sms_cnt = 0;
                this.ui.ums_info.lms_cnt = 0;
                this.ui.ums_info.mms_cnt = 0;

                for (var i = 0; i < this.arr.tmpl_list.length; i++) {
                    let tmp = this.arr.tmpl_list[i]

                    if (tmp.IMG_NM.length > 0) {
                        this.ui.ums_info.mms_cnt += 1;
                    }
                    else if (String(tmp.CONT).koLen() > 90) {
                        this.ui.ums_info.lms_cnt += 1;
                    }
                    else {
                        this.ui.ums_info.sms_cnt += 1;
                    }

                }

                //발송금액
                this.ui.ums_info.sms_amt = this.ui.ums_info.sms_cnt * this.arr.custList.length * this.ui.send.sms_amt
                this.ui.ums_info.lms_amt = this.ui.ums_info.lms_cnt * this.arr.custList.length * this.ui.send.lms_amt
                this.ui.ums_info.mms_amt = this.ui.ums_info.mms_cnt * this.arr.custList.length * this.ui.send.mms_amt
            },  //코인발송관련 정보를 설정한다. 건수와 금액을 구한다.
            item_remove(obj) {
                //자동문자 시리즈 회차 삭제
                this.ui.tmpl_del.seq_no = Number(obj['SEQ_NO'])
                this.ui.tmpl_del.is_view = true
            },
            item_edit(obj) {
                //자동문자 시리즈 수정
                this.tmpl_item.CMD = 'U'                //I신규등록 U:수정 D:삭제
                this.tmpl_item.TMPL_NO = this.TMPL_NO   //시리즈번호
                this.tmpl_item.ITEM_NO = Number(obj['ITEM_NO'])         //아이템번호
                this.tmpl_item.CUST_SEND_MON = Number(obj['CUST_SEND_MON'])      //발송주기 월
                this.tmpl_item.CUST_SEND_DAY = Number(obj['CUST_SEND_DAY'])      //발송주기 일
                this.tmpl_item.CUST_SEND_TM = String(obj['CUST_SEND_TM'])  //발송시간
                this.tmpl_item.TITLE = String(obj['TITLE'])
                this.tmpl_item.MMS_CONT = String(obj['CONT'])
                this.tmpl_item.IMG_NM = String(obj['IMG_NM'])
                this.tmpl_item.IMG_PATH = String(obj['IMG_PATH'])
                this.tmpl_item.IMG_URL = String(obj['IMG_URL'])

                const select = this.arr.tmpl_full_list.filter((r) => r.value == `${this.arr.TMPL_TYPE_CD}-${this.TMPL_NO}`)
                this.$refs.TmplItemEdit.setSeqInfo(`${select.length ? select[0].TMPL_NM : ''} ${obj.SEQ_NO}회차`)
                this.$refs.TmplItemEdit.view();
            },
            item_add() {
                //시리즈 아이템  추가하기
                // item수정 및 신규 등록창을 띄운다.

                const last = this.arr.tmpl_list[this.arr.tmpl_list.length - 1] || {}
                //console.log(last)

                //시리즈 아이템추가
                this.tmpl_item.CMD = 'I'               //I신규등록 U:수정 D:삭제
                this.tmpl_item.TMPL_NO = this.TMPL_NO   //시리즈번호
                this.tmpl_item.ITEM_NO = -1             //아이템번호
                this.tmpl_item.CUST_SEND_MON = last.hasOwnProperty('CUST_SEND_MON') && last.CUST_SEND_MON ? last.CUST_SEND_MON + 1 : 0       //발송주기 월
                this.tmpl_item.CUST_SEND_DAY = last.hasOwnProperty('CUST_SEND_DAY') && last.CUST_SEND_DAY ? last.CUST_SEND_DAY + 1 : 0      //발송주기 일
                this.tmpl_item.CUST_SEND_TM = '10:00'   //발송시간
                this.tmpl_item.TITLE = ''
                this.tmpl_item.MMS_CONT = ''
                this.tmpl_item.IMG_NM = ''
                this.tmpl_item.IMG_PATH = ''
                this.tmpl_item.IMG_URL = ''

                const select = this.arr.tmpl_full_list.filter((r) => r.value == `${this.arr.TMPL_TYPE_CD}-${this.TMPL_NO}`)
                this.$refs.TmplItemEdit.setSeqInfo(`${select.length ? select[0].TMPL_NM : ''} ${this.arr.tmpl_list.length + 1}회차`)
                this.$refs.TmplItemEdit.view();
            },
            setItemInfo(value) {
                // item수정/신규 컴포넌트에서 관리된 item데이터를 전달 받음
                this.tmpl_item = value;

                if (this.tmpl_item.ITEM_NO > 0) {
                    //기존에 있는 아이템을 수정
                    this.arr.tmpl_list.forEach((r) => {
                        if (r.ITEM_NO == this.tmpl_item.ITEM_NO) {
                            r.SEND_TXT = `${this.tmpl_item.CUST_SEND_MON || this.tmpl_item.CUST_SEND_DAY}${this.tmpl_item.CUST_SEND_MON ? '개월' : '일'}`
                            r.CUST_SEND_MON = this.tmpl_item.CUST_SEND_MON
                            r.CUST_SEND_DAY = this.tmpl_item.CUST_SEND_DAY
                            r.CUST_SEND_TM = this.tmpl_item.CUST_SEND_TM
                            r.TITLE = this.tmpl_item.TITLE
                            r.CONT = this.tmpl_item.MMS_CONT
                            r.IMG_NM = this.tmpl_item.IMG_NM
                            r.IMG_PATH = this.tmpl_item.IMG_PATH
                            r.IMG_URL = this.tmpl_item.IMG_URL
                        }
                    })

                    //아이템 변경에 따른 코인량 계산.
                        this.setUms_info()

                }
                else {
                    //신규 등록임으로 아이템을 추가함.
                    this.arr.tmpl_list.push({
                        SEQ_NO: -1,
                        ITEM_NO: -1,
                        CUST_SEND_MON: this.tmpl_item.CUST_SEND_MON,
                        CUST_SEND_DAY: this.tmpl_item.CUST_SEND_DAY,
                        CUST_SEND_TM: this.tmpl_item.CUST_SEND_TM,
                        TITLE: this.tmpl_item.TITLE,
                        CONT: this.tmpl_item.MMS_CONT,
                        IMG_NM: this.tmpl_item.IMG_NM,
                        IMG_PATH: this.tmpl_item.IMG_PATH,
                        IMG_URL: this.tmpl_item.IMG_URL,
                    })
                    //아이템을 다시 정렬한다. -> 코인도 다시 계산
                    this.sort_tmpl_item()
                }

            },
            myTmplCheck(cmd) {
                // 시리즈을 저장한다. my시리즈으로 저장
                if (this.arr.tmpl_list.length == 0) {
                    alert('시리즈의 회차가 정보가 없습니다.')
                    return
                }

                // 1 발송일 겹치는 회차가 있는지 check
                for (var i = 0; i < this.arr.tmpl_list.length - 1; i++) {
                    let item = this.arr.tmpl_list[i]
                    let time_val: number = item.CUST_SEND_MON * 30 + item.CUST_SEND_DAY
                    let tmp = this.arr.tmpl_list.filter((x, idx) => (x.CUST_SEND_MON * 30 + x.CUST_SEND_DAY) == time_val && idx > i)

                    if (tmp.length > 0) {
                        alert(tmp[0].SEQ_NO + '회차 발송 시점이 다른 회차와 중복됩니다.')
                        return
                    }
                }

                // 각 회사별 메시지가 있어야 함 없으면 오류
                let tmp_arr = this.arr.tmpl_list.filter(x => String(x.CONT).koLen() <= 20)

                if (tmp_arr.length > 0) {
                    alert(tmp_arr[0].SEQ_NO + '회차 발송 메시지를 20바이트(한글 10글자)이상 입력해 주세요.')
                    return
                }

                //console.log('myTmplCheck');
                this.api.mytmpl.CMD = cmd       //CMD지정

                if (cmd == 'I') {
                    //마이시리즈 저장 레이어를 띄운다.
                    this.ui.mytmpl.title = ''
                    this.ui.mytmpl.is_view = true
                }
                else if (cmd == 'U') {
                    //현재 시리즈 수정
                    this.ui.mytmpl.title = this.TMPL_NM
                    this.myTmplSave()
                }
            },
            myTmplSave() {
                //시리즈의 아이템 저장 - 실제 시리즈의 저장
                this.api.mytmpl.TMPL_NM = this.ui.mytmpl.title

                if (!this.api.mytmpl.TMPL_NM.length) {
                    alert('시리즈 제목을 입력해주세요.')
                    return
                }

                //{"CUST_SEND_MON":"1","CUST_SEND_DAY":"1", "CUST_SEND_TM":"10","CONT_NO":"", "TITLE":"제목", "CONT":"메시지 발송 테스트", "IMG_PATH":"", "IMG_SAVE_NM":""}
                let tmpl = []

                for (var i = 0; i < this.arr.tmpl_list.length; i++) {
                    let item = this.arr.tmpl_list[i]
                    tmpl.push({
                        CUST_SEND_MON: item.CUST_SEND_MON,
                        CUST_SEND_DAY: item.CUST_SEND_DAY,
                        CUST_SEND_TM: item.CUST_SEND_TM,
                        TITLE: item.TITLE,
                        CONT: item.CONT,
                        IMG_PATH: item.IMG_PATH,
                        IMG_NM: item.IMG_NM,
                    })
                }
                this.api.mytmpl.DATA = JSON.stringify(tmpl)

                //MY시리즈에 저장
                TemplateService.tmpl_mytmpl(
                    {
                        CMD: this.api.mytmpl.CMD,
                        TMPL_NM: this.ui.mytmpl.title,
                        DATA: this.api.mytmpl.DATA,
                    },
                    (res) => {
                        if (res.data.RESULT > 0) {
                            alert(`MY 시리즈가 ${this.api.mytmpl.CMD == 'I' ? '등록' : '수정'}되었습니다.`);
                            this.ui.mytmpl.is_view = false
                            this.getTmplList()
                        }
                        else {
                            alert(`MY시리즈 ${this.api.mytmpl.CMD == 'I' ? '등록' : '수정'}에 실패했습니다.` + res.data.RESULT_MESG);
                        }
                    }
                )
            },
            setCustSelect(value) {
                // 직접선택한 고객을 제외한 나머지는 지우고 다시 추가해줌
                this.arr.custList = this.arr.custList.filter((r) => r.CUST_NO == 0)

                // 중복방지 배열
                const custList = []
                const phoneList = []
                for (var i = 0; i < this.arr.custList.length; i++) {
                    custList.push(this.arr.custList[i].CUST_NO)
                    phoneList.push(this.arr.custList[i].PHONE_CNO)
                }
                // Set을 루프내에서 매번 생성하면 부하가 큼
                const custSet = new Set(custList)
                const phoneSet = new Set(phoneList)

                value.forEach((r) => {
                    if (!custSet.has(r.CUST_NO) && !phoneSet.has(r.PHONE_CNO)) {
                        this.arr.custList.push({
                            CUST_NO: r.CUST_NO,
                            PHONE_CNO: r.PHONE_CNO,
                            CUST_NM: r.CUST_NM
                        })
                    }
                })

                this.arr.custList.sort(utils.compare_CustName)

                //페이징을 위해 일련번호를 매긴다.
                for (var i = 0; i < this.arr.custList.length; i++) {
                    this.arr.custList[i].ROW_NO = i
                }
                this.arr.view_custs = []
                this.setUms_info()

                this.render(true)
            },
            custSearch(isDirect) {
                //고객 개별 검색
                if (isDirect) {
                    const phoneList = this.arr.custList.map((r) => r.PHONE_CNO)

                    if (!isNaN(this.ui.searchPhone)) {
                        this.ui.searchPhone = this.ui.searchPhone.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-");
                    }
                    if (this.ui.searchPhone.isHP()) {
                        if (!new Set(phoneList).has(this.ui.searchPhone)) {
                            this.arr.custList.push({
                                CUST_NO: 0,
                                PHONE_CNO: this.ui.searchPhone,
                                CUST_NM: '',
                            })
                        }
                    }
                    else {
                        alert('휴대폰 번호 형식이 올바르지 않습니다.')
                    }
                    this.ui.searchPhone = ''
                    this.render(false)
                }
                else {
                    if (!isNaN(this.ui.searchKey)) {
                        this.ui.searchKey = this.ui.searchKey.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-")
                    }
                    this.$refs.JSDCustSelect.search();
                }
            },  //고객 개별 검색
            setCustData(obj) {
                //고객 개별 검색결과 반영
                console.log('setCustData');
                console.log(obj);
                if (obj.hasOwnProperty('PHONE_CNO')) {

                    const custList = []
                    const phoneList = []
                    for (var i = 0; i < this.arr.custList.length; i++) {
                        custList.push(this.arr.custList[i].CUST_NO)
                        phoneList.push(this.arr.custList[i].PHONE_CNO)
                    }

                    if (!new Set(custList).has(obj.CUST_NO) && !new Set(phoneList).has(obj.PHONE_CNO)) {
                        this.arr.custList.push({
                            CUST_NO: obj.CUST_NO,
                            PHONE_CNO: obj.PHONE_CNO,
                            CUST_NM: obj.CUST_NM
                        })
                    }
                    this.ui.searchKey = ''                    

                    //let phone_cno: string = String(obj['PHONE_CNO']);
                    //let idx: number = this.arr.custList.findIndex(x => x.PHONE_CNO == phone_cno);

                    //console.log('setCustData 추가로직');
                    //console.log(phone_cno);
                    //console.log(idx);

                    ////없으면 추가
                    //if (idx < 0) {                        
                    //    this.arr.custList.push({
                    //        CUST_NO: Number(obj['CUST_NO']),
                    //        PHONE_CNO: String(obj['PHONE_CNO']),
                    //        CUST_NM: String(obj['CUST_NM']),
                    //    });
                    //    console.log(this.arr.custList);
                    //    this.arr.custList.sort(utils.compare_CustName)

                    //    //코인부분 다시 계산
                    //    this.setUms_info()
                    //    this.render(false)
                    //}    
                    //this.ui.searchKey = ''                    
                }
                this.render(false)
            },
            recentSelect(data) {
                data.forEach((r) => {
                    this.arr.custList.push({
                        CUST_NO: r.CUST_NO,
                        PHONE_CNO: r.PHONE_CNO,
                        CUST_NM: r.CUST_NM
                    })
                })
                this.render(true)
            },
            tmpl_itme_del() {
                //화면에 보이는 아이템 삭제처리
                if (this.ui.tmpl_del.seq_no >= 0) {
                    let seq_no: number = this.ui.tmpl_del.seq_no
                    this.arr.tmpl_list.splice((seq_no - 1), 1)
                    this.ui.tmpl_del.is_view = false

                    //아이템을 재정렬한다. 코인도 다시 계산
                    this.sort_tmpl_item()
                }
            },
            autoPhone(e) {
                //폰번호에 자동으로 -추가
                var text = e.target.value;
                text = text.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-");
                e.target.value = text;
                this.ui.searchKey = text;
            },
            remove_custs(item) {
                console.log(item)
                console.time('remove')
                this.arr.render.forEach((r, idx) => {
                    if (r.CUST_NO == item.CUST_NO && r.PHONE_CNO == item.PHONE_CNO) {
                        this.arr.render.splice(idx, 1)
                    }
                })
                this.arr.custList.forEach((r, idx) => {
                    if (r.CUST_NO == item.CUST_NO && r.PHONE_CNO == item.PHONE_CNO) {
                        this.arr.custList.splice(idx, 1)
                    }
                })
                console.timeEnd('remove')
                this.setUms_info()
            },
            group_select() {
                //그룹 선책 레이어 띄우기
                this.arr.selectList = []
                for (var i = 0; i < this.arr.custList.length; i++) {
                    this.arr.selectList.push(this.arr.custList[i].CUST_NO)
                }
                this.$refs.GroupCustSelect.view(this.arr.selectList);
            },
            render(isInit) {
                if (!this.isLoading) {
                    this.isLoading = true

                    // 초기에만 100건 렌더
                    const cnt = isInit ? 100 : 50
                    if (isInit) {
                        this.arr.render.splice(0, this.arr.render.length)
                    }

                    for (var i = 0; i < cnt; i++) {
                        if (this.arr.render.length >= this.arr.custList.length || i >= this.arr.custList.length) {
                            this.isLoading = false
                            return
                        }
                        this.arr.render.push(this.arr.custList[this.arr.render.length])
                    }
                    this.isLoading = false
                }
            },
            contUpdate(e) {
                this.ui.mytmpl.title = e.target.value
            },
            sort_tmpl_item() {
                // 시리즈의 아이템을 정렬한다.
                this.arr.tmpl_list.sort(utils.compare_TmplItem)
                for (var i = 0; i < this.arr.tmpl_list.length; i++) {
                    this.arr.tmpl_list[i].SEQ_NO = i + 1
                }
                //코인계산부분을 다시 갱신한다.
                this.setUms_info()
            },
            preview_set(seq_no: number) {
                //미리보는 회차변경
                this.ui.preview.seq_no = seq_no
                this.preview_view()
            },
            renderImg(imgUrl) {
                return `${process.env.NODE_ENV == 'development' ? 'https://test.jsdsms.co.kr' : ''}${imgUrl}`
            },
            // 미리보기
            preview_view() {
                //데이터 체크해서 오류면 종료
                if (this.Invalid_tmpl()) {
                    return
                }

                this.ui.preview.is_view = true

                //미리보기 고객데이터 및 메시지 설정
                this.ui.preview.title = this.arr.tmpl_list[this.ui.preview.seq_no - 1].TITLE
                this.ui.preview.mesg = this.arr.tmpl_list[this.ui.preview.seq_no - 1].CONT
                const custList= [];
                for (var i = 0; i < this.arr.custList.length; i++) {
                    custList.push({
                        CUST_NO: this.arr.custList[i].CUST_NO
                    })
                }
                this.$nextTick(() => {
                    this.$refs.UmsSendPreview.view(custList);
                })
            },
            Invalid_tmpl() {
                //저장이나 미리보기전 체크처리
                //빈아이템이 있으면 에러처리
                for (var i = 0; i < this.arr.tmpl_list.length; i++) {
                    let tmp = this.arr.tmpl_list[i]

                    //console.log(tmp);
                    //console.log(tmp.CONT.koLen());
                    if (tmp.CONT.koLen() < 10) {
                        let mesg = tmp.SEQ_NO + '회차 메시지를 입력해 주세요.'
                        alert(mesg);
                        return true
                    }
                }

                //연락처 선택이 없으면 오류
                if (this.arr.custList.length == 0) {
                    alert('시리즈를 예약할 연락처를 선택해 주세요.');
                    return true
                }
                else {
                    if (this.ui.send.is_smt != '1') {
                        if (this.ui.send.coin_amt < (this.ui.ums_info.sms_amt + this.ui.ums_info.lms_amt + this.ui.ums_info.mms_amt)) {
                            alert('코인이 부족합니다.')
                            return true
                        }
                    }
                    else {
                        
                    }
                }

                return false
            },  //저장이나 미리보기전 체크처리
            tmpl_resv_view() {
                // 시리즈 예약발송 클릭시 웹발송 폰발송관련 처리 및 api에 맞는 값을 구한다.
                // 일자별 분산처리를 구한다.

                //데이터 체크해서 오류면 종료
                if (this.Invalid_tmpl()) {
                    return
                }
                if (this.isSending) {
                    alert('예약처리 중입니다.\n잠시후 다시 시도해 주세요.')
                    return
                }
                if (this.$refs.umsSendLayer.IS_STOP) {
                    this.$refs.umsSendLayer.show('stop')
                    return
                }

                this.ui.dist_view.cust_cnt = this.arr.custList.length  //발송고객수
                this.ui.dist_view.send_type = (this.ui.send.is_smt == '1') ? 2 : 1  //1:웹발송 2:폰발송 3: 폰발송 + 웹발송

                this.$nextTick(() => {
                    this.$refs.TmplDistLayer.view();
                })

            },  // 시리즈 예약발송 클릭시 웹발송 폰발송관련 처리 및 api에 맞는 값을 구한다.
            setTmplInfo(obj) {
                //시리즈 정보를 컴포넌트에서 가져온다.
                this.api.send.send_info = []

                for (var i = 0; i < obj.length; i++) {
                    let tmpl = this.arr.tmpl_list.filter(x => x.SEQ_NO == obj[i].SEND_NO)
                    //console.log(tmpl)
                    this.api.send.send_info.push({
                        SEND_DA: obj[i].SEND_DA.reAll('-', ''),
                        SEND_TM: obj[i].SEND_TM,
                        IS_SMT: obj[i].IS_SMT,
                        SEND_NO: obj[i].SEND_NO,
                        CUST_CNT: obj[i].CUST_CNT,
                        TITLE: tmpl[0].TITLE,
                        CONT: tmpl[0].CONT,
                        IMG_PATH: tmpl[0].IMG_PATH,
                        IMG_SAVE_NM: tmpl[0].IMG_NM,
                    })
                }
                //시리즈을 저장한다.
                this.tmpl_resv_send()

            },  //시리즈 정보를 컴포넌트에서 가져온다.
            tmpl_resv_send() {
                //시리즈 예약발송_실제 예약처리
                let custData = []
                for (var i = 0; i < this.arr.custList.length; i++) {
                    custData.push({
                        CNO: this.arr.custList[i].CUST_NO,
                        RN: this.arr.custList[i].PHONE_CNO
                    })
                }

                //발송될 항목별 count를 한다.
                let sms_cnt: number = 0
                let lms_cnt: number = 0
                let mms_cnt: number = 0
                let smt_cnt: number = 0

                let send_info = this.api.send.send_info

                for (var i = 0; i < send_info.length; i++) {
                    if (send_info[i].IS_SMT == 1) {
                        //폰발송
                        smt_cnt += send_info[i].CUST_CNT
                    }
                    else {
                        //웹발송
                        if (String(send_info[i].IMG_SAVE_NM).length > 0) {
                            mms_cnt += send_info[i].CUST_CNT
                        }
                        else if (String(send_info[i].CONT).koLen(this.ui.send.is_smt == '1') > (this.ui.send.is_smt == '1' ? 140 : 90)) {
                            lms_cnt += send_info[i].CUST_CNT
                        }
                        else {
                            sms_cnt += send_info[i].CUST_CNT
                        }
                    }
                }

                //api에 시리즈정보를 json-string형태로 변경하다.
                this.api.send_item = this.api.send.send_info
                this.isSending = true
                //실제 시리즈 예약을 건다.
                TemplateService.tmpl_resv(
                    {
                        TMPL_NM: this.TMPL_NM,
                        CUST_DATA: custData,
                        DATA: this.api.send_item,
                        TOT_SMS_CNT: sms_cnt,
                        TOT_LMS_CNT: lms_cnt,
                        TOT_MMS_CNT: mms_cnt,
                        TOT_SMT_CNT: smt_cnt,
                        IS_DEBUG: false,
                    },
                    (res) => {
                        if (res.data.RESULT > 0) {
                            alert('시리즈문자 발송 예약에 성공했습니다.');
                            this.isSending = false
                            this.ui.mytmpl.is_view = false

                            //화면 및 발송데이터 초기화 처리
                            this.arr.view_custs.splice(0, this.arr.view_custs.length)
                            this.arr.custList.splice(0, this.arr.custList.length)
                            this.arr.render.splice(0, this.arr.render.length)
                            this.arr.group_custs.splice(0, this.arr.group_custs.length)
                            this.arr.send_info.splice(0, this.arr.send_info.length)
                            this.api.item_list.splice(0, this.arr.item_list.length)      //update할 item
                            this.api.send_item = ''    //발송할 item정보    json형태임
                            this.api.send.send_info.splice(0, this.api.send.send_info.length)


                            this.$nextTick(() => {
                                this.getTmplList()
                            })
                        }
                        else {
                            alert('시리즈문자 발송 예약에 실패했습니다.' + res.data.RESULT_MESG);
                        }
                    }
                );
            },  //시리즈 예약발송_실제 발송처리
        },
        mounted() {
            this.init();
        },
    }
