<!--
-->
<template>
    <div class="box">
        <button type="button" class="grayBtn mgR10" @click="preview">미리보기</button>
        <button type="button" class="grayBtn mgR10" @click="send(true)">예약발송</button>
        <button type="button" class="grayBtn mgR10" @click="send(false)">즉시발송</button>
    </div>

    <div class="box overflow left" style="width:calc(100% - 770px); margin-right:20px;">

        <div class="pageTitleTab left" style="width:100%;margin-bottom:20px;">
            <div class="tabBtn" :class="{'select' : api.list.MEDIA_TYPE_CD == 1}" @click="search(1)">단문메시지</div>
            <div class="tabBtn" :class="{'select' : api.list.MEDIA_TYPE_CD == 2}" @click="search(2)">장문메시지</div>
            <div class="tabBtn" :class="{'select' : api.list.MEDIA_TYPE_CD == 3}" @click="search(3)">그림메시지</div>
        </div>

        <div class="msgBoxArea smallbox left" v-for="r in arr.list">
            <div class="staronoff" @click="setBookMark(r)" v-if="api.list.VIEW_TYPE == 1 && r.IS_BKMR_PSBL">
                <img :src="require(`@/assets/images/${r.IS_BKMR ? 'star_on' : 'star_off'}.png`)" />
            </div>
            <div class="msgBox" @click="select(r)" v-if="api.list.VIEW_TYPE == 1">
                <img :src="renderImg(r.MMS_IMAGE1)" v-if="r.MMS_IMAGE1.length" />
                <div v-if="r.MEDIA_TYPE_CD != '1'">{{r.TITLE}}</div>
                {{r.MMS_CONT}}
            </div>
            <div class="msgBox" style="padding: 0; overflow: hidden;" @click="addImg(r)" v-if="api.list.VIEW_TYPE == 2">
                <img style="width: 100%; height: 100%; object-fit: cover; max-width: none;max-height: none;" :src="renderImg(r.WEB_IMG_URL)" />
            </div>
        </div>

        <div class="conBottom">
            <JSDPageCtrl ref="pageCtrl" :page="page" :cntPage="api.list.CNT_PAGE" :maxCnt="page.maxCnt" @page-move="movePage"></JSDPageCtrl>
        </div>

    </div>


    <div class="box overflow left" style="width:665px;">

        <div style="border-radius:10px; padding:10px; margin:0 auto 30px; background:#efefef; font-size:20px;" v-if="isNoti">
            <span class="bold red">
                {{`현재 통신사와 전산문제로 ${new Date(this.enableSendDate).addDate(-1).toStr('MM월 dd일')}까지 단문 문자의 발송이 되지 않으니\n장문 문자와 폰발송을 이용하시기 바랍니다.`}}
            </span>
        </div>

        <div class="msgBoxLeft mgR15" style="position:relative;">
            <div class="msgTextareaBox">
                <div class="blue bold headBox">
                    <!-- 광고일 경우 -->
                    <div v-if="isAdv">(광고)</div>
                    <!-- MMS일 경우  -->
                    <div v-if="isMms">제목 : <input type="text" class="selectCutNumInput" placeholder="제목을 입력하세요" @input="contUpdate($event, 'title')" v-model="api.send.TITLE"></div>
                </div>
                <div class="msgTextarea mgB10" :class="{'addFootBox': isAdv}" style="display:flex;flex-direction:column;">
                    <template v-for="r in arr.imgList">
                        <img :src="renderImg(r.IMG_URL)" @click="removeImg($index)" />
                    </template>
                    <textarea style="width:100%;flex:1;resize:none;min-height:100%;" placeholder="메시지를 입력해주세요" @input="contUpdate($event, 'msg')" v-drt-caret-target="'caret'" @caret-update="contUpdate($event, 'msg')" v-model="api.send.MESG"></textarea>
                </div>
                <div class="blue bold footBox" v-if="isAdv">
                    무료거부 080-880-0141
                </div>
            </div>
            <div class="poRel mgB10">
                <button class="grayBtn" @click="$caret.insert('[%호칭%]')">호칭</button>
                &nbsp;
                <button class="grayBtn" @click="isImgUpload = true">첨부</button>
                &nbsp;
                <button class="grayBtn" ref="emoji" @click="$refs.emojiLayer.visible()">특수문자</button>

                <!-- 첨부파일 레이어 -->
                <div class="layer addimg" v-if="isImgUpload">
                    <div class="layerTitle">이미지 첨부</div>
                    <div class="mgB15">이미지는 <b>한 번에 최대 3개까지<br>첨부</b>할 수 있습니다.</div>
                    <div>
                        <input type="file" id="upFile" class="addimgFile" @change="getImg()" placeholder="클릭하여 첨부할 이미지를 선택하세요" multiple accept=".jpg,.jpeg" />
                    </div>

                    <div class="grayBox mgB15">
                        파일은 JPG 이미지 파일만 첨부할 수 있습니다.
                        이미지가 (1500 픽셀 X 1500픽셀)을 초과하는 경우, 비율에 맞게 자동 축소되며
                        압축으로 인한 품질의 저하가 있을 수 있습니다.
                    </div>

                    <div class="textCenter">
                        <button class="grayBtn" @click="imgInit()">취소</button>
                        <button class="blueBtn" @click="upload()">확인</button>
                    </div>

                </div>
                <!-- 첨부파일 레이어 종료 -->

            </div>
            <div class="overflow mgB10" style="width:100%;">
                <div class="right bold" style="font-size:20px;">
                    <span :class="{red: isMms, blue: !isMms}">{{isMms ? 'MMS' : 'SMS'}}</span> {{msgLen}}/{{msgLimit}} Byte
                </div>
            </div>
            <div style="border:1px solid #c3c3c3; border-radius:5px;padding:10px; margin-top:20px;">
                <span class="bold">문자메시지 이용요금</span>(코인발송시)<br>
                - 단문 8코인, 장문 25코인, 그림 60코인
            </div>
            <div class="sendNumBox">
                <div class="selectBox left">발신번호</div>
                <select class="selectBox left" v-model="api.send.SEND_NO" :disabled="api.send.IS_SMT">
                    <option v-for="r in arr.phoneList" :disabled="r.SEND_APVL_CD != '2' && r.SEND_APVL_CD != '4'">{{r.SEND_PHON_CNO}}</option>
                </select>
            </div>
        </div>
        <div class="msgBoxLeft" style="position: relative;">

            <div class="selectCutBox adSms mgB15">
                <div>
                    <div class="bold" style="font-size:18px;padding:3px 0 0; float:left;">받는사람</div>
                    <button type="button" class="yellowBtn right" @click="$refs.recentLayer.show()">최근발송</button><button type="button" class="whiteBtn right mgR05" @click="groupSearch">그룹선택</button>
                </div>
                <div><input type="text" class="selectCutNumInput" style="width:65%;margin:0;" placeholder="직접번호입력" @input="(e) => { searchPhone = e.target.value}" v-model="searchPhone" @keyup.enter="custSearch(true)"> <button class="whiteBtn right" @click="custSearch(true)">번호추가</button></div>
                <div class="mgB10">
                    <input type="text" ref="custSearch" class="selectCutNumInput" style="width:65%;margin:0;" placeholder="이름, 휴대폰 번호 검색" @input="(e) => { searchKey = e.target.value}" v-model="searchKey" @keyup.enter="custSearch(false)"> <button class="whiteBtn right" @click="custSearch(false)">번호검색</button>
                </div>

                <div>
                    <InfiniteScroll @InfiniteScroll="render(false)" :loading="isLoading">
                        <div class="cutBlock" v-for="r in arr.render">
                            {{`${r.CUST_NM} ${r.PHONE_CNO}`}}
                            <button type="button" class="closeBtn right" @click="custRemove(r)">X</button>
                        </div>
                    </InfiniteScroll>
                </div>
            </div>

            <div class="pageTitleTab mgB15" style="width:100%;">
                <div class="tabBtn" :class="{select: api.send.IS_SMT}" @click="changeSendType(true)">폰발송</div>
                <div class="tabBtn" :class="{select: !api.send.IS_SMT}" @click="changeSendType(false)">코인발송</div>
            </div>
            <div class="mgB15" v-if="api.send.IS_SMT">
                <p class="selectBox bold">폰발송 가능 건수 : <span class="blue">{{sendInfo.SEND_CAPA_CNT}}</span></p>
                <p>
                    ※ 사용자님의 스마트폰에서 직접 발송한 건은 ‘폰발송 가능 건수’에 포함되지 않습니다. <br /><br />
                    ※ 폰발송을 선택하여 문자 발송을 하실때 사용자님의 핸드폰에 상생레터 앱이 미설치 되어 있거나 앱이 절전모드 상태이면 문자 발송이 되지 않습니다.<br /><br />
                    ※ 폰발송을 선택하여 문자 발송을 하실때 문자무제한 요금제가 아니면 별도의 과금이 부과됩니다.<br /><br />
                    ※ 1인 1개 핸드폰 앱만 설치 가능합니다. 발신번호가 2개라고 해서 2개 앱을 설치하고 문자 무제한 요금제를 이용하여 '폰발송' 무료 문자를 2배로 사용할 수 있는 것은 아닙니다.

                </p>
            </div>
            <div class="mgB15" v-if="!api.send.IS_SMT">
                <p class="selectBox bold">발송 대상 : <span class="blue">{{arr.custList.length}}</span> 명</p>
                <p class="selectBox bold">필요 코인 : <span class="red">{{coinCalc}}</span></p>
            </div>
            <div class="textCenter">
                <button class="greenBtn mgR10" @click="preview">미리보기</button>
                <button class="grayBtn mgR10" @click="send(true)">예약발송</button>
                <button class="blueBtn" @click="send(false)">즉시발송</button>
            </div>

            <RecentSendList ref="recentLayer" @setCustSelect="recentSelect"></RecentSendList>
        </div>
    </div>

        <!-- 그룹선택 레이어 -->
        <GroupSelect ref="GroupSelect"
                     @setCustSelect="groupSelect">
        </GroupSelect>

        <!-- 연락처 직접 검색 -->
        <CustSelect ref="CustSelect"
                    :target="$refs.custSearch"
                    :search_key="searchKey"
                    :height="300"
                    :is_direct_add=true
                    @setCustData="custSelect">
        </CustSelect>

        <div class="layer deletAlt" v-show="isPreview">
            <div class="layerTitle">미리보기</div>

            <div class="layerMain">
                <UmsSendPreview ref="UmsSendPreview"
                                :title="api.preview.TITLE"
                                :message="api.preview.MESG">
                </UmsSendPreview>
                * 이미지는 미리보기에서 확인하실수 없습니다.
            </div>

            <div class="layerBtnArea">
                <button class="grayBtn" @click="isPreview = false">닫기</button>
            </div>
        </div>

        <div class="layer deletAlt" style="width:600px; top:150px; left:calc(50% - 300px);" v-if="isAdvWarn" v-drt-center="isAdvWarn">
            <div class="layerTitle">광고성 문자 표기 의무사항 안내</div>

            <div class="layerMain " style="overflow-y: auto;max-height: 500px; padding:0 10px;">
                <div class="layerMainText red bold">
                    의무사항 미 준수시, 메시지 발송이 중단될 수 있습니다.
                </div>

                <div class="overflow mgB15">
                    <div class="bold blue" style="font-size:18px; border-bottom:1px solid #000000;padding:5px;margin-bottom:10px;">광고성 문자 작성 예시</div>

                    <div class="left">
                        <div class="left mgR15">
                            <img src="@/assets/images/adsms_sample_s.jpg">
                        </div>
                        <ol style="margin-left:20px;">
                            <li class="mgB15">광고성 문자 작성 예시</li>
                            <li class="mgB15">메시지 시작 부분에 (광고) 표시 (자동삽입), (광고) 바로 뒤에 업체명 (서비스명), 전화번호 표시 연락처는 발신번호와 상이한 경우 표시</li>
                            <li class="mgB15">메시지 마지막에 무료 수신거부 080번호 표시(자동삽입)</li>
                        </ol>
                    </div>

                </div>

                <div class="layerMainText">
                    <div class="mgB10">
                        주된 정보가 광고성 정보가 아니더라도 <b>부수적으로 광고성 정보가 포함</b>되어 있으면<br>
                        <b>전체가 광고성 정보에 해당</b>됩니다.
                    </div>
                    <ul style="margin-left:20px;">
                        <li>(광고), 080, 인증번호가 자동으로 포함되어 발송 됩니다. 미리보기를 통해 <br>메시지 내용과 byte수를 확인해보세요</li>
                        <li>(광고) 바로 뒤에 '업체명(보내는 사람)과 전화번호를 반드시 작성해 주세요<br> 미작성시 발송이 중단될 수 있습니다.</li>
                    </ul>
                </div>

                <div class="overflow">
                    <div class="bold blue" style="font-size:18px; border-bottom:1px solid #000000;padding:5px;margin-bottom:10px;">수신거부 번호 확인</div>

                    <div class="left">수신거부를 한 경우 문자를 발송해도 수신인이 문자를 <br>수신할 수 없습니다.</div>
                    <button class="blueBtn right" @click="showLayer('reject')">수신거부 목록 확인하기</button>
                </div>

            </div>

            <div style="font-size:16px;">
                <label><input type="checkbox" class="left" style="width:16px; height:16px; margin:3px 5px 0 0;" v-model="isWarnHide"> 다시보지 않기</label>
            </div>

            <div class="layerBtnArea mgT10">
                <button class="blueBtn" @click="hideLayer('warn')">확인</button>
            </div>
        </div>

        <div class="layer deleAlt" v-if="isAdvReject" v-drt-center="isAdvReject">
            <div class="layerTitle">수신거부 목록(자동등록)</div>

            <div class="layerMain">
                <div class="layerMainText red bold">등록된 번호는 광고문자 발송 시 자동으로 차단됩니다.</div>

                <table class="tableList">
                    <tr>
                        <th>No</th>
                        <th>휴대전화번호</th>
                        <th>등록일</th>
                    </tr>
                    <tr class="" v-for="r in arr.rejectList">
                        <td>{{r.SEQ_NO}}</td>
                        <td>{{r.REJE_RCPT_CNO.mask()}}</td>
                        <td>{{r.LAST_CHNG_DT.substring(0, 16)}}</td>
                    </tr>
                    <tr v-if="!arr.rejectList.length">
                        <td colspan="3">등록된 수신거부 목록이 없습니다.</td>
                    </tr>
                </table>
            </div>

            <div class="layerBtnArea mgT10">
                <button class="blueBtn" @click="hideLayer('reject')">확인</button>
            </div>
        </div>

        <EmojiLayer ref="emojiLayer" :target="$refs.emoji" :carets="$caret" />

        <UmsSendLayer ref="umsSendLayer" :custList="arr.custList" :SEND_CAPA_CNT="sendInfo.SEND_CAPA_CNT" :COIN_BAL_AMT="sendInfo.COIN_BAL_AMT" @layer-callback="layerCallback" />
</template>
<script >
    import store from '@/store'

    import UmsLeftMenu from '@/types/ums_leftmenu'

    import JSDPageCtrl from '@/components/PageCtrl.vue'
    import CustSelect from '@/components/CustSelect.vue'
    import GroupSelect from '@/components/GroupCustSelect.vue'
    import UmsSendPreview from '@/components/UmsSendPreview.vue'
    import EmojiLayer from '@/components/EmojiLayer.vue'
    import UmsSendLayer from '@/components/UmsSendLayer.vue'
    import RecentSendList from '@/components/RecentSendList.vue'
    import InfiniteScroll from '@/components/InfiniteScroll.vue'

    import UmsService from '@/service/UmsService'
    import UserService from '@/service/UserService'
    import UploadService from '@/service/UploadService'

    const _ = require('lodash')
    export default {
        name: 'UmsSend',
        components: {
            JSDPageCtrl,
            CustSelect,
            GroupSelect,
            UmsSendPreview,
            EmojiLayer,
            UmsSendLayer,
            InfiniteScroll,
            RecentSendList,
        },
        data() {
            return {
                api: {
                    list: {
                        CATE_NO: -8,
                        // VIEW_TYPE - 1:SENT, 2:IMAGE
                        VIEW_TYPE: 1,
                        //MEDIA_TYPE_CD - 1:SMS 2:LMS 3:MMS 4:IMAGE 5:FILE 6:TEXT 7:HTML
                        MEDIA_TYPE_CD: 1,
                        CNT_PAGE: 20
                    },
                    send: {
                        IS_RESV: false,
                        IS_SMT: false,
                        SEND_INFO: [],
                        SEND_NO: '',
                        UMS_TYPE_CD: '01',
                        TITLE: '',
                        MESG: '',
                        MMSImageNo1: '',
                        MMSImageNo2: '',
                        MMSImageNo3: '',
                        CUST_DATA: '',
                    },
                    preview: {
                        TITLE: '',
                        MESG: '',
                        CUST_DATA: '',
                        CNT_PAGE: 1,
                    }
                },
                arr: {
                    list: [],
                    imgList: [],
                    custList: [],
                    selectList: [],
                    phoneList: [],
                    fileList: [],
                    rejectList: [],
                    // InfiniteScroll용 배열
                    render:[],
                },
                page: {
                    val: 1,
                    maxCnt: 1,
                },
                limit: {
                    size: 300 * 1024, // 300KB
                    cnt: 3,
                },
                sendInfo: {},
                searchKey: '',
                searchPhone: '',
                isPreview: false,
                isImgUpload: false,
                isUploading: false,
                isPushAble: false,
                isAdvWarn: false,
                isAdvReject: false,
                isWarnHide: false,
                isSending: false,
            }
        },
        computed: {
            enableSendDate() {
                return store.getters.getEnableSendDate.toDate('yyyy-MM-dd')
            },
            isNoti() {
                return new Date() < this.enableSendDate
            },
            isAdv() {
                return this.$route.params.type == 'adv'
            },
            smsLimit() {
                return this.api.send.IS_SMT ? 140 : 90
            },
            msgLimit() {
                return this.isMms ? 2000 : this.smsLimit
            },
            isMms() {
                return this.msgLen > this.smsLimit || this.arr.imgList.length
            },
            msgLen() {
                return `${this.api.send.MESG}${this.isAdv ? '(광고)\n\n무료거부 080-880-0141' : ''}`.koLen(this.api.send.IS_SMT)
            },
            coinCalc() {
                return this.arr.custList.length * (this.isMms ? (this.arr.imgList.length > 0 ? this.sendInfo.MMS_AMT : this.sendInfo.LMS_AMT) : this.sendInfo.SMS_AMT)
            },
        },
        methods: {
            init() {
                this.$setTitle('')
                this.$setMainBtn(`+ ${this.isAdv ? '광고' : ''}문자 발송`)
                this.$setMainBtnCallback(() => {
                    location.href = `/app/ums${this.isAdv ? '/adv' : ''}`
                })
                this.$setSideTitle(`${this.isAdv ? '광고' : ''}문자 발송`)
                this.$setSideBtns(this.isAdv ? UmsLeftMenu.advList : UmsLeftMenu.menu_list)
                this.$setSideBtnsCallback((r) => {
                    if (this.isAdv) {
                        if (r.hasOwnProperty('type')) {
                            if (r.type == 'kisa') {
                                window.open('https://www.kisa.or.kr/skin/doc.html?fn=20220105_2030301_19.pdf&rs=/result/2022-01/', '_blank')
                            }
                            else if (r.type == 'warn') {
                                this.showLayer('warn')
                            }
                            else if (r.type == 'reject') {
                                this.showLayer('reject')
                            }
                        }
                        else {
                            if (r.name != '도움말') {
                                this.$movePage(r.path)
                            }
                            else {
                                this.$moveHelp('문자')
                            }
                        }
                    }
                    else {
                        if (r.name != '도움말') {
                            this.$movePage(r.path)
                        }
                        else {
                            this.$moveHelp('문자')
                        }
                    }
                })
                this.$setTreeSelect(`${this.api.list.VIEW_TYPE}-${this.api.list.CATE_NO}`)
                this.$setTreeDataCallback((r) => {
                    this.api.list.VIEW_TYPE = r.VIEW_TYPE
                    this.api.list.CATE_NO = r.CONT_CATE_NO
                    
                    this.$setTreeSelect(`${this.api.list.VIEW_TYPE}-${this.api.list.CATE_NO}`)

                    this.movePage(1)
                })

                this.getSendInfo()
                this.getPhoneList()
                this.getPushInfo()

                this.$nextTick(() => {
                    this.getCate()
                    this.getList()

                    if (sessionStorage.hasOwnProperty('custList')) {
                        this.arr.custList = JSON.parse(sessionStorage.getItem('custList'))
                        sessionStorage.removeItem('custList')
                        this.render(true)
                    }

                    if (this.isAdv && !this.$cookie.getCookie('JSD_ADV_HIDE')) {
                        this.showLayer('warn')
                    }
                })
            },
            async getPushInfo() {
                const { data } = await this.$root.getPushAble()
                this.isPushAble = data[0].APP_PUSH_YN == 'Y'
            },
            getCate() {
                UmsService.umsContCate((res) => {
                    /*
                     * [res.data]
                     * UMS: 글 보관함 전체
                     * IMG: 샘플 이미지
                    */
                    const { data } = res

                    data.UMS.forEach((r) => {
                        r.VIEW_TYPE = 1
                        r.title = r.CONT_CATE_NM
                        r.value = `${r.VIEW_TYPE}-${r.CONT_CATE_NO}`
                    })
                    data.IMG.forEach((r) => {
                        r.VIEW_TYPE = 2
                        r.title = r.CONT_CATE_NM
                        r.value = `${r.VIEW_TYPE}-${r.CONT_CATE_NO}`
                    })

                    const sent = data.UMS.toTree('CONT_CATE_NO', 'UP_CATE_NO', 'DEPTH')
                    const img = data.IMG.toTree('CONT_CATE_NO', 'UP_CATE_NO', 'DEPTH')

                    this.$setTreeData([
                        { title: '글 보관함 전체', value: 1, childs: sent, isOpen: true },
                        { title: '샘플 이미지', value: 2, childs: img, isOpen: false },
                    ])
                })
            },
            getList() {
                UmsService.umsContList(this.page.val, this.api.list, (res) => {
                    this.arr.list = res.data[0]
                    this.arr.list.forEach((r) => {
                        if (!r.MMS_IMAGE1) {
                            r.MMS_IMAGE1 = ''
                        }
                    })
                    this.page.maxCnt = this.arr.list.length > 0 ? this.arr.list[0].MAX_CNT : 1
                    this.$nextTick(() => {
                        this.$refs.pageCtrl.set();
                    })
                })
            },
            movePage(page) {
                this.page.val = page
                this.getList()
            },
            search(type) {
                this.api.list.MEDIA_TYPE_CD = type
                this.movePage(1)
            },
            getSendInfo() {
                UmsService.ums_send_info((res) => {
                    this.sendInfo = res.data
                })
            },
            getPhoneList() {
                UserService.mypage_phone_list((res) => {
                    this.arr.phoneList = res.data
                    this.api.send.SEND_NO = this.arr.phoneList.find((r) => r.IS_DEFAULT).SEND_PHON_CNO
                })
            },
            preview() {
                if (this.valid()) {
                    const custList = []
                    this.arr.custList.forEach((r) => {
                        custList.push({
                            ...r
                        })
                    })
                    console.log(custList)

                    this.isPreview = true

                    this.api.preview.TITLE = this.api.send.TITLE
                    this.api.preview.MESG = this.api.send.MESG

                    this.$refs.UmsSendPreview.view(custList)
                }
            },
            setBookMark(r) {
                // 즐겨찾기 추가 및 해제
                UmsService.umsBookmark({
                    CMD: r.IS_BKMR ? 'D' : 'I',
                    CONT_NO: r.CONT_NO,
                    BKMR_TYPE_CD: r.BKMR_TYPE_CD,
                    MEDIA_TYPE_CD: r.MEDIA_TYPE_CD,
                    MMS_TITLE: r.MMS_TITLE,
                    MMS_CONT: r.MMS_CONT,                    
                }, (res) => {
                    const { data } = res
                    if (!data.RESULT) {
                        let mesg = data.RESULT_MESG

                        if (mesg.length <= 0) {
                            mesg = '즐겨찾기 설정 변경을 실패했습니다.'
                        }
                        alert(mesg)                        
                    }
                    else {
                        this.getCate()
                        this.getList()
                    }
                })
            },
            renderImg(imgUrl) {
                return `${process.env.NODE_ENV == 'development' ? 'https://test.jsdsms.co.kr' : ''}${imgUrl}`
            },
            addImg(r) {
                if (this.arr.imgList.length < this.limit.cnt) {
                    r.IMG_URL = r.WEB_IMG_URL || r.MMS_IMAGE1
                    this.arr.imgList.push(r)
                }
                else {
                    alert(`이미지는 최대 ${this.limit.cnt}개까지 첨부 가능합니다.`)
                }
            },
            removeImg(idx) {
                if (confirm('이미지를 삭제하시겠습니까?')) {
                    this.arr.imgList.splice(idx, 1)
                }
            },
            imgInit() {
                // 첨부 레이어 초기화
                this.isImgUpload = false
                this.arr.fileList.splice(0, this.arr.fileList.length)
                const elFile = document.querySelector('#upFile')
                if (elFile) {
                    elFile.value = ''
                }
            },
            getFile() {
                const elFile = document.querySelector('#upFile')
                if (elFile && elFile.files.length) {
                    return elFile.files
                }
                return []
            },
            getImg() {
                const files = Array.from(this.getFile())
                if (files.length > this.limit.cnt) {
                    files.splice(this.limit.cnt, files.length)
                    alert(`이미지는 최대 ${this.limit.cnt}개까지 첨부 가능합니다.\n초과해서 선택한 건은 제외됩니다.`)
                }
                files.forEach((r) => {
                    console.log(r, r.size > this.limit.size)
                    if (r.size > this.limit.size) {
                        alert(`파일 ${r.name}의 용량이 첨부 가능한 크기를 초과했습니다.`)
                        return
                    }
                    this.arr.fileList.push(r)
                })
                
            },
            upload() {
                // 중복 업로드 방지
                if (!this.isUploading) {
                    this.arr.fileList.splice(3 - this.arr.imgList.length, this.arr.fileList.length);

                    const formData = new FormData()
                    this.arr.fileList.forEach((r) => {
                        formData.append('upFiles', r)
                    })

                    this.isUploading = true

                    UploadService.imgBulkUpload(formData, (res) => {
                        const { RESULT, DATA } = res.data
                        this.isUploading = false
                        if (RESULT) {
                            if (DATA.length) {
                                DATA.forEach((r) => {
                                    r.IMG_URL = r.URL
                                    this.arr.imgList.push(r)
                                })
                                this.imgInit()
                            }
                        }
                        else {
                            alert('이미지 업로드에 실패했습니다.')
                        }
                    })
                }
            },
            select(r) {
                if (r.MEDIA_TYPE_CD != '1' || this.isMms) {
                    this.api.send.TITLE = r.MMS_TITLE
                }
                this.api.send.MESG = r.MMS_CONT
            },
            contUpdate(e, type) {
                if (type == 'title') {
                    this.api.send.TITLE = e.target.value
                }
                else {
                    this.api.send.MESG = e.target.value
                }

                if (this.msgLen > this.msgLimit) {
                    this.api.send.MESG = this.api.send.MESG.koCut(2000)
                }
            },
            custSearch(isDirect) {
                if (isDirect) {
                    const phoneList = []
                    for (var i = 0; i < this.arr.custList.length; i++) {
                        phoneList.push(this.arr.custList[i].PHONE_CNO)
                    }

                    if (!isNaN(this.searchPhone)) {
                        this.searchPhone = this.searchPhone.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-")
                    }
                    if (this.searchPhone.isHP()) {
                        if (!new Set(phoneList).has(this.searchPhone)) {
                            this.arr.custList.push({
                                CUST_NO: 0,
                                PHONE_CNO: this.searchPhone,
                                CUST_NM: '',
                            })
                        }
                    }
                    else {
                        alert('휴대폰 번호 형식이 올바르지 않습니다.')
                    }
                    this.render(false)
                    this.searchPhone = ''
                }
                else {
                    if (!isNaN(this.searchKey)) {
                        this.searchKey = this.searchKey.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-")
                    }
                    this.$refs.CustSelect.search()
                }
            },
            groupSearch() {
                this.arr.selectList.splice(0, this.arr.selectList.length)
                this.arr.custList.forEach((r) => {
                    this.arr.selectList.push(r.CUST_NO)
                })
                this.$refs.GroupSelect.view(this.arr.selectList)
            },
            custSelect(r) {
                if (r.hasOwnProperty('CUST_NO')) {
                    const custList = []
                    const phoneList = []
                    for (var i = 0; i < this.arr.custList.length; i++) {
                        custList.push(this.arr.custList[i].CUST_NO)
                        phoneList.push(this.arr.custList[i].PHONE_CNO)
                    }

                    if (!new Set(custList).has(r.CUST_NO) && !new Set(phoneList).has(r.PHONE_CNO)) {
                        this.arr.custList.push({
                            CUST_NO: r.CUST_NO,
                            PHONE_CNO: r.PHONE_CNO,
                            CUST_NM: r.CUST_NM
                        })
                    }
                    this.searchKey = ''
                    this.searchPhone = ''
                }

                this.render(false)
            },
            custRemove(item) {
                console.time('remove')
                this.arr.render.forEach((r, idx) => {
                    if (r.CUST_NO == item.CUST_NO && r.PHONE_CNO == item.PHONE_CNO) {
                        this.arr.render.splice(idx, 1)
                    }
                })
                this.arr.custList.forEach((r, idx) => {
                    if (r.CUST_NO == item.CUST_NO && r.PHONE_CNO == item.PHONE_CNO) {
                        this.arr.custList.splice(idx, 1)
                    }
                })
                console.timeEnd('remove')
            },
            groupSelect(groupList) {
                // 직접선택한 고객을 제외한 나머지는 지우고 다시 추가해줌
                this.arr.custList = this.arr.custList.filter((r) => r.CUST_NO == 0)

                // 중복방지 배열
                const custList = []
                const phoneList = []
                for (var i = 0; i < this.arr.custList.length; i++) {
                    custList.push(this.arr.custList[i].CUST_NO)
                    phoneList.push(this.arr.custList[i].PHONE_CNO)
                }
                // Set을 루프내에서 매번 생성하면 부하가 큼
                const custSet = new Set(custList)
                const phoneSet = new Set(phoneList)

                groupList.forEach((r) => {
                    if (!custSet.has(r.CUST_NO) && !phoneSet.has(r.PHONE_CNO)) {
                        this.arr.custList.push({
                            CUST_NO: r.CUST_NO,
                            PHONE_CNO: r.PHONE_CNO,
                            CUST_NM: r.CUST_NM
                        })
                    }
                })
                this.render(true)
            },
            recentSelect(data) {
                data.forEach((r) => {
                    this.arr.custList.push({
                        CUST_NO: r.CUST_NO,
                        PHONE_CNO: r.PHONE_CNO,
                        CUST_NM: r.CUST_NM
                    })
                })
                this.render(true)
            },
            render(isInit) {
                if (!this.isLoading) {
                    this.isLoading = true

                    // 초기에만 100건 렌더
                    const cnt = isInit ? 100 : 50
                    if (isInit) {
                        this.arr.render.splice(0, this.arr.render.length)
                    }

                    for (var i = 0; i < cnt; i++) {
                        if (this.arr.render.length >= this.arr.custList.length || i >= this.arr.custList.length) {
                            this.isLoading = false
                            return
                        }
                        this.arr.render.push(this.arr.custList[this.arr.render.length])
                    }
                    this.isLoading = false
                }
            },
            changeSendType(isSmt) {
                if (isSmt && !this.isPushAble) {
                    alert('어플의 동작을 확인해주세요.')
                    return
                }
                this.api.send.IS_SMT = isSmt
            },
            valid() {
                if (!this.api.send.MESG.trim().length) {
                    alert('내용을 입력해주세요.')
                    return false
                }
                if (!this.arr.custList.length) {
                    alert('선택된 고객이 없습니다.')
                    return false
                }

                const custList = this.arr.custList.filter((r) => { r.CUST_NO == 0 })
                if (this.api.send.MESG.indexOf('[%호칭%]') > -1 && custList.length) {
                    alert('직접 입력한 고객이 있을 경우 호칭 삽입이 불가능합니다.')
                    return false
                }

                return true
            },
            send(isResv) {
                if (this.isSending) {
                    alert(`${this.api.send.IS_RESV ? '예약' : '발송'}처리 중입니다.\n잠시후 다시 시도해 주세요.`)
                    return
                }
                if (this.$refs.umsSendLayer.IS_STOP) {
                    this.$refs.umsSendLayer.show('stop')
                    return
                }
                if (this.valid()) {
                    const cust = []
                    this.api.send.IS_RESV = isResv
                    this.arr.custList.forEach((r) => {
                        cust.push({
                            CNO: r.CUST_NO,
                            RN: r.PHONE_CNO,
                        })
                    })

                    this.api.send.CUST_DATA = JSON.stringify(cust)
                    this.api.send.SEND_INFO = ''
                    this.arr.imgList.forEach((r, idx) => {
                        console.log(`MMSImageNo${idx + 1}`)
                        this.api.send[`MMSImageNo${idx + 1}`] = `D:${r.IMG_URL.reAll('/', '\\')}`
                    })

                    if (this.api.send.IS_SMT) {
                        if (isResv) {
                            this.$refs.umsSendLayer.getSplitInfo(new Date())
                        }
                        else {
                            if (this.arr.custList.length > this.sendInfo.SEND_CAPA_CNT) {
                                this.$refs.umsSendLayer.getExceed()
                            }
                            else {
                                //this.umsSend()
                                this.$refs.umsSendLayer.show('send')
                            }
                        }
                    }
                    else {
                        if (this.sendInfo.COIN_BAL_AMT < this.coinCalc) {
                            this.$refs.umsSendLayer.getCoin(this.coinCalc)
                        }
                        else {
                            if (isResv) {
                                this.$refs.umsSendLayer.getResv(new Date())
                            }
                            else {
                                //this.umsSend()
                                this.$refs.umsSendLayer.show('send')
                            }
                        }
                    }
                }
            },
            async layerCallback({ type, data }) {
                // 폰발송 가능 건수만큼 폰발송 후 웹발송 프로세스
                if (type == 'exceed') {
                    const cust = this.arr.custList.map((r) => { return { CUST_NO: r.CUST_NO, PHONE_CNO: r.PHONE_CNO } })
                    const coinList = cust.splice(this.sendInfo.SEND_CAPA_CNT, cust.length)

                    const reqPhone = _.cloneDeep(this.api.send)
                    const reqCoin = _.cloneDeep(this.api.send)

                    reqPhone.IS_RESV = false
                    reqPhone.IS_SMT = true
                    reqPhone.CUST_DATA = JSON.stringify(cust.map((r) => {
                        return {
                            CNO: r.CUST_NO,
                            RN: r.PHONE_CNO
                        }
                    }))

                    reqCoin.IS_RESV = false
                    reqCoin.IS_SMT = false
                    reqCoin.CUST_DATA = JSON.stringify(coinList.map((r) => {
                        return {
                            CNO: r.CUST_NO,
                            RN: r.PHONE_CNO
                        }
                    }))

                    const resPhone = await UmsService.umsSend2(reqPhone)
                    const resCoin = await UmsService.umsSend2(reqCoin)

                    const result = []
                    if (resPhone.data.RESULT) {
                        result.push(`폰발송 ${resPhone.data.SMT_CNT}건`)
                    }
                    if (resCoin.data.RESULT) {
                        result.push(`웹발송 ${resCoin.data.SMS_CNT + resCoin.data.MMS_CNT + resCoin.data.MMS_IMG_CNT}건`)
                    }

                    if (result.length) {
                        alert(`${result.join(',')}이 발송되었습니다.`)
                        this.clear()
                    }
                }
                else if (type == 'split') {
                    const dateInfo = []
                    if (!data.hasOwnProperty('list')) {
                        alert('분산 발송 데이터 처리 중 오류가 발생했습니다')
                        return
                    }
                    data.list.forEach((r) => {
                        dateInfo.push({
                            SEND_DA: r.DATE.toStr('yyyyMMdd'),
                            SEND_TM: r.DATE.toStr('HH:mm'),
                            CNT: r.SEND_CNT
                        })
                    })
                    this.api.send.SEND_INFO = dateInfo.length ? JSON.stringify(dateInfo) : ''
                    this.umsSend()
                }
                else if (type == 'resv' || type == 'resv:save') {
                    if (!data.hasOwnProperty('date')) {
                        alert('예약 발송 데이터 처리 중 오류가 발생했습니다')
                        return
                    }
                    // 예약발송 처리
                    this.api.send.SEND_INFO = JSON.stringify([{
                        SEND_DA: data.date.toStr('yyyyMMdd'),
                        SEND_TM: data.date.toStr('HH:mm'),
                        CNT: this.arr.custList.length,
                    }])
                    this.umsSend(data.isSave)
                }
                else if (type == 'send' || type == 'send:save') {
                    this.umsSend(data.isSave)
                }
            },
            umsSend(isSave) {
                this.isSending = true
                if (this.isAdv) {
                    this.api.send.MESG = `(광고)\n${this.api.send.MESG}\n무료거부 080-880-0141`
                }
                UmsService.umsSend(this.api.send, (res) => {
                    const { RESULT, RESULT_MESG } = res.data
                    if (RESULT > 0) {
                        alert(`${this.api.send.IS_RESV ? '예약' : '발송'}되었습니다.`)
                        if (isSave) {
                            this.setBookMark({
                                IS_BKMR: false,
                                CONT_NO: -1,
                                BKMR_TYPE_CD: 2,
                                MEDIA_TYPE_CD: this.isMms ? '2' : '1',
                                MMS_TITLE: this.api.send.TITLE,
                                MMS_CONT: this.api.send.MESG,
                            })
                        }
                        this.clear()
                    }
                    else {
                        alert(RESULT_MESG)
                    }
                    this.isSending = false
                })
            },
            clear() {
                this.imgInit()
                this.arr.imgList.splice(0, this.arr.imgList.length)
                this.arr.custList.splice(0, this.arr.custList.length)
                this.arr.render.splice(0, this.arr.render.length)

                this.api.send.IS_RESV = false
                this.changeSendType(false)
                this.api.send.SEND_INFO = []
                this.api.send.SEND_NO = this.arr.phoneList.find((r) => r.IS_DEFAULT).SEND_PHON_CNO
                this.api.send.UMS_TYPE_CD = '01'
                this.api.send.TITLE = ''
                this.api.send.MESG = ''
                this.api.send.MMSImageNo1 = ''
                this.api.send.MMSImageNo2 = ''
                this.api.send.MMSImageNo3 = ''
                this.api.send.CUST_DATA = []

                this.getSendInfo()
            },
            showLayer(type) {
                if (type == 'warn') {
                    this.isAdvWarn = true
                }
                else {
                    this.isAdvReject = true
                    UmsService.umsRejectList((res) => {
                        const { data } = res

                        this.arr.rejectList = data[0]
                    })
                }
            },
            hideLayer(type) {
                if (type == 'warn') {
                    this.isAdvWarn = false
                    if (this.isWarnHide) {
                        this.$cookie.setCookie('JSD_ADV_HIDE', true, { expire: -1 })
                        this.isWarnHide = false
                    }
                }
                else {
                    this.isAdvReject = false
                }
            },
        },
        mounted() {
            this.init()
        },
    }
</script>

<style scoped>
    .adSms{
        overflow-y: hidden!important;
    }
    .infiniteScrollBox {
        overflow-y: auto!important;
        height: calc(100% - 110px);
        margin-right: -20px;
        padding-right: 20px;
    }
</style>