
    import store from '@/store'
    import LeftMenu from '@/types/mypage_leftmenu'
    import UserService from '@/service/UserService'

    declare global {
        interface Window {
            cert: any
        }
    }

    window.cert = {
        popup: {},
        data: {}, // 승인요청한 데이터
        request: function (acntNo) {
            if (process.env.NODE_ENV == 'development') {
                alert('개발환경에서는 휴대폰 인증 기능이 제한됩니다.')
                return;
            }

            this.popup = window.open(`${process.env.VUE_APP_DOMAIN}/pay/cp/Cert.aspx?type=cp&IS_AUTH=0&ACNT_NO=${acntNo}`, 'popupCert', 'width=500, height=500, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        },
        callback: function (isSuccess, data) {
            if (isSuccess) {
                this.popup.close();

                window.$$broadcast('certCallback', {
                    IS_CERT: this.data.SEND_PHON_CNO == data.cvPhone(),
                    SEND_PHON_CNO: data.cvPhone(),
                })
            }
        },
        error: function (err) {
            alert(err)
            this.popup.close();
        }
    }

    export default {
        name: 'MySend',
        components: {
        },
        data() {
            return {
                ui: {
                    layer_del: false,           //번호 삭제용 레이어                    
                    layer_etc: false,            //휴대폰아닌번호 등록시                    
                    timeCounter: 0,             //인증처리시간
                    resTimeData: '',           //인증남은시간
                    cert_phone_cno : '',        //인증처리중인 번호

                },
                api: {
                    update: {                   //발신번호 등록/수정/삭제
                        CMD: '',
                        SEND_PHON_CNO: '',
                    },
                    def_change: {
                        phone_no : 0,
                    }
                },
                arr: {
                    phone_list:[],
                }
            }
        },
        computed: {

        },
        methods: {
            init() {
                this.$setTitle('발신번호 설정');
                this.$setSideTitle('My 페이지');

                //메뉴 리스트에서 가져온다.
                this.$setSideBtns(LeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        // 도움말 기능 처리
                    }
                });
                this.$nextTick(() => {
                    this.getList()
                })

                this.$$on(this, 'certCallback', (r) => {
                    const { detail } = r

                    if (detail[0].IS_CERT) {
                        UserService.mypage_phone_update({
                            CMD: 'I',
                            SEND_PHON_CNO: detail[0].SEND_PHON_CNO
                        },
                            (res) => {
                                if (res.data.RESULT > 0) {
                                    alert('인증되었습니다.')

                                    this.$root.sessionUpdate()
                                    //승인되었으니 화면 reload
                                    this.$nextTick(() => { this.getList() })
                                }
                                else {
                                    alert('인증에 실패했습니다.(' + res.data.RESULT_MESG + ')')
                                }
                            }
                        )
                    }
                    else {
                        alert('휴대폰 정보가 일치하지 않습니다.')
                    }
                })
            },
            getList() {
                //발신번호 리스트
                UserService.mypage_phone_list(
                    (res) => {
                        
                        this.arr.phone_list = res.data.map((r) => {
                            r.CRTF_TXT = ''
                            r.CERT_LAYER = false
                            r.IS_NEW = false
                            return r
                        })

                        //하단의 신규 추가를 위한 row값
                        this.arr.phone_list.push({
                            PHONE_NO: -1,
                            SEND_PHON_CNO: '',
                            SEND_APVL_CD: '',
                            SEND_APVL_CDNM: '',
                            IS_DEFAULT: 0,
                            IS_JSD_ASGN: 0,
                            REG_DT: '',
                            APLY_DT: '',
                            CRTF_TXT: '',                  //승인번호처리를위한 컬럼
                            CERT_LAYER: false,
                            IS_NEW: true,
                        });                        
                        
                        //현재 기본발신번호를 지정한다.
                        let phone = this.arr.phone_list.filter(x => x.IS_DEFAULT == 1);
                        this.api.def_change.phone_no = phone[0].PHONE_NO;
                        //console.log(this.api.def_change.phone_no)
                    }
                );
            },
            phone_add( obj:Object) {
                //화면상에 발신번호 추가 
                // 번호검사 -> 등록 -> 휴대폰이면 인증절차 / 휴대폰이 아니면 관리자 승인 요청
                let phone_cno: string = obj['SEND_PHON_CNO'];
                let is_phone: Boolean = (phone_cno.substring(0, 3) == '010' ? true : false);
                //console.log(phone_cno)
                //console.log(is_phone);
                

                //휴대전화번호 체크
                if (is_phone) {
                    if (!phone_cno.isHP(false)) {
                        alert('올바른 휴대폰 번호를 입력하세요');
                        this.$nextTick(() => { this.$refs.SEND_PHON_CNO.focus(); });
                        return false;
                    }
                }
                else {
                    if (!phone_cno.isPhone(false) ) {
                        alert('올바른 전화 번호를 입력하세요');
                        this.$nextTick(() => { this.$refs.SEND_PHON_CNO.focus(); });
                        return false;
                    }
                }
                //같은번호를 추가 등록되지 않게 한다.
                let exists_phone = this.arr.phone_list.filter(x => x.IS_NEW == 0 && x.SEND_PHON_CNO == phone_cno);
                if (exists_phone.length > 0 ) {
                    alert('동일한 번호를 등록하실수 없습니다.');
                    obj['SEND_PHON_CNO'] = '';
                    return false;
                }

                //다른 인증-승인번호 처리중이면 더이상 처리 안되게 한다.
                for (var i = 0; i < this.arr.phone_list.length; i++) {
                    if (this.arr.phone_list[i].CERT_LAYER) {
                        alert('발신번호 인증을 완료하시거나 취소후 신규번호 등록을 해주세요.');
                        return;
                    }
                }

                if (is_phone) {
                    if (this.$root.user && this.$root.user.ACNT_NO > -1) {
                        window.cert.data = obj
                        window.cert.request(this.$root.user.ACNT_NO)
                    }
                    else {
                        alert('로그인 정보를 찾을 수 없습니다.')
                    }
                }
                else {
                    //폰번호등록하고 
                    this.api.update.CMD = 'I';
                    this.api.update.SEND_PHON_CNO = phone_cno;

                    UserService.mypage_phone_update(
                        this.api.update,
                        (res) => {
                            if (res.data.RESULT > 0) {

                                //휴대폰이면 승인요청까지
                                if (is_phone) {
                                    this.ui.layer_etc = false;
                                }
                                else {
                                    this.ui.layer_etc = true;       //통신이용증명원 제출
                                    obj['CERT_LAYER'] = false;
                                }
                                //등록처리후 화면 reload한다.
                                this.$nextTick(() => {
                                    this.getList()
                                })
                            }
                            else {
                                alert('발신번호 등록에 실패했습니다.(' + res.data.RESULT_MESG + ')');
                            }
                        }
                    )
                }
            },
            change_default(obj:Object) {
                //기본 발신번호 변경하기
                let phone_cno: string = String(obj['SEND_PHON_CNO'])

                this.api.update.CMD = 'A'
                this.api.update.SEND_PHON_CNO = phone_cno

                // 기본 발신번호 변경하기
                UserService.mypage_phone_update(
                    this.api.update,
                    (res) => {
                        if (res.data.RESULT > 0) {
                            this.$nextTick(() => {
                                this.getList()
                            })                            
                        }
                        else {
                            alert('기본 발신번호 변경에 실패했습니다.(' + res.data.RESULT_MESG + ')');
                        }
                    }
                )

            },
            phone_del_layer( bFlag : boolean,  obj : Object) {
                // 현재 선택한 번호 삭제하기
                //  기본발신번호는 삭제못한다.                
                if (bFlag && obj['IS_DEFAULT'] == 1) {
                    alert('기본 발신번호는 삭제할수 없습니다. 기본 발신번호를 다른 번호로 변경후 삭제하세요.');
                    return;
                }
                this.ui.layer_del = bFlag;
                this.api.update.SEND_PHON_CNO = obj['SEND_PHON_CNO'];                
            },
            phone_del() {

                this.api.update.CMD = 'D';                
                // 발신번호 삭제하기
                UserService.mypage_phone_update(
                    this.api.update,
                    (res) => {
                        //console.log('mypage_phone_update')
                        //console.log(res.data)

                        this.ui.layer_del = false;

                        if (res.data.RESULT > 0) {
                            
                            this.$nextTick(() => {this.getList()})
                        }
                        else {                            
                            alert('발신번호 삭제에 실패했습니다.(' + res.data.RESULT_MESG + ')');                            
                        }
                    }
                )
            },
            phone_cert(obj : object) {
                //발신번호 인증하기                
                //0	미승인 1:핸드폰승인요청 2:핸드폰승인 3:관리자승인요청 4:관리자승인 5:관리자승인취소
                let apvl_cd: string = String( obj['SEND_APVL_CD'])
                let phone_cno: string = String(obj['SEND_PHON_CNO'])
                let is_phone: Boolean = (phone_cno.substring(0, 3) == '010' ? true : false);

                //console.log('phone_cert');
                //console.log(apvl_cd);
                //console.log(phone_cno);

                if (apvl_cd == '3') {
                    alert('관리자에게 [통신서비스 이용증명원]을 팩스나 메일로 보내시기 바랍니다. 관리자가 검토후 승인처리 됩니다.');
                    return;
                }

                //휴대폰승인요청은 처리한다.
                if (apvl_cd == '0') {

                    if (is_phone) {
                        if (this.$root.user && this.$root.user.ACNT_NO > -1) {
                            window.cert.data = obj
                            window.cert.request(this.$root.user.ACNT_NO)
                        }
                        else {
                            alert('로그인 정보를 찾을 수 없습니다.')
                        }
                    }
                    else {

                        this.api.update.CMD = 'R'
                        this.api.update.SEND_PHON_CNO = phone_cno

                        // 발신번호 삭제하기
                        UserService.mypage_phone_update(
                            this.api.update,
                            (res) => {
                                if (res.data.RESULT > 0) {
                                    obj['SEND_APVL_CD'] = '3';
                                    obj['SEND_APVL_CDNM'] = '관리자승인요청';
                                }
                                else {
                                    alert('발신번호 승인요청에 실패했습니다.(' + res.data.RESULT_MESG + ')');
                                }
                            }
                        )
                    }
                }
            },
            phone_cert_check(obj: Object) {
                let phone_cno: string = String( obj['SEND_PHON_CNO'] );
                let crtf_txt: string = String( obj['CRTF_TXT'] );

                //console.log('phone_cert_check');
                //console.log(phone_cno);
                //console.log(crtf_txt.length);
                //console.log(crtf_txt);

                //인증완료처리
                if (crtf_txt.length != 5) {
                    alert('휴대폰에 수신된 인증번호(숫자 5자리)를 정상적으로 입력해 주세요.');
                    return;
                }
                if (this.ui.timeCounter <= 0) {
                    alert('제한시간내에 입력해 주세요.');
                    return;
                }

                //인증번호 승인한다.
                this.api.update.CMD = 'C';
                this.api.update.SEND_PHON_CNO = phone_cno
                this.api.update.CRTF_TXT = crtf_txt;

                UserService.mypage_phone_update(
                    this.api.update,
                    (res) => {
                        if (res.data.RESULT > 0) {
                            alert('인증되었습니다.')
                            obj['CERT_LAYER'] = false
                            obj['CRTF_TXT'] = ''                           

                            //승인되었으니 화면 reload
                            this.$nextTick(() => { this.getList() })
                        }
                        else {
                            alert('인증에 실패했습니다.(' + res.data.RESULT_MESG + ')')                           
                        }
                    }
                );

            },
            start() {
                this.ui.timeCounter = 180;
                this.ui.resTimeData = this.prettyTime();

                // timer시작하기
                this.polling = setInterval(() => {
                    this.ui.timeCounter-- //1찍 감소
                    this.ui.resTimeData = this.prettyTime()
                    if (this.ui.timeCounter <= 0)
                        this.timeStop()
                    }, 1000)                    
            },
            prettyTime() {                
                let minutes: number = Math.floor(this.ui.timeCounter / 60);                
                let secondes = this.ui.timeCounter % 60;
                return minutes.padLeft(1,'0') + ":" + secondes.padLeft(2, '0');
            },            
            timeStop() {
                clearInterval(this.polling);
                //인증창을 close한다.
                let cert_phone = this.arr.phone_list.filter(x => x.SEND_PHON_CNO == this.ui.cert_phone_cno);

                this.api.update.CMD = 'X';
                this.api.update.SEND_PHON_CNO = this.ui.cert_phone_cno

                //미인증상태로 되될린다.
                UserService.mypage_phone_update(
                    this.api.update,
                    (res) => {
                        if (res.data.RESULT > 0) {                            
                            this.$nextTick(() => { this.getList() })
                        }
                        else {
                            alert(res.data.RESULT_MESG)
                        }
                    }
                );

                this.ui.cert_phone_cno = '';
                cert_phone[0]['CERT_LAYER'] = false;                
            },
            smsReset() {
                //sms재시작
                clearInterval(this.polling)
                this.ui.timeCounter = 180
                this.startTime();
            },            
            autoPhone(e, obj : Object) {
                //폰번호에 자동으로 -추가 
                var text = e.target.value;
                text = text.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-");                
                e.target.value = text;
                obj['SEND_PHON_CNO'] = text;                
            },
        },
        mounted() {
            this.init();
        },
    }
