<!--

프로그램 명  : TmplItemEdit.vue
프로그램 설명: 자동문자 템플릿 아이템 수정/등록
                - 자동문자의 템플릿 아이템을 수정허거나 신규 등록시 사용한다.
                - 로직분리를 위해 개발함.
                - 관리자에 있는 기능을 가져다 씀
props
    left : layer가 뜰 위치
    top : layer가 뜰 위치
    item : 연락처 필터링 조건. 이값을 parent에서 저장하고 있다가 레이어 띄울때 다시 넘기면 동일하 화면으로 뜸
        {
            CMD: '',            //I신규등록 U:수정 D:삭제
            TMPL_NO: -1,        //템플릿번호
            ITEM_NO: -1,        //아이템번호
            CUST_SEND_MON: -1,      //발송주기 월
            CUST_SEND_DAY: -1,      //발송주기 일
            CUST_SEND_TM: '09:00',  //발송시간
            TITLE: '',
            MMS_CONT: '',
            IMG_NM: '',
            IMG_PATH: '',
            IMG_URL: '',
        }

method
    view()          : parent에서 호출하는 함수, 그룹별 연락처 선택을 띄운다.
    setItemInfo()   : ITEM정보 등록하거나 수정한 결과값을 parent로 Object형태로 전달한다.
        {
            TMPL_NO: -1,        //템플릿번호
            ITEM_NO: -1,        //아이템번호
            CUST_SEND_MON: -1,      //발송주기 월
            CUST_SEND_DAY: -1,      //발송주기 일
            CUST_SEND_TM: '09:00',  //발송시간
            TITLE: '',
            MMS_CONT: '',
            IMG_NM: '',
            IMG_PATH: '',
            IMG_URL: '',
        }


작성자		 : 20211119 (주)UCRM/김태훈
수정이력)
-->

<template>
    <div class="layer imgUploadLayer" :style="{top : top+'px', left : left+'px'}" v-show="is_view">

        <div class="layerMain">
            <div class="msgListArea left" style="margin-right:20px;">
                <div class="msgListTitle overflow">
                    <div class="left">{{seqInfo}}</div>
                </div>

                <div class="templateDaySelectArea">
                    <div class="left">등록 </div>
                    <input type="number" class="dayNumInput left" min="1" max="31" v-model="ui.item.sendVal">
                    <select class="selectBox left" v-model="ui.item.sendType">
                        <option value="D">일</option>
                        <option value="M">개월</option>
                    </select>
                    <div class="left"> 후 </div>
                    <input type="time" class="left" min="09:00" max="19:00" step="900" v-model="api.CUST_SEND_TM">
                    <div class="left"> 에 발송 </div>

                </div>

                <div class="textinputLine" v-show="api.MMS_CONT.koLen() > 90">
                    <div class="left" style="margin-right:35px;">제목</div>
                    <input type="text" placeholder="제목을 입력해주세요" v-model="api.TITLE" @input="api.TITLE = $event.target.value" style="font-size:15px;">
                </div>

                <textarea class="msgSampleTextInput" placeholder="메시지를 입력해 주세요" v-model="api.MMS_CONT" @input="contUpdate" v-drt-caret-target="'caret'" @caret-update="contUpdate">
                </textarea>

                <div style="margin-bottom:10px; overflow:auto;">
                    <button class="grayBtn left mgR10" @click="$caret.insert('[%호칭%]')">호칭</button>
                    <!--<button class="grayBtn left mgR10">특수문자</button>-->
                    <div class="filebox left mgR10">
                        <label @click="isImgUpload=true"> 이미지 업로드</label>
                    </div>

                    <!--<input type="file" accept="image/*" style="width:200px;">-->
                    <div class="right smsMmsText" style="font-size:20px;">
                        <span class="red bold"> {{api.MMS_CONT.koLen() > 90 ? 'MMS' : 'SMS' }}</span>
                        {{api.MMS_CONT.koLen()}}/{{api.MMS_CONT.koLen() > 90 || ui.previewImg.length > 0 ? 2000 : 90}} byte
                    </div>
                </div>

                <div class="msgListserch">
                    <button type="button" class="grayBtn right" style="width:100%;margin-bottom:10px;" @click="preview()">미리보기</button>
                </div>

                <!--<div class="preViewArea" :class="{'show': ui.previewImg.length > 0}">
                    <img :src="ui.previewImg" @click="imgRemove()">
                </div>-->

                <div class="BtnArea">
                    <button class="blueBtn" @click="item_save();is_preview=false;isImgUpload=false;">저장</button>&nbsp;
                    <button class="grayBtn" @click="is_view=false;is_preview=false;isImgUpload=false;">취소</button>
                </div>
            </div>
        </div>
    </div>


    <!-- 첨부파일 레이어 -->
    <div class="layer addimg" v-if="isImgUpload" :style="{top : top+'px', left : (left + 550 )+'px'}" style="width:250px">
        <div class="layerTitle">이미지 첨부</div>
        <!--<div class="mgB15">이미지는 <b>한 번에 최대 3개까지<br>첨부</b>할 수 있습니다.</div>-->
        <div>
            <input type="file" id="upFile" class="addimgFile" @change="getImg()" placeholder="클릭하여 첨부할 이미지를 선택하세요" multiple accept="image/jpeg" />
        </div>

        <div class="grayBox mgB15">
            파일은 JPG 이미지 파일만 첨부할 수 있습니다.
            이미지가 (1500 픽셀 X 1500픽셀)을 초과하는 경우, 비율에 맞게 자동 축소되며
            압축으로 인한 품질의 저하가 있을 수 있습니다.
        </div>

        <div class="textCenter">
            <button class="grayBtn" @click="imgInit()">취소</button>&nbsp;
            <button class="blueBtn" @click="upload()">확인</button>
        </div>

    </div>
    <!-- 첨부파일 레이어 종료 -->


    <!-- 미리보기 레이어 -->
    <div class="layer imgUploadLayer preview" :style="{top : top+'px', left : (left + 550 )+'px'}" v-show="is_preview">
        <div class="layerMain">
            <div class="msgListArea left" style="margin-right:20px;">
                <div class="msgListTitle">
                    <div>미리보기 화면</div>
                </div>

                <div class="msgSampleTextInput" contenteditable="true">
                    <div style="text-align:center;">
                        <img :src="renderImg(ui.previewImg)" v-if="ui.previewImg.length > 0" @click="imgRemove()">
                    </div>
                    <div style="white-space:pre-wrap">
                        {{api.MMS_CONT.reAll('[%이름%]님', '철수님').reAll('[%호칭%]', '철수씨')}}
                    </div>
                </div>
                <div class="BtnArea">
                    <button type="button" class="grayBtn" @click="is_preview=false">확인</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import store from '@/store'

    import UploadService from '@/service/UploadService'

    const _ = require('lodash')

    export default {
        name: 'TmplItemEdit',
        props: {
            item_no: {              //item수정시 원본 item번호
                type: Number,
                default: -1,
            },
            item: {
                type: Object,
                default() {
                    return {
                        CMD: '',            //I신규등록 U:수정 D:삭제
                        TMPL_NO: -1,        //템플릿번호
                        ITEM_NO: -1,        //아이템번호
                        CUST_SEND_MON: -1,      //발송주기 월
                        CUST_SEND_DAY: -1,      //발송주기 일
                        CUST_SEND_TM: '10:00',  //발송시간
                        TITLE: '',
                        MMS_CONT: '',
                        IMG_NM: '',
                        IMG_PATH: '',
                        IMG_URL: '',
                        TMPL_DATA: {},
                        TMPL_ITEM: {},
                    }
                },
            }
        },
        data() {
            return {
                is_view: false,         //레이어 띄울지 여부
                is_preview: false,      //미리보기 레이어
                is_msg_preview: false,  //미리보기
                isImgUpload: false,     //이미지업로드레이어
                isUploading: false,     //업로드중
                top: 200,
                left:500,
                api: {
                    CMD : '',
                    TMPL_NO : -1,
                    ITEM_NO : -1,
                    CUST_SEND_MON : -1,
                    CUST_SEND_DAY : -1,
                    CUST_SEND_TM : '10:00',
                    TITLE  : '',
                    MMS_CONT : '',
                    IMG_NM : '',
                    IMG_PATH : '',
                    IMG_URL :  '',
                },
                ui: {
                    previewImg: '',
                    msgPreview: false,      //미리보기 실행 여부. 반드시 저장전 미리보기 후 저장되야 함.
                    item_title: '',         //item제목 ex:상생방송 시청 독려 1회차,
                    item: {
                        sendType: 'D',
                        sendVal: 1,
                        hasImg: false,
                    },
                },
                seqInfo: '',
            }
        },
        methods: {
            init() {                
            },
            setSeqInfo(v) {
                this.seqInfo = v
            },
            view() {

                this.top = document.documentElement.scrollTop + 300;

                this.is_view = true;

                this.api.CMD = this.item.CMD                //I신규등록 U:수정 D:삭제
                this.api.TMPL_NO = this.item.TMPL_NO        //템플릿번호
                this.api.ITEM_NO = this.item.ITEM_NO        //아이템번호
                this.api.CUST_SEND_MON = this.item.CUST_SEND_MON        //발송주기 월
                this.api.CUST_SEND_DAY = this.item.CUST_SEND_DAY        //발송주기 일
                this.api.CUST_SEND_TM = this.item.CUST_SEND_TM          //발송시간
                this.api.TITLE = this.item.TITLE
                this.api.MMS_CONT = this.item.MMS_CONT
                this.api.IMG_NM = this.item.IMG_NM
                this.api.IMG_PATH = this.item.IMG_PATH
                this.api.IMG_URL = this.item.IMG_URL

                //실제 ITEM수정시 전달받은 값을 처리용 api초기화 함
                this.ui.item.sendType = (this.api.CUST_SEND_MON == 0) ? 'D' : 'M'
                this.ui.item.sendVal = (this.api.CUST_SEND_MON == 0) ? this.api.CUST_SEND_DAY : this.api.CUST_SEND_MON
                this.ui.previewImg = this.item.IMG_URL
            },
            item_save() {
                //해당 아이템 저장

                if (this.valid('item')) {
                    this.api.CUST_SEND_MON = (this.ui.item.sendType == 'D') ? 0 : this.ui.item.sendVal
                    this.api.CUST_SEND_DAY = (this.ui.item.sendType == 'M') ? 0 : this.ui.item.sendVal

                    console.log(this.api);

                    this.$emit("setItemInfo", this.api);
                    this.is_view = false;
                }
            },
            imgRemove() {
                if (confirm('이미지를 제거하시겠습니까?')) {
                    this.previewImg = ''
                    this.item.hasImg = false
                    this.api.IMG_NM = ''
                    this.api.IMG_PATH = ''
                    this.api.IMG_URL = ''

                    //document.querySelector('#upFile').value = ''
                    //this.$refs.upFile.value = ''
                }
            },
            getFile() {
                const elFile = document.querySelector('#upFile')

                if (elFile && elFile.files.length > 0) {
                    return elFile.files[0]
                }
                return null
            },
            getImg() {
                const file = this.getFile()

                this.previewImg = file != null ? URL.createObjectURL(file) : ''

                this.item.hasImg = true
            },
            preview() {                
                this.is_preview = true
                this.is_msg_preview = true
            },
            upload() {
                if (!this.isUploading) {
                    const file = this.getFile()
                    const formData = new FormData()
                    formData.append('upFile', file)

                    this.isUploading = true

                    UploadService.imgUpload(formData, (res) => {
                        const data = res.data
                        
                        this.isUploading = false

                        if (data.RESULT > 0) {
                            
                            this.api.IMG_NM = data.FILE_NM
                            this.api.IMG_PATH = data.PATH
                            this.api.IMG_URL = data.URL
                            this.ui.previewImg = data.URL

                            this.imgInit()
                        }
                        else {
                            alert('이미지 업로드를 실패했습니다.')
                        }
                    })
                }
            },
            imgInit() {
                // 첨부 레이어 초기화
                this.isImgUpload = false                
                const elFile = document.querySelector('#upFile')
                if (elFile) {
                    elFile.value = ''
                }
            },

            renderImg(imgUrl) {
                return `${process.env.NODE_ENV == 'development' ? 'https://test.jsdsms.co.kr' : ''}${imgUrl}`
            },
            valid(type) {
                // 저장전 항목 체크

                if (type == 'item') {
                    //제목은 mms일때만 체크 90바이트 넘거나 이미지가 있을때만
                    if (this.api.MMS_CONT.koLen() > 90) {
                        if (this.api.TITLE.length == 0) {
                            alert('제목을 입력해주세요.')
                            return false
                        }
                    }                    
                    if (this.api.MMS_CONT.length == 0) {
                        alert('내용을 입력해주세요.')
                        return false
                    }
                    if (this.ui.item.sendVal.length == 0) {
                        alert('발송일자를 입력해주세요.')
                        return false
                    }
                    if (!this.api.CUST_SEND_TM.isDate('HH:mm')) {
                        alert('시간의 형식이 올바르지 않습니다.')
                        return false
                    }
                    if (!this.is_msg_preview) {
                        alert('미리보기 후 저장가능합니다.')
                        return false
                    }
                    return true
                }

            },
            contUpdate(e) {
                this.api.MMS_CONT = e.target.value
                this.is_msg_preview = false
            },

        },
        mounted() {
            this.init();
        },
    }
</script>

<style scoped>
</style>