<template>
    <div>
        <JSDHeader ref="headCtrl" v-if="!isPopup"></JSDHeader>
        <div v-if="isLogin" v-bind:class="{'mainArea': useSide && !isPopup}">
            <JSDLeftMenu v-if="useSide && !isPopup"></JSDLeftMenu>
            <div v-bind:class="{'contentsArea': useSide && !isPopup}">
                <div class="title" v-if="!isPopup">{{title}}</div>
                <router-view />
            </div>
        </div>
        <div class="footArea" v-if="!isPopup">
            <div class="footCenter">
                <div class="ucrmlogo left">U-CRM</div>
                <div class="ucrmInfo left">
                    <p>
                        서울특별시 영등포구 당산로41길 11 당산 SK V1 center E1004호<span class="gray">|</span>Tel <span class="gray">|</span>E-mail webmaster@u-crm.com
                        사업자등록번호 : 106-86-52246<br>
                        통신판매업 : 2015-서울특별시-0560호<span class="gray">|</span>대표 : 이삼영<span class="gray">|</span>개인정보관리 : 이삼영<span class="gray">|</span>
                        <span class="mgLeft0 gray" style="margin: 0;"> <a href="#" @click="goPrivacy(2)">이용약관</a> 및  <a href="#" @click="goPrivacy(1)">개인정보취급방침</a></span> | Copyright@U-CRM Co.,Ltd All Rights Reserved
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!isLogin">
        <router-view />
    </div>
    <div class="loading" v-if="isLoading">
        <div class="spinner"></div>
    </div>
</template>

<script>
    import api from '@/utils/api'
    import store from '@/store'

    import JSDHeader from '@/components/Header.vue'
    import JSDLeftMenu from '@/components/LeftMenu.vue'

    import AuthService from '@/service/AuthService'
    import CommonService from '@/service/CommomService'
    import MenuService from '@/service/MenuService'
    import BoardService from '@/service/BoardService'

    export default {
        name: 'jsdApp',
        components: {
            JSDHeader,
            JSDLeftMenu
        },
        computed: {
            title() {
                return store.getters.getTitle
            },
            user() {
                return store.getters.getUser
            },
            systemCode() {
                return store.getters.getSystemCode || '1'
            },
        },
        data() {
            return {
                isLogin: false,
                useSide: false,
                isPopup: false,
                isLoading: false,
                expireTime: 3600,
                invl: -1,
                IP: '',
            }
        },
        methods: {
            /* 
             * 로딩화면 표시 기능
             * this.$root.setLoading(Boolean)으로 호출
            */
            setLoading(isLoading) {
                this.isLoading = isLoading
            },
            getRouteMeta() {
                this.useSide = this.$route.meta.useSide
                this.isPopup = this.$route.meta.isPopup

                this.$nextTick(() => {
                    if (document.querySelector('.footArea')) {
                        document.querySelector('.footArea').style.minWidth = !this.useSide ? '100%' : null
                    }
                })
            },
            getMenus() {
                MenuService.list('1', (res) => {
                    const data = res.data[0]
                    store.dispatch('setMenus', { data: data.toTree('MENU_NO', 'UP_MENU_NO', 'DEPTH') })
                })
            },
            getIp() {
                AuthService.getIp((res) => {
                    const { data } = res
                    this.IP = data.IP
                })
            },
            getLogin() {
                this.isLogin = this.$cookie.isCookieAvailable('JSD_USER')
            },
            async sessionUpdate() {
                const { data } = await AuthService.userInfo()
                const { USER } = data
                if (USER.length) {
                    store.dispatch('setUser', { data: USER[0] })
                }
            },
            getSession() {
                //세션 정보를 가져온다.
                AuthService.login_jsd_session(
                    {
                        ACNT_NO: this.user.ACNT_NO
                    },
                    (res) => {
                        let acnt_no = res.data.ACNT_NO
                        if (acnt_no > -1) {
                            const user = res.data.USER[0]
                            store.dispatch('setUser', { data: user }).then(() => {
                                let token = this.$cookie.getCookie('JSD_USER')

                                //token으로 로그인한 정보가 있는지 확인하고 없으면 로그아웃
                                //이중로그인 방지처리
                                AuthService.session_exists(
                                    {
                                        SSON_ID: token,
                                        STM_CD: this.systemCode,
                                        ACNT_NO: acnt_no
                                    },
                                    (res) => {
                                        let sson_cnt = res.data[0][0].SSON_CNT
                                        this.isLogin = sson_cnt > 0

                                        //세션정보가 없을 경우 강제로그 아웃처리한다.
                                        if (sson_cnt == 0) {
                                            this.logout()
                                        }
                                        else {
                                            this.$cookie.setCookie('JSD_USER', token, { expire: '1h' })
                                        }
                                    }
                                )
                            })
                        }
                    }
                )
            },
            getHelpInfo() {
                BoardService.cateList({ VIEW_TYPE: '2', BOARD_CATE_CD: '2' }, (res) => {
                    const { data } = res

                    const json = {}

                    if (data.length > 0) {
                        data[0].forEach((r) => {
                            if (r.ORD_NO > 1) {
                                json[r.PDS_CATE_NM] = r.PDS_CATE_NO
                            }
                        })

                        store.dispatch('setHelpList', {
                            data: json
                        })
                    }
                })
            },
            resetExpire() {
                this.expireTime = 3600
            },
            checkExpire() {
                this.resetExpire()
                if (this.invl) {
                    clearInterval(this.invl)
                }
                this.invl = setInterval(() => {
                    this.expireTime -= 1
                    if (this.expireTime <= 0 || !this.$cookie.isCookieAvailable('JSD_USER')) {
                        clearInterval(this.invl)
                        this.logout()
                    }
                }, 1000)
            },
            movePage(pg) {
                this.$router.push(pg)
            },
            getPushAble() {
                return AuthService.isAble()
            },
            goPrivacy(type) {
                window.open(`/app/privacy/${type || '1'}`, 'PopPrivacy', 'width=1000,height=701')
            },
            logout() {
                //logout
                if (this.$route.meta.requireAuth) {
                    if (this.$cookie.isCookieAvailable('JSD_USER')) {
                        let token = this.$cookie.getCookie('JSD_USER');
                        //세션정보에서 삭제처리
                        AuthService.session_delete(
                            {
                                STM_CD: '1',        //1:웹사이트 2:관리자로그인 3:APP
                                ACNT_NO: this.user.ACNT_NO,
                                SSON_ID: token,
                            },
                            (res) => {
                                store.dispatch('logout').then(() => {
                                    this.$cookie.removeCookie('JSD_USER')
                                    document.cookie = `JSD_USER=; expires=${'1970-01-01'.toDate('yyyy-MM-dd').toUTCString()}`
                                    setTimeout(() => {
                                        location.href = '/app/login'
                                        if (window.opener && !window.opener.closed) {
                                            window.close()
                                        }
                                    }, 500)
                                })
                            }
                        )
                    }
                    else {
                        store.dispatch('logout').then(() => {
                            this.$cookie.removeCookie('JSD_USER')
                            document.cookie = `JSD_USER=; expires=${'1970-01-01'.toDate('yyyy-MM-dd').toUTCString()}`
                            setTimeout(() => {
                                location.href = '/app/login'
                                if (window.opener && !window.opener.closed) {
                                    window.close()
                                }
                            }, 500)
                        })
                    }
                }
            },
            getSysConf() {
                CommonService.sysconf_select('ENBL_SEND_DA', (res) => {
                    const { data } = res
                    console.log(data[0])
                    const date = data.length > 0 ? data[0].SYS_VALUE : ''
                    store.dispatch('setEnableSendDate', {
                        data: date
                    })
                })
            }
        },
        created() {
            api.interceptors.request.use(
                (config) => {
                    if (process.env.NODE_ENV == 'development') {
                        config.url = config.url.replace('api/', '')
                    }
                    this.setLoading(true)
                    this.getLogin()
                    config.headers.Authorization = `Bearer ${store.getters.getToken}`
                    this.checkExpire()
                    return config
                },
                (error) => {
                    return Promise.reject(error)
                })
            api.interceptors.response.use(
                (res) => {
                    this.setLoading(false)
                    return res
                },
                async (error) => {
                    this.setLoading(false)
                    const originalRequest = error.config;
                    if (error.response.status === 401) {
                        if (!originalRequest._retry) {
                            originalRequest._retry = true
                            api.defaults.headers.common['Authorization'] = `Bearer ${store.getters.getToken}`
                            this.getLogin()
                            return api(originalRequest)
                        }
                        else {
                            store.dispatch('logout').then(() => {
                                this.$cookie.removeCookie('JSD_USER')
                                document.cookie = `JSD_USER=; expires=${'1970-01-01'.toDate('yyyy-MM-dd').toUTCString()}`
                                setTimeout(() => {
                                    location.href = '/app/login'
                                }, 500)
                            })
                        }
                    }
                    return Promise.reject(error)
                })
            this.$nextTick(() => {
                this.getSysConf()
                this.$router.afterEach((to, from) => {
                    if (this.$cookie.isCookieAvailable('JSD_USER')) {
                        this.getMenus()
                        this.checkExpire()
                        this.getSession()
                    }
                })
            })
        },
        mounted() {
            this.getIp()
            this.$router.afterEach((to, from) => {
                this.setLoading(false)
                this.getLogin()
                this.getRouteMeta()

                if (to.name == 'Login') {
                    document.querySelector('body').classList.add('loginP')
                } else {
                    document.querySelector('body').classList.remove('loginP')
                }
            })

            this.$nextTick(() => {
                if (this.$cookie.isCookieAvailable('JSD_USER')) {
                    this.getMenus()
                    this.checkExpire()
                    this.getHelpInfo()
                }
                else {
                    this.$nextTick(() => {
                        this.logout()
                    })
                }
            })
        }
    }
</script>

<style>
    @import '../node_modules/bxslider/dist/jquery.bxslider.css';
    @import 'assets/css/jsd_main.css';
    @import 'assets/css/jsd_pop.css';

    .show {
        display: block !important;
    }

    .hide {
        display: none !important;
    }

    .layer {
        max-height: 600px;
        overflow: hidden;
        overflow-y: auto;
    }
</style>

<style scoped>
    .loading{
        position: fixed;
        background: rgba(0, 0, 0, 0.3);
        width:100%;
        height:100%;
        z-index: 999;
        top:0;
        display:flex;
        justify-content:center;
        align-items:center;
    }

    .spinner {
        position:absolute;
        padding: 0 !important;
        display: inline-block; /* Setting display to inline-block prevents the custom event from being fired multiple times at once */
        margin: 5px auto;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        border: 3px dashed white;
        position: relative;
        animation: spin 2s ease infinite;
    }

        .spinner::before {
            position: absolute;
            content: "";
            top: 0;
            left: -7px;
            width: 40%;
            height: 70%;
            background: transparent;
        }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
</style>