<!--
프로그램 명  : TrashList.vue
프로그램 설명: 연락처 휴지통 리스트
                - 휴지통 리스트 조회하고 복원한다.
작성자		 : 20211007 (주)UCRM/김태훈
수정이력)
-->
<template>
    
    <!-- 상단 검색 부분 -->
    <div class="box">
        <button class="blueBtn" @click="trash_clear();">휴지통 비우기</button>&nbsp;
        <button class="blueBtn" @click="trash_updae();">선택 연락처 복원</button>
        <div class="searchArea right">
            <input type="text" class="search" maxlength="20" placeholder="이름 휴대폰 번호 검색" v-model="api.list.SEARCH_KEY" @keyup.enter="movePage(1);">
            <div class="searchBtn" @click="movePage(1);">검색</div>
        </div>
    </div>
    
    <div class="box">

        <table class="tableList">

            <!-- 휴지통 리스트 제목-->
            <tr>
                <th style="width:5%;" class="textCenter"><input type="checkbox" v-model="ui.CHECK_ALL" @change="check_all();"></th>
                <th>이름</th>
                <th>호칭</th>

                <th>대표 연락처</th>
                <th>그룹</th>
                <th>지역</th>
                <th>도서</th>
                <th>삭제일시</th>
            </tr>

            <!-- 휴지통 리스트 -->
            <tr v-for="r in arr.trash_list">
                <td class="textCenter"><input type="checkbox" :value="r.CUST_NO" v-model="ui.CUST_NOS">&nbsp;{{r.SEQ_NO}}</td>
                <td @click="cust_layer_view(r);" style="cursor:pointer">{{r.CUST_NM}}({{r.RTNG_CD}}/{{r.CUST_AGE}}/{{ r.IS_SEX == '1' ? '남' : '여'}})</td>
                <td>{{r.VOCA_NM}}</td>
                <td>
                    {{r.PHONE_CNO}}
                    <span class="blue" v-if="r.PHONE_CNT > 0">(+{{r.PHONE_CNT}})</span>
                </td>
                <td>{{r.GROUP_NMS}}</td>
                <td>{{r.REGI_CDNM}}</td>
                <td>
                    {{r.BOOK_TITLE}}
                    <span class="blue" v-if="r.JSD_BOOK_CNT > 0">(+{{r.JSD_BOOK_CNT}})</span>
                </td>
                <td class="textCenter">{{r.LAST_CHNG_DT}}</td>
            </tr>
            <tr v-if="arr.trash_list.length == 0">
                <td colspan="8">검색결과가 없습니다.</td>
            </tr>
        </table>
        <br />
        <div class="mgT15 mgB15">
            <button class="blueBtn" @click="trash_clear();">휴지통 비우기</button>&nbsp;
            <button class="blueBtn" @click="trash_updae();">선택 연락처 복원</button>
        </div>
        <div class="mgB15">
            ※ 삭제한 모든 연락처는 30일간 휴지통에 보관되며, 그 후 완전 삭제 됩니다.
        </div>

        <!--페이징 처리-->
        <div class="conBottom">
            <JSDPageCtrl ref="pageCtrl" :page="page" :cntPage="api.list.CNT_PAGE" :maxCnt="page.maxCnt" @page-move="movePage"></JSDPageCtrl>
            <div class="rightbtn">
                <select class="selectBox left" v-model="api.list.CNT_PAGE" @change="movePage(1)">
                    <option v-for="n in arr.cnt" v-bind:value="n">{{`${n}줄 보기`}}</option>
                </select>
            </div>
        </div>

        <!--연락처 상세 보기  -->
        <JSDCustView ref="JSDCustView" :cust_no="ui.cust_layer.cust_no"></JSDCustView>
    </div>   

</template>

<script>
    import store from '@/store'
    import JSDPageCtrl from '@/components/PageCtrl.vue';
    import UserService from '@/service/UserService'
    import UserLeftMenu from '@/types/user_leftmenu'
    import JSDCustView from '@/components/CustView.vue';


    export default {
        name: 'UserTrashList',
        components: {
            JSDPageCtrl,
            JSDCustView
        },
        data() {
            return {
                ui: {
                    CHECK_ALL: false,
                    CUST_NOS: [],
                    cust_layer: {
                        cust_no: 0,
                    },
                },
                api: {
                    list: {
                        SEARCH_KEY : '',
                        CNT_PAGE: 10,                        
                    },
                    update: {
                        CMD: 'R',       //D:휴지통넣기 R:복원
                        CUST_NOS : ''   //연락처번호 ","로 연결됨
                    },
                },
                arr: {
                    trash_list: [],
                    cnt: [10, 30, 50, 70, 100, 300],
                },
                page: {
                    val: 1,
                    maxCnt: 1
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('휴지통');

                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                this.$nextTick(() => {
                    this.getList()
                });
            },
            cust_layer_view(obj) {
                //
                // 연락처 상세 보기
                //
                this.ui.cust_layer.cust_no = obj["CUST_NO"];

                //console.log('-------------');
                //console.log(this.ui.cust_layer);

                // 동기화 ...
                this.$nextTick(() => {
                    this.$refs.JSDCustView.cust_view();
                });
            },
            check_all() {
                //
                // 전체 체크 여부
                //
                if (this.ui.CHECK_ALL === true) {
                    this.ui.CUST_NOS = [];
                    for (var i = 0; i < this.arr.trash_list.length; i++) {
                        this.ui.CUST_NOS.push(this.arr.trash_list[i].CUST_NO);
                    }
                }
                else {
                    this.ui.CUST_NOS = [];
                }
            },
            getList() {
                //console.log('getList');
                // 
                // 휴지통 리스트 조회 
                // 
                UserService.trash_list(
                    this.page.val,
                    this.api.list,
                    (res) => {
                        //console.log(res.data[0]);
                        this.arr.trash_list = res.data[0];				//휴지통리스트조회

                        this.page.maxCnt = this.arr.trash_list.length > 0 ? this.arr.trash_list[0].MAX_CNT : 1
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set();
                        })
                    }
                )
            },
            trash_clear() {
                // 
                // 휴지통 비우기
                //
                if (confirm('휴지통에 있는 모든 연락처를 삭제하시겠습니까?') === false) {
                    return;
                }

                UserService.trash_clear(
                    this.api.list,
                    (res) => {                        
                        if (res.data > 0) {
                            alert('휴지통 비우기 성공했습니다.');
                            this.movePage(1);           //현재 page reload
                        }
                        else {
                            alert('휴지통 비우기 실패했습니다.');
                        }
                    }
                );
            },
            trash_updae() {
                // 
                // 복원처리
                // 
                var check_cnt = this.ui.CUST_NOS.length;

                if (check_cnt === 0 ) {
                    alert('휴지통에서 복원할 대상을 선택하셔야 합니다.');
                    return;
                }

                //숫자배열을 문자 배열로 변경하고 ","를 넣어서 하나의 문자열로 만들어 낸다.
                this.api.update.CUST_NOS = this.ui.CUST_NOS.map(function (i) { return String(i) }).join(",");


                console.log(this.api.update.CMD);
                console.log(this.api.update.CUST_NOS);
                
                UserService.trash_update(
                    this.api.update,
                    (res) => {
                        if (res.data > 0) {
                            alert('연락처 복원에 성공했습니다.');
                            this.movePage(1);           
                        }
                        else {
                            alert('연락처 복원에 실패했습니다.');
                        }
                    }
                );
            },
            movePage(page) {
                // 
                // page이동.검색시 초기값
                //  
                //console.log('movePage')
                this.page.val = page
                this.getList();
            },            
        },
        mounted() {
            this.init();
        },
    }
</script>
