<template>
    <div class="layer adminSystem" style="top:0;left:0; width:500px;">
        <div class="layerTitle">나의 신청결과 확인</div>

        <div class="layerMain">

            <table class="tableList">
                <tbody>
                    <tr>
                        <th>일시</th>
                        <th>충전할분</th>
                        <th>입금자명</th>
                        <th>금액</th>
                        <th>결과</th>
                    </tr>
                    <tr v-if="!list.length">
                        <td colspan="5">신청 내역이 없습니다.</td>
                    </tr>
                    <tr v-for="r in list" :class="{'grayTr': r.COIN_STAT_CD != '1'}">
                        <td width="20%">{{r.REG_DT}}</td>
                        <td>{{r.IS_DIFF ? r.CHAR_NM : r.DEPO_NM}}<br>{{r.IS_DIFF ? r.CHAR_HDPH_NO : r.DEPO_HDPH_NO}}</td>
                        <td>{{r.DEPO_NM}}<br>{{r.DEPO_HDPH_NO}}</td>
                        <td>{{r.DEPO_AMT}}</td>
                        <td>
                            <button class="whiteBtn" @click="edit(r)" v-if="r.COIN_STAT_CD == '1'">대기</button>
                            <span v-if="r.COIN_STAT_CD != '1'">{{r.COIN_STAT_CDNM}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>
                ※ 신청 내역을 클릭하시면, 수정하실 수 있습니다. (완료건은 수정불가)<br>
                ※ 신청 내역은 최근 10건까지만 확인하실 수 있습니다.
            </p>

        </div>
        <div class="layerBtnArea">
            <button class="grayBtn" onclick="window.close()">닫기</button>
        </div>
    </div>
</template>

<script setup>
    import { ref, toRefs, reactive, onMounted, computed, inject, nextTick } from 'vue'
    const utils = inject('utils')

    import PayService from '@/service/PayService'
    import store from '@/store'

    import JSDPageCtrl from '@/components/PageCtrl.vue'

    const arr = reactive({
        list: [],
    })

    const { list } = toRefs(arr)

    function getList() {
        PayService.reqList((res) => {
            if (res.data.length > 0) {
                arr.list = res.data[0]
            }
        })
    }

    function edit(r) {
        sessionStorage.setItem('REQ_DATA', JSON.stringify(r))
        window.open('/app/pay/req', 'CoinChargeReq', 'toolbar=no, width=423,height=391,scrollbars=no,resizable=no')
    }

    onMounted(() => {
        window.resizeTo(window.outerWidth, 700)
        getList()
    })
</script>