import api from '@/utils/api'
import { AxiosResponse } from 'axios';

export default class UmsService {

    //UMS/템플릿 발송전 미리보기
    public static ums_send_preview(page: number, data: any, callback: (result: any) => void): void {        
        api.post(`api/ums/send/preview/${page}`, data).then((r) => callback(r))
    }

    //폰발송가능 건수 확인
    public static ums_send_info(callback: (result: any) => void): void {
        const url: string = `api/app/ums/send/info`;
        api.get(`${url}`).then((r) => callback(r))
    }

    //폰발송가능 건수 확인
    public static ums_smt_stat(data: any, callback: (result: any) => void): void {
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`api/app/ums/smt/stat?${query.join('&')}`).then((r) => callback(r))
    }

    //UMS발송이력조회-WEB용
    public static ums_web_send_list(page: number, data: any, callback: (result: any) => void): void {
        api.post(`api/app/ums/send/web/list/${page}`, data).then((r) => callback(r))
    }

    //UMS발송상세이력조회-WEB용
    public static ums_web_detail_list(page: number, data: any, callback: (result: any) => void): void {
        api.post(`api/app/ums/send/web/detail/${page}`, data).then((r) => callback(r))
    }

    //ums실패내역 삭제
    public static ums_delete(callback: (result: any) => void): void {
        api.get(`api/app/ums/send/web/delete`).then((r) => callback(r))
    }


    //ums예약내역조회
    public static ums_resv_list(page: number, data: any, callback: (result: any) => void): void {
        api.post(`api/app/ums/resv/${page}`, data).then((r) => callback(r))
    }

    //ums예약내역 상세 조회
    public static ums_resv_detail_list(page: number, data: any, callback: (result: any) => void): void {
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`api/app/ums/resv/detail/${page}?${query.join('&')}`).then((r) => callback(r))
    }

    //ums예약내역삭제
    public static ums_resv_delete(data: any, callback: (result: any) => void): void {
        api.post(`api/app/ums/resv/delete`, data).then((r) => callback(r))
    }

    //ums예약내역일괄삭제
    public static ums_resv_bulk_delete(data: any, callback: (result: any) => void): void {
        api.post(`api/app/ums/resv/bulk/del`, data).then((r) => callback(r))
    }

    public static umsContCate(callback: (result: any) => void): void {
        api.get('/api/app/ums/cont/web/cate').then((r) => callback(r))
    }

    public static umsContList(page: number, data: any, callback: (result: any) => void): void {
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`api/app/ums/cont/web/${page}?${query.join('&')}`).then((r) => { callback(r) })
    }

    public static umsSend(data: any, callback: (result: any) => void): void {
        api.post('api/app/ums/send', data).then((r) => callback(r))
    }

    // 리턴형이 Promise인 문자발송
    public static umsSend2(data: any): Promise<AxiosResponse<any>> {
        return api.post('api/app/ums/send', data)
    }

    //ums다시선택
    public static ums_resend(data: any, callback: (result: any) => void): void {
        api.post(`api/app/ums/resend`, data).then((r) => callback(r))
    }

    public static umsBookmark(data: any, callback: (result: any) => void): void {
        api.post('api/app/ums/sent/bookmark', data).then((r) => callback(r))
    }

    public static umsRejectList(callback: (result: any) => void): void {
        api.get(`api/ums/reject/list`).then((r) => { callback(r) })
    }
    public static umsRecentList(callback: (result: any) => void): void {
        api.get(`api/ums/recent/list`).then((r) => { callback(r) })
    }
    public static umsRecentDetail(data: any, callback: (result: any) => void): void {
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`api/ums/recent/detail?${query.join('&')}`).then((r) => { callback(r) })
    }


}