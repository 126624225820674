import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "preViewLayer" }
const _hoisted_2 = { class: "preViewCut" }
const _hoisted_3 = { class: "bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "preViewTextBox" }
const _hoisted_6 = {
  key: 0,
  class: "bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InfiniteScroll, {
      onInfiniteScroll: $options.getCustList,
      loading: $data.isLoading
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.mesgList, (r, index) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(index + 1) + " . " + _toDisplayString(r.CUST_NM), 1),
              (r.CUST_NO > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(r.CUST_AGE > 0 ? r.CUST_AGE +'/' : '') + _toDisplayString(r.IS_SEX ? '남' : '여') + ") ", 1))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(r.PHONE_CNO), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              ((r.TITLE || '').length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(r.TITLE), 1))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(r.SEND_MESG), 1)
            ])
          ]))
        }), 256))
      ]),
      _: 1
    }, 8, ["onInfiniteScroll", "loading"])
  ]))
}