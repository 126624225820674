
    import store from '@/store'

    import CommomService from '@/service/CommomService'    
    import MemoLeftMenu from '@/types/memo_leftmenu'
    import MemoService from '@/service/MemoService'
    import JSDPageCtrl from '@/components/PageCtrl.vue';
    import JSDCustSelect from '@/components/CustSelect.vue'
    import GroupCustSelect from '@/components/GroupCustSelect.vue'

    import utils from '@/helpers/utils'                         //공용으로 사용될 js함수를 import

    var _ = require('lodash')

    export default {
        name: 'memoList',
        components: {
            JSDPageCtrl,
            JSDCustSelect,
            GroupCustSelect,
        },
        data() {
            return {
                ui: {
                    edit_layer: false,      //메모 등록/수정 레이어
                    change_layer: false,   //메모 tag변경 정보알림 레이어
                    del_layer: false,      //메모삭제 알림 레이어
                    alert_layer: false,    //메모 알람 레이아
                    alert_del_layer: false,  //메모 알람 삭제 레이어
                    tag_layer: false,         //태그변경 팝업 레이어
                    tag_bulk_nos: [],            //선택한 tag
                    is_check_all: false,       //전체 선택여부
                    memo_nos: [],          //선택한 메모값
                    memo_check_cnt: 0,      //선택한 메모 건수
                    tag_nms: '',           //선택한 메모 tag명
                    memo_cust_json: [],           //선택한 고객 명 - 메모 수정시
                    memo_tag_json: [],           //선택한 메모태그명 - 메모 수정시
                    memo_tag_layer: false,         //메모별 태그변경 팝업 레이어
                    not_found_layer: false,        //고객 검색없을 경우
                    cust_search_layer: false,      //매칭고객 찾았을때
                    tag_nm: '',                     // 신규등록하는 tag명
                    tag_nos: [],                //메모개별건의 tag선택정보 
                    call_da: '',                //알림설정일자
                    call_HH: '',                //알림설정시간
                    call_mm: '',               //알림설정분
                    selectList: [],    //GroupCustSelect에 넘기는 이미 선택한 연락처 리스트

                },
                api: {
                    memo: {         //메모 등록/수정시 api
                        MEMO_NO : -1,       //메모번호 신규등록 -1, 그외는 수정에 필요한 메모 고유번호
                        CMD: '',            //I:등록 U:수정  D:삭제
                        MEMO_DESC: '',      //메모내용
                        IS_CALL: false,     //알림여부
                        IS_IMPO: false,     //중요메모여부
                        CALL_DA: '',        //알림일자
                        CALL_TM: '',        //알림시간
                        MEMO_TAGS: '',      //메모 태그 ","로 연결돰
                        CUST_NOS: '',       //고객  ","로 연결돰
                    },
                    list : {
                        SEARCH_KEY: '', //검색key
                        ORD_NO: 1,      //정렬순서
                        TAG_NO: 0,      //tag필터링
                        PAGE: 1,                //현재 조회하는 page
                        CNT_PAGE: 17,           //조회페이지당 건수
                    },
                    bulk_update: {      //일괄삭제나 tag일괄변경에 사용한다.
                        CMD: '',        //M:TAG일괄변경 D:삭제
                        MEMO_NOS: '',   //memo번호
                        MEMO_TAGS : '', //변경할 tag
                    },
                    tag_update: {
                        CMD: '',            //I:등록 U:수정 D:삭제                        
                        TAG_NO: 0,
                        TAG_NM: '',
                        DEL_TYPE: 1,
                    },
                    page_cnt: [10, 30, 50, 70, 100, 150, 300],        //페이징처리 건수배열
                },
                arr: {
                    memo_list: [],      //메모 리스트
                    tag_list: [],       //메모 tag리스트

                },
                page: {
                    val: 1,
                    maxCnt: 1
                },
                searchKey: '',
            }
        },
        computed: {
        },
        methods: {
            init() {
                this.$setSideTitle('메모');
                this.$setTitle('메모');
                this.$setMainBtn();

                //this.$setMainBtn('+ 메모 작성');
                this.$setMainBtnCallback(() => {
                    this.$nextTick(() => {
                        //this.is_edit = false;
                        //this.$refs.JSDCustEdit.cust_add();
                    });
                });

                //좌측메뉴 제목                
                //this.$setSideTitle('메모 목록');

                //좌측 메뉴 구성
                this.$setSideBtns(MemoLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                //좌측 tag 클릭시 처리(리스트 리로드)
                this.$setTreeDataCallback((r) => {
                    this.$setTreeSelect(r.value)
                    this.api.list.TAG_NO = r.value;

                    console.log('click');
                    console.log(this.api.list);

                    this.movePage(1);
                });

                this.$nextTick(() => {

                    if (sessionStorage.hasOwnProperty('custview_data')) {
                        let data = JSON.parse(sessionStorage.getItem('custview_data'))

                        //console.log('init() -> $nextTick');
                        //console.log(data);

                        //조회key에 고객이름을 넣고 검색하게 한다.
                        this.api.list.SEARCH_KEY = data.CUST_NM;
                        this.movePage(1)

                        // 메모 등록 모드일때 메모 등록창을 띄우고 선택된 연락처가 선택된 상태로 만든다.
                        if (data.CMD === 'ADD') {                           

                            this.memo_add_init()        //메모 등록 레이어 띄우고

                            this.ui.memo_cust_json = [];    //연락처 선택상태로 만든다.
                            this.ui.memo_cust_json.push({
                                CUST_NO: Number(data.CUST_NO),
                                PHONE_CNO: data.PHONE_CNO,
                                CUST_NM: String(data.CUST_NM),
                            });
                        }
                        
                        sessionStorage.removeItem('custview_data');
                    }
                    else {
                        this.getList();
                    }                    

                    MemoService.memo_web_tag_list(
                        1,      //1:좌측 레이어용 2:메모용 TAG리스트 용 
                        1,      //@VIEW_TYPE=2일때 1:직접정렬값으로 2:태그명 오름차순 3:태그명 내림차순
                        (res) => {
                            let tag_list = res.data[0];
                            let tree_group_list = [];

                            for (var i = 0; i < tag_list.length; i++) {
                                //좌측 트리구조...그룹리스트
                                // title : 메모tag명
                                // useCnt : 카운트 표시여부
                                // cnt : tag별 메모 숫자
                                // 클릭시 전달할 값 (tag_no)
                                tree_group_list.push({
                                    'title': tag_list[i].TAG_NM,
                                    'useCnt': true,
                                    'cnt': tag_list[i].MEMO_CNT,
                                    'value': tag_list[i].TAG_NO,
                                });                                
                            }
                            console.log(tree_group_list);
                            this.$setTreeData(tree_group_list);
                        }
                    );
                });

                //메모 tag를 select
                MemoService.memo_web_tag_list(
                    2,      //1:좌측 레이어용 2:메모용 TAG리스트 용 
                    1,      //@VIEW_TYPE=2일때 1:직접정렬값으로 2:태그명 오름차순 3:태그명 내림차순
                    (res) => {
                        this.arr.tag_list = res.data[0];
                    }
                );                


                this.memo_new_inti();
            },
            getList() {
                //console.log('getList');
                
                MemoService.memo_web_list(
                    this.page.val,
                    {
                        ORD_NO: this.api.list.ORD_NO,
                        TAG_NO: this.api.list.TAG_NO,
                        SEARCH_KEY: this.api.list.SEARCH_KEY,
                        CNT_PAGE: this.api.list.CNT_PAGE,
                    },
                    (res) => {
                        this.arr.memo_list = res.data[0];
                        this.page.maxCnt = this.arr.memo_list.length > 0 ? this.arr.memo_list[0].MAX_CNT : 1
                        //console.log(this.arr.memo_list);

                        //화면 처리를 위한  요소 추가
                        for (var i = 0; i < this.arr.memo_list.length; i++) {
                            this.arr.memo_list[i].IS_CHECK = false
                        }
                        //console.log(this.arr.memo_list);
                        this.$nextTick(() => {
                            this.$refs.pageCtrl.set();
                        })
                    });
            },
            memo_new_inti() {
                //메모 초기화 : 메모 신규 등록이나 수정시 이전에 사용하던 내용을 레이어창에서 비울때 사용한다.

                this.api.memo.MEMO_DESC = ''//메모내용
                this.api.memo.IS_CALL = false       //알림여부
                this.api.memo.IS_IMPO = false       //중요메모여부
                this.api.memo.CALL_DA = ''          //알림일자
                this.api.memo.CALL_TM = ''         //알림시간
                this.api.memo.MEMO_TAGS = ''        //메모 태그
                this.api.memo.CUST_NOS = ''        //고객

                //화면 제어용 고객과 태그선택을 초기화
                this.ui.memo_cust_json = []           //선택한 고객 명 - 메모 수정시
                this.ui.memo_tag_json = []           //선택한 메모태그명 - 메모 수정시
            },
            group_select() {
                //
                // 그룹으로 연락처 선택 레이어 띄우기                
                // 
                const selectList = []

                // 화면에 선택한 명단을 가져다가 그룹선택 컴포넌트에 기 선택된 리스트를 넘김...
                // 기선택 연락처는 체크된 상태로 뜨게 됨
                for (var i = 0; i < this.ui.memo_cust_json.length; i++) {
                    selectList.push(this.ui.memo_cust_json[i].CUST_NO)
                }
                //그룹선택 컴포넌트 실행
                this.$refs.GroupCustSelect.view(selectList);
            },
            setCustSelect(value) {
                // 중복방지 배열
                const custList = []
                
                for (var i = 0; i < this.ui.memo_cust_json.length; i++) {
                    custList.push(this.ui.memo_cust_json[i].CUST_NO)                    
                }
                // Set을 루프내에서 매번 생성하면 부하가 큼
                // set을 사용해서 중복된 cust_no를 자동 필터링한다.
                const custSet = new Set(custList)

                value.forEach((r) => {
                    if ( !custSet.has(r.CUST_NO) ) {
                        this.ui.memo_cust_json.push({
                            CUST_NO: r.CUST_NO,
                            PHONE_CNO: r.PHONE_CNO,
                            CUST_NM: r.CUST_NM
                        })
                    }
                })

                this.ui.memo_cust_json.sort(utils.compare_CustName)
            },
            tag_bulk_view() {
                // 일괄변경 tag설정 변경 /선택한 tag가 있는 경우만 진행
                if (this.arr.memo_list.findIndex(e => e.IS_CHECK == true) < 0) {
                    alert('태그를 변경할 메모를 선택해 주세요!');
                    return
                }

                if (this.ui.tag_layer) {
                    this.ui.tag_layer = false;
                }
                else {
                    this.ui.tag_layer = true;
                    //this.ui.tag_bulk_nos = []
                }
            },
            tag_check_view() {
                //tag를 선택했는지 체크,
                if (this.ui.tag_bulk_nos.length <= 0) {
                    alert('변경할 메모 태그를 선택해 주세요!');
                    return
                }
                this.ui.tag_layer = false;
                this.ui.change_layer = true;

                this.ui.memo_check_cnt = this.arr.memo_list.filter(e => e.IS_CHECK == true).length
                this.ui.tag_nms = ''

                for (var i = 0; i < this.ui.tag_bulk_nos.length; i++) {
                    //console.log(this.ui.tag_bulk_nos[i]);
                    this.ui.tag_nms = this.ui.tag_nms + '#' + this.arr.tag_list.filter(e => e.TAG_NO == this.ui.tag_bulk_nos[i])[0].TAG_NM + ' '
                }
            },
            tag_bulk_update() {
                //tag일괄변경
                //filter된 object에서 memo_no만 따로 구한다.               

                //console.log('tag_bulk_update()');
                var memo_nos = []
                this.arr.memo_list.filter(e => e.IS_CHECK == true).forEach((e) => {
                    memo_nos.push(e.MEMO_NO)
                })

                this.api.bulk_update.CMD = 'M';
                this.api.bulk_update.MEMO_NOS = memo_nos.join(',')
                this.api.bulk_update.MEMO_TAGS = this.ui.tag_bulk_nos.join(',')
                console.log(this.api.bulk_update);

                MemoService.memo_web_bulk_update(
                    this.api.bulk_update,
                    (res) => {
                        if (res.data > 0) {
                            alert('메모 태그 변경이 완료되었습니다.');
                            this.getList();
                        }
                        else {
                            alert('태그 변경에 실패했습니다.');
                        }
                        this.ui.change_layer = false;
                    }
                );
            },
            tag_add() {
                //
                // 새로운 메모 tag 추가
                //
                //console.log(this.ui.tag_nm)
                if (this.ui.tag_nm.koLen() < 4) {
                    alert('새 메모 태그는 2글자(2byte)이상을 입력해 주세요.');
                    return;
                }

                //같은 tag명이 있으면 체크
                let idx = this.arr.tag_list.findIndex(x => x.TAG_NM == this.ui.tag_nm)
                if (idx >= 0) {
                    alert('동일한 메모 태그는 등록하실수 없습니다.');
                    return
                }

                //api에 값추가
                this.api.update.CMD = 'I';
                this.api.update.TAG_NM = this.ui.tag_nm;
                this.api.update.TAG_NO = 0;

                //console.log(this.api.update);

                MemoService.memo_tag_update(
                    this.api.update,
                    (res) => {
                        if (res.data > 0) {
                            alert('새 메모 태그 추가가 완료되었습니다.');                            
                            this.ui.tag_nm = ''

                            //메모 tag를 select
                            MemoService.memo_web_tag_list(
                                2,      //1:좌측 레이어용 2:메모용 TAG리스트 용 
                                1,      //@VIEW_TYPE=2일때 1:직접정렬값으로 2:태그명 오름차순 3:태그명 내림차순
                                (res) => {
                                    this.arr.tag_list = res.data[0];
                                }
                            );
                        }
                        else {
                            alert('새 메모 태그 추가에 실패했습니다.');
                        }
                    }
                );
            },
            check_all_memo() {
                //메모 전체 check on/off처리
                this.ui.is_check_all = !this.ui.is_check_all

                for (var i = 0; i < this.arr.memo_list.length; i++) {
                    this.arr.memo_list[i].IS_CHECK = this.ui.is_check_all
                }
            },
            memo_state_update(type, obj) {
                // 메모 상태 변경에 따른 처리
                // 중요여부와 알림관련 처리
                // type : impo(중요메모여부), call(알림설정여부)

                //console.log('memo_state_update');
                //console.log(type);
                //console.log(this.api.memo.IS_CALL);

                if (type == 'impo') {
                    this.api.memo.IS_IMPO = !this.api.memo.IS_IMPO;
                }
                else if (type == 'call') {                   
                    
                    if (this.api.memo.IS_CALL) {
                        // 알림이 켜진상태에서 클릭했으면 알림을 변경하는 것임으로 알림 삭제레이어가 뜸
                        this.ui.alert_del_layer = true;
                        this.ui.alert_layer = false;
                    }
                    else {
                        // 알림이 꺼진상탸에서 클릭했으면 알림을 켜는 것임으로 알림 설정 레이어아 뜸
                        this.ui.alert_del_layer = false;
                        this.ui.alert_layer = true;                        

                        //수정화면에서 호출한거면 기존에 등록한 값을 설정하고 등록화면이면 지금 시간 설정
                        if (obj === undefined || obj === null) {
                            this.ui.call_da = new Date().toStr('yyyy-MM-dd');
                            this.ui.call_HH = new Date().toStr('HH');

                            let min = new Date().getMinutes()
                            min = ((min % 10 == 0) ? (min / 10) + 1 : Math.ceil(min / 10)) * 10
                            console.log(min);

                            if (min == 60) {
                                this.ui.call_HH = new Date().addHour(1).toStr('HH');
                                min = 0;
                            }
                            this.ui.call_mm = min.padLeft(2, '0');
                        }
                        else {
                            let tmpData = `${obj.CALL_DA} ${obj.CALL_TM}`.toDate('yyyyMMdd HH:mm')

                            this.ui.call_da = tmpData.toStr('yyyy-MM-dd');
                            this.ui.call_HH = tmpData.toStr('HH');
                            this.ui.call_mm = tmpData.toStr('mm');                            
                        }
                    }
                    
                }
            },  // 메모 상태 변경에 따른 처리
            memo_alert_save() {
                // 메모 알림 저장
                this.api.memo.IS_CALL = true;
                this.api.memo.CALL_DA = this.ui.call_da

                let tm: string = this.ui.call_HH + ':' + this.ui.call_mm;                
                this.api.memo.CALL_TM = tm;

                //console.log(this.api.memo.CALL_DA);
                //console.log(this.api.memo.CALL_TM);

                let org_dt: string = new Date().toStr('yyyy-MM-dd HH:mm');
                //console.log(org_dt);

                //메모 알림 설정가능한지 확인
                if (org_dt >= this.api.memo.CALL_DA + ' ' + this.api.memo.CALL_TM) {
                    this.api.memo.CALL_DA = '';
                    this.api.memo.CALL_TM = '';
                    alert('현재시간 이후만 메모 알림 설정이 가능합니다.');
                    return 
                }
                this.ui.alert_layer = false;
            },
            memo_alert_delete() {
                // 메모에 설정된 알람을 삭제한다.
                this.api.memo.CALL_DA = '';
                this.api.memo.CALL_TM = '';
                this.api.memo.IS_CALL = false;
                this.ui.alert_del_layer = false;
                this.ui.alert_layer = false;
            },
            memo_alert_edit() {
                // 메모에 설정된 알람을 수정한다.
                let tmpData = `${this.api.memo.CALL_DA} ${this.api.memo.CALL_TM}`.toDate('yyyy-MM-dd HH:mm')

                this.ui.call_da = tmpData.toStr('yyyy-MM-dd');
                this.ui.call_HH = tmpData.toStr('HH');
                this.ui.call_mm = tmpData.toStr('mm');

                this.ui.alert_del_layer = false;
                this.ui.alert_layer = true;
            },
            memo_delete_view() {
                //메모 삭제..선택한 메모가 있는지 체크하고 삭제를 진행한다. alert창을 띄운다
                if (this.arr.memo_list.findIndex(e => e.IS_CHECK == true) < 0) {
                    alert('삭제할 메모를 선택해 주세요!');
                    return
                }
                this.ui.del_layer = true;
            },
            memo_delete() {
                //filter된 object에서 memo_no만 따로 구한다.
                var memo_nos = []
                this.arr.memo_list.filter(e => e.IS_CHECK == true).forEach((e) => {
                    memo_nos.push(e.MEMO_NO)
                })

                this.api.bulk_update.CMD = 'D';
                this.api.bulk_update.MEMO_NOS = memo_nos.join(',')
                this.api.bulk_update.MEMO_TAGS = ''
                //console.log(this.api.bulk_update);

                MemoService.memo_web_bulk_update(
                    this.api.bulk_update,
                    (res) => {
                        if (res.data > 0) {
                            alert('메모 삭제가 완료되었습니다.');
                            this.getList();
                        }
                        else {
                            alert('메모 삭제에 실패했습니다.');
                        }
                        this.ui.del_layer = false;
                    }
                );
            },
            memo_add_init() {
                // 메모 신규 등록에 필요한 초기화 처리
                this.memo_new_inti()    //기존 화면에 있던 내용을 clear

                this.ui.edit_layer = true;
                this.api.memo.CMD = 'I'     //신규메모 등록 모드
                this.api.memo.MEMO_NO = -1; //신규메모 등록 모드
                this.api.memo.MEMO_DESC = '';
            },
            memo_edit(obj) {                
                // 기존 메모 수정  
                this.memo_new_inti();       //기존 화면에 있던 내용을 clear
                this.api.memo.MEMO_DESC = obj.MEMO_DESC//메모내용
                this.api.memo.IS_CALL = obj.IS_CALL     //알림여부
                this.api.memo.IS_IMPO = obj.IS_IMPO     //중요메모여부
                this.api.memo.CMD = 'U'     //신규메모 등록 모드
                this.api.memo.MEMO_NO = obj.MEMO_NO
                this.api.memo.CALL_DA = obj.CALL_DA
                this.api.memo.CALL_TM = obj.CALL_TM

                this.ui.memo_cust_json = JSON.parse(obj.CUST_JSON)
                this.ui.memo_tag_json = JSON.parse(obj.MEMO_TAGS_JSON)

                //console.log('memo_edit');
                //console.log(this.ui.memo_cust_json);
                //console.log(this.ui.memo_tag_json);

                for (var i = 0; i < this.ui.memo_tag_json.length; i++) {
                    this.ui.tag_nos.push(this.ui.memo_tag_json[i].TAG_NO)
                }
                this.ui.edit_layer = true;                
            },
            memo_update_off() {
                // 메모 등록/수정 레이어 닫기.
                // 열려있는 많은 레이어들을 닫는다. -메모 개별건에 관련된 레이어들

                this.ui.alert_layer = false     //메모 알람 레이아
                this.ui.alert_del_layer = false //메모 알람 삭제 레이어
                this.ui.tag_layer = false       //태그변경 팝업 레이어
                this.ui.edit_layer = false;     //메인 레이어 닫기
            },
            memo_update() {
                //메모 등록/수정을 같이 처리한다.
                if (this.api.memo.MEMO_DESC.koLen() <= 4 ) {
                    alert('메모 내용을 입력해 주세요.')
                    return
                }

                let s_mesg: string = '메모 ' + ((this.api.memo.CMD == 'I') ? '등록' : '수정') + '되었습니다.';
                let f_mesg: string = '메모 ' + ((this.api.memo.CMD == 'I') ? '등록' : '수정') + '이 실패했습니다.';

                // 메모거 등록일때 
                if (this.api.memo.CMD == 'I') {
                    this.api.memo.MEMO_NO = -1;
                }

                this.api.memo.MEMO_TAGS = ''
                this.api.memo.MEMO_TAGS = this.ui.tag_nos.join(',')

                var cust_nos = []
                for (var i = 0; i < this.ui.memo_cust_json.length; i++) {
                    cust_nos.push(this.ui.memo_cust_json[i].CUST_NO)
                }
                this.api.memo.CUST_NOS = ''
                this.api.memo.CUST_NOS = cust_nos.join(',')

                //console.log('memo_update');
                //console.log(this.api.memo);
                //return

                MemoService.memo_web_update(
                    this.api.memo,
                    (res) => {
                        if (res.data > 0) {
                            alert(s_mesg);
                            this.getList();
                            this.all_layer_off();
                        }
                        else {
                            alert(f_mesg);
                        }
                        this.ui.del_layer = false;
                    }
                );
            },
            memo_tag_update() {
                //개별 메모에 tag지정시 
                this.ui.memo_tag_layer = false;
                //console.log(this.ui.tag_nos.length);
                //console.log(this.arr.tag_list);

                this.ui.memo_tag_json = [];

                if (this.ui.tag_nos.length > 0 ) {
                    for (var i = 0; i < this.ui.tag_nos.length; i++) {
                        let idx = this.arr.tag_list.findIndex(e => e.TAG_NO == this.ui.tag_nos[i])
                        //console.log(idx);
                        //console.log(this.arr.tag_list[idx]);
                        if (idx >= 0) {
                            this.ui.memo_tag_json.push({
                                TAG_NO: this.arr.tag_list[idx].TAG_NO,
                                TAG_NM: this.arr.tag_list[idx].TAG_NM,
                            });
                        }                        
                    }
                    //console.log(this.ui.memo_tag_json);
                }                
            },  //개별 메모에 tag지정시
            memo_cust_delete(cust_no) {
                //메모 선택한 고객 삭제
                let index = this.ui.memo_cust_json.findIndex(e => e.CUST_NO == cust_no)
                if (index >= 0) {                    
                    this.ui.memo_cust_json.splice(index, 1)
                }
            },
            memo_tag_view() {
                //memo tag개별 설정되게
                this.ui.memo_tag_layer = true;
            },
            all_layer_off() {
                // 메모 등록성공이나 수정성공시 모든 레이어창을 닫는다
                this.ui.edit_layer = false      //메모 등록/수정 레이어
                this.ui.change_layer = false   //메모 tag변경 정보알림 레이어
                this.ui.del_layer =  false      //메모삭제 알림 레이어
                this.ui.alert_layer=  false    //메모 알람 레이아
                this.ui.alert_del_layer = false  //메모 알람 삭제 레이어
                this.ui.tag_layer=  false         //태그변경 팝업 레이어
            },
            custSearch() {
                //메모에 등록할 고객 개별 검색
                if (!isNaN(this.ui.searchKey)) {
                    this.ui.searchKey = this.ui.searchKey.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-")
                }
                this.$refs.JSDCustSelect.search();
                
            },  //메모에 등록할 고객 개별 검색
            setCustData(obj) {
                //고객 개별 검색결과를 메모 등록 레이어 화면에 반영
                //console.log('setCustData');
                //console.log(obj);
                if (obj.hasOwnProperty('PHONE_CNO')) {

                    let cust_no: number  = Number(obj['CUST_NO']);
                    let idx: number = this.ui.memo_cust_json.findIndex(e => e.CUST_NO == cust_no)

                    //console.log('setCustData 추가로직');
                    //console.log(this.ui.memo_cust_json);                    
                    //console.log(idx);

                    //없으면 추가
                    if (idx < 0) {
                        //console.log(this.arr.custList);
                        this.ui.memo_cust_json.push({
                            CUST_NO: Number(obj['CUST_NO']),
                            PHONE_CNO: cust_no,
                            CUST_NM: String(obj['CUST_NM']),
                        });
                        this.ui.memo_cust_json.sort(utils.compare_CustName)
                    }
                    else {
                        alert('이미 해당 메모의 연락처 리스트에 추가되어 있습니다.')
                    }
                    this.ui.searchKey = ''
                }
            },  //고객 개별 검색결과를 메모 등록 레이어 화면에 반영

            movePage(page) {
                // 
                // page이동.검색시 초기값
                //                 
                this.page.val = page
                this.getList();
            },

        },
        mounted() {
            this.init();
        },
    }
