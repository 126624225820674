
    import store from '@/store'    
    import UserService from '@/service/UserService'
    import JSDCustView from '@/components/CustView.vue';
    import UserLeftMenu from '@/types/user_leftmenu'

    export default {
        name: 'DupCntaList',
        components: {
            JSDCustView
        },
        data() {
            return {
                //화면 표시 요소 정보
                ui: {
                    TOT_CNTA: 0,        //전체연락처 갯수
                    MERGE_CNTA_CNT: 0, //병합될 연락처 갯수

                    cust_layer: {
                        cust_no: 0,
                    },
                },
                api: {                    
                    list: {
                        //리스트 조회를 위한 정보
                    },
                    merge: {
                        CUST_DATA : '',
                    },
                },
                arr: {
                    dup_list: [],
                    merge_list: [],
                },
            }
        },
        computed: {
        },
        methods: {
            init() {
                //화면 mounted시 실행. 화면 초기화 실행
                this.$setTitle('중복 연락처 정리');

                //좌측 메뉴 구성
                this.$setSideBtns(UserLeftMenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                this.$nextTick(() => {
                    this.getList()
                });
            },
            getList() {                
                // 
                // 중복 연락처 정리 리스트 조회
                // 
                var phone_array = [];
                var tmp = [];

                UserService.dup_cnta_list(
                    (res) => {
                        //api에서 받은 object를 구한다.
                        this.arr.dup_list = res.data[0];				//휴지통리스트조회
                        // 리스트 초기화
                        this.arr.merge_list.splice(0, this.arr.merge_list.length)

                        //폰번호를 가지고 중복제거를 한다. 중복제거된 폰번호 배열
                        phone_array = [... new Set(this.arr.dup_list.map(x => x.PHONE_CNO))];

                        for (var i = 0; i < phone_array.length; i++) {

                            //해당번호와 동일한 object를 filter한다.
                            tmp = this.arr.dup_list.filter(x => x.PHONE_CNO === phone_array[i]);

                            // api에서 받은 row정보에 선택여부를 타나내는 항목(IS_RPRS)을 강제 추가한다.
                            for (var j = 0; j < tmp.length; j++) {
                                tmp[j].IS_RPRS = (j === 0 ? true : false );
                            }
                            //리스트용 object를 만든다.
                            this.arr.merge_list.push({ "PHONE_CNO": phone_array[i], "PHONE_CNT": tmp.length, "IS_CHECK":false,  "LIST": tmp });
                        }
                        this.$nextTick(() => {                            
                        })
                    }
                )
            },
            check_all( check_value : string) {
                // 전체 선택 / 해제 

                for (var i = 0; i < this.arr.merge_list.length; i++) {
                    //선택한 요소를 count한다.
                    this.arr.merge_list[i].IS_CHECK = (check_value === "on") ? true : false;                        
                }

                //전체 체크된 정보를 업데이트 
                this.info_check();
            },
            merge_proc() {
                //
                // 연락처 병합할 목록을 만든다.
                // 

                //merge대상 선택여부 확인
                const check_list = this.arr.merge_list.filter(x => x.IS_CHECK === true);                

                if (check_list.length === 0 ) {
                    alert('병합할 대상을 선택해 주세요.');
                    return;
                }

                //병합할 정보를 json형태의 string으로 만든다.
                //[{ "CUST_NO": 1, "ETC_CUST_NOS": [{ "ETC_NO": 2 }, { "ETC_NO": 3 }, { "ETC_NO": 4 }] }]
                // CUST_NO : 병합하는 메인고객번호
                // ETC_NO : 병합될 고객번호

                var cust_data = [];
                var main_cust_no = 0;
                var etc_cust = [];

                //console.log('check_list.length=' + check_list.length);
                for (var i = 0; i < check_list.length; i++) {

                    main_cust_no = 0;
                    etc_cust = [];

                    //console.log('check_list[i].LIST.length=' + check_list[i].LIST.length);

                    for (var j = 0; j < check_list[i].LIST.length; j++) {

                        if (check_list[i].LIST[j].IS_RPRS === false) {
                            etc_cust.push({ "ETC_NO": check_list[i].LIST[j].CUST_NO });
                        }
                        else {
                            main_cust_no = check_list[i].LIST[j].CUST_NO;
                        }
                    }
                    cust_data.push({ "CUST_NO": main_cust_no, "ETC_CUST_NOS": etc_cust})                   
                }

                this.api.merge.CUST_DATA = JSON.stringify(cust_data);
                console.log('this.api.merge.CUST_DATA=' + this.api.merge.CUST_DATA);
                
                UserService.dup_cnta_merge(
                    this.api.merge,
                    (res) => {
                        if (res.data > 0) {
                            alert('중복 연락처 합치는 작업이 완료되었습니다.');
                            this.init();
                            this.info_check();
                        }
                        else {
                            alert('중복 연락처 합치는 작업이 실패되었습니다.');
                        }
                    }
                );                
            },
            info_check() {
                //
                // merge할 대상 check시 정보내용 갱신 처리
                // 
                this.ui.TOT_CNTA = 0;      
                this.ui.MERGE_CNTA_CNT= 0;

                for (var i = 0; i < this.arr.merge_list.length; i++) {
                    //선택한 요소를 count한다.
                    if (this.arr.merge_list[i].IS_CHECK ===true ) {
                        this.ui.TOT_CNTA = this.ui.TOT_CNTA + this.arr.merge_list[i].PHONE_CNT;
                        this.ui.MERGE_CNTA_CNT = this.ui.MERGE_CNTA_CNT + 1;
                    }
                }
            },
            change_rprs(obj, cust_no) {
                //
                // 대표번호 변경처리
                //
                for (var i = 0; i < obj.LIST.length; i++) {
                    obj.LIST[i].IS_RPRS = (obj.LIST[i].CUST_NO === cust_no ? true:false);
                }
            },
            cust_layer_view(obj) {
                //
                // 연락처 상세 보기
                //
                this.ui.cust_layer.cust_no = obj["CUST_NO"];

                //console.log('-------------');
                //console.log(this.ui.cust_layer);
                // 동기화 ...
                this.$nextTick(() => {
                    this.$refs.JSDCustView.cust_view();
                });
            },
        },
        mounted() {
            this.init();
        },
    }
