

	import store from '@/store'
	import LeftMenu from '@/types/mypage_leftmenu'
	import UserService from '@/service/UserService'

    export default {
        name: 'MyInfo',
        components: {
        },
        data() {
            return {
				arr : {
					cust_info : [],		//신도 상세정보
				},
            }
        },
        methods: {
			init() {
				this.$setTitle('나의 정보');
				this.$setSideTitle('My 페이지');                

				//메뉴 리스트에서 가져온다.
				this.$setSideBtns(LeftMenu.menu_list);

				//좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        // 도움말 기능 처리
                    }
				});

				this.$nextTick(() => {
					this.getList();
				})
			},
			getList() {
				UserService.mypage_myinfo(
					(res) => {
						this.arr.cust_info = res.data[0][0];						
                        //console.log(this.arr.cust_info)
					}
				);
            }
        },
        mounted() {
			this.init();
        },
    }
