import api from '@/utils/api'
import { AxiosResponse } from 'axios'

export default class AuthService {
    public static getIp(callback: (result: any) => void): void {
        api.get('api/admin/auth/ip').then((r) => callback(r))
    }

    //신도코드로 로그인 : open후 미지원
    public static login(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/login', data).then((r) => callback(r))
    }

    //도무넷을 통한 로그인 시도
    public static login_jsd(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/login2', data).then((r) => callback(r))
    }

    //도무넷에서 신도정보 조회
    public static info2_jsd(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/info2', data).then((r) => callback(r))
    }

    //상생레터 회원가입
    public static user_insert(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/signup', data).then((r) => callback(r))
    }

    //관리자가 사용자계정 임시로그인
    public static tmpr_login(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/tmpr/login', data).then((r) => callback(r))
    }

    //로그인 이력남기기
    public static login_history(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/log_info', data).then((r) => callback(r))
    }

    //신도코드 유효한지 확인
    public static user_exists(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/user_exists', data).then((r) => callback(r))
    }    

    //도무넷을 통한 로그인하고 세션생성
    public static login_jsd_session(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/login_jsd', data).then((r) => callback(r))
    }   

    //이중로그인 방지를 위한 세션정보 등록
    public static session_add(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/sson/add', data).then((r) => callback(r))
    }

    //이중로그인 방지를 위한 세션정보 삭제-로그아웃시
    public static session_delete(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/sson/delete', data).then((r) => callback(r))
    }

    //이중로그인 방지를 위한 세션정보 존재여부 확인
    public static session_exists(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/sson/exists', data).then((r) => callback(r))
    }

    public static isAble(): Promise<AxiosResponse<any>> {
        return api.get('api/admin/push/able')
    }

    public static admin(data: object, callback: (result: any) => void): void {
        api.post('api/admin/auth/admin', data).then((r) => callback(r))
    }

    public static userInfo(): Promise<AxiosResponse<any>>{
        return api.post('api/admin/auth/info')
    }

    // sms인증문자 발송
    public static certSend(data: any, callback: (result: any) => void): void {
        api.post(`api/admin/auth/cert/send`, data).then((r) => callback(r))
    }
    // sms인증문자 확인
    public static certCheck(data: any, callback: (result: any) => void): void {
        api.post(`api/admin/auth/cert/check`, data).then((r) => callback(r))
    }
}