import api from '@/utils/api'

export default class PayService {

    //코인이력리스트 - 사용자 코인 이력 조회
    public static coin_list(page: number, data: any, callback: (result: any) => void): void {
        const url: string = `api/app/user/coin/${page}`;
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`${url}?${query.join('&')}`).then((r) => callback(r))
    }
    //코인 선물하기
    public static coin_cust_send(data: any, callback: (result: any) => void): void {
        api.post(`api/app/user/coin/pres`, data).then((r) => callback(r))
    }
    //코인선물하기 신도검색
    public static coin_cust_list(page: number, data: any, callback: (result: any) => void): void {
        const url: string = `api/app/user/coin/pres/${page}`;
        const query: any[] = []
        Object.keys(data).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(data, k)) {
                query.push(`${k}=${data[k]}`)
            }
        })
        api.get(`${url}?${query.join('&')}`).then((r) => callback(r))
    }
    //코인 update
    public static coin_update(data: any, callback: (result: any) => void): void {
        api.post(`api/app/user/coin/update`, data).then((r) => callback(r))
    }

    public static reqList(callback: (result: any) => void): void {
        api.get(`api/pay/charge`).then((r) => callback(r))
    }

    public static reqUpdate(data: object, callback: (result: any) => void): void {
        api.post(`api/pay/charge`, data).then((r) => callback(r))
    }
}