
    import store from '@/store'   
    
    import Memo_leftmenu from '@/types/memo_leftmenu'
    import MemoService from '@/service/MemoService'

    var _ = require('lodash')

    export default {
        name: 'memoTagList',
        components: {
            
        },
        data() {
            return {
                ui: {
                    del_layer: false,                    
                    is_all: false,
                    tag_nm: '',
                    ord_no: 1,
                    tag_nos:[],
                },
                api: {
                    update: {
                        CMD: '',            //I:등록 U:수정 D:삭제                        
                        TAG_NO: 0,
                        TAG_NM: '',
                        DEL_TYPE : 1,

                    },
                    delete: {
                        DEL_TYPE: 1,
                        TAG_NOS : '',
                    },
                },
                arr: {
                    tag_list: [],           //memo tag 리스트
                },                
            }
        },
        computed: {
        },
        methods: {
            init() {
                this.$setSideTitle('메모');
                this.$setTitle('메모 태그 편집');
                
                this.$setMainBtnCallback(() => {
                    this.$nextTick(() => {
                        //this.is_edit = false;
                        //this.$refs.JSDCustEdit.cust_add();
                    });
                });

                //좌측메뉴 제목                
                //this.$setSideTitle('메모 목록');

                //좌측 메뉴 구성
                this.$setSideBtns(Memo_leftmenu.menu_list);

                //좌측메뉴 클릭시 동작
                this.$setSideBtnsCallback((r) => {
                    if (r.name != '도움말') {
                        this.$movePage(r.path)
                    }
                    else {
                        this.$moveHelp('연락처')
                    }
                });

                //화면 초기화 처리
                this.$nextTick(() => {
                    this.getList();
                });
                this.ui.org_no = 1
            },
            getList() {
                MemoService.memo_web_tag_list(
                    2,
                    this.ui.org_no,
                    (res) => {
                        this.arr.tag_list = res.data[0];
                        //console.log(this.arr.tag_list);
                    });
            },
            tag_add() {
                //
                // 새로운 메모 tag 추가
                //
                //console.log(this.ui.tag_nm)
                if (this.ui.tag_nm.koLen() < 4) {
                    alert('새 메모 태그는 2글자(2byte)이상을 입력해 주세요.');
                    return;
                }

                //같은 tag명이 있으면 체크
                let idx = this.arr.tag_list.findIndex(x => x.TAG_NM == this.ui.tag_nm)
                if (idx >= 0) {
                    alert('동일한 메모 태그는 등록하실수 없습니다.');
                    return
                }

                //api에 값추가
                this.api.update.CMD = 'I';
                this.api.update.TAG_NM = this.ui.tag_nm;
                this.api.update.TAG_NO = 0;

                //console.log(this.api.update);

                MemoService.memo_tag_update(
                    this.api.update,
                    (res) => {
                        if (res.data > 0) {
                            alert('새 메모 태그 추가가 완료되었습니다.');
                            this.getList();
                            this.ui.tag_nm = ''
                        }
                        else {
                            alert('새 메모 태그 추가에 실패했습니다.');
                        }
                    }
                );
            },
            tag_del_layer(del_type, tag_no) {
                // tag 삭제 레이어 on 및 변수 초기화

                if (del_type == 'all') {
                    if (this.ui.tag_nos.length === 0) {
                        alert('삭제할 메모 태그가 선택되지 않았습니다.');
                        return;
                    }
                }
                else if (del_type == 'one') {
                    this.ui.tag_nos = []
                    this.ui.tag_nos.push(tag_no)
                }

                this.api.delete.DEL_TYPE = 1;
                this.ui.del_layer = true;
            },
            tag_del() {
                //tag 실제 삭제
                this.api.delete.TAG_NOS = this.ui.tag_nos.join(',');

                MemoService.memo_tag_delete(
                    this.api.delete,
                    (res) => {
                        if (res.data > 0) {
                            alert('메모 태그 삭제가 완료되었습니다.');
                            this.getList();
                            this.ui.tag_nm = ''
                            this.ui.del_layer = false
                        }
                        else {
                            alert('메모 태그 삭제에 실패했습니다.');
                        }
                    }
                );
            },  

            order_update(tag_no, cmd) {
                //
                // 메모tag 변경
                // cmd - up/down/top/bottom

                let cur_idx: number = -1;
                let max_idx: number = -1;
                let next_idx: number = -1;
                let tmp_obj: any = null;


                for (var i = 0; i < this.arr.tag_list.length; i++) {
                    if (this.arr.tag_list[i].TAG_NO == tag_no) {
                        cur_idx = i;
                        max_idx = this.arr.tag_list.length - 1;
                        break;
                    }
                }

                if (cmd === 'up') {
                    if (cur_idx === 0) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = cur_idx - 1;
                    tmp_obj = this.arr.tag_list[next_idx];
                    this.arr.tag_list[next_idx] = this.arr.tag_list[cur_idx];
                    this.arr.tag_list[cur_idx] = tmp_obj;

                }
                else if (cmd === 'top') {
                    if (cur_idx === 0) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = 0;
                    tmp_obj = this.arr.tag_list[cur_idx];
                    //console.log(cur_idx)

                    for (var i = cur_idx - 1; i >= 0; i--) {
                        //console.log(i)
                        this.arr.tag_list[i + 1] = this.arr.tag_list[i];
                    }
                    this.arr.tag_list[next_idx] = tmp_obj;
                }
                else if (cmd === 'down') {
                    if (cur_idx === max_idx) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    next_idx = cur_idx + 1;
                    tmp_obj = this.arr.tag_list[next_idx];
                    this.arr.tag_list[next_idx] = this.arr.tag_list[cur_idx];
                    this.arr.tag_list[cur_idx] = tmp_obj;
                }
                else if (cmd === 'bottom') {
                    if (cur_idx === max_idx) {
                        alert('더이상 순서변경을 할수 없습니다.');
                        return;
                    }
                    tmp_obj = this.arr.tag_list[cur_idx];
                    for (var i = cur_idx; i < max_idx; i++) {
                        this.arr.tag_list[i] = this.arr.tag_list[i + 1];
                    }
                    this.arr.tag_list[max_idx] = tmp_obj;
                }
                //console.log(cur_idx)
                //console.log(max_idx)
            },
            save_all() {
                //
                // 그룹수정화면 저장
                //  - 정렬순서 및 수정된 명칭 저장


                //동일한 그룹명이 있는지 체크
                for (var i = 0; i < this.arr.tag_list.length; i++) {
                    let tag_nm = this.arr.tag_list[i].TAG_NM

                    let idx = this.arr.tag_list.findIndex(x => x.TAG_NM == tag_nm)
                    if (idx >= 0 && idx != i) {
                        alert('동일한 그룹명을 등록하실수 없습니다.');
                        return
                    }
                }

                if (confirm('수정하신 정보들을 일괄 저장하시겠습니까?') === false) {
                    return;
                }

                let tag_info = [];
                let data: string = '';
                for (var i = 0; i < this.arr.tag_list.length; i++) {
                    tag_info.push({
                        "TAG_NO": this.arr.tag_list[i].TAG_NO,
                        "TAG_NM": this.arr.tag_list[i].TAG_NM,
                        "ORD_NO": i + 1
                    });
                }
                data = JSON.stringify(tag_info);
                //console.log(data);

                MemoService.memo_tag_bulk_update(
                    {
                        TAG_INFO: data
                    },
                    (res) => {
                        //console.log(res.data.RESULT);
                        //console.log(res.data.RESULT_MESG);
                        if (res.data.RESULT > 0) {
                            alert('메모 태그 일괄수정이 완료되었습니다.');
                            this.getList();
                        }
                        else {
                            alert('메모 태그 일괄수정이 실패했습니다.\n' + res.data.RESULT_MESG);
                        }
                    }
                );
            },
            check_all() {
                //
                // 전체 체크버튼 동작
                //
                this.ui.tag_nos = [];     //먼저 선택한 그룹들 초기화 하고

                if (this.ui.is_all === true) {
                    for (var i = 0; i < this.arr.tag_list.length; i++) {
                        this.ui.tag_nos.push(this.arr.tag_list[i].TAG_NO);
                    }
                }
            },

        },
        mounted() {
            this.init();
        },
    }
