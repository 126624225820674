
    import UserService from '@/service/UserService'
    import InfiniteScroll from '@/components/InfiniteScroll.vue'
    import store from '@/store'

    export default {
        name: 'JSDCustView',
        components: {
            InfiniteScroll,
        },
        props: {
            cust_no: Number,           //연락처 고객번호            
        },
        data() {
            return {
                isLoading: false,                
                maxPage: 1,
                is_view: false,          //레이어 띄울지 여부
                ui: {
                    is_memo_view: false,
                    is_memo_tag: false,
                    is_book_view: false,
                    view_type: 0,       //0:전체 1:문자전체 9:메모
                    ums_type: 1,        //1:문자전체 2:문자-발송완료 3:문자-예약

                },
                api: {
                    cnta_list: {
                        CUST_NO: this.cust_no,
                        VIEW_TYPE: 0,      //0:전체 1:문자전체 2:문자-발송완료 3:문자-예약 9:메모
                        MEMO_TAG_NOS: '',   //선택한 메모tag번호
                        STRT_DA: new Date().addMonth(-12).toStr('yyyyMMdd'),        //조회시작일 기본 1년
                        END_DA: new Date().toStr('yyyyMMdd'),                       //조회마감일
                        PAGE: 1,            //페이징
                        CNT_PAGE: 20,      //조회된건수
                    },
                    cnta_del: {
                        CUST_NOS :'',
                    }

                },
                cust_info: [],
                ums_list: [],                // 문자및 메모 리스트
                phone_arr: [],
                book_arr: [],                 //
                book_list: [],                //증산도 도서 전체목록
                memo_tag_list: [],              //메모 tag전체 리스트

                book_nos: [],               //선택한 도서 목록
                tag_nos: [],                //선택한 tag목록
                view_top: 100,
                view_left: 700,

            }
        },
        methods: {
            init() {                
                //this.$nextTick(() => {
                //    this.getCustInfo();
                //    // 도서정보 조회
                //    UserService.jsdbook_list((res) => { this.book_list = res.data[0]; });
                //    UserService.memo_tag_list(
                //        "1",
                //        (res) => {
                //            this.memo_tag_list = res.data[0];
                //        }
                //    );
                //    this.getMemoUmsList();
                //});
            },
            cust_view() {
                this.$nextTick(() => {
                    //화면 초기화에 필요한 변수값 설정
                    this.ui.view_type = 0;
                    this.ui.ums_type = 1;
                    this.ums_list = [];
                    this.api.cnta_list.PAGE = 1;

                    this.is_view = true;
                    this.getCustInfo();
                    // 도서정보 조회
                    UserService.jsdbook_list((res) => { this.book_list = res.data[0]; });

                    //memo tag관련 작업이 제외되어 주석처리함
                    //UserService.memo_tag_list(
                    //    "1",
                    //    (res) => {
                    //        this.memo_tag_list = res.data[0];
                    //    }
                    //);
                    this.getMemoUmsList();

                    //scroll되는 화면의 중앙에 위치하게 한다.
                    this.view_top = document.documentElement.scrollTop + 100;
                });
            },
            cust_layer_close() {
                this.$nextTick(() => {
                    //console.log(this.is_view)
                    this.is_view = false;
                });                
            },
            getCustInfo() {
                //
                // 연락처 정보 조회
                //
                UserService.cust_select(
                    this.cust_no,
                    (res) => {
                        this.cust_info = res.data[0][0];
                        //console.log(this.cust_info);

                        if (this.cust_info.CUST_PHONE_JSON.length > 0) {
                            this.phone_arr = JSON.parse(this.cust_info.CUST_PHONE_JSON)
                        }
                        if (this.cust_info.JSD_BOOK_JSON.length > 0) {
                            this.book_arr = JSON.parse(this.cust_info.JSD_BOOK_JSON)
                        }
                    }
                );

            },
            getMemoUmsList( data_type ) {
                //
                // 발송정보와 메모정보 조회
                //
                if (data_type == 'init') {
                    this.ums_list = [];
                    this.api.cnta_list.PAGE = 1;
                }

                if (this.ui.view_type == 1) {

                    //1:문자전체 2:문자-발송완료 3:문자-예약
                    this.api.cnta_list.VIEW_TYPE = this.ui.ums_type;
                }
                else {
                    this.api.cnta_list.VIEW_TYPE = this.ui.view_type;
                }

                if (!this.isLoading) {
                    this.isLoading = true

                    //최대 page까지 조회하게 하는지 체크
                    if (this.api.cnta_list.PAGE <= this.maxPage ) {
                        UserService.cust_memo_list(
                            this.cust_no,
                            this.api.cnta_list.PAGE,
                            this.api.cnta_list,
                            (res) => {
                                const list = res.data[0]

                                if (list.length) {
                                    this.maxPage = list.length > 0 ? (Math.ceil(list[0].MAX_CNT / this.api.cnta_list.CNT_PAGE)) : 1
                                    this.ums_list.push(...list)
                                    this.api.cnta_list.PAGE++
                                }                               
                            }
                        );
                    }
                    this.isLoading = false;
                }                
            },
            cust_book_save() {
                //
                // 도서 정보 등록
                //
                let jsd_book: string = this.book_nos.join(',');
                //console.log(jsd_book);
                UserService.jsdbook_update(
                    {
                        CUST_NO: this.cust_no,
                        JSD_BOOK: jsd_book
                    },
                    (res) => {
                        if (res.data > 0) {
                            alert('도서선물정보 수정에 성공했습니다.');
                            this.ui.is_book_view = false;
                            this.getCustInfo();             //수정된 정보를 update하고 갱신
                        }
                        else {
                            alert('도서선물정보 수정에 실패했습니다.');
                        }
                    }
                );
            },
            move_page( type : string, obj) {
                //
                // 상세 조회에서 해당 건 클릭시 연결 페이지로 이동하게 함. 페이지 넘길때 인자들을 object로 만들어 넘긴다.
                // 
                console.log(type);
                console.log(this.cust_info);
                let data = {
                    CUST_NM: this.cust_info.CUST_NM,
                    CUST_NO: this.cust_info.CUST_NO,
                    PHONE_CNO: '',
                    STRT_DA: new Date().addYear(-1).toStr('yyyyMMdd'),
                    END_DA: new Date().addYear(1).toStr('yyyyMMdd'),
                    CMD : '',
                }                

                //같은 메모일때 cmd를 구한다. 메모 신규 등록창과 메모 검색으로
                if (type === 'MEMO') {
                    data.CMD = 'LIST'
                }
                else if (type === 'MEMO_ADD') {
                    data.CMD = 'ADD'
                }

                //console.log(JSON.stringify(data));
                //페이지로 전달할 데이터를 string으로 변경
                sessionStorage.setItem('custview_data', JSON.stringify(data));

                if (type === 'MEMO') {
                    //메모                    
                    this.$movePage('/app/memo')
                }
                else if (type === 'MEMO_ADD') {
                    //메모                    
                    this.$movePage('/app/memo')
                }
                else if (type === 'TMPL') {
                    //문자 템플릿                    
                    this.$movePage('/app/tmpl/resv')
                }
                else if (type === 'RESV') {
                    //문자예약                    
                    this.$movePage('/app/ums/resvlist')
                }
                else if (type === 'SEND') {
                    //문자발송이력
                    this.$movePage('/app/ums/sendlist')
                }
            },
            layer_book() {
                //
                // 도서등록 레이어
                //
                this.ui.is_book_view = true;
                this.book_nos = [];

                //선물받은 도서를 체크상태로 만든다.
                for (var i = 0; i < this.book_arr.length; i++) {
                    this.book_nos.push(this.book_arr[i].BOOK_NO);
                }
            },
            layer_memo_tag() {
                //
                // 메모 tag선택
                //
                this.ui.is_memo_tag = true;
            },
            cust_del() {
                //
                // 연락처 삭제 -휴지통에 넣기
                //
                if (confirm('해당 연락처정보를 삭제하시겠습니까?') === false) {
                    return;
                }

                this.api.cnta_del.CUST_NOS = this.cust_no;

                UserService.cust_del(
                    this.api.cnta_del,                    
                    (res) => {
                        if (res.data > 0) {
                            alert('연락처 정보를 삭제했습니다.');
                            this.$nextTick(() => {                                
                                this.is_view = false;                                
                            });
                            this.$emit('page_reload');
                        }
                        else {
                            alert('연락처 정보 삭제에 실패 했습니다.');
                        }
                    }
                );

            },
            cust_edit() {
                this.$nextTick(() => {
                    this.is_view = false;
                });
                this.$emit('cust_edit');
            },
            send() {
                const custList = []
                const data = this.phone_arr.filter((r) => { r.IS_RPRS == 1 })

                if (data.length) {
                    custList.push({
                        CUST_NO: this.cust_info.CUST_NO,
                        CUST_NM: this.cust_info.CUST_NM,
                        PHONE_CNO: data[0].PHONE_CNO
                    })
                }
                else {
                    custList.push({
                        CUST_NO: this.cust_info.CUST_NO,
                        CUST_NM: this.cust_info.CUST_NM,
                        PHONE_CNO: this.phone_arr[0].PHONE_CNO
                    })
                }

                if (custList.length) {
                    sessionStorage.setItem('custList', JSON.stringify(custList))
                    this.$movePage('/app/ums')
                }
                else {
                    alert('발송 가능한 연락처 정보가 없습니다.')
                }
                
            },
        },
        mounted() {
            this.init();
        },
    }
