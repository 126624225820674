<template>

</template>

<script>
    import store from '@/store'

    export default {
        name: 'UserHome',
        computed: {

        },
        data() {

        },
        methods: {

        },
        mounted() {
            if (store.getters.getUser['ACNT_NO'] > -1 && this.$cookie.isCookieAvailable('JSD_USER')) {
                this.$root.movePage('/app/login')
            }
            else {
                this.$root.movePage(this.$homeUrl)
            }
        }
    }
</script>